import { useTaskCustomFieldsDetails } from "@jugl-web/domain-resources/tasks/hooks/useTaskCustomFieldsDetails";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { ListBoxItem } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useMemo } from "react";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as LabelIcon } from "./assets/label.svg";
import { ReactComponent as StatusIcon } from "./assets/status.svg";

export const useAlignColumnFieldItems = () => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { customFields } = useTaskFields({ entityId });
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();

  const alignColumnFieldItems = useMemo(
    (): ListBoxItem<{
      label: string;
      icon: React.ReactNode;
    }>[] => [
      {
        id: "due_at",
        value: {
          label: t({
            id: "tasks-page.due-date",
            defaultMessage: "Due date",
          }),
          icon: <CalendarIcon className="shrink-0" />,
        },
      },
      {
        id: "label",
        value: {
          label: t({
            id: "tasks-page.label",
            defaultMessage: "Label",
          }),
          icon: <LabelIcon className="shrink-0" />,
        },
      },
      {
        id: "status",
        value: {
          label: t({
            id: "tasks-page.status",
            defaultMessage: "Status",
          }),
          icon: <StatusIcon className="shrink-0" />,
        },
      },
      ...customFields
        .filter((el) => el.id !== "dropdown")
        .map((el) => ({
          id: el.id,
          value: {
            label: el.name,
            icon: React.createElement(
              customFieldTypeToDetails[el.type].primaryIcon,
              {
                style: {
                  flexShrink: 0,
                },
              }
            ),
          },
        })),
    ],
    [customFields, customFieldTypeToDetails, t]
  );

  return { alignColumnFieldItems };
};
