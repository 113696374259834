export const TABLE_DIMENSIONS = {
  TABLE_CELL_GAP: 2,

  TABLE_TITLE_ROW_HEIGHT: 50,
  TABLE_HEADINGS_ROW_HEIGHT: 46,
  TABLE_TASK_ROW_HEIGHT: 56,
  TABLE_TASK_NEW_ITEM_ROW_HEIGHT: 40,
  TABLE_TASK_SEPARATOR_ROW_HEIGHT: 48,

  TABLE_OUTERMOST_COLUMN_WIDTH: 56,

  TABLE_TASK_TITLE_COLUMN_WIDTH: 576,
  TABLE_DUE_DATE_COLUMN_WIDTH: 224,
  TABLE_ASSIGNEE_COLUMN_WIDTH: 220,
  TABLE_PROJECT_COLUMN_WIDTH: 220,
  TABLE_DATE_OF_ARCHIVING_COLUMN_WIDTH: 220,
  TABLE_LABEL_COLUMN_WIDTH: 220,
  TABLE_STATUS_COLUMN_WIDTH: 220,
  TABLE_CUSTOMER_COLUMN_WIDTH: 220,
};
