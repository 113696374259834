import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { AvatarStack } from "@jugl-web/ui-components/cross-platform/AvatarStack";
import { AvatarMember } from "@jugl-web/ui-components/cross-platform/CollectiveAvatar";
import { FC, useMemo } from "react";

interface BoardUsersAvatarStackProps {
  entityId: string;
  userIds: string[];
}

export const BoardUsersAvatarStack: FC<BoardUsersAvatarStackProps> = ({
  entityId,
  userIds,
}) => {
  const { profiles } = useMultipleUserProfiles({ entityId, userIds });

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      (profiles || []).map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || "",
      })),
    [profiles]
  );

  return (
    <AvatarStack members={profilesAsAvatarMembers} maxCount={5} size="sm" />
  );
};
