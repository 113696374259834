import {
  EntityModel,
  EntityParticipantRole,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import {
  usePaginationUpdate,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import useLiveConversationReceipts from "@web-src/features/chats/hooks/useLiveConversationReceipts";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEntity } from "../appSlice";

export const useSelectEntity = () => {
  const { toast } = useToast({ variant: "web" });
  const { t } = useTranslations();

  const { navigateToPage } = useNavigation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { entitiesApi } = useRestApiProvider();

  const { reset: resetLiveConversationReceipts } =
    useLiveConversationReceipts();

  const { resetAll: resetAllPagination } = usePaginationUpdate();

  const [markEntityAsRead] = entitiesApi.useMarkEntityAsReadMutation();

  return useCallback(
    (entity: EntityModel, showToast?: boolean, customInitialPage?: string) => {
      // Resets all state related to the previous entity (if any)
      resetLiveConversationReceipts?.();
      resetAllPagination();

      // Marks entity as read if it has any unread updates
      if (entity.unread) {
        markEntityAsRead({ entityId: entity.id });
      }

      // Sets the new entity in the store
      dispatch(setEntity(entity));

      if (customInitialPage) {
        navigate(customInitialPage);
      }
      // Navigates to the chats tab of the new entity
      else if (entity.role === EntityParticipantRole.employee) {
        navigateToPage(
          "tasksTasks",
          { boardId: "my" },
          { entityId: entity.id }
        );
      } else {
        navigateToPage("chatsChats", undefined, { entityId: entity.id });
      }

      if (showToast) {
        toast(
          <span>
            {t(
              {
                id: "feedback.switched-to-entity",
                defaultMessage: "Switched to {entityName}",
              },
              { entityName: <b>{entity.name}</b> }
            )}
          </span>
        );
      }
    },
    [
      resetLiveConversationReceipts,
      resetAllPagination,
      dispatch,
      markEntityAsRead,
      navigate,
      navigateToPage,
      toast,
      t,
    ]
  );
};
