import { cx, useResizeObserver } from "@jugl-web/utils";
import { FC, useCallback, useState } from "react";
import { CompleteChecklistInOrderField } from "./components/CompleteChecklistInOrderField";
import { SaveTaskAsTemplateField } from "./components/SaveTaskAsTemplateField";
import { TaskExtraPropertiesPanelConfig } from "./types";
import { PrivateTaskField } from "./components/PrivateTaskField";

export interface TaskExtraPropertiesPanelProps {
  entityId: string;
  config: TaskExtraPropertiesPanelConfig;
  className?: string;
}

export const TaskExtraPropertiesPanel: FC<TaskExtraPropertiesPanelProps> = ({
  entityId,
  config,
  className,
}) => {
  const [isContainerEmpty, setIsContainerEmpty] = useState(false);

  const { ref } = useResizeObserver({
    onResize: useCallback(
      (entry) => setIsContainerEmpty(entry.borderBoxSize[0].blockSize === 0),
      []
    ),
  });

  return (
    <div
      ref={ref}
      className={cx(
        "flex flex-wrap items-center gap-2.5",
        !isContainerEmpty && className
      )}
    >
      {config.privateTask && <PrivateTaskField {...config.privateTask} />}
      {config.saveTaskAsTemplate && (
        <SaveTaskAsTemplateField
          entityId={entityId}
          {...config.saveTaskAsTemplate}
        />
      )}
      {config.completeChecklistInOrder && (
        <CompleteChecklistInOrderField {...config.completeChecklistInOrder} />
      )}
    </div>
  );
};
