import {
  DatePicker,
  DatePickerHandle,
} from "@jugl-web/ui-components/cross-platform/DatePicker";
import { useAppVariant } from "@jugl-web/utils";
import { FC, useEffect, useRef } from "react";
import { Subject } from "rxjs";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";

export const calendarReset$ = new Subject<{ formId: string }>();

interface CalendarProps {
  onAfterSelect?: () => void;
}

export const Calendar: FC<CalendarProps> = ({ onAfterSelect }) => {
  const { formConfig, selectedDate, setSelectedDate } =
    useTaskDueDateFormContext();

  const { isMobile } = useAppVariant();

  const datePickerRef = useRef<DatePickerHandle | null>(null);

  const handleSubmit = (date: Date) => {
    setSelectedDate((previousDate) => {
      if (previousDate) {
        const updatedDate = new Date(date);

        updatedDate.setHours(
          previousDate.getHours(),
          previousDate.getMinutes()
        );

        return updatedDate;
      }

      return date;
    });

    onAfterSelect?.();
  };

  useEffect(() => {
    const subscription = calendarReset$.subscribe((event) => {
      if (event.formId === formConfig.id) {
        datePickerRef.current?.reset();
      }
    });

    return () => subscription.unsubscribe();
  }, [formConfig.id]);

  if (!formConfig.withCalendar) {
    return null;
  }

  return (
    <DatePicker
      ref={datePickerRef}
      initialDate={selectedDate || undefined}
      dateTransformation="endOfDay"
      onDaySelect={isMobile ? undefined : handleSubmit}
      onSubmit={isMobile ? (date) => handleSubmit(date as Date) : undefined}
    />
  );
};
