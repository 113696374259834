import {
  TaskCommentInput,
  useTaskCommentsProvider,
} from "@jugl-web/domain-resources/tasks-comments";
import { useManageCommentsListener } from "@jugl-web/domain-resources/tasks-comments/hooks/useManageCommentsListener";
import { useTaskActivities } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { TaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { User, useRestApiProvider } from "@jugl-web/rest-api";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { PlainButton } from "@jugl-web/ui-components";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform/LoadingSpinner";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  parseActivityType,
  TaskActivityItem,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskActivityItem";
import {
  cx,
  isoToLocalDate,
  PaginationComponent,
  PaginationComponentHandle,
  useTranslations,
} from "@jugl-web/utils";
import { TaskActivityFilterMenuTrigger } from "@web-src/pages/TaskDetails/TaskActivityFilterMenuTrigger";
import { FC, useRef } from "react";
import { TaskTableLayout } from "../TaskTableLayout";
import { ReactComponent as ActivityMenuIcon } from "./assets/activity-menu.svg";
import { ReactComponent as OptionsVerticalIcon } from "./assets/options-vertical.svg";

interface ActivityCellPopoverContentProps {
  entityId: string;
  meUser: User | undefined;
  task: PreviewTask;
  permissions: TaskPermissions;
  onClose: () => void;
}

export const ActivityCellPopoverContent: FC<
  ActivityCellPopoverContentProps
> = ({ entityId, meUser, task, permissions, onClose }) => {
  const {
    activities,
    activitiesFilters,
    isLoading,
    isInitialLoading,
    setActivitiesFilters,
    loadMore,
    addActivity,
    modifyActivity,
  } = useTaskActivities({
    meUser,
    entityId,
    taskId: task.id,
  });

  const paginationRef = useRef<PaginationComponentHandle | null>(null);

  const { tasksCommentsApi } = useRestApiProvider();

  const { manageComments$, setCommentAction } = useTaskCommentsProvider();

  useManageCommentsListener({
    taskId: task.id,
    entityId,
    tasksCommentsApi,
    manageComments$,
    addActivity: (activity) => {
      addActivity(activity);
      setTimeout(() => {
        paginationRef.current?.scrollToBottom();
      }, 50);
    },
    modifyActivity,
  });

  const { t } = useTranslations();

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="border-grey-200 border-b-px flex h-[62px] items-center justify-between border-t-0 border-r-0 border-l-0 border-solid px-8">
          <div className="flex items-center gap-2.5">
            <ActivityMenuIcon />
            <span className="text-base font-medium -tracking-[1%] text-[#69757C]">
              {t({
                id: "tasks-page.task-activity",
                defaultMessage: "Task Activity",
              })}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <TaskActivityFilterMenuTrigger
              activityFilters={activitiesFilters}
              setActivityFilters={setActivitiesFilters}
            />
            <TaskTableLayout.ClearCellValueButton onClick={onClose} />
          </div>
        </div>
        <div className="relative flex-1 overflow-y-auto">
          {!isInitialLoading && activities.length === 0 && (
            <div className="px-7 py-12 text-center">
              <Text variant="body2" className="text-[#828282]">
                {t(
                  {
                    id: "tasks-page.empty-activity-column",
                    defaultMessage:
                      "Post a comment to start a discussion. <mark>@Mention</mark> someone to notify them.",
                  },
                  {
                    mark: (mention: (string | JSX.Element)[]) => (
                      <span className="text-primary">{mention}</span>
                    ),
                  }
                )}
              </Text>
            </div>
          )}
          {isInitialLoading ? (
            <LoadingSpinner className="my-7" />
          ) : (
            <PaginationComponent
              ref={paginationRef}
              items={activities}
              isLoading={isLoading}
              endReached={loadMore}
              reverse
              extraVirtuosoParams={{ alignToBottom: false }}
              renderer={(index, item) => {
                const isComment =
                  item.data.raw.action_details.action === "commented";

                const canManageComment =
                  isComment &&
                  permissions.canEditOrDeleteComment(
                    item.data.raw.action_by.user_id
                  );

                const isLast = index === activities.length - 1;

                return (
                  <div
                    className={cx(
                      !isLast &&
                        "border-grey-200 border-b-px border-t-0 border-r-0 border-l-0 border-solid"
                    )}
                  >
                    <TaskActivityItem
                      variant="web"
                      type={parseActivityType(
                        item.data.raw.action_details.action
                      )}
                      username={item.data.user.username}
                      userImageUrl={item.data.user.avatarUrl}
                      message={item.data.message}
                      date={isoToLocalDate(item.data.raw.inserted_at)}
                      contextMenuSlot={
                        canManageComment && (
                          <Menu
                            className="z-[100]"
                            placement="bottom-end"
                            sections={[
                              [
                                {
                                  id: "edit",
                                  label: t({
                                    id: "common.edit",
                                    defaultMessage: "Edit",
                                  }),
                                  onSelect: (_, close) => {
                                    setCommentAction({
                                      type: "edit",
                                      comment: item.data.raw,
                                    });
                                    close();
                                  },
                                },
                                {
                                  id: "delete",
                                  label: t({
                                    id: "common.delete",
                                    defaultMessage: "Delete",
                                  }),
                                  onSelect: (_, close) => {
                                    manageComments$.next({
                                      action: "delete",
                                      taskId: task.id,
                                      comment: item.data.raw,
                                    });
                                    close();
                                  },
                                },
                              ],
                            ]}
                            renderTrigger={({ Trigger, triggerRef }) => (
                              <Trigger as={PlainButton} ref={triggerRef}>
                                <OptionsVerticalIcon />
                              </Trigger>
                            )}
                          />
                        )
                      }
                    />
                  </div>
                );
              }}
            />
          )}
        </div>
        <div
          className="p-4"
          style={{ boxShadow: "1px -6px 15px 0px rgba(18, 22, 34, 0.06)" }}
        >
          <TaskCommentInput entityId={entityId} meId={meUser?.id || ""} />
        </div>
      </div>
    </>
  );
};
