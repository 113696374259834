import {
  AvatarContainer,
  ConfirmationPopup,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { EntityModel } from "@jugl-web/rest-api";
import { ReactComponent as BuildingIcon } from "./assets/building.svg";

export interface EntityUpdateListItemProps {
  entity: EntityModel;
  updatesCount: number;
  onEntitySwitch: (entity: EntityModel) => void;
}

export const EntityUpdateListItem: FC<EntityUpdateListItemProps> = ({
  entity,
  updatesCount,
  onEntitySwitch,
}) => {
  const [
    isSwitchEntityConfirmationDialogOpen,
    setIsSwitchEntityConfirmationDialogOpen,
  ] = useState(false);

  const { t } = useTranslations();

  return (
    <>
      <InteractiveContainer
        className="hover:bg-grey-100 flex w-full items-center gap-4 rounded-lg py-2 px-4 transition-colors"
        onClick={() => setIsSwitchEntityConfirmationDialogOpen(true)}
      >
        <AvatarContainer className="border-dark-100 flex h-10 w-10 shrink-0 items-center justify-center border border-solid">
          {entity.logoImg ? (
            <img
              src={entity.logoImg}
              alt={entity.name}
              className="h-full w-full object-cover"
            />
          ) : (
            <BuildingIcon />
          )}
        </AvatarContainer>
        <span className="text-dark flex-grow truncate text-base font-normal leading-3">
          {entity.name}
        </span>
        <div className="bg-secondary flex h-[19px] w-[40px] shrink-0 items-center justify-center rounded-[50px]">
          <span className="text-[13px] font-medium text-white">
            {updatesCount}
          </span>
        </div>
      </InteractiveContainer>
      <ConfirmationPopup
        variant="web"
        isOpen={isSwitchEntityConfirmationDialogOpen}
        title={t({
          id: "entity-updates-popup-component.switch-workspaces-title",
          defaultMessage: "Switch workspaces",
        })}
        message={t(
          {
            id: "entity-updates-popup-component.switch-workspaces-confirmation",
            defaultMessage:
              "Do you really want to switch workspace to {entityName}?",
          },
          {
            entityName: (
              <span className="text-primary font-medium">{entity.name}</span>
            ),
          }
        )}
        hasCancelButton
        buttons={[
          {
            label: t({
              id: "common.switch",
              defaultMessage: "Switch",
            }),
            color: "primary",
            onClick: () => {
              onEntitySwitch(entity);
              setIsSwitchEntityConfirmationDialogOpen(false);
            },
          },
        ]}
        onRequestClose={() => setIsSwitchEntityConfirmationDialogOpen(false)}
      />
    </>
  );
};
