import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import {
  BoardAvatar,
  ListBoxItem,
  ListBoxProps,
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { FC, useCallback, useMemo, useState } from "react";
import { useTaskBoards } from "../../../../hooks/useTaskBoards";
import { getBoardFieldColors } from "../../../TaskCard/utils";
import { ReactComponent as BoardIcon } from "../../assets/board.svg";
import { ReactComponent as LockIcon } from "../../assets/lock.svg";
import { FieldComponentProps } from "../../types";

interface BoardFieldProps extends FieldComponentProps<"board"> {
  entityId: string;
}

export const BoardField: FC<BoardFieldProps> = ({
  boardId,
  entityId,
  hasBoardAccess = true,
  isReadonly,
  isHidden,
  shouldShowUpdateToast,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { boards, isLoading, getBoardById } = useTaskBoards({ entityId });

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();
  const { toast } = useToast();

  const hasBoard = !!boardId;
  const isFieldVisible = !isHidden && (hasBoard || !isReadonly);

  const selectedBoardDetails = useMemo(() => {
    if (!hasBoardAccess) {
      return null;
    }

    return hasBoard ? getBoardById(boardId) : null;
  }, [boardId, getBoardById, hasBoard, hasBoardAccess]);

  const boardLabel = useMemo(() => {
    if (selectedBoardDetails) {
      return t(
        {
          id: "tasks-page.board-name",
          defaultMessage: "Board: {boardName}",
        },
        {
          boardName: selectedBoardDetails.name,
        }
      );
    }

    if (hasBoard && !hasBoardAccess) {
      return t({
        id: "tasks-page.board",
        defaultMessage: "Board",
      });
    }

    return t({
      id: "tasks-page.select-board",
      defaultMessage: "Select board",
    });
  }, [hasBoard, hasBoardAccess, selectedBoardDetails, t]);

  const boardAsListItems = useMemo<ListBoxItem<TaskBoardModel>[]>(
    () =>
      (boards || []).map((board) => ({
        id: board.id,
        value: board,
      })),
    [boards]
  );

  const boardFieldColors = useMemo(
    () =>
      selectedBoardDetails
        ? getBoardFieldColors(selectedBoardDetails.color)
        : undefined,
    [selectedBoardDetails]
  );

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly || (hasBoard && !hasBoardAccess),
    startIcon: selectedBoardDetails ? (
      <BoardAvatar size="md" {...selectedBoardDetails} />
    ) : (
      <BoardIcon />
    ),
    endIcon: hasBoard && !hasBoardAccess ? <LockIcon /> : undefined,
    className: "max-w-[250px]",
    style: boardFieldColors
      ? {
          backgroundColor: boardFieldColors.backgroundColor,
          color: boardFieldColors.textColor,
        }
      : undefined,
    children: boardLabel,
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-board",
      defaultMessage: "Select Board",
    }),
    items: boardAsListItems,
    selectionBehavior: { mode: "single", canToggle: true },
    hasSearch: true,
    shouldFilterOnSearch: true,
    loading: isLoading ? "skeleton" : undefined,
    defaultSelectedIds: hasBoard ? [boardId] : undefined,
    maxVisibleItems: 6,
    itemSize: "lg",
    spaceBetweenItems: "normal",
    renderLabel: (item) => item.value.name,
    renderStartIcon: (item) => (
      <BoardAvatar size="md" name={item.value.name} color={item.value.color} />
    ),
  } satisfies ListBoxProps<TaskBoardModel> & { title: string };

  const handleUpdate = useCallback(
    (board?: TaskBoardModel) => {
      onChange?.(board?.id || null);
      if (shouldShowUpdateToast && board) {
        toast(
          <span>
            {t(
              {
                id: "tasks-page.task-moved-to-board",
                defaultMessage: "Task has been moved to {boardName}",
              },
              { boardName: <span className="font-semibold">{board.name}</span> }
            )}
          </span>
        );
      }
    },
    [shouldShowUpdateToast, onChange, t, toast]
  );

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
          data-id="board-field"
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={(_, [item]) => handleUpdate(item?.value)}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      shouldCloseOnAddButtonClick
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
          data-id="board-field"
        />
      )}
      onSelect={({ item, isSelected, onClose }) => {
        handleUpdate(isSelected ? item.value : undefined);
        onClose();
      }}
      {...commonResourcePickerProps}
    />
  );
};
