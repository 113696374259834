import { ReactNode } from "react";
import { InteractiveContainer, Tooltip } from "@jugl-web/ui-components";
import { cx } from "@jugl-web/utils";

export const SidebarLinkButton = ({
  text,
  icon,
  isActive,
  tooltip,
  isSidebarExpanded,
  className,
  onClick,
}: {
  text: ReactNode;
  icon: ReactNode;
  isActive?: boolean;
  tooltip?: ReactNode;
  isSidebarExpanded?: boolean;
  className?: string;
  onClick?: () => void;
}) => (
  <Tooltip
    isDisabled={isSidebarExpanded || !tooltip}
    placement="right"
    renderTrigger={({ props, ref }) => (
      <InteractiveContainer {...props} onClick={onClick} ref={ref}>
        <div
          className={cx(
            "font-secondary text-primary-50 mx-2.5 flex h-[41px] items-center gap-2 rounded-lg px-2.5 text-sm",
            isActive ? "bg-primary-800 font-semibold" : "hover:bg-[#2F7DDA]",
            isSidebarExpanded
              ? "w-[190px]"
              : "w-[60px] flex-col justify-center text-center",
            className
          )}
        >
          <div className="flex h-full items-center justify-center">{icon}</div>
          {isSidebarExpanded && (
            <div
              title={text?.toString()}
              className="flex h-full w-full items-center pr-2"
            >
              <span className="w-3/4 truncate">{text}</span>
            </div>
          )}
        </div>
      </InteractiveContainer>
    )}
  >
    {tooltip}
  </Tooltip>
);
