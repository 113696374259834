import { SubscriptionInfoModel } from "@jugl-web/rest-api";
import { SubscriptionCustomerAddress } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionCustomerAddress";
import { Input } from "@jugl-web/ui-components/cross-platform";
import { forwardRef, useImperativeHandle } from "react";
import { UseFormHandleSubmit, useForm, Controller } from "react-hook-form";
import { CountryPicker } from "@jugl-web/ui-components/web";
import { cx } from "@jugl-web/utils";
import countries from "countries-phone-masks";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrow-down.svg";

export type BillingInfoFormType = {
  address: SubscriptionCustomerAddress;
  name: string;
  notes: string;
  gst: string;
};

export type BillingInfoFormHandle = {
  handleSubmit: UseFormHandleSubmit<BillingInfoFormType>;
};

export type BillingInfoFormProps = {
  subscriptionInfo: SubscriptionInfoModel;
};

export const BillingInfoForm = forwardRef<
  BillingInfoFormHandle,
  BillingInfoFormProps
>(({ subscriptionInfo }, ref) => {
  const { handleSubmit, control, setValue, watch, register } =
    useForm<BillingInfoFormType>({
      defaultValues: {
        address: subscriptionInfo.customerAddress || undefined,
        name: subscriptionInfo.customerName || undefined,
        notes: subscriptionInfo.customerNotes || undefined,
        gst: subscriptionInfo.customerTaxId || undefined,
      },
    });
  const selectedCountry = watch("address.country");

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="flex w-full flex-col gap-10 text-left">
      <label>
        <div className="text-grey-1000 text-xs">Payer Name*</div>
        <Controller
          control={control}
          name="name"
          rules={{
            required: {
              value: true,
              message: "Payer Name is required",
            },
          }}
          render={({ field }) => (
            <Input
              onChange={field.onChange}
              placeholder="Enter Name"
              className="w-full"
              maxLength={256}
              value={field.value}
            />
          )}
        />
      </label>
      <div className="flex flex-row justify-between gap-4">
        <label className="w-1/2">
          <div className="text-grey-1000 text-xs">Country*</div>
          <Controller
            control={control}
            name="address.country"
            rules={{
              required: {
                value: true,
                message: "Country is required",
              },
            }}
            render={({ field }) => (
              <CountryPicker
                onSelect={(item) => setValue("address.country", item.iso)}
                placement="top"
                className="z-[2001]"
                countryIso={field.value}
              >
                {(isOpen) => (
                  <div className="flex items-center justify-between border-0 border-b border-solid border-black/20 p-[11px]">
                    <span
                      className={cx(
                        "overflow-hidden truncate whitespace-nowrap",
                        field.value ? "text-dark" : "text-dark-500"
                      )}
                    >
                      {!field.value && "Select Country"}
                      {field.value &&
                        `${
                          countries.find((c) => c.iso === field.value)?.name
                        } (${field.value})`}
                    </span>
                    <ArrowDownIcon className={cx(isOpen && "rotate-180")} />
                  </div>
                )}
              </CountryPicker>
            )}
          />
        </label>
        <label className="w-1/2">
          <div className="text-grey-1000 text-xs">State</div>
          <Controller
            control={control}
            name="address.state"
            rules={{
              maxLength: 256,
            }}
            render={({ field }) => (
              <Input
                placeholder="Enter Address"
                className="w-full"
                maxLength={256}
                {...field}
              />
            )}
          />
        </label>
      </div>
      <div className="flex flex-row justify-between gap-4">
        <label className="w-1/2">
          <div className="text-grey-1000 text-xs">City</div>
          <Controller
            control={control}
            name="address.city"
            rules={{
              maxLength: 128,
            }}
            render={({ field }) => (
              <Input
                placeholder="Enter Address"
                className="w-full"
                maxLength={128}
                {...field}
              />
            )}
          />
        </label>
        <label className="w-1/2">
          <div className="text-grey-1000 text-xs">ZIP/Postal code</div>
          <Controller
            control={control}
            name="address.postal_code"
            rules={{
              maxLength: 32,
            }}
            render={({ field }) => (
              <Input
                placeholder="Enter Address"
                className="w-full"
                maxLength={32}
                {...field}
              />
            )}
          />
        </label>
      </div>

      <label>
        <div className="text-grey-1000 text-xs">Address (line 1)</div>
        <Controller
          control={control}
          name="address.line1"
          rules={{
            maxLength: 256,
          }}
          render={({ field }) => (
            <Input
              placeholder="Enter Address"
              className="w-full"
              maxLength={256}
              {...field}
            />
          )}
        />
      </label>
      <label>
        <div className="text-grey-1000 text-xs">Address (line 2)</div>
        <Controller
          control={control}
          name="address.line2"
          rules={{
            maxLength: 256,
          }}
          render={({ field }) => (
            <Input
              placeholder="Enter Address"
              className="w-full"
              maxLength={256}
              {...field}
            />
          )}
        />
      </label>
      <label>
        <div className="text-grey-1000 text-xs">Notes</div>
        <Controller
          control={control}
          name="notes"
          rules={{
            maxLength: 256,
          }}
          render={({ field }) => (
            <Input
              placeholder="Enter"
              className="w-full"
              maxLength={256}
              {...field}
            />
          )}
        />
      </label>
      {selectedCountry === "IN" && (
        <label>
          <div className="text-grey-1000 text-xs">GST Code</div>
          <Input
            placeholder="Enter"
            className="w-full"
            maxLength={256}
            {...register("gst")}
          />
        </label>
      )}
    </div>
  );
});
