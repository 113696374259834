import {
  UserCPanelModel,
  UserEntityStatus,
  UserRole,
} from "@jugl-web/rest-api";
import { InteractiveContainer, Popover, Radio } from "@jugl-web/ui-components";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { cx, useTranslations } from "@jugl-web/utils";
import { useUserRoleName } from "@jugl-web/domain-resources/users/hooks/useUserRoleName";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { UserRoleChangeAlert } from "../../../UserRoleChangeAlert";

export const UserRolePicker: React.FC<{
  cPanelUser: UserCPanelModel;
}> = ({ cPanelUser }) => {
  const { t } = useTranslations();
  const { getRoleName } = useUserRoleName();

  const roles = useMemo(
    () => [
      {
        role: UserRole.admin,
        emoji: "🚀",
        desc: t({
          id: "cpanel-page.admin-role-description",
          defaultMessage: "Full permission to manage Workspace information",
        }),
      },
      {
        role: UserRole.member,
        emoji: "🤝",
        desc: t({
          id: "cpanel-page.member-role-description",
          defaultMessage:
            "Access to all app features, without permission to manage Workspace",
        }),
      },
      {
        role: UserRole.employee,
        emoji: "🙋",
        desc: t({
          id: "cpanel-page.employee-role-description",
          defaultMessage:
            "Access to Tasks, Chats, Drive and People list only, without permission to manage Workspace",
        }),
      },
    ],
    [t]
  );

  const meId = useSelector(selectUserId);
  const [updatedRole, setUpdatedRole] = useState<UserRole>();
  const isActive = useMemo(
    () =>
      (cPanelUser.status === UserEntityStatus.active ||
        cPanelUser.status === UserEntityStatus.inviteRequested) &&
      cPanelUser.id !== meId,
    [cPanelUser, meId]
  );

  return (
    <>
      <Popover
        placement="bottom"
        isDisabled={!isActive}
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            as={InteractiveContainer}
            ref={triggerRef}
            isDisabled={!isActive}
            className={cx(
              "hover:bg-grey-100 text-dark-700 flex w-full items-center justify-center gap-0.5 rounded py-2 px-2.5 outline-none transition-colors",
              cPanelUser.role === UserRole.admin &&
                "bg-primary-50 hover:bg-primary-50",
              !isActive && "bg-grey-100 hover:bg-grey-100 text-dark-300"
            )}
          >
            <span>{getRoleName(cPanelUser.role)}</span>
            <ArrowIcon
              className={cx("transition-transform", isOpen && "rotate-180")}
            />
          </Trigger>
        )}
        className="border-dark-100 rounded-2xl border border-solid bg-white p-4 drop-shadow-[0_18px_27px_rgba(0,0,0,0.12)]"
      >
        {({ onClose }) => (
          <div className="flex flex-col gap-1.5">
            {roles.map((el) => (
              <InteractiveContainer
                className={cx(
                  "bg-grey-100 hover:bg-grey-200 w-[270px] rounded-lg p-3 transition-colors",
                  el.role === cPanelUser.role &&
                    "bg-primary-50 hover:bg-primary-100"
                )}
                key={el.role}
                isDisabled={el.role === cPanelUser.role}
                onClick={() => {
                  setUpdatedRole(el.role);
                  onClose();
                }}
              >
                <div className="flex items-center justify-between">
                  <span className="text-dark font-secondary font-medium leading-[27px]">
                    {el.emoji} {getRoleName(el.role)}
                  </span>
                  <Radio isChecked={el.role === cPanelUser.role} readOnly />
                </div>
                <div className="text-grey-900 mt-1.5 text-sm leading-[140%]">
                  {el.desc}
                </div>
              </InteractiveContainer>
            ))}
          </div>
        )}
      </Popover>
      <UserRoleChangeAlert
        isOpen={!!updatedRole}
        onRequestClose={() => setUpdatedRole(undefined)}
        role={updatedRole}
        user={cPanelUser}
      />
    </>
  );
};
