import { Alert, Button } from "@jugl-web/ui-components/cross-platform";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import readOnlyAnimation from "./assets/read-only-animation.json";

interface WriteRestrictedAlertProps {
  isOpen: boolean;
  isAdmin: boolean;
  onRequestClose: () => void;
  onRequestPlanUpgrade?: (closeAlert: () => void) => void;
}

export const WriteRestrictedAlert: FC<WriteRestrictedAlertProps> = ({
  isOpen,
  isAdmin,
  onRequestClose,
  onRequestPlanUpgrade,
}) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const canUpgradePlan = isAdmin && !!onRequestPlanUpgrade;

  const copy = {
    header: t({
      id: "write-restricted-alert-component.header",
      defaultMessage: "Workspace Subscription",
    }),
    title: t({
      id: "write-restricted-alert-component.title",
      defaultMessage: "No actions allowed",
    }),
    readOnlyMessage: t({
      id: "write-restricted-alert-component.read-only-message",
      defaultMessage:
        "You cannot update anything because the workspace is in read-only mode.",
    }),
    upgradeSubscriptionMessageAdmin: t(
      {
        id: "write-restricted-alert-component.upgrade-subscription-message-admin",
        defaultMessage:
          "To enable edit mode, please <b>upgrade the workspace subscription.</b>",
      },
      {
        b: (chunks: (string | JSX.Element)[]) => (
          <span className="text-primary-900 font-bold">{chunks}</span>
        ),
      }
    ),
    upgradeSubscriptionMessageMember: t(
      {
        id: "write-restricted-alert-component.upgrade-subscription-message-member",
        defaultMessage:
          "To enable edit mode, please contact the workspace admin to <b>upgrade the subscription.</b>",
      },
      {
        b: (chunks: (string | JSX.Element)[]) => (
          <span className="text-primary-900 font-bold">{chunks}</span>
        ),
      }
    ),
  };

  if (isMobile) {
    return (
      <MobileDrawer isOpen={isOpen} onClose={onRequestClose}>
        <MobileDrawer.Content className="flex flex-col items-center">
          <div className="my-8">
            <Lottie
              options={{ animationData: readOnlyAnimation }}
              width={250}
            />
          </div>
          <span className="text-dark mb-4 text-xl font-medium leading-5 tracking-[0.2px]">
            {copy.title}
          </span>
          <span className="text-dark-800 mb-12 text-center text-base font-normal leading-[22px] tracking-[0.16px]">
            {copy.readOnlyMessage}
            {isAdmin ? (
              <span>{copy.upgradeSubscriptionMessageAdmin}</span>
            ) : (
              <span>{copy.upgradeSubscriptionMessageMember}</span>
            )}
          </span>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className="h-10 shrink-0"
            onClick={onRequestClose}
          >
            {t({
              id: "common.okay",
              defaultMessage: "Okay",
            })}
          </Button>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      isCloseButtonVisible
      onRequestClose={onRequestClose}
      header={copy.header}
      title={copy.title}
      img={
        <Lottie options={{ animationData: readOnlyAnimation }} width={250} />
      }
      buttons={
        canUpgradePlan
          ? [
              {
                text: t({
                  id: "write-restricted-alert-component.upgrade-plan-button-text",
                  defaultMessage: "Upgrade plan",
                }),
                onClick: (_, { closeAlert }) =>
                  onRequestPlanUpgrade(closeAlert),
              },
            ]
          : undefined
      }
      content={
        <span>
          {copy.readOnlyMessage}
          {isAdmin ? (
            <span>{copy.upgradeSubscriptionMessageAdmin}</span>
          ) : (
            <span>{copy.upgradeSubscriptionMessageMember}</span>
          )}
        </span>
      }
    />
  );
};
