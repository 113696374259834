import {
  TasksSourceInfo,
  getTasksSourceInfo,
} from "@jugl-web/domain-resources/tasks";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useTasksSource = () => {
  const { boardId, customerId } = useParams();

  const tasksSource = useMemo<TasksSource>(() => {
    if (customerId) {
      return { type: "customerTasks", customerId };
    }

    return { type: "boardTasks", boardId: boardId || "my" };
  }, [boardId, customerId]);

  const tasksSourceInfo = useMemo<TasksSourceInfo>(
    () => getTasksSourceInfo(tasksSource),
    [tasksSource]
  );

  return { tasksSource, tasksSourceInfo };
};
