import { EntityPropType } from "./types";

export enum EntitiesApiTag {
  getEntityList = "getEntityList",
  getEntity = "getEntity",
  getEntityInvites = "getEntityInvites",
  getSubscriptionInfo = "getSubscriptionInfo",
  getInvoicesList = "getInvoicesList",
}

export enum EntityPropsApiTag {
  entityPropsList = "entityPropsList",
}

export const getEntityPropsListTag = (
  type: EntityPropType,
  entityId: string
) => ({
  id: `${type}-${entityId}`,
  type: EntityPropsApiTag.entityPropsList,
});
