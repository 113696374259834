import { FILE_FORMAT, FILE_QUALITY } from "../consts";

export const resizeImage = async (file: File, size = 1024) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = size;
  canvas.height = size;

  const bitmap = await createImageBitmap(file);
  const { width, height } = bitmap;

  const ratio = Math.min(size / width, size / height);

  const newWidth = width * ratio;
  const newHeight = height * ratio;

  canvas.width = newWidth;
  canvas.height = newHeight;

  ctx?.drawImage(bitmap, 0, 0, width, height, 0, 0, newWidth, newHeight);

  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob(resolve, FILE_FORMAT, FILE_QUALITY);
  });

  return blob;
};
