import { TaskCommentsProvider } from "@jugl-web/domain-resources/tasks-comments";
import { useTaskUnreadIndicator } from "@jugl-web/domain-resources/tasks/hooks/useTaskUnreadIndicator";
import { Popover } from "@jugl-web/ui-components/cross-platform/Popover";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { FC } from "react";
import { ReactComponent as ActivityIcon } from "../../assets/activity.svg";
import { ReactComponent as UnreadActivityIcon } from "../../assets/unread-activity.svg";
import { TaskTableCellComponentProps } from "../../types";
import { ActivityCellPopoverContent } from "../ActivityCellPopoverContent";
import { TaskTableLayout } from "../TaskTableLayout";

export const ActivityCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
}) => {
  const { tasksSource } = useTasksPageContext();

  const { isTaskUnread, readTask } = useTaskUnreadIndicator({
    entityId,
    source: tasksSource,
  });

  const me = useMe();

  const isUnread = isTaskUnread(task.id);
  const Icon = isUnread ? UnreadActivityIcon : ActivityIcon;

  const handleOpenActivityList = () => {
    if (isUnread) {
      readTask(task.id);
    }
  };

  return (
    <Popover
      placement="bottom"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={isFutureTask}
          className="justify-center"
          onClick={handleOpenActivityList}
          {...cellProps}
        >
          {!isFutureTask && <Icon />}
        </Trigger>
      )}
      className="h-[440px] w-[375px] rounded-lg"
      style={{ boxShadow: "0px 5px 24px 0px rgba(0, 0, 0, 0.16)" }}
    >
      {({ onClose }) => (
        <TaskCommentsProvider
          userId={me?.id || ""}
          entityId={entityId}
          taskId={task.id}
          task={task}
        >
          <ActivityCellPopoverContent
            entityId={entityId}
            meUser={me?.me}
            task={task}
            permissions={permissions}
            onClose={onClose}
          />
        </TaskCommentsProvider>
      )}
    </Popover>
  );
};
