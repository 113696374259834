import { Menu, PlainButton } from "@jugl-web/ui-components";
import React from "react";
import { ReactComponent as OptionsIcon } from "./assets/options.svg";
import { ReactComponent as ImportIcon } from "./assets/import.svg";
import { ReactComponent as ExportIcon } from "./assets/export.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";

export const TasksTopBarMenu: React.FC<{
  onTasksImport?: () => void;
  onTasksExport?: () => void;
}> = ({ onTasksExport, onTasksImport }) => (
  <Menu
    placement="bottom-end"
    renderTrigger={({ Trigger, triggerRef }) => (
      <Trigger
        as={PlainButton}
        ref={triggerRef}
        className="hover:bg-grey-200 flex h-10 w-10 items-center justify-center rounded-lg transition-colors"
      >
        <OptionsIcon />
      </Trigger>
    )}
    onSelect={(_item, _event, close) => close()}
    sections={[
      [
        {
          id: "export-tasks",
          label: "Export Tasks to Excel",
          icon: <ExportIcon />,
          endSlot: <ArrowIcon />,
          isHidden: !onTasksExport,
          onSelect: onTasksExport,
        },
        {
          id: "import-tasks",
          label: "Import Tasks from Excel",
          icon: <ImportIcon />,
          endSlot: <ArrowIcon />,
          isHidden: !onTasksImport,
          onSelect: onTasksImport,
        },
      ],
    ]}
  />
);
