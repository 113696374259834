import React, { useContext, useState, useMemo } from "react";
import { useTranslations } from "@jugl-web/utils";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { Text, Button } from "@jugl-web/ui-components/cross-platform";
import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { CallAvatar } from "../../../CallAvatar";
import { parseUserId } from "../../../../../../utils";
import { CallsContext } from "../../../../../../providers";
import { ReactComponent as MuteMicIcon } from "./assets/participant-mute-mic.svg";
import { ReactComponent as MuteCameraIcon } from "./assets/participant-mute-camera.svg";
import { ReactComponent as ParticipantsIcon } from "./assets/participants-sidebar.svg";
import { ShareCallToChatSidebar } from "../ShareCallToChatSidebar/ShareCallToChatSidebar";

export const ParticipantsSidebar: React.FC<{
  toggleSelf: () => void;
  isOpen: boolean;
  onClose: () => void;
}> = ({ toggleSelf, isOpen, onClose }) => {
  const { activeCall } = useContext(CallsContext);
  const { t } = useTranslations();
  const [search, setSearch] = useState("");

  const { entity } = useEntitySelectedProvider();
  const userIds =
    activeCall?.remoteUsers?.map((user) => parseUserId(user.uid as string)) ||
    [];
  const { profiles } = useMultipleUserProfiles({
    entityId: entity.id,
    userIds,
  });

  const { me } = useMe();
  const [isShareToChatSidebarOpen, setShareToChatSidebarOpen] =
    useState<boolean>(false);

  const usersList = useMemo(() => {
    const localUser = {
      uid: me?.id || "",
      name: "You",
      audioTrack: activeCall?.audioEnabled,
      videoTrack: activeCall?.videoEnabled,
    };
    if (!activeCall?.remoteUsers) return [localUser];
    const users = activeCall.remoteUsers
      .map((user) => {
        const id: string = parseUserId(user.uid as string);
        const profile = profiles.find((p) => p.id === id);
        return {
          uid: id,
          name:
            profile?.displayName ||
            t({
              id: "common.unknown",
              defaultMessage: "Unknown",
            }),
          audioTrack: Boolean(user.audioTrack) || false,
          videoTrack: Boolean(user.videoTrack) || false,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    return [localUser, ...users].filter((user) =>
      user.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [
    me?.id,
    activeCall?.audioEnabled,
    activeCall?.videoEnabled,
    activeCall?.remoteUsers,
    profiles,
    t,
    search,
  ]);

  const usersCount = useMemo(() => usersList?.length || 1, [usersList]);

  return (
    <>
      <SidebarDrawer
        className="width-[480px]"
        title={
          <div className="flex flex-row items-center gap-2">
            {t({
              id: "call-conference-participants.title",
              defaultMessage: "Meeting Participants",
            })}
            <ParticipantsIcon />
            <Text variant="body3" className="text-grey-800">
              {usersCount}
            </Text>
          </div>
        }
        onClose={onClose}
        isOpen={isOpen}
      >
        <SidebarDrawer.Content>
          <div className="mt-6 px-10">
            <SearchInput
              variant="blank"
              className="bg-grey-100 my-2"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
            />
          </div>
          {usersList?.map((user) => (
            <div className="px-8">
              <div className="border-grey-200 flex flex-row items-center justify-between border-b-[1px] border-t-0 border-r-0 border-l-0 border-solid py-4">
                <div className="flex flex-row items-center gap-4">
                  <CallAvatar userId={user.uid} size={48} isSpeaking={false} />
                  <Text
                    variant="body2"
                    className="text-dark max-w-[200px] overflow-hidden font-medium"
                  >
                    <UserProfileName userId={user.uid} />
                  </Text>
                </div>
                <div className="flex flex-row items-center gap-2">
                  {!user.audioTrack && <MuteMicIcon />}
                  {!user.videoTrack && <MuteCameraIcon />}
                </div>
              </div>
            </div>
          ))}
        </SidebarDrawer.Content>
        <SidebarDrawer.Actions>
          <Button
            color="primary"
            fullWidth
            uppercase
            onClick={() => {
              toggleSelf();
              setShareToChatSidebarOpen(true);
            }}
          >
            {t({
              id: "call-conference-participants.invite-button",
              defaultMessage: "Invite more users",
            })}
          </Button>
        </SidebarDrawer.Actions>
      </SidebarDrawer>
      <ShareCallToChatSidebar
        isOpen={isShareToChatSidebarOpen}
        onClose={() => setShareToChatSidebarOpen(false)}
        onGoBack={() => {
          setShareToChatSidebarOpen(false);
          toggleSelf();
        }}
      />
    </>
  );
};
