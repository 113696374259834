import React from "react";
import { useTranslations } from "@jugl-web/utils";
import { Pill, Checkbox } from "@jugl-web/ui-components";
import { Controller, Control } from "react-hook-form";
import { Section } from "../Section/Section";
import { InternalExportFormParams } from "../../types";

export const AdditionalField: React.FC<{
  control: Control<InternalExportFormParams>;
}> = ({ control }) => {
  const { t } = useTranslations();

  return (
    <Section
      label={t({
        id: "tasks-export-dialog.additional",
        defaultMessage: "Additional",
      })}
      button={
        <Controller
          name="no_due"
          control={control}
          render={({ field }) => (
            <Pill
              size="md"
              className="cursor-pointer select-none"
              onClick={() => field.onChange(!field.value)}
              startIcon={<Checkbox size="sm" isChecked={field.value} />}
              label={t({
                id: "tasks-export-dialog.no-due-date",
                defaultMessage: "No Due Date",
              })}
            />
          )}
        />
      }
    />
  );
};
