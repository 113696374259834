import { DataLoadingWrapper } from "@jugl-web/ui-components/cross-platform";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { FC, useRef } from "react";
import { useEntitiesListProvider } from "@web-src/features/entities/providers/EntitiesListProvider/EntitiesListProvider";
import { CreateNewEntityButton } from "../../../../components/CreateNewEntityButton";
import { EntityCard } from "../../../../components/EntityCard";

export const EntitiesTab: FC = () => {
  const {
    entitiesState: { isError, isFetching, isLoading, entities },
    refetchEntities,
  } = useEntitiesListProvider();

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  return (
    <>
      <DataLoadingWrapper
        onRetry={refetchEntities}
        isError={isError}
        isFetching={isFetching}
        isLoading={isLoading}
        className="w-full"
      >
        {entities?.length === 0 && (
          <div className="mb-16 px-8 text-center">
            Currently you don&apos;t have any{" "}
            <b className="text-primary-500">Workspaces</b> or{" "}
            <b className="text-primary-500">Invitations</b>, but once you got
            them, you can always find them on this page 🔥
          </div>
        )}
        <div className="flex flex-col gap-6">
          <CreateNewEntityButton />
          {entities?.map((entity) => (
            <EntityCard
              key={entity.id}
              entity={entity}
              onLeaveEntity={() =>
                leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                  entity
                )
              }
            />
          ))}
        </div>
      </DataLoadingWrapper>
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
    </>
  );
};
