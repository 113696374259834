import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import {
  Popover,
  PopoverProps,
} from "@jugl-web/ui-components/cross-platform/Popover";
import { FC } from "react";
import { ColorBoard } from "./components/ColorBoard";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

interface ColorPickerPopoverContentProps
  extends Omit<ColorPickerPopoverProps, "renderTrigger" | "placement"> {
  onClose: () => void;
}

export const ColorPickerPopoverContent: FC<ColorPickerPopoverContentProps> = ({
  title,
  color,
  onColorChange,
  onClose,
}) => (
  <>
    <div className="flex items-center justify-between">
      <span className="text-[17px] font-medium leading-[22px] text-black">
        {title}
      </span>
      <PlainButton
        className="bg-primary hover:bg-primary-600 flex h-10 w-10 items-center justify-center rounded-xl transition-colors"
        onClick={onClose}
      >
        <CheckIcon />
      </PlainButton>
    </div>
    <ColorBoard color={color} className="mt-6" onColorChange={onColorChange} />
  </>
);

export interface ColorPickerPopoverProps
  extends Pick<PopoverProps, "renderTrigger" | "placement"> {
  title: string;
  color: string;
  onColorChange: (color: string) => void;
}

export const ColorPickerPopover: FC<ColorPickerPopoverProps> = ({
  placement,
  renderTrigger,
  ...props
}) => (
  <Popover
    placement={placement}
    renderTrigger={renderTrigger}
    className="w-[390px] rounded-2xl px-4 py-6"
    style={{ boxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.16)" }}
  >
    {({ onClose }) => (
      <ColorPickerPopoverContent onClose={onClose} {...props} />
    )}
  </Popover>
);
