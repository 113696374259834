import {
  LoadingAnimation,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import React, { useCallback, useRef, useState } from "react";
import { cx, useTranslations } from "@jugl-web/utils";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

export interface DroppableFileInputProps {
  onUpdate: (file?: File) => void;
  isProcessing: boolean;
  accept?: string;
  value: {
    file?: File;
    state?: { type: "success" | "error"; title: string; info?: string };
  };
}

export const DroppableFileInput: React.FC<DroppableFileInputProps> = ({
  onUpdate,
  value,
  accept = "*",
  isProcessing,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslations();

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const title = value.state?.title;
  const info = value.state?.info;
  const type = value.state?.type;

  const isFileAccepted = useCallback(
    (file: File): boolean => {
      if (accept === "*") return true;

      const acceptedTypes = accept
        .split(",")
        .map((acceptedType) => acceptedType.trim().toLowerCase());

      return acceptedTypes.some((acceptedType) => {
        if (acceptedType.includes("/")) {
          const acceptRegex = new RegExp(acceptedType.replace("*", ".*"));
          return acceptRegex.test(file.type.toLowerCase());
        }
        if (acceptedType.startsWith(".")) {
          return file.name.toLowerCase().endsWith(acceptedType.toLowerCase());
        }
        return false;
      });
    },
    [accept]
  );

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
    if (value.file) return;

    const droppedFile = event.dataTransfer.files[0];

    if (isFileAccepted(droppedFile)) {
      onUpdate(droppedFile);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      onUpdate(selectedFiles[0]);
    }
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setIsDraggingOver(true)}
      onDragLeave={() => setIsDraggingOver(false)}
      onClick={() => {
        if (!value.file) {
          inputRef.current?.click();
        }
      }}
      className={cx(
        "bg-grey-100 border-messages-chatBubbleSender hover:bg-grey-200 flex h-[200px] w-full items-center justify-center truncate rounded-2xl border border-dashed p-6 transition-colors",
        !value.file && "cursor-pointer",
        isDraggingOver && "bg-grey-200",
        type === "success" && "border-none bg-[#E8F7FC] hover:bg-[#E8F7FC]",
        type === "error" && "bg-tertiary-50 hover:bg-tertiary-50 border-none"
      )}
    >
      {(() => {
        if (isProcessing) {
          return <LoadingAnimation size="xl" />;
        }

        if (value.state) {
          return (
            <div className="w-full text-center">
              {title && (
                <div className="font-secondary text-dark truncate leading-[140%]">
                  {title}
                </div>
              )}
              {info && (
                <div className="font-secondary text-dark-700 mt-2 truncate text-sm leading-[140%]">
                  {info}
                </div>
              )}
              {value.file && (
                <div className="border-grey-300 mx-auto mt-4 flex w-min max-w-[200px] items-center justify-center gap-2 truncate rounded-lg border border-solid bg-white py-1.5 pr-2.5 pl-4">
                  <span className="text-dark truncate leading-[140%]">
                    {value.file.name}
                  </span>
                  <PlainButton onClick={() => onUpdate()}>
                    <CloseIcon />
                  </PlainButton>
                </div>
              )}
            </div>
          );
        }

        return (
          <span className="font-secondary text-dark text-sm">
            {t(
              {
                id: "droppable-file-input-component.browse-or-drag-and-drop-file-here",
                defaultMessage:
                  "<highlightedText>Browse</highlightedText> or drag and drop XLS, XLSX or CSV file here",
              },
              {
                highlightedText: (text: (string | JSX.Element)[]) => (
                  <span className="text-primary underline">{text}</span>
                ),
              }
            )}
          </span>
        );
      })()}
      <input
        type="file"
        ref={inputRef}
        hidden
        accept={accept}
        onChange={handleFileInputChange}
      />
    </div>
  );
};
