export enum FilesModule {
  drive = "drive",
  attachment = "attachment",
}

export type PreviewAttachmentDetails = {
  id: string;
  contentType: string;
  size: number;
  name: string;
  streamUrl: string;
};
