import { InteractiveContainer, PlainButton } from "@jugl-web/ui-components";
import React from "react";
import { Switch } from "@jugl-web/ui-components/cross-platform/Switch";
import { cx, useTranslations } from "@jugl-web/utils";
import { TaskCustomFieldType } from "@jugl-web/rest-api/tasks";
import { DragOverlay } from "@dnd-kit/core";
import { Portal } from "@headlessui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useTaskCustomFieldsDetails } from "../../../tasks/hooks/useTaskCustomFieldsDetails";
import { ReactComponent as ReorderIcon } from "./assets/reorder.svg";
import { ReactComponent as BinIcon } from "./assets/bin.svg";

export const OrderFormComponent: React.FC<{
  id: string;
  title: string;
  type: TaskCustomFieldType;
  index?: number;
  className?: string;
  isCompact?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  onChange?: () => void;
  onDelete?: () => void;
}> = ({
  id,
  title,
  type,
  index,
  className,
  isCompact,
  isRequired,
  isDisabled,
  onChange,
  onDelete,
}) => {
  const { t } = useTranslations();
  const { customFieldTypeToDetails } = useTaskCustomFieldsDetails();

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    data: {
      index,
    },
    disabled: isDisabled,
  });

  return (
    <>
      {isDragging && (
        <Portal>
          <DragOverlay
            style={{
              opacity: 100,
            }}
          >
            <OrderFormComponent
              id={id}
              title={title}
              type={type}
              isCompact
              className={
                typeof index === "number"
                  ? "drop-shadow-[0_4px_8px_rgba(0,0,0,0.16)]"
                  : "bg-grey-200 border-primary-800 rotate-2 border border-dashed"
              }
            />
          </DragOverlay>
        </Portal>
      )}
      <InteractiveContainer
        className={cx(
          "bg-grey-100 hover:bg-grey-200 group flex w-full min-w-[248px] items-center justify-between gap-2 rounded-lg p-3 transition-colors",
          isDisabled && "hover:bg-grey-100 cursor-default",
          className
        )}
        style={{
          transform: CSS.Translate.toString(transform),
          transition,
        }}
        ref={isDisabled ? undefined : setNodeRef}
        {...listeners}
        {...attributes}
      >
        <div className="flex min-w-0 items-center gap-3">
          {React.createElement(customFieldTypeToDetails[type].secondaryIcon, {
            className: "shrink-0",
          })}
          <div className="flex flex-col truncate">
            <span className="text-dark font-secondary truncate text-xs leading-[140%]">
              {title}
            </span>
            <span className="font-secondary text-[10px] leading-[140%] text-[#828282]">
              {customFieldTypeToDetails[type].fullLabel}
              {!isCompact && isRequired && (
                <span className="text-gradients-danger"> *</span>
              )}
            </span>
          </div>
        </div>
        <div className="flex shrink-0 items-center gap-2">
          {!isCompact && !isDisabled && (
            <div className="flex items-center gap-4 opacity-0 transition-opacity group-hover:opacity-100">
              {onChange && (
                <div className="flex items-center gap-2">
                  <span className="text-dark-700 font-secondary text-[10px] leading-[15px]">
                    {t({
                      id: "order-form-create-page.required-field",
                      defaultMessage: "Required Field",
                    })}
                  </span>
                  <Switch isChecked={!!isRequired} onChange={onChange} />
                </div>
              )}
              {onDelete && (
                <PlainButton onClick={onDelete}>
                  <BinIcon />
                </PlainButton>
              )}
            </div>
          )}
          {!isDisabled && (
            <PlainButton className="shrink-0">
              <ReorderIcon />
            </PlainButton>
          )}
        </div>
      </InteractiveContainer>
    </>
  );
};
