import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import { UserEntityStatus, useRestApiProvider } from "@jugl-web/rest-api";
import {
  AvatarStack,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import React, { useMemo } from "react";
import { Tooltip } from "@jugl-web/ui-components/web";
import { useCPanelPageContext } from "../../../../CPanelPageProvider";
import { ReactComponent as UserIcon } from "./assets/user.svg";
import { ReactComponent as InfoIcon } from "./assets/info-icon.svg";

export const UserManagers: React.FC<{
  managers: string[];
  userId: string;
  status: UserEntityStatus;
  userEntityRelId: string;
}> = ({ managers, userId, status, userEntityRelId }) => {
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { toast } = useToast({ variant: "web" });
  const { getListBoxProps, clearSearchQuery, users } = useUserListBox({
    entityId,
  });
  const { profiles } = useMultipleUserProfiles({
    entityId,
    userIds: managers,
  });
  const { usersApi } = useRestApiProvider();
  const [updateUser] = usersApi.useUpdateUserMutation();
  const { updateUser$ } = useCPanelPageContext();

  const filteredUsers = useMemo(
    () =>
      users
        .filter((user) => user.id !== userId)
        .map((user) => ({ id: user.id, value: user })),
    [users, userId]
  );

  const handleManagersChange = async (selectedManagers: string[]) => {
    const response = await updateUser({
      entityId,
      entityRelId: userEntityRelId,
      data: { managers: selectedManagers },
    });
    if (response && "data" in response) {
      updateUser$.next({
        id: userEntityRelId,
        data: { managers: selectedManagers },
      });
      toast(
        t({
          id: "cpanel-page.managers-successfully-updated",
          defaultMessage: "Managers successfully updated",
        })
      );
    }
  };

  const managersProfiles = useMemo(
    () =>
      profiles.map((el) => ({
        firstName: el.firstName || "",
        lastName: el.lastName || "",
        imageUrl: el.avatar || "",
      })),
    [profiles]
  );

  return (
    <ResourcePickerPopover
      placement="bottom-end"
      title={
        <div className="flex items-center gap-3">
          <span>
            {t({
              id: "cpanel-page.direct-managers",
              defaultMessage: "Direct managers",
            })}
          </span>
          <Tooltip
            placement="bottom-end"
            className="z-tooltip p-4"
            delay="none"
            renderTrigger={({ ref, props }) => (
              <InfoIcon ref={ref} className="cursor-help" {...props} />
            )}
          >
            {t({
              id: "cpanel-page.direct-managers-description",
              defaultMessage:
                "Direct Managers can see Tasks, Reports and other work flow information 📊",
            })}
          </Tooltip>
        </div>
      }
      className="w-[315px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={InteractiveContainer}
          className="hover:bg-grey-100 w-full py-2 px-1"
          isDisabled={status !== UserEntityStatus.active}
        >
          <div className="flex items-center gap-1">
            {managersProfiles.length ? (
              <>
                <AvatarStack
                  maxCount={5}
                  size="sm"
                  members={managersProfiles}
                />
                {managersProfiles.length === 1 && (
                  <span className="text-sm font-medium text-[#4F4F4F]">
                    {managersProfiles[0].firstName}{" "}
                    {managersProfiles[0].lastName}
                  </span>
                )}
              </>
            ) : (
              <>
                <UserIcon />
                <span className="text-grey-700 text-sm">
                  {t({
                    id: "cpanel-page.tap-to-set",
                    defaultMessage: "Tap to set",
                  })}
                </span>
              </>
            )}
          </div>
        </Trigger>
      )}
      onUnmount={clearSearchQuery}
      onSubmit={handleManagersChange}
      {...getListBoxProps({
        items: filteredUsers,
        selectionBehavior: { mode: "multiple" },
        defaultSelectedIds: managers,
        maxVisibleItems: 5,
      })}
    />
  );
};
