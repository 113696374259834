import {
  ListBoxItem,
  PlainButton,
  Popover,
} from "@jugl-web/ui-components/cross-platform";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { useEffect, useMemo } from "react";
import {
  TaskReminderOptionWithId,
  useTaskReminders,
} from "../../../../hooks/useTaskReminders";
import { ReactComponent as BellIcon } from "../../assets/bell.svg";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";
import { TaskDueDateFormScreenToParametersMap } from "../../types";
import { ReminderList } from "../ReminderList";
import { TriggerButtonBase } from "../TriggerButtonBase";

export const ReminderListTrigger = () => {
  const {
    selectedDate,
    selectedDateInTargetTimezone,
    selectedReminderId,
    setSelectedReminderId,
  } = useTaskDueDateFormContext();

  const {
    getReminderOptionById,
    isReminderMatchingDueDate,
    getDueDateMatchingReminderOptions,
  } = useTaskReminders();

  const { transitionTo } =
    useScreenTransitionManager<TaskDueDateFormScreenToParametersMap>();

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const reminderOptionsAsListItems = useMemo<
    ListBoxItem<TaskReminderOptionWithId>[]
  >(() => {
    if (!selectedDateInTargetTimezone) {
      return [];
    }

    const dueDateMatchingReminderOptions = getDueDateMatchingReminderOptions(
      selectedDateInTargetTimezone
    );

    if (
      dueDateMatchingReminderOptions.length === 1 &&
      dueDateMatchingReminderOptions[0].id === "none"
    ) {
      return [];
    }

    return dueDateMatchingReminderOptions.map((option) => ({
      id: option.id,
      value: option,
    }));
  }, [getDueDateMatchingReminderOptions, selectedDateInTargetTimezone]);

  const selectedReminderOption = getReminderOptionById(selectedReminderId);

  // Clear reminder if the selected date is not matching the reminder
  useEffect(() => {
    if (
      selectedDateInTargetTimezone &&
      !isReminderMatchingDueDate(
        selectedReminderId,
        selectedDateInTargetTimezone
      )
    ) {
      setSelectedReminderId("none");
    }
  }, [
    isReminderMatchingDueDate,
    selectedDateInTargetTimezone,
    selectedReminderId,
    setSelectedReminderId,
  ]);

  return (
    <Popover
      placement="bottom-end"
      isDisabled={isMobile}
      className="w-[315px] p-3"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as={TriggerButtonBase}
          ref={triggerRef}
          isDisabled={!selectedDate || reminderOptionsAsListItems.length === 0}
          isFocused={isOpen}
          label={t({
            id: "tasks-page.task-reminder",
            defaultMessage: "Reminder",
          })}
          value={selectedReminderOption.label}
          Icon={BellIcon}
          customEndSlot={
            selectedReminderId !== "none" ? (
              <PlainButton
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedReminderId("none");
                }}
              >
                <RemoveIcon />
              </PlainButton>
            ) : undefined
          }
          onClick={() => {
            if (isMobile) {
              transitionTo({ name: "reminderList" });
            }
          }}
        />
      )}
    >
      {({ onClose }) => <ReminderList onAfterSelect={onClose} />}
    </Popover>
  );
};
