import { useRestApiProvider } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { useCallback } from "react";

interface UseSubscriptionInfoOptions {
  entityId: string;
}

export const useSubscriptionInfo = ({
  entityId,
}: UseSubscriptionInfoOptions) => {
  const { entitiesApi } = useRestApiProvider();

  const { data: subscriptionInfo } = entitiesApi.useGetSubscriptionInfoQuery({
    entityId,
  });

  const isModuleAvailable = useCallback(
    (module: SubscriptionPlanModuleId) => {
      if (!subscriptionInfo) {
        return false;
      }

      if (!subscriptionInfo.availableModules) {
        return true;
      }

      return subscriptionInfo.availableModules.some(
        (m) => m.id === module && m.available
      );
    },
    [subscriptionInfo]
  );

  return { isModuleAvailable };
};
