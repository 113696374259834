import { useDroppable } from "@dnd-kit/core";
import {
  TASK_COLUMNS_IDS,
  TaskCalendarManageColumnTab,
  TaskColumnDroppableData,
} from "@jugl-web/domain-resources/tasks";
import {
  TaskCalendarCard,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskSorting } from "@jugl-web/domain-resources/tasks/components/TaskSortingProvider";
import { useTaskListPreferences } from "@jugl-web/domain-resources/tasks/hooks/useTaskListPreferences";
import { useTasks } from "@jugl-web/domain-resources/tasks/hooks/useTasks";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { Button, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useIsScrollable } from "@jugl-web/utils/hooks/useIsScrollable";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { FC, useMemo } from "react";
import { ReactComponent as ArrowRightIcon } from "../../assets/arrow-right.svg";
import { useCalendarNavigation } from "../../hooks/useCalendarNavigation";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { NewTaskColumnButton } from "../NewTaskColumnButton";

export const NavigationColumn: FC = () => {
  const { entityId } = useEntitySelectedProvider();

  const {
    tasksSource,
    taskCalendarDate,
    taskCalendarView,
    navigateToTaskDetailsPage,
  } = useTasksPageContext();

  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({ entityId, source: tasksSource });

  const selectedTab = taskListPreferences.calendarManageColumnTab;

  const { label, moveInTime } = useCalendarNavigation();

  const { searchQuery, filters } = useTaskFiltering();
  const { sorting } = useTaskSorting();

  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: tasksSource,
  });

  const { selectOverdueTasks, selectNoDueDateTasks } = useTasks({
    entityId,
    source: tasksSource,
    searchQuery,
    filters,
    sorting,
    forceShowCompleted: true,
  });

  const { t } = useTranslations();

  const columnId =
    selectedTab === "noDueDate"
      ? TASK_COLUMNS_IDS.DATE_VIEW_WITHOUT_DUE_DATE
      : TASK_COLUMNS_IDS.DATE_VIEW_OVERDUE;

  const { isOver, setNodeRef, active } = useDroppable({
    id: columnId,
    disabled: selectedTab !== "noDueDate",
    data: {
      type: "dueDate",
      value: null,
    } satisfies TaskColumnDroppableData | undefined,
  });

  const { ref: listRef, isScrollable: isColumnScrollable } = useIsScrollable();

  const tabButtons: { tab: TaskCalendarManageColumnTab; label: string }[] =
    useMemo(
      () => [
        {
          tab: "overdue",
          label: t({ id: "tasks-page.overdue", defaultMessage: "Overdue" }),
        },
        {
          tab: "noDueDate",
          label: t({
            id: "tasks-page.no-due-date",
            defaultMessage: "No due date",
          }),
        },
      ],
      [t]
    );

  const overdueTasks = useMemo(() => {
    let excludeFrom: Date;
    let excludeTo: Date;

    switch (taskCalendarView) {
      case "day":
        excludeFrom = startOfDay(taskCalendarDate);
        excludeTo = endOfDay(taskCalendarDate);
        break;
      case "week":
        excludeFrom = startOfWeek(taskCalendarDate, { weekStartsOn: 1 });
        excludeTo = endOfWeek(taskCalendarDate, { weekStartsOn: 1 });
        break;
      case "month":
        excludeFrom = startOfWeek(startOfMonth(taskCalendarDate), {
          weekStartsOn: 1,
        });
        excludeTo = endOfWeek(endOfMonth(taskCalendarDate), {
          weekStartsOn: 1,
        });
        break;
      default:
        throw new Error("Overdue tasks are not supported for this view");
    }

    return selectOverdueTasks({
      excludeInterval: { start: excludeFrom, end: excludeTo },
    });
  }, [selectOverdueTasks, taskCalendarDate, taskCalendarView]);

  const noDueDateTasks = useMemo(
    () => selectNoDueDateTasks(),
    [selectNoDueDateTasks]
  );

  const tabBasedTasks =
    selectedTab === "overdue" ? overdueTasks : noDueDateTasks;

  const isEmpty = tabBasedTasks.length === 0;

  const handleTaskClick = (task: PreviewTask) => {
    navigateToTaskDetailsPage(task.id);
  };

  return (
    <CalendarViewLayout.Column
      ref={setNodeRef}
      className="group mr-1 min-w-[260px]"
    >
      <CalendarViewLayout.ColumnHeader className="justify-between px-6">
        <PlainButton
          className="hover:bg-dark-100 text-grey-700 hover:text-primary-800 flex h-8 w-8 rotate-180 items-center justify-center rounded-lg transition-colors"
          onClick={() => moveInTime("backward")}
        >
          <ArrowRightIcon />
        </PlainButton>
        <span className="font-secondary text-dark-800 font-semibold tracking-[0.16px]">
          {label}
        </span>
        <PlainButton
          className="hover:bg-dark-100 text-grey-700 hover:text-primary-800 flex h-8 w-8 items-center justify-center rounded-lg transition-colors"
          onClick={() => moveInTime("forward")}
        >
          <ArrowRightIcon />
        </PlainButton>
      </CalendarViewLayout.ColumnHeader>
      <CalendarViewLayout.ColumnList
        ref={listRef}
        isDragging={!!active}
        isOver={isOver}
        canDrop={selectedTab === "noDueDate"}
        className="px-6"
      >
        <div className="flex items-center gap-1">
          {tabButtons.map((tabButton) => (
            <Button
              key={tabButton.tab}
              color={selectedTab === tabButton.tab ? "primary" : "grey"}
              fullWidth
              className="h-8 whitespace-nowrap text-xs font-medium"
              onClick={() =>
                updateTaskListPreference(
                  "calendarManageColumnTab",
                  tabButton.tab
                )
              }
            >
              {tabButton.label}
            </Button>
          ))}
        </div>
        {isEmpty ? (
          <CalendarViewLayout.EmptyStateMessage className="mt-9">
            {selectedTab === "overdue"
              ? t({
                  id: "tasks-page.no-overdue-tasks",
                  defaultMessage: "No overdue tasks 😌",
                })
              : t({
                  id: "tasks-page.no-no-due-date-tasks",
                  defaultMessage: "No tasks without due date 😌",
                })}
          </CalendarViewLayout.EmptyStateMessage>
        ) : (
          <>
            {tabBasedTasks.map((task) => (
              <TaskCalendarCard
                key={task.id}
                className="shrink-0"
                highlightedText={searchQuery}
                onClick={() => handleTaskClick(task)}
                columnId={columnId}
                draggable
                {...getTaskCardProps(task)}
              />
            ))}
          </>
        )}
        {selectedTab === "noDueDate" && (
          <NewTaskColumnButton
            entityId={entityId}
            initialState={{ dueDate: null }}
            taskPropertiesPanelConfigOverrides={{
              dueDate: { isReadonly: true },
            }}
            isElevated={isColumnScrollable}
            className="-mx-6 px-6"
          />
        )}
      </CalendarViewLayout.ColumnList>
    </CalendarViewLayout.Column>
  );
};
