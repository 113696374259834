import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { BoardAvatar } from "@jugl-web/ui-components";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useToast, useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const BoardCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { boards, getBoardById } = useTaskBoards({ entityId });

  const { toast } = useToast();
  const { t } = useTranslations();

  const boardsAsListItems = useMemo<ListBoxItem<TaskBoardModel>[]>(
    () => boards.map((b) => ({ id: b.id, value: b })),
    [boards]
  );

  const boardDetails = task.board_id ? getBoardById(task.board_id) : null;
  const hasBoard = !!boardDetails;
  const isEditable = permissions.canEditBoard && !task.is_self && !isFutureTask;

  const handleMoveTask = (board: TaskBoardModel | null) => {
    onUpdate({ board_id: board ? board.id : null });

    toast(
      <span>
        {board
          ? t(
              {
                id: "tasks-page.task-moved-to-board",
                defaultMessage: "Task has been moved to {boardName}",
              },
              { boardName: <span className="font-semibold">{board.name}</span> }
            )
          : t({
              id: "tasks-page.task-removed-from-board",
              defaultMessage: "Task has been removed from the board",
            })}
      </span>
    );
  };

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen, onClose }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          className="justify-between gap-2"
          {...cellProps}
        >
          {hasBoard ? (
            <div className="flex items-center gap-2 overflow-hidden">
              <BoardAvatar
                name={boardDetails.name}
                color={boardDetails.color}
                size="md"
                className="shrink-0"
              />
              <TaskTableLayout.CellText>
                {boardDetails.name}
              </TaskTableLayout.CellText>
            </div>
          ) : (
            <TaskTableLayout.AppearingAddValueCellText
              isStatic={isOpen}
              isHidden={!isEditable}
            />
          )}
          {hasBoard && isEditable && (
            <TaskTableLayout.ClearCellValueButton
              isAppearing={!isOpen}
              onClick={(event) => {
                event.stopPropagation();
                handleMoveTask(null);
                onClose();
              }}
            />
          )}
        </Trigger>
      )}
      hasSearch
      items={boardsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: true }}
      defaultSelectedIds={task.board_id ? [task.board_id] : undefined}
      shouldScrollToFirstSelectedItem
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.name}
      renderStartIcon={(item) => (
        <BoardAvatar
          size="md"
          name={item.value.name}
          color={item.value.color}
        />
      )}
      onSelect={({ item, isSelected, onClose }) => {
        handleMoveTask(isSelected ? item.value : null);
        onClose();
      }}
    />
  );
};
