import {
  TaskChecklist,
  taskChecklistItemAdapters,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskCreator } from "@jugl-web/domain-resources/tasks/components/TaskCreator";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { DetailedTask, PreviewTask } from "@jugl-web/rest-api/tasks";
import { Button, Tooltip } from "@jugl-web/ui-components";
import { TaskCardPill } from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { assert, useTranslations } from "@jugl-web/utils";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useTimeZone } from "@web-src/modules/settings/providers/TimeZoneProvider";
import { isoToLocalDate } from "@web-src/utils/helper";
import { FC, useMemo } from "react";
import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as RecurringIcon } from "../../assets/recurring.svg";
import { ReactComponent as ScheduleIcon } from "../../assets/schedule.svg";

interface FutureTaskDialogContentProps {
  entityId: string;
  meId: string;
  futureTask: PreviewTask;
  sourceTask: DetailedTask;
}

export const FutureTaskDialogContent: FC<FutureTaskDialogContentProps> = ({
  entityId,
  meId,
  futureTask,
  sourceTask,
}) => {
  assert(!!futureTask._future_task, "Task has to be a future task");

  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId });

  const { timeZone } = useTimeZone();
  const { t } = useTranslations();
  const { navigateToTaskDetailsPage } = useTasksPageContext();

  const adaptedChecklistItems = useMemo(
    () =>
      sourceTask.checklist.map((item) => ({
        ...taskChecklistItemAdapters.toInternalModel(item),
        // Reset the completion state as it is not relevant for the scheduled task preview
        isCompleted: false,
        completedAt: null,
      })),
    [sourceTask.checklist]
  );

  return (
    <>
      <TaskFormDialog.Content className="animate-fade-in">
        <TaskFormDialog.TitleSection>
          <div className="mb-4 flex items-center justify-between">
            <TaskCreator
              entityId={entityId}
              userId={sourceTask.created_by}
              createdAt={futureTask._future_task.recurrence_date.toISOString()}
            />
            <Tooltip
              className="px-3"
              renderTrigger={({ ref, props }) => (
                <TaskCardPill
                  ref={ref}
                  label={t({
                    id: "tasks-page.scheduled-task",
                    defaultMessage: "Scheduled task",
                  })}
                  textColor="#A24A0F"
                  backgroundColor="#FFF2E8"
                  startIcon={<ScheduleIcon />}
                  endIcon={<InfoIcon />}
                  className="h-[26px] cursor-help py-0"
                  title={undefined}
                  {...props}
                />
              )}
            >
              {t({
                id: "tasks-page.future-task-info",
                defaultMessage:
                  "This task is scheduled for the future. If you want to edit the task info, please open the Source Task 🤓",
              })}
            </Tooltip>
          </div>
          <div className="flex items-center gap-3">
            <div className="bg-tertiary-100 flex h-8 w-8 items-center justify-center rounded-md">
              <RecurringIcon />
            </div>
            <TitleBox title={sourceTask.name} isEditable={false} />
          </div>
          {sourceTask.desc && (
            <DescriptionBox
              description={sourceTask.desc}
              isEditable={false}
              classes={{ descriptionText: "mt-2 py-2" }}
            />
          )}
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              board: {
                boardId: sourceTask.board_id,
                isReadonly: true,
                hasBoardAccess: sourceTask.board_id
                  ? hasBoardAccess(sourceTask.board_id)
                  : undefined,
              },
              dueDate: {
                state: futureTask.due_at
                  ? { date: isoToLocalDate(futureTask.due_at) }
                  : null,
                displayAs: "date",
                isReadonly: true,
              },
              assignees: {
                assigneeIds: sourceTask.assignees,
                isReadonly: true,
              },
              label: {
                labelId: sourceTask.label_id,
                isReadonly: true,
              },
              customer: {
                customerId: sourceTask.cust_id,
                isReadonly: true,
                isHidden: sourceTask.is_self,
              },
              recurrence: {
                state: sourceTask.recurrence,
                defaultTimezone: timeZone.name,
                isReadonly: true,
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection>
          <TaskChecklist
            entityId={entityId}
            meId={meId}
            items={adaptedChecklistItems}
            isManageable={false}
            isCompletable={false}
          />
        </TaskFormDialog.ChecklistSection>
      </TaskFormDialog.Content>
      <TaskFormDialog.Actions className="animate-fade-in">
        <Button
          variant="contained"
          color="primary"
          className="h-10 w-[200px]"
          onClick={() => navigateToTaskDetailsPage(sourceTask.id)}
        >
          {t({
            id: "tasks-page.open-source-task",
            defaultMessage: "Open Source Task",
          })}
        </Button>
      </TaskFormDialog.Actions>
    </>
  );
};
