import React, { useMemo } from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import { Pill } from "@jugl-web/ui-components";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  ResourcePickerPopover,
  ResourcePickerPopoverProps,
} from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { ResourcePickerDrawerProps } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { DotIcon } from "@jugl-web/ui-components/cross-platform/DotIcon";
import { Control, FieldValues, Controller } from "react-hook-form";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";
import { Section } from "../Section/Section";
import { InternalExportFormParams } from "../../types";

export const LabelField: React.FC<{
  entityId: string;
  control: Control<FieldValues>;
  formParams: InternalExportFormParams;
}> = ({ entityId, control, formParams }) => {
  const { t } = useTranslations();

  const { labels, getLabelById } = useTaskFields({ entityId });

  const labelsAsListItems = useMemo<ListBoxItem<TaskLabel>[]>(
    () => [...labels.map((l) => ({ id: l.id, value: l }))],
    [labels]
  );

  const selectedItems = (formParams.label && formParams.label.split(",")) || [];

  const commonResourcePickerProps = {
    title: "",
    items: labelsAsListItems,
    selectionBehavior: { mode: "multiple" },
    hasSearch: true,
    defaultSelectedIds: selectedItems,
    maxVisibleItems: 8,
    itemSize: "lg",
    spaceBetweenItems: "compact",
    renderStartIcon: (item) => <DotIcon color={item.value.color} />,
    renderLabel: (item) => item.value.text,
  } satisfies Partial<
    ResourcePickerDrawerProps<TaskLabel> | ResourcePickerPopoverProps<TaskLabel>
  >;

  return (
    <Section
      label={t({
        id: "tasks-export-dialog.filter-by-label",
        defaultMessage: "Filter by Label",
      })}
      button={
        <Controller
          name="label"
          control={control}
          render={({ field }) => (
            <ResourcePickerPopover
              placement="bottom-start"
              className="w-[375px]"
              shouldCloseOnAddButtonClick
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  size="md"
                  className="cursor-pointer select-none"
                  ref={triggerRef}
                  as={Pill}
                  endIcon={<AddIcon />}
                  label={
                    selectedItems.length
                      ? t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })
                      : t({
                          id: "common.all",
                          defaultMessage: "All",
                        })
                  }
                />
              )}
              onSubmit={(items) => {
                field.onChange(items.join(","));
              }}
              {...commonResourcePickerProps}
            />
          )}
        />
      }
    >
      <div
        className={cx(
          "animate-fade-in flex flex-wrap items-center gap-2.5 pb-5",
          {
            "pb-0": !selectedItems.length,
          }
        )}
      >
        {selectedItems.map((labelId) => {
          const labelData = getLabelById(labelId);
          if (!labelData) {
            return null;
          }
          return (
            <Controller
              name="labels"
              control={control}
              render={({ field }) => (
                <Pill
                  label={labelData.text}
                  size="md"
                  startIcon={<DotIcon color={labelData.color} />}
                  endIcon={
                    <RemoveIcon
                      onClick={() =>
                        field.onChange(
                          selectedItems.filter((id) => id !== labelId).join(",")
                        )
                      }
                      className="cursor-pointer"
                    />
                  }
                />
              )}
            />
          );
        })}
      </div>
    </Section>
  );
};
