import { BaseTask } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";
import { useTaskBoards } from "./useTaskBoards";

export type RequiredTaskFields = Pick<
  BaseTask,
  "board_id" | "assignees" | "created_by"
>;

interface UseTaskRolesFactoryOptions {
  entityId: string;
  meId: string | undefined;
}

const DEFAULT_TASK_ROLES: TaskRoles = {
  isTaskCreator: false,
  isTaskAssignee: false,
  isBoardMember: false,
};

export const useTaskRolesFactory = ({
  entityId,
  meId,
}: UseTaskRolesFactoryOptions) => {
  const { hasAccess: hasBoardAccess } = useTaskBoards({ entityId });

  const getTaskRoles = useCallback(
    (task: RequiredTaskFields) => {
      const isTaskCreator = task.created_by === meId;

      const isTaskAssignee = task.assignees.some(
        (assigneeId) => assigneeId === meId
      );

      // If the board property was not supplied, we assume the user is not a board member (collaborator)
      const isBoardMember = task.board_id
        ? hasBoardAccess(task.board_id)
        : false;

      return {
        isTaskCreator,
        isTaskAssignee,
        isBoardMember,
      };
    },
    [hasBoardAccess, meId]
  );

  return getTaskRoles;
};

interface UseTaskRolesOptions extends UseTaskRolesFactoryOptions {
  task: RequiredTaskFields | undefined;
}

export const useTaskRoles = (options: UseTaskRolesOptions) => {
  const getTaskRoles = useTaskRolesFactory(options);

  const taskRoles = useMemo(
    () => (options.task ? getTaskRoles(options.task) : DEFAULT_TASK_ROLES),
    [getTaskRoles, options.task]
  );

  return taskRoles;
};

export type TaskRoles = ReturnType<ReturnType<typeof useTaskRolesFactory>>;
