import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import DrivePage from "./DrivePage";

export type DrivePagesNavigationParams = {
  driveDrive: PageNavParams<
    never,
    {
      directoryId?: string;
      directoryName?: string;
      openedFile?: string;
      path?: string;
      full_path?: string;
    }
  >;
};

export const drivePagesNavigationConfig: ModulePagesConfig<DrivePagesNavigationParams> =
  {
    driveDrive: {
      element: <DrivePage />,
      path: "drive",
      isPrivate: true,
    },
  };
