import { useCallback } from "react";
import {
  ChatMessage,
  ChatMessagePayloadAction,
  ChatMessageType,
} from "@web-src/features/chats/types";
import { useDispatch } from "react-redux";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { WorkspacesApiTag, useRestApiProvider } from "@jugl-web/rest-api";
import useConversations from "./useConversations";

const useGroupInfoMessageHandler = () => {
  const dispatch = useDispatch();
  const { updateItem, conversations } = useConversations();
  const { entity } = useEntity();
  const { workspacesApi } = useRestApiProvider();
  const [loadWorkspace] = workspacesApi.useLazyGetWorkspaceQuery();
  const handler = useCallback(
    async (message: ChatMessage) => {
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action === "updated" &&
        entity
      ) {
        const response = await loadWorkspace({
          entityId: entity.id,
          workspaceId: message.to,
        });
        if (response?.data) {
          updateItem(
            {
              id: message.to,
              data: {
                title: response.data.title,
                img: response.data.display_picture,
              },
            },
            true
          );
        }
      }
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action &&
        [
          ChatMessagePayloadAction.added,
          ChatMessagePayloadAction.removed,
          ChatMessagePayloadAction.left,
        ].includes(message.payload?.action)
      ) {
        const conversation = conversations.find(
          (item) => item.id === message.to
        );
        if (conversation) {
          const updatedUsers =
            message.payload.action === ChatMessagePayloadAction.added
              ? [
                  ...(message.payload.participants || []),
                  ...(conversation.data.users || []),
                ]
              : conversation.data.users?.filter(
                  (item) => item !== message.payload.action_for
                ) || [];

          updateItem({
            id: message.to,
            data: {
              ...conversation.data,
              users: updatedUsers,
              userCount: updatedUsers.length,
            },
          });
        }

        dispatch(
          workspacesApi.util.invalidateTags([
            {
              type: WorkspacesApiTag.participants,
              id: message.to,
            },
          ])
        );
      }
      if (
        message.type === ChatMessageType.muc &&
        message.payload?.action === ChatMessagePayloadAction.deleted &&
        entity
      ) {
        updateItem(
          {
            id: message.to,
            data: {
              deleted: true,
            },
          },
          true
        );
      }
    },
    [
      entity,
      loadWorkspace,
      updateItem,
      dispatch,
      workspacesApi.util,
      conversations,
    ]
  );
  return handler;
};

export default useGroupInfoMessageHandler;
