import { useSearchInput, useTranslations } from "@jugl-web/utils";
import React, { useState, useMemo } from "react";
import { ExpandableSearchBar } from "@jugl-web/ui-components/web/ExpandableSearchBar";
import { Button, Menu } from "@jugl-web/ui-components/cross-platform";
import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { ReactComponent as AddIcon } from "./assets/add-icon.svg";
import { ReactComponent as BinIcon } from "./assets/bin.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";
import { DeleteUsersConfirmation } from "../DeleteUsersConfirmation";
import { ReactComponent as AddMemberIcon } from "./assets/add-member.svg";
import { ReactComponent as CopyLinkIcon } from "./assets/copy-link.svg";
import { ReactComponent as PeopleIcon } from "./assets/people-icon.svg";

export const PageControlBar: React.FC = () => {
  const {
    setSearchQuery,
    selectedUsers,
    clearSelectedUsers,
    setOpenedSideBar,
    setOpenedAlert,
  } = useCPanelPageContext();
  const { openSpotlight, openedSpotlight } = useOnboarding();
  const { inputProps } = useSearchInput();
  const { subscriptionInfo } = useEntityProvider();
  const { t } = useTranslations();
  const [
    isOpenDeleteUsersConfirmationPopup,
    setIsOpenDeleteUsersConfirmationPopup,
  ] = useState(false);
  const canAddMembers = useMemo(() => {
    if (
      !subscriptionInfo ||
      subscriptionInfo.status === "free" ||
      subscriptionInfo.status === "trialing" ||
      !subscriptionInfo?.seatsLicensed
    ) {
      return true;
    }
    return subscriptionInfo.seatsLicensed - subscriptionInfo.seatsUtilized > 0;
  }, [subscriptionInfo]);
  return (
    <>
      <div className="flex h-[72px] items-center justify-between gap-4 bg-[#EEF2F5] px-8">
        <div className="[&_input]:w-[280px]">
          <ExpandableSearchBar
            color="white"
            isAlwaysExpanded
            size="lg"
            onSearch={setSearchQuery}
            {...inputProps}
          />
        </div>
        <div className="flex gap-4">
          <Button iconStart={<PeopleIcon />} color="white" className="h-10">
            {t(
              {
                id: "common.plan-seats-available-counter",
                defaultMessage:
                  "Available {seatAvailable} of {seatsTotal} seats",
              },
              {
                seatAvailable: subscriptionInfo?.seatsAvailable,
                seatsTotal: subscriptionInfo?.seatsLicensed,
              }
            )}
          </Button>
          {!!selectedUsers.length && (
            <button
              className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border-none bg-white outline-none"
              onClick={() => setIsOpenDeleteUsersConfirmationPopup(true)}
              type="button"
            >
              <BinIcon />
            </button>
          )}
          <SpotlightTooltip
            isOpen={openedSpotlight === "people"}
            placement="bottom"
            tip={t({
              id: "cpanel-page.add-workspace-people-onboarding-hint",
              defaultMessage: "Tap here to add People to the Workspace ✨",
            })}
            showButton={false}
            renderTrigger={({ ref, props }) => (
              <div ref={ref} {...props}>
                <Menu
                  placement="bottom-end"
                  renderTrigger={({ Trigger, triggerRef }) => (
                    <Trigger
                      as={Button}
                      disabled={!canAddMembers}
                      ref={triggerRef}
                      className="bg-primary h-10 rounded-lg"
                      iconEnd={<AddIcon />}
                      onClick={() => openSpotlight(undefined)}
                    >
                      {t({
                        id: "common.add-member",
                        defaultMessage: "Add members",
                      })}
                    </Trigger>
                  )}
                  onSelect={(_item, _event, close) => close()}
                  sections={[
                    [
                      {
                        id: "new-chat",
                        label: t({
                          id: "cpanel-page.add-new-member",
                          defaultMessage: "Add new member",
                        }),
                        onSelect: () => setOpenedSideBar({ sideBar: "invite" }),
                        icon: <AddMemberIcon />,
                      },
                    ],
                    [
                      {
                        id: "new-chat",
                        label: t({
                          id: "cpanel-page.copy-invitation-link",
                          defaultMessage: "Copy Invitation Link",
                        }),
                        onSelect: () =>
                          setOpenedAlert({ alert: "copyInvitation" }),
                        icon: <CopyLinkIcon />,
                      },
                    ],
                  ]}
                />
              </div>
            )}
            onDismiss={() => openSpotlight(undefined)}
          />
        </div>
      </div>
      <DeleteUsersConfirmation
        isOpen={isOpenDeleteUsersConfirmationPopup}
        onRequestClose={() => setIsOpenDeleteUsersConfirmationPopup(false)}
        users={selectedUsers}
        onSuccess={clearSelectedUsers}
      />
    </>
  );
};
