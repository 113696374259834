import { useMultipleUserProfiles } from "@jugl-web/domain-resources/users/hooks/useMultipleUserProfiles";
import {
  AvatarMember,
  AvatarStack,
} from "@jugl-web/ui-components/cross-platform";
import { FC, useMemo } from "react";

export const AssigneeCell: FC<{
  assigneeIds: string[];
  entityId: string;
  isExpanded?: boolean;
}> = ({ assigneeIds, entityId, isExpanded }) => {
  const { profiles } = useMultipleUserProfiles({
    entityId,
    userIds: assigneeIds,
  });

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      assigneeIds.map((id) => {
        const findProfile = profiles.find((p) => p.id === id);

        return {
          firstName: findProfile?.firstName || "",
          lastName: findProfile?.lastName || "",
          imageUrl: findProfile?.avatar || null,
          isFormerMember: !findProfile,
        };
      }),
    [assigneeIds, profiles]
  );

  return (
    <AvatarStack
      maxCount={6}
      size="sm"
      members={profilesAsAvatarMembers}
      shouldShowNameForSingleMember={isExpanded}
    />
  );
};
