/* eslint-disable react/destructuring-assignment */
import { cx, omit } from "@jugl-web/utils";
import { Fragment, HTMLAttributes, ReactNode } from "react";
import { TopBarSearchInput, TopBarSearchInputProps } from "./TopBarSearchInput";

interface TopBarContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
}

export const TOP_BAR_HEIGHT_PX = 56;

export const TopBarContainer = ({
  children,
  className,
  style,
  ...divProps
}: TopBarContainerProps) => (
  <div
    className={cx(
      "flex w-full items-center justify-between gap-4 bg-white px-4 py-2",
      className
    )}
    style={{ height: `${TOP_BAR_HEIGHT_PX}px`, ...style }}
    {...divProps}
  >
    {children}
  </div>
);

interface HeadingTopBarProps {
  type: "heading";
  title?: ReactNode;
  endSlots?: JSX.Element[];
}

interface SearchTopBarProps extends TopBarSearchInputProps {
  type: "search";
  initialSearchQuery: string;
}

export type TopBarProps = HTMLAttributes<HTMLDivElement> &
  (HeadingTopBarProps | SearchTopBarProps);

export const TopBar = (props: TopBarProps) => {
  if (props.type === "heading") {
    const { title, endSlots = [], ...restProps } = props;

    return (
      <TopBarContainer {...omit(restProps, "type")}>
        <div className="flex items-center gap-4 overflow-hidden">
          {typeof title === "string" ? (
            <h1 className="text-dark text-xl font-medium leading-4">{title}</h1>
          ) : (
            title
          )}
        </div>
        {endSlots.length > 0 && (
          <div className="flex items-center gap-3">
            {endSlots.map((slot, index) => (
              <Fragment key={+index}>{slot}</Fragment>
            ))}
          </div>
        )}
      </TopBarContainer>
    );
  }

  const {
    initialSearchQuery,
    onSearch,
    onClose,
    onSettingsClick,
    hasSettingsIndicator,
    ...restProps
  } = props;

  return (
    <TopBarContainer {...omit(restProps, "type")}>
      <TopBarSearchInput
        initialSearchQuery={initialSearchQuery}
        onSearch={onSearch}
        onClose={onClose}
        onSettingsClick={onSettingsClick}
        hasSettingsIndicator={hasSettingsIndicator}
      />
    </TopBarContainer>
  );
};
