import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform/InteractiveContainer";
import { cx } from "@jugl-web/utils";
import { insertList, removeList } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $setBlocksType } from "@lexical/selection";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
} from "lexical";
import React, { useCallback, useEffect, useState } from "react";
import { getListType } from "./utils";
import { ReactComponent as BoldIcon } from "./icons/bold.svg";
import { ReactComponent as ItalicIcon } from "./icons/italic.svg";
import { ReactComponent as OrderedListIcon } from "./icons/ordered-list.svg";
import { ReactComponent as StrikeIcon } from "./icons/strike.svg";
import { ReactComponent as UnderlineIcon } from "./icons/underline.svg";
import { ReactComponent as UnorderedListIcon } from "./icons/undordered-list.svg";
import { ReactComponent as HighlightIcon } from "./icons/highlight.svg";

enum ListType {
  normal = "normal",
  ordered = "ordered",
  unordered = "unordered",
}

export const StyledToolbar: React.FC<{
  classes: { activeButton: string; inactiveButton: string };
}> = ({ classes }) => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [listType, setListType] = useState<ListType>(ListType.normal);

  const insertParagraph = () => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createParagraphNode());
      }
    });
  };

  const handleList = (selectedListType: ListType) => {
    switch (selectedListType) {
      case ListType.normal:
        insertParagraph();
        return;

      case ListType.unordered:
        if (listType === ListType.unordered) {
          removeList(editor);
        } else {
          insertList(editor, "bullet");
        }
        return;

      case ListType.ordered:
        if (listType === ListType.ordered) {
          removeList(editor);
        } else {
          insertList(editor, "number");
        }
        return;

      default:
        throw new Error("Unsupported list type");
    }
  };

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsHighlight(selection.hasFormat("highlight"));

      const list = getListType(selection);

      if (list === "bullet") {
        setListType(ListType.unordered);
      } else if (list === "number") {
        setListType(ListType.ordered);
      } else {
        setListType(ListType.normal);
      }
    }
  }, []);

  useEffect(() => {
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        $updateToolbar();
      });
    });
  }, [$updateToolbar, editor]);

  return (
    <div className="flex w-full items-center justify-between">
      <InteractiveContainer
        onClick={() =>
          handleList(
            listType === ListType.unordered
              ? ListType.normal
              : ListType.unordered
          )
        }
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          listType === ListType.unordered
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <UnorderedListIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() =>
          handleList(
            listType === ListType.ordered ? ListType.normal : ListType.ordered
          )
        }
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          listType === ListType.ordered
            ? classes.activeButton
            : classes.inactiveButton
        )}
      >
        <OrderedListIcon />
      </InteractiveContainer>
      <div className="bg-dark-200 h-8 w-px" />
      <InteractiveContainer
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          isBold ? classes.activeButton : classes.inactiveButton
        )}
      >
        <BoldIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          isItalic ? classes.activeButton : classes.inactiveButton
        )}
      >
        <ItalicIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() =>
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
        }
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          isStrikethrough ? classes.activeButton : classes.inactiveButton
        )}
      >
        <StrikeIcon />
      </InteractiveContainer>
      <InteractiveContainer
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          isUnderline ? classes.activeButton : classes.inactiveButton
        )}
      >
        <UnderlineIcon />
      </InteractiveContainer>
      <div className="bg-dark-200 h-8 w-px" />
      <InteractiveContainer
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "highlight")}
        className={cx(
          "flex h-8 w-8 items-center justify-center rounded",
          isHighlight ? classes.activeButton : classes.inactiveButton
        )}
      >
        <HighlightIcon />
      </InteractiveContainer>
    </div>
  );
};
