import React, { useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import {
  DirectoryListItem,
  FileType,
  DriveItemDisplayType,
  PermissionsMode,
} from "@jugl-web/rest-api/drive/types";
import { RootState } from "@web-src/store";
import { useToast, useTranslations } from "@jugl-web/utils";
import { useUserGeneralProfile } from "@jugl-web/domain-resources/users/hooks/useUserGeneralProfile";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { selectUserId } from "@web-src/features/auth/authSlice";
import DeleteIcon from "./assets/bin.svg";
import EditIcon from "./assets/edit.svg";
import { useDeleteObject } from "../../../../hooks/useDeleteObject";
import DriveDeleteModal from "../../../DriveDeleteModal";
import DriveObject from "../DriveObject";
import { actionSidebar, setVisited } from "../../../../driveSlice";
import CopyIcon from "./assets/copy.svg";

const DriveFolder: React.FC<{
  allowedUsers: DirectoryListItem["permission"]["users"];
  search: string;
  id: string;
  name: string;
  type: FileType;
  documents: number;
  display: DriveItemDisplayType;
  size: number;
  accessType: PermissionsMode;
  shortUrl: string;
  meta?: DirectoryListItem["meta"];
}> = ({
  allowedUsers,
  id,
  search,
  name,
  type,
  documents,
  display,
  size,
  accessType,
  meta,
  shortUrl,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entityId } = useEntitySelectedProvider();
  const { deleteObject } = useDeleteObject();
  const visited = useSelector((state: RootState) => state.drive.visited);
  const meId = useSelector(selectUserId);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { profile } = useUserGeneralProfile({
    skip: meta?.name_format !== "user_id",
    userId: name,
    entityId,
  });

  const displayName = useMemo(() => {
    if (meta?.name_format) {
      return profile?.displayName || "";
    }
    return name;
  }, [meta?.name_format, name, profile?.displayName]);

  const allowedUsersIdsWithoutMe = useMemo(
    () => allowedUsers.filter((el) => el.id !== meId).map((el) => el.id),
    [allowedUsers, meId]
  );

  const fileType = useMemo(() => {
    if (accessType === PermissionsMode.public) return "public";
    if (!allowedUsersIdsWithoutMe.length) return "private";
    return "shared";
  }, [accessType, allowedUsersIdsWithoutMe]);

  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(shortUrl);
    toast(
      t(
        {
          id: "drive-page.link-was-copied-to-clipboard",
          defaultMessage: "{type} link was copied to clipboard",
        },
        {
          type: "Folder",
        }
      )
    );
  }, [shortUrl, t, toast]);
  const displayFileMenuElements = useMemo(
    () =>
      !meta?.sys_dir
        ? [
            {
              id: "edit",
              icon: EditIcon,
              text: t({
                id: "drive-page.edit-folder",
                defaultMessage: "Edit folder",
              }),
              onClick: () =>
                dispatch(
                  actionSidebar({
                    id,
                    type,
                    name: displayName,
                    allowed_users: allowedUsers,
                    action: "edit",
                  })
                ),
            },
            {
              id: "copy-link",
              icon: CopyIcon,
              text: t({
                id: "common.copy-link",
                defaultMessage: "Copy link",
              }),
              onClick: () => handleCopyLink(),
            },
            {
              id: "delete",
              icon: DeleteIcon,
              text: t({
                id: "common.delete",
                defaultMessage: "Delete",
              }),
              onClick: () => setIsDeleteModalOpen(true),
            },
          ]
        : [],
    [type, allowedUsers, dispatch, id, displayName, meta, t, handleCopyLink]
  );
  return (
    <>
      <DriveObject
        displayFileMenuElements={displayFileMenuElements}
        search={search}
        title={displayName}
        firstSubtitle={t(
          {
            id: "drive-page.documents-count",
            defaultMessage:
              "{count} {count, plural, one {document} other {documents}}",
          },
          {
            count: documents,
          }
        )}
        secondSubtitle={getFileSizeLabel(size)}
        fileType={fileType}
        icon="folder"
        display={display}
        onClick={() => {
          dispatch(setVisited([...visited, { id, name: displayName }]));
        }}
        allowedUsersIds={allowedUsersIdsWithoutMe}
        meta={meta}
      />
      {isDeleteModalOpen && (
        <DriveDeleteModal
          name={displayName}
          type="directory"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onClick={() => deleteObject("directory", id)}
        />
      )}
    </>
  );
};

export default DriveFolder;
