import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { FC } from "react";
import { Link } from "react-router-dom";
import { TopBarButton } from "../TopBarButton";
import { ReactComponent as TemplateIcon } from "./assets/template.svg";

export const TemplatesTopBarButton: FC = () => {
  const { generateUrl } = useNavigation();

  const { t } = useTranslations();

  return (
    <Link to={generateUrl("tasksTemplates")} className="no-underline">
      <TopBarButton
        label={t({
          id: "tasks-page.templates",
          defaultMessage: "Templates",
        })}
        icon={<TemplateIcon />}
      />
    </Link>
  );
};
