import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback } from "react";
import writeXlsxFile, { SheetData } from "write-excel-file";
import { ExportedTaskModel } from "@jugl-web/rest-api/tasks/models/ExportedTask";
import { ExportTasksParams } from "@jugl-web/rest-api/tasks/types";
import { useTranslations } from "@jugl-web/utils";
import { useTaskFields } from "../useTaskFields";
import { formatUserName } from "./utils/utils";
import { useExportedTableRowGenerator } from "./utils/useExportedTableRowGenerator";

export const useExportTasks = ({ entityId }: { entityId: string }) => {
  const { tasksApi } = useRestApiProvider();
  const [exportTasks, { isLoading: isExportTasksError }] =
    tasksApi.useLazyExportTasksQuery();
  const { isError } = useTaskFields({ entityId });
  const { t } = useTranslations();
  const {
    generateNameRows,
    generateHeaderRow,
    generateTaskRow,
    generateTaskChecklistsHeaderRow,
    generateTaskChecklistRow,
  } = useExportedTableRowGenerator({ entityId });

  const groupTasks = useCallback(
    (
      tasks: ExportedTaskModel[]
    ): { name: string; data: ExportedTaskModel[] }[] => {
      const groupedTasks: { [key: string]: ExportedTaskModel[] } = {};

      tasks.forEach((task) => {
        const boardName = task.board?.name || "Without Board";
        if (!groupedTasks[boardName]) {
          groupedTasks[boardName] = [];
        }
        groupedTasks[boardName].push(task);
      });

      let result = Object.keys(groupedTasks).map((boardName) => ({
        name: boardName,
        data: groupedTasks[boardName],
      }));

      const withoutBoard = result.find(
        (group) => group.name === "Without Board"
      );
      result = result.filter((group) => group.name !== "Without Board");

      if (withoutBoard) {
        result.push({
          ...withoutBoard,
          name: t({
            id: "tasks-page.without-board",
            defaultMessage: "Without Board",
          }),
        });
      }

      return result;
    },
    [t]
  );

  const groupTeamTasks = useCallback(
    (
      tasks: ExportedTaskModel[]
    ): { name: string; data: ExportedTaskModel[] }[] => {
      const groupedTasks: { [key: string]: ExportedTaskModel[] } = {};

      tasks.forEach((task) => {
        if (task.assignees.length === 0) {
          if (!groupedTasks["Without User"]) {
            groupedTasks["Without User"] = [];
          }
          groupedTasks["Without User"].push(task);
        } else {
          task.assignees.forEach((assignee) => {
            const userName = formatUserName(assignee);

            if (!groupedTasks[userName]) {
              groupedTasks[userName] = [];
            }

            groupedTasks[userName].push(task);
          });
        }
      });

      let result = Object.keys(groupedTasks).map((userName) => ({
        name: userName,
        data: groupedTasks[userName],
      }));

      const withoutUser = result.find((group) => group.name === "Without User");
      result = result.filter((group) => group.name !== "Without User");

      if (withoutUser) {
        result.push({
          ...withoutUser,
          name: t({
            id: "tasks-page.without-user",
            defaultMessage: "Without User",
          }),
        });
      }

      return result;
    },
    [t]
  );

  const generateExcel = useCallback(
    async (type: "tasks" | "team", params?: ExportTasksParams) => {
      const tasks = await exportTasks({ entityId, params });
      const processedData: SheetData = [];

      if ((tasks && "errors" in tasks) || isExportTasksError || isError) {
        throw new Error("Unable to export tasks");
      }

      if (tasks && "data" in tasks && tasks.data && tasks.data.length === 0) {
        throw new Error("No tasks to export");
      }

      if (tasks && "data" in tasks && tasks.data) {
        const groupedTasks =
          type === "tasks"
            ? groupTasks(tasks.data)
            : groupTeamTasks(tasks.data);
        groupedTasks.forEach((el) => {
          processedData.push(...generateNameRows(el.name));
          processedData.push(generateHeaderRow(type === "tasks"));
          el.data.forEach((task) => {
            processedData.push(generateTaskRow(task, type === "tasks"));
            if (task.checklist?.length) {
              processedData.push(generateTaskChecklistsHeaderRow());
              task.checklist.forEach((checklistItem) =>
                processedData.push(generateTaskChecklistRow(checklistItem))
              );
            }
          });
        });
      }

      await writeXlsxFile(processedData, {
        columns: [...Array(generateHeaderRow(type === "tasks").length)].map(
          () => ({
            width: 30,
          })
        ),
        fileName: "Jugl tasks.xlsx",
      });
    },
    [
      entityId,
      isError,
      isExportTasksError,
      exportTasks,
      groupTasks,
      groupTeamTasks,
      generateNameRows,
      generateHeaderRow,
      generateTaskRow,
      generateTaskChecklistsHeaderRow,
      generateTaskChecklistRow,
    ]
  );

  return { generateExcel };
};
