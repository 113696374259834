import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import endOfDay from "date-fns/endOfDay";
import { FC } from "react";
import { useTaskReminders } from "../../../../hooks/useTaskReminders";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";
import { TaskDueDateFormProps } from "../../types";
import { Calendar, calendarReset$ } from "../Calendar";
import { CalendarTrigger } from "../CalendarTrigger";
import { ReminderListTrigger } from "../ReminderListTrigger";
import { TimeInput } from "../TimeInput";
import { TimezoneListTrigger } from "../TimezoneListTrigger";

type EntryScreenProps = Pick<TaskDueDateFormProps, "onSubmit" | "onClose">;

export const EntryScreen: FC<EntryScreenProps> = ({ onSubmit, onClose }) => {
  const {
    formConfig,
    selectedDate,
    selectedDateInTargetTimezone,
    selectedReminderId,
    selectedTimezone,
    setSelectedDate,
  } = useTaskDueDateFormContext();

  const { getNextExecutionTime } = useTaskReminders();

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const handleSubmit = () => {
    if (!selectedDateInTargetTimezone) {
      onSubmit(null);
      onClose?.();
      return;
    }

    onSubmit({
      date: selectedDateInTargetTimezone,
      timezone: selectedTimezone,
      reminderExecutionTime:
        selectedReminderId !== "none"
          ? getNextExecutionTime(
              selectedDateInTargetTimezone,
              selectedReminderId
            )
          : undefined,
    });

    onClose?.();
  };

  const handleClear = () => {
    if (!selectedDate) {
      return;
    }

    if (formConfig.withCalendar) {
      calendarReset$.next({ formId: formConfig.id });
      setSelectedDate(null);
      return;
    }

    setSelectedDate(endOfDay(selectedDate));
  };

  return (
    <div className="flex flex-col">
      {!isMobile && <Calendar />}
      <div
        className={cx("mb-10 flex flex-col gap-[18px]", !isMobile && "mt-6")}
      >
        {isMobile && <CalendarTrigger />}
        <TimeInput />
        {formConfig.withAdvancedOptions && (
          <>
            <TimezoneListTrigger />
            <ReminderListTrigger />
          </>
        )}
      </div>
      <div className="flex items-center gap-3">
        <Button
          fullWidth
          variant="contained"
          color="grey"
          className="h-10"
          onClick={handleClear}
        >
          {t({ id: "common.clear", defaultMessage: "Clear" })}
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className="h-10"
          onClick={handleSubmit}
        >
          {t({ id: "common.save", defaultMessage: "Save" })}
        </Button>
      </div>
    </div>
  );
};
