import { useRestApiProvider } from "@jugl-web/rest-api";
import { BoardRole } from "@jugl-web/rest-api/tasks";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { MobileDrawer, Tooltip } from "@jugl-web/ui-components";
import {
  Alert,
  Button,
  ConfirmationPopup,
  PlainButton,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import {
  ColorPickerPopover,
  ColorPickerPopoverContent,
} from "@jugl-web/ui-components/web/ColorPickerPopover/ColorPickerPopover";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { getFirstSymbol } from "@jugl-web/utils/utils/getFirstSymbol";
import { colord } from "colord";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { BoardUserPicker } from "./components/BoardUserPicker";

interface ManageTaskBoardDialogProps {
  entityId: string;
  meId: string;
  isOpen: boolean;
  board?: TaskBoardModel;
  isReadonly?: boolean;
  onClose: () => void;
  onOpenDeleteBoardDialog?: () => void;
  onAfterCreateBoard?: (boardId: string) => void;
}

interface FormValues {
  boardName: string;
  users: { id: string; type: BoardRole }[];
  color: string;
}

const DEFAULT_BOARD_COLOR = "#F33A9E";

const BOARD_NAME_MAX_LENGTH = 32;

export const ManageTaskBoardDialog: FC<ManageTaskBoardDialogProps> = ({
  entityId,
  meId,
  isOpen,
  board,
  isReadonly,
  onClose,
  onOpenDeleteBoardDialog,
  onAfterCreateBoard,
}) => {
  const [isLeaveConfirmationDialogOpen, setIsLeaveConfirmationDialogOpen] =
    useState(false);

  const [isMobileColorPickerOpen, setIsMobileColorPickerOpen] = useState(false);

  const emptyState = useMemo<FormValues>(
    () => ({
      boardName: "",
      users: [{ id: meId, type: "owner" }],
      color: DEFAULT_BOARD_COLOR,
    }),
    [meId]
  );

  const { control, formState, register, handleSubmit, watch, reset, setFocus } =
    useForm<FormValues>({ defaultValues: emptyState });

  const boardState = watch();

  const { tasksApi } = useRestApiProvider();
  const [createBoard, { isLoading: isCreatingInProgress }] =
    tasksApi.useCreateTaskBoardMutation();
  const [updateBoard, { isLoading: isUpdatingInProgress }] =
    tasksApi.useUpdateTaskBoardMutation();

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();
  const { toast } = useToast();

  const isEditMode = !!board;

  const handleCreateBoard = async () => {
    const users: { id: string; type: BoardRole }[] = boardState.users.filter(
      (user) => user.id !== meId
    );

    const response = await createBoard({
      data: {
        name: boardState.boardName.trim(),
        users,
        color: boardState.color,
      },
      entityId: entityId || "",
    });
    if ("data" in response) {
      onClose();
      toast(
        t({
          id: "feedback.board-created",
          defaultMessage: "Board has been created",
        })
      );
      onAfterCreateBoard?.(response.data.id);
    }
  };

  const handleEditBoard = async () => {
    if (!board?.id) return;

    const usersWithMe = boardState.users.find((user) => user.id === meId)?.type
      ? boardState.users
      : [...boardState.users, { id: meId, type: "owner" as BoardRole }];

    const response = await updateBoard({
      data: {
        name: boardState.boardName.trim(),
        color: boardState.color,
        users: usersWithMe,
      },
      boardId: board.id,
      entityId,
    });

    if ("data" in response) {
      onClose();
      toast(
        t({
          id: "feedback.board-updated",
          defaultMessage: "Board has been updated",
        })
      );
    }
  };

  const stepTitle = useMemo(() => {
    if (isEditMode) {
      return t({
        id: "tasks-board-dialog.board-details",
        defaultMessage: "Board details",
      });
    }

    return t({
      id: "tasks-board-dialog.new-board",
      defaultMessage: "New Board",
    });
  }, [isEditMode, t]);

  const makeNewUsersState = useCallback(
    (users: string[], type: "member" | "owner") => {
      let usersState = boardState.users;

      users.forEach((submittedUserId) => {
        const find = usersState.find((user) => user.id === submittedUserId);
        if (find) {
          find.type = type;
        }
      });

      usersState = usersState.filter((user) => user.type !== type);

      const newUsers = users.map((userId) => ({
        id: userId,
        type,
      }));

      const newState = usersState.concat(newUsers);
      return newState;
    },
    [boardState.users]
  );

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    reset(
      board
        ? {
            boardName: board.name,
            users: board.users.map((user) => ({
              id: user.id,
              type: user.type,
            })),
            color: board.color,
          }
        : emptyState
    );

    window.setTimeout(() => {
      setFocus("boardName");
    }, 300);
  }, [board, emptyState, isOpen, reset, setFocus]);

  const boardNamePlaceholder = useMemo(
    () =>
      t({
        id: "tasks-board-form.board-name.placeholder",
        defaultMessage: "Enter",
      }),
    [t]
  );

  const boardNamePreviewSymbol = useMemo(
    () =>
      getFirstSymbol(
        boardState.boardName.trim().length > 0
          ? boardState.boardName
          : boardNamePlaceholder
      ),
    [boardNamePlaceholder, boardState.boardName]
  );

  const colorPickerTextColor = useMemo(
    () =>
      colord(boardState.color).brightness() < 0.6
        ? "#ffffff"
        : colord(boardState.color).darken(0.7).toHex(),
    [boardState.color]
  );

  const colorComponent = useMemo(
    () => (
      <Controller
        name="color"
        control={control}
        render={({ field: { onChange, value } }) => {
          if (isMobile) {
            return (
              <>
                <PlainButton
                  className="font-secondary flex h-[80px] w-[80px] items-center justify-center rounded-[10.5px] text-[32px] uppercase transition hover:brightness-90"
                  style={{
                    backgroundColor: boardState.color,
                    color: colorPickerTextColor,
                  }}
                  onClick={() => setIsMobileColorPickerOpen(true)}
                >
                  {boardNamePreviewSymbol}
                </PlainButton>
                <MobileDrawer
                  isOpen={isMobileColorPickerOpen}
                  onClose={() => setIsMobileColorPickerOpen(false)}
                >
                  <MobileDrawer.Content className="pt-5">
                    <ColorPickerPopoverContent
                      onClose={() => setIsMobileColorPickerOpen(false)}
                      title={t({
                        id: "tasks-board-dialog.icon-color",
                        defaultMessage: "Icon color",
                      })}
                      color={value}
                      onColorChange={(color) => onChange(color)}
                    />
                  </MobileDrawer.Content>
                </MobileDrawer>
              </>
            );
          }

          return (
            <ColorPickerPopover
              title={t({
                id: "tasks-board-dialog.icon-color",
                defaultMessage: "Icon color",
              })}
              color={value}
              placement="right-start"
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  ref={triggerRef}
                  as={PlainButton}
                  disabled={isReadonly}
                  className="font-secondary flex h-[80px] w-[80px] items-center justify-center rounded-[10.5px] text-[32px] uppercase transition hover:brightness-90"
                  style={{
                    backgroundColor: boardState.color,
                    color: colorPickerTextColor,
                  }}
                >
                  {boardNamePreviewSymbol}
                </Trigger>
              )}
              onColorChange={(color) => {
                onChange(color);
              }}
            />
          );
        }}
      />
    ),
    [
      control,
      isMobile,
      t,
      colorPickerTextColor,
      boardState.color,
      boardNamePreviewSymbol,
      isMobileColorPickerOpen,
      isReadonly,
    ]
  );

  const userInputs = useMemo(
    () => (
      <div className="flex w-full flex-col items-center justify-center px-4">
        <div className="flex w-full flex-col gap-10">
          <TextField
            variant="underline"
            isFullWidth
            autoComplete="off"
            value={boardState.boardName}
            inputClassName="font-secondary text-dark text-2xl ml-0 pl-0 border-dark-100"
            labelClassName="text-dark-800 text-xs ml-0"
            placeholder={boardNamePlaceholder}
            label={t({
              id: "tasks-board-dialog.board-name",
              defaultMessage: "Board name",
            })}
            errorMessage={formState.errors.boardName?.message}
            maxLength={BOARD_NAME_MAX_LENGTH}
            {...register("boardName", {
              required: {
                value: true,
                message: t({
                  id: "feedback.field-required",
                  defaultMessage: "This field is required",
                }),
              },
              maxLength: BOARD_NAME_MAX_LENGTH,
            })}
          />
          <Controller
            name="users"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BoardUserPicker
                entityId={entityId}
                meId={meId}
                users={value}
                onChange={(members) => {
                  const newState = makeNewUsersState(members, "member");

                  onChange(newState);
                }}
                type="member"
              />
            )}
          />
          <Controller
            name="users"
            control={control}
            render={({ field: { onChange, value } }) => (
              <BoardUserPicker
                entityId={entityId}
                meId={meId}
                users={value}
                onChange={(owners, type) => {
                  const newState = makeNewUsersState(owners, type);

                  onChange(newState);
                }}
                type="owner"
              />
            )}
          />
        </div>
      </div>
    ),
    [
      boardNamePlaceholder,
      boardState.boardName,
      control,
      entityId,
      formState.errors.boardName?.message,
      makeNewUsersState,
      meId,
      register,
      t,
    ]
  );

  const handleSafeClose = () => {
    if (formState.isDirty) {
      setIsLeaveConfirmationDialogOpen(true);
      return;
    }

    onClose();
  };

  if (isMobile) {
    return (
      <>
        <MobileDrawer
          isOpen={isOpen}
          onClose={handleSafeClose}
          header={{
            title: stepTitle,
          }}
          size="xl"
        >
          <MobileDrawer.Content>
            <div className="flex flex-col gap-6 pt-[40px] pb-[32px]">
              <div className="flex justify-center">{colorComponent}</div>
              {userInputs}
            </div>
          </MobileDrawer.Content>
          <MobileDrawer.Actions>
            <Button
              onClick={
                isEditMode
                  ? handleSubmit(handleEditBoard)
                  : handleSubmit(handleCreateBoard)
              }
            >
              {isEditMode
                ? t({ id: "common.save", defaultMessage: "Save" })
                : t({ id: "common.create", defaultMessage: "Create" })}
            </Button>
          </MobileDrawer.Actions>
        </MobileDrawer>
        <ConfirmationPopup
          variant="mobile"
          isOpen={isLeaveConfirmationDialogOpen}
          title={t({
            id: "tasks-board-dialog.discard-changes",
            defaultMessage: "Discard changes",
          })}
          message={t({
            id: "tasks-board-dialog.discard-changes-description",
            defaultMessage: "If you discard, entered info will not be saved",
          })}
          hasCancelButton
          buttons={[
            {
              label: t({
                id: "common.discard",
                defaultMessage: "Discard",
              }),
              color: "tertiary",
              onClick: () => {
                onClose();
                setIsLeaveConfirmationDialogOpen(false);
              },
            },
          ]}
          onRequestClose={() => setIsLeaveConfirmationDialogOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <Alert
        className="z-50 w-[560px]"
        isOpen={isOpen && Boolean(meId)}
        onRequestClose={handleSafeClose}
        isCloseButtonVisible
        header={stepTitle}
        headerRightContent={
          isEditMode ? (
            <Tooltip
              placement="bottom"
              className="z-[999]"
              renderTrigger={({ props, ref }) => (
                <PlainButton
                  ref={ref}
                  className="hover:bg-tertiary-50 mr-6 flex h-8 w-8 items-center justify-center rounded-lg"
                  onClick={() => {
                    onClose();
                    onOpenDeleteBoardDialog?.();
                  }}
                  {...props}
                >
                  <DeleteIcon />
                </PlainButton>
              )}
            >
              {t({
                id: "tasks-board-dialog.delete-board",
                defaultMessage: "Delete Board",
              })}
            </Tooltip>
          ) : undefined
        }
        buttons={[
          {
            className: "max-w-[300px] h-10 mx-auto",
            isDisabled: isCreatingInProgress || isUpdatingInProgress,
            text: isEditMode
              ? t({ id: "common.save", defaultMessage: "Save" })
              : t({ id: "common.create", defaultMessage: "Create" }),
            color: "primary",
            onClick: isEditMode
              ? handleSubmit(handleEditBoard)
              : handleSubmit(handleCreateBoard),
          },
        ]}
        img={colorComponent}
        content={userInputs}
      />
      <Alert
        isOpen={isLeaveConfirmationDialogOpen}
        title={t({
          id: "tasks-board-dialog.discard-changes",
          defaultMessage: "Discard changes",
        })}
        content={t({
          id: "tasks-board-dialog.discard-changes-description",
          defaultMessage: "If you discard, entered info will not be saved",
        })}
        buttons={[
          {
            text: t({
              id: "common.cancel",
              defaultMessage: "Cancel",
            }),
            role: "close",
          },
          {
            text: t({
              id: "common.discard",
              defaultMessage: "Discard",
            }),
            color: "primary",
            onClick: () => {
              onClose();
              setIsLeaveConfirmationDialogOpen(false);
            },
          },
        ]}
        onRequestClose={() => setIsLeaveConfirmationDialogOpen(false)}
      />
    </>
  );
};
