import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";
import { TaskDueDateFormScreenToParametersMap } from "../../types";
import { TriggerButtonBase } from "../TriggerButtonBase";

export const CalendarTrigger = () => {
  const { selectedDate, setSelectedDate } = useTaskDueDateFormContext();

  const { transitionTo } =
    useScreenTransitionManager<TaskDueDateFormScreenToParametersMap>();

  const { formatDateLabel } = useFormattedDate();
  const { t } = useTranslations();

  return (
    <TriggerButtonBase
      label={t({ id: "tasks-page.date", defaultMessage: "Date" })}
      value={
        selectedDate
          ? formatDateLabel(selectedDate)
          : t({ id: "common.none", defaultMessage: "None" })
      }
      Icon={CalendarIcon}
      customEndSlot={
        selectedDate ? (
          <PlainButton
            onClick={(event) => {
              event.stopPropagation();
              setSelectedDate(null);
            }}
          >
            <RemoveIcon />
          </PlainButton>
        ) : undefined
      }
      onClick={() => transitionTo({ name: "calendar" })}
    />
  );
};
