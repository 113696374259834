import { ExportedTaskUser } from "@jugl-web/rest-api/tasks/models/common-types/ExportedTaskUser";
import { isoToLocalDate } from "@jugl-web/utils";
import format from "date-fns/format";

export const formatUserName = (user: Omit<ExportedTaskUser, "user_id">) =>
  [user.first_name, user.last_name].filter(Boolean).join(" ").trim();

export const formatUsersList = (list: ExportedTaskUser[]) =>
  list.map(formatUserName).join(", ");

export const formatDate = (date: string, dateLocale?: Locale) =>
  format(isoToLocalDate(date), "dd MMM, yyyy, h:mm a", {
    locale: dateLocale,
  });

export const parseMention = (input: string): string => {
  const mentionRegex = /@<[^>]+>\[([^\]]+)\]/g;
  return input.replace(mentionRegex, "@$1");
};
