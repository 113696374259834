import { HookOutOfContextError } from "@jugl-web/utils";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { TaskReminderOptionId } from "../../../hooks/useTaskReminders";
import { TaskDueDateFormProps } from "../types";

export interface TaskDueDateFormContextValue {
  formConfig: { id: string } & Required<
    Pick<
      TaskDueDateFormProps,
      "defaultTimezone" | "withCalendar" | "withAdvancedOptions"
    >
  >;
  selectedDate: Date | null;
  selectedDateInTargetTimezone: Date | null;
  selectedTimezone: string;
  selectedReminderId: TaskReminderOptionId;
  setSelectedDate: Dispatch<SetStateAction<Date | null>>;
  setSelectedTimezone: Dispatch<SetStateAction<string>>;
  setSelectedReminderId: Dispatch<SetStateAction<TaskReminderOptionId>>;
}

export const TaskDueDateFormContext =
  createContext<TaskDueDateFormContextValue | null>(null);

export const useTaskDueDateFormContext = () => {
  const context = useContext(TaskDueDateFormContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskDueDateFormContext",
      "TaskDueDateFormContext"
    );
  }

  return context;
};
