import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  DetailedTask,
  TasksSource,
  adaptDetailedTaskToPreviewTask,
  getTaskIdTag,
  previewTasksAdapter,
} from "@jugl-web/rest-api/tasks";
import { TASK_COMMENTS_TAGS_CREATORS } from "@jugl-web/rest-api/tasks-comments";
import {
  DetailedTaskTemplate,
  adaptDetailedTemplateToPreviewTemplate,
  previewTasksTemplatesAdapter,
} from "@jugl-web/rest-api/tasks-templates";
import { HookOutOfContextError, assert } from "@jugl-web/utils";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import { Observable, Subject, filter } from "rxjs";
import { LiveUpdateEvent } from "../../../live-updates";
import { MY_TASKS_SOURCE } from "../../consts";
import {
  TaskCommentLiveUpdateEvent,
  TaskLiveUpdateArchivedEvent,
  TaskLiveUpdateAttachmentAddedEvent,
  TaskLiveUpdateAttachmentDeletedEvent,
  TaskLiveUpdateAttachmentRenamedEvent,
  TaskLiveUpdateCreatedEvent,
  TaskLiveUpdateDeletedEvent,
  TaskLiveUpdateEvent,
  TaskLiveUpdateRestoredEvent,
  TaskLiveUpdateUpdatedEvent,
} from "./types";
import {
  isTaskArchivedEvent,
  isTaskAttachmentAddedEvent,
  isTaskAttachmentDeletedEvent,
  isTaskAttachmentRenamedEvent,
  isTaskCommentCreatedEvent,
  isTaskCommentDeletedEvent,
  isTaskCommentLiveUpdateEvent,
  isTaskCommentUpdatedEvent,
  isTaskCreatedEvent,
  isTaskDeletedEvent,
  isTaskLiveUpdateEvent,
  isTaskRestoredEvent,
  isTaskUpdatedEvent,
} from "./utils";

type TemplatesResponse = { isTemplate: true; template: DetailedTaskTemplate };
type TasksResponse = { isTemplate: false; task: DetailedTask };

type FetchTaskOrTemplateHandler = (args: {
  entityId: string;
  taskOrTemplateId: string;
  noCache?: true;
}) => Promise<TemplatesResponse | TasksResponse>;

interface TaskLiveUpdatesContextValue {
  taskCommentEvents$: Observable<TaskCommentLiveUpdateEvent>;
}

const TaskLiveUpdatesContext =
  createContext<TaskLiveUpdatesContextValue | null>(null);

export interface TaskLiveUpdatesProviderProps {
  children: React.ReactNode;
  events$: Subject<LiveUpdateEvent>;
  meId: string | undefined;
  isInTaskDetailsView: boolean;
}

export const TaskLiveUpdatesProvider: FC<TaskLiveUpdatesProviderProps> = ({
  children,
  events$,
  isInTaskDetailsView,
  meId,
}) => {
  const {
    moduleNotificationsApi,
    tasksApi,
    tasksCommentsApi,
    tasksTemplatesApi,
  } = useRestApiProvider();

  const [getTask] = tasksApi.useLazyGetTaskQuery();

  const taskEvents$ = useMemo(
    () => events$.pipe(filter(isTaskLiveUpdateEvent)),
    [events$]
  );

  const taskCommentEvents$ = useMemo(
    () => events$.pipe(filter(isTaskCommentLiveUpdateEvent)),
    [events$]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch = useDispatch<ThunkDispatch<any, unknown, AnyAction>>();

  const fetchTaskOrTemplate = useCallback<FetchTaskOrTemplateHandler>(
    async ({ entityId, taskOrTemplateId, noCache }) => {
      const response = await getTask({
        entityId,
        taskId: taskOrTemplateId,
        noCache,
      });

      if (!response.data) {
        throw new Error("Couldn't fetch task or template");
      }

      if (response.data.type === "template") {
        return {
          isTemplate: true,
          template: response.data as unknown as DetailedTaskTemplate,
        };
      }

      return {
        isTemplate: false,
        task: response.data,
      };
    },
    [getTask]
  );

  const handleTaskCreatedEvent = useCallback(
    async (event: TaskLiveUpdateCreatedEvent) => {
      const { id, entity_id: entityId } = event.data;

      try {
        const response = await fetchTaskOrTemplate({
          entityId,
          taskOrTemplateId: id,
        });

        if (response.isTemplate) {
          const addTemplateToCollectionAction =
            tasksTemplatesApi.util.updateQueryData(
              "getTemplates",
              { entityId },
              (state) => {
                previewTasksTemplatesAdapter.addOne(
                  state,
                  adaptDetailedTemplateToPreviewTemplate(response.template)
                );
              }
            );

          dispatch(addTemplateToCollectionAction);
          return;
        }

        const addTaskToCollectionAction = tasksApi.util.updateQueryData(
          "getTasks",
          { entityId, source: MY_TASKS_SOURCE },
          (state) => {
            // Assertions are needed here because, for some reason, there's a type error in CI
            previewTasksAdapter.addOne(
              state,
              adaptDetailedTaskToPreviewTask((response as TasksResponse).task)
            );
          }
        );

        dispatch(addTaskToCollectionAction);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [dispatch, fetchTaskOrTemplate, tasksApi.util, tasksTemplatesApi.util]
  );

  const handleTaskRestoredEvent = useCallback(
    async (event: TaskLiveUpdateRestoredEvent) => {
      const { id, entity_id: entityId } = event.data;

      try {
        const response = await fetchTaskOrTemplate({
          entityId,
          taskOrTemplateId: id,
        });

        const addTaskToCollectionAction = tasksApi.util.updateQueryData(
          "getTasks",
          { entityId, source: MY_TASKS_SOURCE },
          (state) => {
            // Assertions are needed here because, for some reason, there's a type error in CI
            previewTasksAdapter.addOne(
              state,
              adaptDetailedTaskToPreviewTask((response as TasksResponse).task)
            );
          }
        );

        dispatch(addTaskToCollectionAction);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [dispatch, fetchTaskOrTemplate, tasksApi.util]
  );

  const handleTaskUpdatedEvent = useCallback(
    async (event: TaskLiveUpdateUpdatedEvent) => {
      const { id, entity_id: entityId } = event.data;

      try {
        const response = await fetchTaskOrTemplate({
          entityId,
          taskOrTemplateId: id,
          noCache: true,
        });

        if (response.isTemplate) {
          const updatePreviewTemplateAction =
            tasksTemplatesApi.util.updateQueryData(
              "getTemplates",
              { entityId },
              (state) => {
                previewTasksTemplatesAdapter.updateOne(state, {
                  id: response.template.id,
                  changes: adaptDetailedTemplateToPreviewTemplate(
                    response.template
                  ),
                });
              }
            );

          const updateDetailedTemplateAction =
            tasksTemplatesApi.util.updateQueryData(
              "getTemplate",
              { entityId, templateId: response.template.id },
              (state) => {
                Object.assign(state, response.template);
              }
            );

          dispatch(updatePreviewTemplateAction);
          dispatch(updateDetailedTemplateAction);
          return;
        }

        const updateDetailedTaskAction = tasksApi.util.updateQueryData(
          "getTask",
          { entityId, taskId: response.task.id },
          (state) => {
            Object.assign(state, response.task);
          }
        );

        // Collect all the collections that might have the task
        const potentialSourcesToUpdate: (TasksSource | null)[] = [
          { type: "boardTasks", boardId: "my" },
          { type: "boardTasks", boardId: "team" },
          // If the task belongs to a customer, update the customer tasks collection
          response.task.cust_id
            ? { type: "customerTasks", customerId: response.task.cust_id }
            : null,
          // If the task belongs to a board, update the board tasks collection
          response.task.board_id
            ? { type: "boardTasks", boardId: response.task.board_id }
            : null,
        ];

        const relevantSourcesToUpdate = potentialSourcesToUpdate.filter(
          (source): source is TasksSource => Boolean(source)
        );

        const updatePreviewTaskActions = relevantSourcesToUpdate.map((source) =>
          tasksApi.util.updateQueryData(
            "getTasks",
            { entityId, source },
            (state) => {
              const taskToUpdate = state.entities[response.task.id];

              // If the task was updated locally before the request was completed,
              // we don't need to update it as the local changes might be more actual
              const isIncomingTaskOutdated =
                taskToUpdate &&
                taskToUpdate.updated_at >= response.task.updated_at;

              if (isIncomingTaskOutdated) {
                return;
              }

              previewTasksAdapter.upsertOne(
                state,
                adaptDetailedTaskToPreviewTask(response.task)
              );
            }
          )
        );

        updatePreviewTaskActions.forEach((action) => dispatch(action));
        dispatch(updateDetailedTaskAction);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [dispatch, fetchTaskOrTemplate, tasksApi.util, tasksTemplatesApi.util]
  );

  const handleTaskDeletedEvent = useCallback(
    (event: TaskLiveUpdateDeletedEvent | TaskLiveUpdateArchivedEvent) => {
      const { id, entity_id: entityId } = event.data;

      const removeTaskFromCollectionAction = tasksApi.util.updateQueryData(
        "getTasks",
        { entityId, source: MY_TASKS_SOURCE },
        (state) => {
          previewTasksAdapter.removeOne(state, id);
        }
      );

      const removeTemplateFromCollectionAction =
        tasksTemplatesApi.util.updateQueryData(
          "getTemplates",
          { entityId },
          (state) => {
            previewTasksTemplatesAdapter.removeOne(state, id);
          }
        );

      dispatch(
        tasksApi.util.updateQueryData(
          "getTask",
          { entityId, taskId: id },
          (state) => {
            Object.assign<DetailedTask, Partial<DetailedTask>>(state, {
              _is_deleted: true,
            });
          }
        )
      );

      // At this point, we don't know if the event refers to a task or a template,
      // so we attempt to remove it from both collections, just in case
      dispatch(removeTaskFromCollectionAction);
      dispatch(removeTemplateFromCollectionAction);
    },
    [dispatch, tasksApi.util, tasksTemplatesApi.util]
  );

  const handleTaskAttachmentAddedEvent = useCallback(
    (event: TaskLiveUpdateAttachmentAddedEvent) => {
      const { id: taskId } = event.data;

      const invalidateTaskTagAction = tasksApi.util.invalidateTags([
        getTaskIdTag(taskId),
      ]);

      dispatch(invalidateTaskTagAction);
    },
    [dispatch, tasksApi.util]
  );

  const handleTaskAttachmentRenamedEvent = useCallback(
    (event: TaskLiveUpdateAttachmentRenamedEvent) => {
      const {
        id: taskId,
        entity_id: entityId,
        attachment_id: attachmentId,
        file_name: fileName,
      } = event.data;

      const renameAttachmentAction = tasksApi.util.updateQueryData(
        "getTask",
        { entityId, taskId },
        (task) => {
          const index = task.attachments.findIndex(
            (attachment) => attachment.id === attachmentId
          );

          if (index === -1) {
            return;
          }

          task.attachments[index].name = fileName;
        }
      );

      dispatch(renameAttachmentAction);
    },
    [dispatch, tasksApi.util]
  );

  const handleTaskAttachmentDeletedEvent = useCallback(
    (event: TaskLiveUpdateAttachmentDeletedEvent) => {
      const {
        id: taskId,
        entity_id: entityId,
        attachment_id: attachmentId,
      } = event.data;

      const removeAttachmentAction = tasksApi.util.updateQueryData(
        "getTask",
        { entityId, taskId },
        (task) => {
          const index = task.attachments.findIndex(
            (attachment) => attachment.id === attachmentId
          );

          if (index === -1) {
            return;
          }

          task.attachments.splice(index, 1);
        }
      );

      dispatch(removeAttachmentAction);
    },
    [dispatch, tasksApi]
  );

  // Every time a task live update arrives, we manually mark notifications
  // as unread, triggering the red indicator without the need to send requests
  // to the backend
  const markNotificationsAsUnread = useCallback(
    (entityId: string, updateProducerId: string) => {
      assert(!!meId);

      const isSelfUpdate = updateProducerId === meId;

      if (isSelfUpdate) {
        return;
      }

      const taskNotificationsUnreadIndicatorUpdateAction =
        moduleNotificationsApi.util.updateQueryData(
          "getModuleNotificationsUnreadIndicator",
          { entityId, module: "task" },
          () => ({ unread: true })
        );

      dispatch(taskNotificationsUnreadIndicatorUpdateAction);
    },
    [dispatch, meId, moduleNotificationsApi.util]
  );

  const onTaskLiveUpdateEvent = useCallback(
    (event: TaskLiveUpdateEvent<string, object>) => {
      if (isTaskCreatedEvent(event)) {
        handleTaskCreatedEvent(event);
        markNotificationsAsUnread(event.data.entity_id, event.action_by);
        return;
      }

      if (isTaskRestoredEvent(event)) {
        handleTaskRestoredEvent(event);
        markNotificationsAsUnread(event.data.entity_id, event.action_by);
      }

      if (isTaskUpdatedEvent(event)) {
        handleTaskUpdatedEvent(event);
        markNotificationsAsUnread(event.data.entity_id, event.action_by);
        return;
      }

      if (isTaskDeletedEvent(event) || isTaskArchivedEvent(event)) {
        handleTaskDeletedEvent(event);
        markNotificationsAsUnread(event.data.entity_id, event.action_by);
        return;
      }

      if (isTaskAttachmentAddedEvent(event)) {
        handleTaskAttachmentAddedEvent(event);
        return;
      }

      if (isTaskAttachmentRenamedEvent(event)) {
        handleTaskAttachmentRenamedEvent(event);
        return;
      }

      if (isTaskAttachmentDeletedEvent(event)) {
        handleTaskAttachmentDeletedEvent(event);
        return;
      }

      // eslint-disable-next-line no-console
      console.warn(
        `Unhandled task live update event action: ${event.action}`,
        event
      );
    },
    [
      handleTaskCreatedEvent,
      markNotificationsAsUnread,
      handleTaskRestoredEvent,
      handleTaskUpdatedEvent,
      handleTaskDeletedEvent,
      handleTaskAttachmentAddedEvent,
      handleTaskAttachmentRenamedEvent,
      handleTaskAttachmentDeletedEvent,
    ]
  );

  const onTaskCommentLiveUpdateEvent = useCallback(
    (event: TaskCommentLiveUpdateEvent<string, object>) => {
      if (isInTaskDetailsView) {
        return;
      }

      if (
        isTaskCommentLiveUpdateEvent(event) &&
        (isTaskCommentCreatedEvent(event) ||
          isTaskCommentUpdatedEvent(event) ||
          isTaskCommentDeletedEvent(event))
      ) {
        const invalidateTaskCommentsTagsAction =
          tasksCommentsApi.util.invalidateTags([
            TASK_COMMENTS_TAGS_CREATORS.TASK_ID(event.data.task_id),
          ]);

        dispatch(invalidateTaskCommentsTagsAction);
      }
    },
    [dispatch, isInTaskDetailsView, tasksCommentsApi.util]
  );

  useEffect(() => {
    const taskEventsSubscription = taskEvents$.subscribe(onTaskLiveUpdateEvent);
    const taskCommentEventsSubscription = taskCommentEvents$.subscribe(
      onTaskCommentLiveUpdateEvent
    );

    return () => {
      taskEventsSubscription.unsubscribe();
      taskCommentEventsSubscription.unsubscribe();
    };
  }, [
    taskEvents$,
    onTaskLiveUpdateEvent,
    taskCommentEvents$,
    onTaskCommentLiveUpdateEvent,
  ]);

  const contextValue = useMemo<TaskLiveUpdatesContextValue>(
    () => ({ taskCommentEvents$ }),
    [taskCommentEvents$]
  );

  return (
    <TaskLiveUpdatesContext.Provider value={contextValue}>
      {children}
    </TaskLiveUpdatesContext.Provider>
  );
};

export const useTaskLiveUpdates = () => {
  const context = useContext(TaskLiveUpdatesContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskLiveUpdates",
      "TaskLiveUpdatesContext"
    );
  }

  return context;
};
