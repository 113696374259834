import { emptySplitApi } from "@web-src/features/api/createApi";
import { addSearchParamsToURL } from "@jugl-web/utils";
import {
  ChatMessage,
  ChatHistoryItem,
  ChatItem,
  ChatType,
  SearchChatMessage,
  MessagesApiFetchParams,
  CallListHistoryItem,
} from "./types";
import { messageToConversationLastMessage } from "./utils";

const apiWithTags = emptySplitApi.enhanceEndpoints({});

export const messagesApi = apiWithTags.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    chatHistory: build.query<
      ChatItem[],
      {
        params: {
          entity_id: string;
          time: string;
          limit: number;
          self_chat?: "true";
        };
      }
    >({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async ({ params }, a1, a2, baseQuery) => {
        const response:
          | {
              data: { chat_list: ChatHistoryItem[]; remaining_item: true };
            }
          | { error: unknown } = await baseQuery(
          addSearchParamsToURL(`/api/auth/messages/chat_history`, params)
        );
        if ("error" in response) {
          return { error: response.error, __NETWORK_ERROR: true };
        }
        let chatHistoryItems = response.data.chat_list;
        let remainingItem = response.data.remaining_item;
        while (!params.self_chat && remainingItem) {
          const whileResponse:
            | {
                data: { chat_list: ChatHistoryItem[]; remaining_item: true };
              }
            // TODO:
            // eslint-disable-next-line no-await-in-loop
            | { error: unknown } = await baseQuery(
            addSearchParamsToURL(`/api/auth/messages/chat_history`, {
              ...params,
              time: chatHistoryItems[chatHistoryItems.length - 1].conversation
                ?.timestamp,
            })
          );
          if ("error" in whileResponse) {
            return { error: whileResponse.error, __NETWORK_ERROR: true };
          }
          remainingItem = whileResponse.data.remaining_item;
          chatHistoryItems = [
            ...chatHistoryItems,
            ...whileResponse.data.chat_list,
          ];
        }
        const chatItems: ChatItem[] = chatHistoryItems
          // TODO: @critical, check with backend
          .filter((item) => !!item.conversation)
          .map((item) => ({
            id: item.conversation?.id,
            img: item.conversation?.img,
            title: item.conversation?.title,
            type: item.conversation?.type || ChatType.chat,
            isSelf: !item.conversation,
            unreadCount: item.unread_count?.unread_count,
            userCount: item.conversation?.user_count,
            users: item.conversation?.users,
            firstUnreadMessage: item.unread_count?.first_item
              ? {
                  msgId: item.unread_count.first_item.msg_id,
                  createdAt: item.unread_count.first_item.created_at,
                }
              : undefined,
            lastMessage: messageToConversationLastMessage(item),
          }));
        return { data: chatItems };
      },
    }),
    callsHistory: build.query<
      {
        call_list: CallListHistoryItem[];
        remaining_item: boolean;
      },
      {
        params: {
          entity_id: string;
          time: string;
          limit: number;
        };
      }
    >({
      query: ({ params }) => ({
        url: "/api/auth/messages/chat_history",
        params: { type: "call", ...params },
      }),
      transformResponse: (data: {
        call_list?: CallListHistoryItem[];
        // BACKEND TYPO
        remainig_item: boolean;
      }) => ({
        call_list: data.call_list || [],
        remaining_item: data.remainig_item,
      }),
    }),
    messages: build.query<
      { data: ChatMessage[]; remaining_item: boolean },
      {
        params: MessagesApiFetchParams;
      }
    >({
      query: ({ params }) => ({
        url: `/api/auth/messages`,
        params: {
          ...params,
          time: params.time ? params.time.replace("T", " ") : undefined,
        },
        transformResponse: (response: string) => {
          const parsedResponse: {
            data: ChatMessage[];
            remaining_item: boolean;
          } = JSON.parse(response);
          return {
            ...parsedResponse,
            data: parsedResponse.data.filter(
              (item) => item.payload?.type !== "call"
            ),
          };
        },
      }),
    }),
    messageInfo: build.query<
      {
        data: {
          delivered: { action_at: string; user_id: string }[];
          read: { action_at: string; user_id: string }[];
          participants: number;
        };
      },
      {
        msgId: string;
      }
    >({
      query: ({ msgId }) => ({
        url: `/api/auth/messages/${msgId}/info`,
      }),
    }),
    messageSearch: build.query<
      {
        current_entries: number;
        data: SearchChatMessage[];
        total_entries: number;
      },
      {
        params: {
          term: string;
          chat_id: string;
          entity_id: string;
          start_from?: number;
        };
      }
    >({
      query: ({ params }) => ({
        url: `/api/auth/messages/search`,
        params,
      }),
    }),
  }),
});

export const {
  useMessageInfoQuery,
  useMessagesQuery,
  useLazyMessagesQuery,
  useChatHistoryQuery,
  useLazyChatHistoryQuery,
  useMessageSearchQuery,
  useLazyMessageSearchQuery,
  useLazyCallsHistoryQuery,
} = messagesApi;
