import { SearchInput } from "@jugl-web/ui-components/cross-platform/SearchInput";
import { useSearchInput } from "@jugl-web/utils/hooks";
import { FC, useEffect, useId } from "react";

export interface TopBarSearchInputProps {
  initialSearchQuery: string;
  hasSettingsIndicator?: boolean;
  onSearch: (searchQuery: string) => void;
  onClose: () => void;
  onSettingsClick?: () => void;
}

export const TopBarSearchInput: FC<TopBarSearchInputProps> = ({
  initialSearchQuery,
  hasSettingsIndicator,
  onSearch,
  onClose,
  onSettingsClick,
}) => {
  const inputId = useId();

  const { inputProps, reset } = useSearchInput({
    defaultValue: initialSearchQuery,
    onSearch,
  });

  const handleClear = () => {
    if (inputProps.value) {
      reset();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    window.setTimeout(() => {
      const inputElement = document.getElementById(inputId);
      inputElement?.focus();
    }, 500);
  }, [inputId]);

  return (
    <SearchInput
      id={inputId}
      variant="filled"
      color="grey"
      alwaysShowClear
      containerClassName="w-full"
      onClear={handleClear}
      onSettingsClick={onSettingsClick}
      hasSettingsIndicator={hasSettingsIndicator}
      {...inputProps}
    />
  );
};
