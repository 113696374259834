import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import { Tooltip } from "@jugl-web/ui-components/web";
import { assignRefs, useTranslations } from "@jugl-web/utils";
import { Dispatch, FC, SetStateAction } from "react";
import { ReactComponent as FilterActivityIcon } from "./icons/filter-activity.svg";

interface TaskActivityFilterMenuTriggerProps {
  activityFilters: string[];
  setActivityFilters: Dispatch<SetStateAction<string[]>>;
}

export const TaskActivityFilterMenuTrigger: FC<
  TaskActivityFilterMenuTriggerProps
> = ({ activityFilters, setActivityFilters }) => {
  const { t } = useTranslations();

  const handleFilterSelect = (filter: string) => {
    setActivityFilters((previousActivityFilters) => {
      const isChecked = previousActivityFilters.includes(filter);

      if (isChecked) {
        return previousActivityFilters.filter((f) => f !== filter);
      }

      return [...previousActivityFilters, filter];
    });
  };

  return (
    <Tooltip
      renderTrigger={({ props, ref }) => (
        <Menu
          placement="bottom-end"
          sections={[
            [
              {
                id: "comments",
                label: t({
                  id: "common.comments",
                  defaultMessage: "Comments",
                }),
                onSelect: () => handleFilterSelect("comments"),
                toggle: "checkbox",
                isSelected: activityFilters.includes("comments"),
              },
              {
                id: "updates",
                label: t({
                  id: "tasks-page.task-updates",
                  defaultMessage: "Task Updates",
                }),
                onSelect: () => handleFilterSelect("updates"),
                toggle: "checkbox",
                isSelected: activityFilters.includes("updates"),
              },
              {
                id: "actions",
                label: t({
                  id: "tasks-page.user-actions",
                  defaultMessage: "User Actions",
                }),
                onSelect: () => handleFilterSelect("actions"),
                toggle: "checkbox",
                isSelected: activityFilters.includes("actions"),
              },
            ],
          ]}
          className="z-[100]"
          renderTrigger={({ Trigger, triggerRef }) => (
            <Trigger
              as={PlainButton}
              ref={assignRefs([ref, triggerRef])}
              className="jugl__focusable-outline hover:bg-grey/5 relative flex h-[34px] w-[34px] shrink-0 items-center justify-center rounded transition-colors"
              {...props}
            >
              <FilterActivityIcon />
              {activityFilters.length > 0 && (
                <div className="border-px bg-gradients-success absolute right-[5px] top-[5px] h-2.5 w-2.5 rounded-full border-solid border-white" />
              )}
            </Trigger>
          )}
        />
      )}
    >
      {t({
        id: "tasks-page.filter-activity",
        defaultMessage: "Filter Activity",
      })}
    </Tooltip>
  );
};
