import {
  UnreadIndicatorSupportedModule,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect } from "react";

interface UseMarkModuleAsReadOptions {
  entityId: string;
  module: UnreadIndicatorSupportedModule;
  boardId?: string;
  skip?: boolean;
}

export const useMarkModuleAsRead = ({
  entityId,
  module,
  boardId,
  skip,
}: UseMarkModuleAsReadOptions) => {
  const { entitiesApi } = useRestApiProvider();

  const { data: moduleUnreadIndicators } =
    entitiesApi.endpoints.getModuleUnreadIndicators.useQueryState(
      entityId ? { entityId } : skipToken
    );

  const [updateModuleUnreadIndicators] =
    entitiesApi.useUpdateModuleUnreadIndicatorsMutation();

  const [markEntityModuleAsRead] =
    entitiesApi.useMarkEntityModuleAsReadMutation();

  useEffect(() => {
    if (!entityId || !moduleUnreadIndicators || skip) {
      return;
    }

    if (
      module === "task" &&
      moduleUnreadIndicators.task[boardId || "my"] === 0
    ) {
      return;
    }

    if (moduleUnreadIndicators[module] === 0) {
      return;
    }

    // Resets the counter in advance to avoid the indicator's flashing effect in the sidebar
    updateModuleUnreadIndicators({
      entityId,
      state: {
        ...moduleUnreadIndicators,
        [module]:
          module === "task"
            ? { ...moduleUnreadIndicators.task, [boardId || "my"]: 0 }
            : 0,
      },
    });

    markEntityModuleAsRead({ entityId, module, boardId });
  }, [
    entityId,
    boardId,
    module,
    moduleUnreadIndicators,
    skip,
    markEntityModuleAsRead,
    updateModuleUnreadIndicators,
  ]);
};
