import { AxiosResponse } from "axios";

export type ImportTasksAPIError = AxiosResponse<
  { errors: { field: string; message: string }[] } | { error: string }
>;

export function isImportTasksApiError(
  error: unknown
): error is ImportTasksAPIError {
  return !!(error && typeof error === "object" && "data" in error);
}
