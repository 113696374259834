import { TaskPriority } from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import {
  ResourcePickerDrawer,
  ResourcePickerDrawerProps,
} from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import {
  ResourcePickerPopover,
  ResourcePickerPopoverProps,
} from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { colord } from "colord";
import { FC, useMemo, useState } from "react";
import {
  TaskPriorityDetails,
  useTaskPriorities,
} from "../../../../hooks/useTaskPriorities";
import { ReactComponent as PriorityIcon } from "../../assets/priority.svg";
import { FieldComponentProps } from "../../types";

export const PriorityField: FC<FieldComponentProps<"priority">> = ({
  priority,
  isReadonly,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const isFieldVisible = priority !== TaskPriority.none || !isReadonly;

  const { allPriorities, getPriorityDetailsById } = useTaskPriorities();

  const priorityDetails = getPriorityDetailsById(priority);
  const hasPriority = priority !== TaskPriority.none;

  const prioritiesAsListItems = useMemo<ListBoxItem<TaskPriorityDetails>[]>(
    () => allPriorities.map((p) => ({ id: p.id, value: p })),
    [allPriorities]
  );

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: hasPriority ? <priorityDetails.Icon /> : <PriorityIcon />,
    className: cx(
      hasPriority &&
        !isReadonly &&
        "hover:brightness-100 hover:backdrop-brightness-95"
    ),
    style: {
      color: hasPriority ? priorityDetails.textColor : undefined,
      backgroundColor: hasPriority
        ? colord(priorityDetails.textColor).alpha(0.12).toHex()
        : undefined,
    },
    children: hasPriority
      ? t(
          {
            id: "tasks-page.priority-with-label",
            defaultMessage: "Priority: {label}",
          },
          {
            label: priorityDetails.shortLabel,
          }
        )
      : t({
          id: "tasks-page.add-priority",
          defaultMessage: "Add priority",
        }),
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-task-priority",
      defaultMessage: "Select task priority",
    }),
    items: prioritiesAsListItems,
    selectionBehavior: { mode: "single", canToggle: false },
    defaultSelectedIds: [priority],
    maxVisibleItems: 8,
    itemSize: "lg",
    spaceBetweenItems: "compact",
    renderLabel: (item) => item.value.shortLabel,
    renderStartIcon: (item) => <item.value.Icon />,
    onSelect: ({ item, onClose }) => {
      onChange?.(item.value.id);
      onClose();
    },
  } satisfies Partial<
    | ResourcePickerDrawerProps<TaskPriorityDetails>
    | ResourcePickerPopoverProps<TaskPriorityDetails>
  >;

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          {...commonResourcePickerProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      {...commonResourcePickerProps}
    />
  );
};
