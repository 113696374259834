import { useRestApiProvider } from "@jugl-web/rest-api";
import { SideImageLayout } from "@jugl-web/ui-components/web/layouts";
import { cx, useTranslations } from "@jugl-web/utils";
import { useUserAuth } from "@web-src/modules/auth/providers/UserAuthProvider";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useEffectOnce } from "react-use";
import { useLinkToOpenAfterLogin } from "@web-src/modules/auth/hooks/useLinkToOpenAfterLogin";
import { EntitiesTab } from "./components/EntitiesTab";
import { InvitationsTab } from "./components/InvitationsTab";
import { ReactComponent as LogoutIcon } from "./assets/logout-icon.svg";
import AccesDeniedAnimationData from "./assets/access-denied-animation.json";
import { EntityLinkInvitationAlert } from "./components/EntityLinkInvitationAlert";
import {
  EntitiesListProvider,
  useEntitiesListProvider,
} from "../../../../features/entities/providers/EntitiesListProvider/EntitiesListProvider";

type PageTab = "workspaces" | "invitations";

export const EntitySelectPageContent: React.FC = () => {
  const { usersApi } = useRestApiProvider();
  const { logout } = useUserAuth();
  const { data: me } = usersApi.useGetUserProfileQuery({});
  const navigate = useNavigate();
  const [isAccessDeniedAlertOpen, setAccessDeniedAlertOpen] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [linkToOpenAfterLogin, setLinkToOpenAfterLogin] =
    useLinkToOpenAfterLogin();
  const {
    entityInvitesState: { entityInvites },
  } = useEntitiesListProvider();

  const { t } = useTranslations();

  const [selectedTab, setSelectedTab] = useState<PageTab>("workspaces");
  const tabs: {
    tab: PageTab;
    title: string;
    counter?: number;
  }[] = useMemo(
    () => [
      { tab: "workspaces", title: "My Workspaces" },
      {
        tab: "invitations",
        title: "Invitations",
        counter: entityInvites?.length,
      },
    ],
    [entityInvites?.length]
  );

  const welcomeString = useMemo(
    () =>
      `${t({
        id: "entity-select-page.welcome-to-jugl",
        defaultMessage: "Welcome to Jugl",
      })}${me?.general?.first_name ? `, ${me?.general?.first_name}` : ""} 👋🏻`,
    [me?.general?.first_name, t]
  );
  useEffectOnce(() => {
    if (linkToOpenAfterLogin) {
      navigate(linkToOpenAfterLogin);
      setLinkToOpenAfterLogin(null);
    }
  });
  useEffect(() => {
    if (searchParams.get("error") === "access_denied") {
      setAccessDeniedAlertOpen(true);
      setSearchParams({});
    }
  }, [searchParams, setSearchParams]);
  return (
    <>
      <EntityLinkInvitationAlert />
      <SideImageLayout
        menu={[
          {
            id: "logout",
            label: "Logout",
            onClick: () => logout(true),
            icon: <LogoutIcon />,
          },
        ]}
      >
        <div className="flex w-[480px] flex-col items-center">
          <div className="text-dark font-secondary mb-10 text-center text-[32px] font-semibold leading-8">
            {welcomeString}
          </div>
          <div className="w-[360px]">
            <div className="grid grid-cols-2">
              {tabs.map((item, idx) => (
                <div key={+idx} onClick={() => setSelectedTab(item.tab)}>
                  <div
                    className={cx(
                      "flex h-[42px] cursor-pointer items-center justify-center gap-2",
                      {
                        "text-grey-800": item.tab !== selectedTab,
                      }
                    )}
                  >
                    <span>{item.title}</span>
                    {item.counter !== undefined ? (
                      <div className="bg-grey-200 text-grey-800 flex h-[18px] items-center justify-center rounded-full px-[6px] text-xs">
                        {item.counter}
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
            <div className="relative mt-1 h-1 rounded-lg bg-gray-200">
              <div
                className={cx(
                  "bg-primary-500 absolute h-full w-1/2 rounded-lg transition-all",
                  selectedTab === "workspaces" ? "left-0" : "left-1/2"
                )}
              />
            </div>
          </div>
          <div className="jugl__custom-scrollbar -mr-2 mt-16 h-[50vh] w-full overflow-y-auto pr-2">
            {selectedTab === "workspaces" && <EntitiesTab />}
            {selectedTab === "invitations" && <InvitationsTab />}
          </div>
        </div>
      </SideImageLayout>
      <Alert
        isOpen={isAccessDeniedAlertOpen}
        buttons={[
          {
            text: t({
              id: "common.okay",
              defaultMessage: "Okay",
            }),
            color: "primary",
            role: "close",
            onClick: () => {
              setAccessDeniedAlertOpen(false);
            },
          },
        ]}
        img={
          <Lottie
            options={{
              animationData: AccesDeniedAnimationData,
            }}
            width={300}
            height={262}
          />
        }
        title={t({
          id: "org-select-page.not-part-of-workspace",
          defaultMessage: "You're not a part of this Workspace",
        })}
        content={t(
          {
            id: "org-select-page.not-part-of-workspace-description",
            defaultMessage:
              "{resourceName} belongs to Workspace, that you are not a member of. Only members of this Workspace can access",
          },
          {
            resourceName: (
              <span className="text-primary font-semibold">
                {searchParams.get("resourceName") || "Resource"}
              </span>
            ),
          }
        )}
      />
    </>
  );
};

export const EntitySelectPage: React.FC = () => (
  <EntitiesListProvider>
    <EntitySelectPageContent />
  </EntitiesListProvider>
);
