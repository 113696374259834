import { cx } from "@jugl-web/utils";
import { colord } from "colord";
import { FC } from "react";

export interface CustomColorBoxProps {
  hexColor: string;
  size?: "md" | "lg";
  variant?: "outlined" | "plain";
  className?: string;
}

export const CustomColorBox: FC<CustomColorBoxProps> = ({
  hexColor,
  size = "md",
  variant = "outlined",
  className,
}) => (
  <div
    className={cx(
      "rounded-lg transition",
      size === "md" ? "h-8 w-8" : "h-10 w-10",
      variant === "outlined" && "border-4 border-solid border-white",
      className
    )}
    style={{ backgroundColor: colord(hexColor).alpha(0.5).toHex() }}
  />
);
