import { useRestApiProvider } from "@jugl-web/rest-api";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { MobileDrawer } from "@jugl-web/ui-components";
import {
  Alert,
  Button,
  TextField,
} from "@jugl-web/ui-components/cross-platform";
import { useAppVariant, useToast, useTranslations } from "@jugl-web/utils";
import { getFirstSymbol } from "@jugl-web/utils/utils/getFirstSymbol";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import Lottie from "react-lottie";
import dangerAnimation from "./assets/danger-animation.json";

interface DeleteBoardConfirmationDialogProps {
  entityId: string;
  isOpen: boolean;
  board: TaskBoardModel;
  onClose: () => void;
  onAfterDelete?: (boardId: string) => void;
}

export const DeleteBoardConfirmationDialog: FC<
  DeleteBoardConfirmationDialogProps
> = ({ isOpen, entityId, board, onClose, onAfterDelete }) => {
  const [repeatedBoardName, setRepeatedBoardName] = useState("");

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();
  const { toast } = useToast();

  const { tasksApi } = useRestApiProvider();

  const [deleteBoard, { isLoading: isDeleteInProgress }] =
    tasksApi.useDeleteTaskBoardMutation();

  const handleConfirm = async () => {
    const response = await deleteBoard({
      entityId,
      boardId: board.id,
    });

    if ("data" in response) {
      toast(
        t({
          id: "feedback.board-removed",
          defaultMessage: "Board has been removed",
        })
      );
      onAfterDelete?.(board.id);
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setRepeatedBoardName("");
      window.setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  }, [isOpen]);

  const content = useMemo(
    () => (
      <div className="flex flex-col">
        <span className="text-dark-800 leading-4">
          {t(
            {
              id: "tasks-board-dialog.confirm-delete-board",
              defaultMessage:
                "Are you sure you want to delete the board <b>{name}</b>? To confirm this action, please type the name of the board you wish to delete.",
            },
            {
              name: board.name,
              b: (text: (string | JSX.Element)[]) => (
                <span className="text-primary-800 font-semibold">{text}</span>
              ),
            }
          )}
        </span>
        <TextField
          className="mt-8"
          ref={inputRef}
          value={repeatedBoardName}
          placeholder={t({
            id: "tasks-board-dialog.delete-board-input-placeholder",
            defaultMessage: "Enter board name",
          })}
          isFullWidth
          onChange={(event) => setRepeatedBoardName(event.target.value)}
        />
      </div>
    ),
    [board.name, repeatedBoardName, t]
  );

  if (isMobile) {
    return (
      <MobileDrawer
        header={{ title: "", hasCloseButton: false }}
        isOpen={isOpen}
        onClose={onClose}
        onTransitionEnd={() => {
          if (isOpen) {
            inputRef.current?.focus();
          }
        }}
      >
        <MobileDrawer.Content className="pt-2">
          <div className="flex flex-col items-center text-center">
            <div
              style={{ backgroundColor: board.color }}
              className="font-secondary relative mb-5 flex h-[60px] w-[60px] items-center justify-center rounded-[10.5px] text-[32px] uppercase text-white transition"
            >
              {getFirstSymbol(board.name)}
              <div className="absolute -right-[20px] -bottom-[20px] flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white">
                <Lottie
                  options={{ animationData: dangerAnimation }}
                  height={33}
                  width={33}
                />
              </div>
            </div>
            <div className="font-secondary text-dark my-4 text-xl font-medium leading-5">
              {t({
                id: "tasks-board-dialog.delete-board",
                defaultMessage: "Delete Board",
              })}
            </div>
            {content}
          </div>
        </MobileDrawer.Content>
        <MobileDrawer.Actions className="flex flex-row justify-center pb-6">
          <Button onClick={onClose} variant="contained" color="grey" fullWidth>
            {t({ id: "common.cancel", defaultMessage: "Cancel" })}
          </Button>
          {repeatedBoardName === board.name && (
            <Button
              onClick={handleConfirm}
              variant="contained"
              color="tertiary"
              fullWidth
              isDisabled={isDeleteInProgress}
            >
              {t({ id: "common.delete", defaultMessage: "Delete" })}
            </Button>
          )}
        </MobileDrawer.Actions>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onClose}
      isCloseButtonVisible
      header={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      title={t({
        id: "tasks-board-dialog.delete-board",
        defaultMessage: "Delete Board",
      })}
      img={
        <div
          style={{ backgroundColor: board.color }}
          className="font-secondary relative mb-5 flex h-[80px] w-[80px] items-center justify-center rounded-[10.5px] text-[32px] uppercase text-white transition"
        >
          {getFirstSymbol(board.name)}
          <div className="absolute -right-[35px] -bottom-[20px] flex h-[60px] w-[60px] items-center justify-center rounded-full bg-white">
            <Lottie
              options={{ animationData: dangerAnimation }}
              height={40}
              width={40}
            />
          </div>
        </div>
      }
      content={content}
      buttons={[
        {
          text: t({
            id: "task-board-dialog.delete-board",
            defaultMessage: "Delete Board",
          }),
          className: "h-10",
          color: "tertiary",
          onClick: handleConfirm,
          isDisabled: repeatedBoardName !== board.name || isDeleteInProgress,
        },
      ]}
    />
  );
};
