import { ImportTasksAPIError, useTranslations } from "@jugl-web/utils";
import { useCallback } from "react";

export const useImportApiErrorMessage = () => {
  const { t } = useTranslations();

  const getImportApiErrorMessage = useCallback(
    (response: ImportTasksAPIError["data"]) => {
      const commonErrorMessage = t({
        id: "tasks-page.file-is-corrupted",
        defaultMessage:
          "File is corrupted or have inappropriate format. Please check the details and try again",
      });

      if (response && "errors" in response) {
        switch (response.errors[0].field) {
          case "due_at":
            return t({
              id: "tasks-page.due-date-field-not-match",
              defaultMessage:
                "Selected Excel Column value doesn't match Due Date Field Type",
            });
          case "name":
            return t({
              id: "tasks-page.task-name-column-is-empty",
              defaultMessage:
                "Column You selected as Task Name is Empty. Please select different one and try again",
            });
          case "board_id":
            return t({
              id: "tasks-page.selected-board-not-exist",
              defaultMessage:
                "Selected Board is missing or deleted. Please select different one and try again",
            });
          default:
            return commonErrorMessage;
        }
      }

      if (response && "error" in response) {
        if ((response.error as string) === "max_limit_exceeded") {
          return t({
            id: "tasks-page.max-limit-exceeded",
            defaultMessage:
              "File for Importing should not have more than 5000 rows",
          });
        }
        return commonErrorMessage;
      }

      return commonErrorMessage;
    },
    [t]
  );

  return { getImportApiErrorMessage };
};
