export enum UserProfileVisibility {
  visible = "1",
  invisible = "0",
}

// TODO: re-check
export enum UserProfileGender {
  male = "male",
  female = "female",
}

export type UserProfileEducations = {
  end: string | null;
  grade: string | null;
  id: string;
  name: string | null;
  specialization: string | null;
  start: string | null;
  visibility: UserProfileVisibility;
  where: string | null;
};

export type UserProfileLocation = {
  city: string | null;
  country: string | null;
  id: string;
  moved_in: string | null;
  moved_out: string | null;
  state: string | null;
  visibility: UserProfileVisibility;
};

export type UserProfileCareers = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  current: any;
  end: string | null;
  id: string;
  location: string | null;
  org: string | null;
  start: string | null;
  title: string | null;
  visibility: UserProfileVisibility;
};

export type UserProfileServices = {
  id: string;
  img: string | null;
  ref: string | null;
  service_type: string | null;
  since: string | null;
  until: string | null;
  visibility: UserProfileVisibility;
};

export type UserProfileAchievements = {
  date: string | null;
  id: string;
  img: string | null;
  ref: string | null;
  title: string | null;
  visibility: UserProfileVisibility;
};

export type UserProfileCertificates = {
  completion: string | null;
  expires_on: string | null;
  id: string;
  img: string | null;
  issued_by: string | null;
  name: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: any;
  visibility: UserProfileVisibility;
};

export type UserProfileAwards = {
  id: string;
  name: string | null;
  date: string | null;
  awarded_by: string | null;
  img: string | null;
  visibility: UserProfileVisibility | null;
};

export type UserProfileNative = {
  city: string | null;
  country: string | null;
  id: string;
  state: string | null;
  visibility: UserProfileVisibility;
};

export type UserProfile = {
  // TODO: types
  achievements: UserProfileAchievements[];
  awards: UserProfileAwards[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  businesses: any[];
  careers: UserProfileCareers[];
  certificates: UserProfileCertificates[];
  educations: UserProfileEducations[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dept: any;
  general: {
    // TODO: type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    desc: any;
    dob: string | null;
    first_name: string;
    gender: UserProfileGender | null;
    id: string;
    img: string | null;
    last_name: string;
    mobile: string;
    nickname: string;
    updated_at: string | null;
    inserted_at: string | null;
    visibility: {
      first_name: UserProfileVisibility;
      parent: UserProfileVisibility;
    };
  };
  // TODO: type
  group_form: unknown;
  id: string;
  // TODO: type
  locations: UserProfileLocation[];
  native: UserProfileNative | null;
  services: UserProfileServices[];
  space: string[];
  user_domain: string;
  user_id: string;
  work_info: Record<string, string | null>[] | null;
};
