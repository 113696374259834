import {
  TaskRecurrenceInterval,
  TaskRecurrenceIntervalEvery,
} from "@jugl-web/rest-api/tasks";
import { assert } from "@jugl-web/utils";
import { FC, FocusEvent } from "react";
import { RecurrenceNumberInput } from "../RecurrenceNumberInput";

interface MonthlyOnDayInputProps {
  interval: TaskRecurrenceInterval;
  onUpdateInterval: (interval: TaskRecurrenceInterval) => void;
}

type MonthlyOnDayInterval = TaskRecurrenceInterval & { on: { date: number } };

function assertIntervalIsMonthlyOnDay(
  interval: TaskRecurrenceInterval
): asserts interval is MonthlyOnDayInterval {
  assert(
    interval.every === TaskRecurrenceIntervalEvery.month &&
      "date" in interval.on,
    "The interval should be set to monthly on day at this point"
  );
}

export const MonthlyOnDayInput: FC<MonthlyOnDayInputProps> = ({
  interval,
  onUpdateInterval,
}) => {
  assertIntervalIsMonthlyOnDay(interval);

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    assertIntervalIsMonthlyOnDay(interval);

    if (!event.target.validity.valid) {
      event.target.value = interval.on.date.toString();
      return;
    }

    const updatedDayInMonth = Number(event.target.value);

    onUpdateInterval({
      every: TaskRecurrenceIntervalEvery.month,
      freq: interval.freq,
      on: { date: updatedDayInMonth },
    });
  };

  return (
    <RecurrenceNumberInput
      min={1}
      max={31}
      required
      defaultValue={interval.on.date}
      onBlur={handleBlur}
    />
  );
};
