import {
  TaskChecklist,
  TaskChecklistHandle,
  taskNameValidator,
} from "@jugl-web/domain-resources/tasks";
import { DescriptionBox } from "@jugl-web/domain-resources/tasks/components/DescriptionBox";
import { TaskExtraPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskExtraPropertiesPanel";
import { TaskPropertiesPanel } from "@jugl-web/domain-resources/tasks/components/TaskPropertiesPanel";
import { TitleBox } from "@jugl-web/domain-resources/tasks/components/TitleBox";
import { useLocalTaskChecklistHandlers } from "@jugl-web/domain-resources/tasks/hooks/useLocalTaskChecklistHandlers";
import {
  TaskFormState,
  useTaskFormStateContext,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFormState";
import { useToast, useTranslations } from "@jugl-web/utils";
import { TaskFormDialog } from "@web-src/features/tasks/TaskFormDialog";
import { FC, useEffect, useRef, useState } from "react";
import { Subject } from "rxjs";
// import { ReactComponent as FileIcon } from "./assets/file.svg";

interface OrderTaskDetailProps {
  entityId: string;
  meId: string;
  completeStep$: Subject<void>;
  prefix: string;
  onSubmit: (taskFormState: TaskFormState) => void;
}

export const OrderTaskDetails: FC<OrderTaskDetailProps> = ({
  entityId,
  meId,
  completeStep$,
  prefix,
  onSubmit,
}) => {
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isError, setIsError] = useState(false);

  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  const $titleTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const $taskChecklistRef = useRef<TaskChecklistHandle | null>(null);

  const { formState, updateFormState, updateChecklistItems } =
    useTaskFormStateContext();

  const taskChecklistHandlers = useLocalTaskChecklistHandlers({
    itemsSetter: updateChecklistItems,
  });

  const isTaskTitleValid = taskNameValidator(formState.title);
  const isTaskAssigneesValid = !!formState.assigneeIds.length;
  const isFormValid = isTaskTitleValid && isTaskAssigneesValid;
  const shouldShowTitleError = isError && !isTaskTitleValid;
  const isDescriptionBoxVisible = formState.description || isEditingDescription;

  useEffect(() => {
    const subscription = completeStep$.subscribe(() => {
      if (!isFormValid) {
        setIsError(true);
        if (!isTaskTitleValid && !isTaskAssigneesValid) {
          toast(
            t({
              id: "order-form-create-page.to-proceed-add-task-title-and-assignees",
              defaultMessage: "To proceed, add Task Title and Assignees",
            }),
            { variant: "error" }
          );
          return;
        }
        if (!isTaskTitleValid) {
          $titleTextareaRef.current?.focus();
        }
        if (!isTaskAssigneesValid) {
          toast(
            t({
              id: "order-form-create-page.to-proceed-add-task-assignees",
              defaultMessage: "To proceed, add Task Assignees",
            }),
            { variant: "error" }
          );
        }
        return;
      }
      onSubmit(formState);
    });
    return () => subscription.unsubscribe();
  }, [
    completeStep$,
    formState,
    onSubmit,
    toast,
    t,
    isFormValid,
    isTaskTitleValid,
    isTaskAssigneesValid,
  ]);

  return (
    <div className="flex h-full w-[1060px] shrink-0 flex-col overflow-hidden rounded-t-2xl bg-white">
      <TaskFormDialog.Content>
        <TaskFormDialog.TitleSection>
          <div className="text-grey-700 mb-3 text-xs">
            {t({
              id: "order-form-create-page.task-details",
              defaultMessage: "Task Details",
            })}
          </div>
          <div className="flex w-full max-w-full items-center gap-20">
            <div className="grow overflow-hidden">
              <TitleBox
                title={formState.title}
                textareaRef={$titleTextareaRef}
                classes={{
                  titleText: "py-3",
                  editingBox: shouldShowTitleError ? "bg-tertiary-50" : "",
                }}
                onInternalValueChange={(title) =>
                  updateFormState("title", title)
                }
                prefix={prefix}
              />
              {shouldShowTitleError && (
                <span className="text-gradients-danger text-xs">
                  {t({
                    id: "order-form-create-page.please-indicate-task-title",
                    defaultMessage: "Please indicate task title",
                  })}
                </span>
              )}
              {isDescriptionBoxVisible && (
                <DescriptionBox
                  description={formState.description}
                  isEditing={isEditingDescription}
                  classes={{
                    editingBox: "mt-2",
                    descriptionText: "mt-2 py-2",
                  }}
                  onChange={(value) => updateFormState("description", value)}
                  onStartEditing={() => setIsEditingDescription(true)}
                  onFinishEditing={() => {
                    setIsEditingDescription(false);
                  }}
                />
              )}
            </div>
            {/* <Button className="shrink-0" size="small" iconStart={<FileIcon />}>
              {t({
                id: "order-form-create-page.select-task-template",
                defaultMessage: "Select Task Template",
              })}
            </Button> */}
          </div>
          <TaskPropertiesPanel
            entityId={entityId}
            config={{
              board: {
                boardId: formState.boardId,
                onChange: (boardId) => updateFormState("boardId", boardId),
                isHidden: formState.isPrivate,
              },
              description: {
                isHidden: isEditingDescription || !!formState.description,
                onClick: () => setIsEditingDescription(true),
              },
              dueDate: {
                state: formState.dueDate,
                displayAs: "days",
                onChange: (state) => updateFormState("dueDate", state),
              },
              assignees: {
                assigneeIds: formState.assigneeIds,
                onChange: (assigneeIds) =>
                  updateFormState("assigneeIds", assigneeIds),
                isHidden: formState.isPrivate,
              },
              priority: {
                priority: formState.priority,
                onChange: (priority) => updateFormState("priority", priority),
              },
              label: {
                labelId: formState.labelId,
                onChange: (labelId) => updateFormState("labelId", labelId),
              },
            }}
            className="mt-8"
          />
          <TaskExtraPropertiesPanel
            entityId={entityId}
            config={{
              completeChecklistInOrder: {
                isChecked: formState.completeChecklistInOrder,
                onChange: (isChecked) =>
                  updateFormState("completeChecklistInOrder", isChecked),
              },
            }}
            className="mt-8"
          />
        </TaskFormDialog.TitleSection>
        <TaskFormDialog.ChecklistSection
          onAddItem={() => $taskChecklistRef.current?.addItem()}
        >
          <TaskChecklist
            ref={$taskChecklistRef}
            entityId={entityId}
            meId={meId}
            items={formState.checklistItems}
            isCompletable={false}
            displayDueDateAs="days"
            {...taskChecklistHandlers}
          />
        </TaskFormDialog.ChecklistSection>
      </TaskFormDialog.Content>
    </div>
  );
};
