import React from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import loadingAnimation from "./assets/loading-animation.json";

export const ImportLoadingScreen: React.FC = () => {
  const { t } = useTranslations();

  return (
    <div className="flex h-full flex-col items-center text-center">
      <Lottie
        options={{ animationData: loadingAnimation }}
        height={150}
        width={150}
      />
      <span className="text-dark font-secondary mt-6 text-xl font-medium leading-5">
        {t({
          id: "tasks-page.importing-your-data",
          defaultMessage: "Importing your Data",
        })}
      </span>
      <span className="text-dark-700 mt-3 leading-[140%] tracking-[0.16px]">
        {t({
          id: "tasks-page.stay-during-importing-file",
          defaultMessage:
            "Please stay here for a while, we are importing your file",
        })}
      </span>
    </div>
  );
};
