import { BoardRole } from "@jugl-web/rest-api/tasks";
import { AvatarMember, InteractiveContainer } from "@jugl-web/ui-components";
import { AvatarStack } from "@jugl-web/ui-components/cross-platform/AvatarStack";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { useMultipleUserProfiles } from "../../../../../users/hooks/useMultipleUserProfiles";
import { useUserListBox } from "../../../../../users/hooks/useUserListBox/useUserListBox";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";

interface BoardUserPickerProps {
  entityId: string;
  meId: string;
  users: { id: string; type: BoardRole }[];
  type: BoardRole;
  isReadonly?: boolean;
  onChange?: (item: string[], type: BoardRole) => void;
}
export const BoardUserPicker: FC<BoardUserPickerProps> = ({
  entityId,
  meId,
  users,
  type,
  isReadonly,
  onChange,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const membersIds = useMemo(
    () => users.filter((user) => user.type === "member").map((user) => user.id),
    [users]
  );
  const ownersIds = useMemo(
    () => users.filter((user) => user.type === "owner").map((user) => user.id),
    [users]
  );

  const variantIds = type === "member" ? membersIds : ownersIds;

  const { profiles } = useMultipleUserProfiles({
    entityId,
    userIds:
      type === "owner" && !variantIds.includes(meId)
        ? variantIds.concat([meId])
        : variantIds,
  });

  const title = useMemo<ReactNode>(() => {
    if (type === "owner") {
      return t({
        id: "tasks-board-dialog.owners",
        defaultMessage: "Owners",
      });
    }
    return t({
      id: "tasks-board-dialog.board-members",
      defaultMessage: "Board members",
    });
  }, [type, t]);

  const profilesAsAvatarMembers = useMemo<AvatarMember[]>(
    () =>
      profiles.map((profile) => ({
        firstName: profile.firstName || "",
        lastName: profile.lastName || "",
        imageUrl: profile.avatar || null,
      })),
    [profiles]
  );

  const { getListBoxProps, clearSearchQuery } = useUserListBox({
    entityId,
    excludeUsers: `${meId}`,
  });

  const listBoxProps = getListBoxProps({
    selectionBehavior: { mode: "multiple" },
    defaultSelectedIds: (type === "member" ? membersIds : ownersIds).filter(
      (userId) => userId !== meId
    ),
  });

  const subtitle = useMemo(
    () =>
      type === "owner"
        ? t({
            id: "tasks-board-dialog.select-owners-description",
            defaultMessage:
              "Board owners will have full permissions to manage it",
          })
        : undefined,
    [t, type]
  );

  const getInputJSX = useCallback(
    (isUserListOpen: boolean) => (
      <div>
        <div className="text-dark-800 text-left text-xs">{title}</div>
        <InteractiveContainer
          className={cx(
            "font-secondary text-dark border-dark-100 mt-2 flex w-full items-center justify-between border-0 border-b-[1px] border-solid pb-2 text-base",
            { "border-primary": isUserListOpen }
          )}
          onClick={isMobile ? () => setIsDrawerOpen(true) : undefined}
        >
          {variantIds.length === 0 && type === "member" && (
            <div className="flex w-full items-center justify-between">
              {t({
                id: "task-board-dialog.tap-to-select",
                defaultMessage: "Tap to select",
              })}
              <PlusIcon />
            </div>
          )}
          {(type === "member" &&
            variantIds.length &&
            t(
              {
                id: "tasks-board-dialog.count-members",
                defaultMessage:
                  "{count} {count, plural, one {member} other {members}}",
              },
              { count: membersIds.length }
            )) ||
            null}
          {(type === "owner" &&
            t(
              {
                id: "tasks-board-dialog.count-owners",
                defaultMessage:
                  "{count} {count, plural, one {owner} other {owners}}",
              },
              {
                count: ownersIds.includes(meId)
                  ? ownersIds.length
                  : ownersIds.length + 1,
              }
            )) ||
            null}
          <AvatarStack
            members={profilesAsAvatarMembers}
            maxCount={5}
            size="lg"
          />
        </InteractiveContainer>
      </div>
    ),
    [
      isMobile,
      meId,
      membersIds.length,
      ownersIds,
      profilesAsAvatarMembers,
      t,
      title,
      type,
      variantIds.length,
    ]
  );

  if (isMobile) {
    return (
      <>
        {getInputJSX(isDrawerOpen)}
        <ResourcePickerDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onSubmit={(item) => {
            onChange?.(item, type);
            clearSearchQuery();
          }}
          onTransitionEnd={() => {
            if (!isDrawerOpen) {
              clearSearchQuery();
            }
          }}
          title={
            <div className="flex flex-col gap-1">
              <span className="text-dark text-base font-medium">{title}</span>
              {subtitle && (
                <span className="text-xs text-[#828282]">{subtitle}</span>
              )}
            </div>
          }
          {...listBoxProps}
        />
      </>
    );
  }

  return (
    <ResourcePickerPopover
      placement="right-end"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          isDisabled={isReadonly}
          as={InteractiveContainer}
          ref={triggerRef}
          className="h-[63px]"
        >
          {getInputJSX(isOpen)}
        </Trigger>
      )}
      onSubmit={(item) => {
        onChange?.(item, type);
        clearSearchQuery();
      }}
      onUnmount={clearSearchQuery}
      title={title}
      subtitle={subtitle}
      {...listBoxProps}
    />
  );
};
