import { PaginationItem, usePagination } from "@jugl-web/utils";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { selectUserId } from "@web-src/features/auth/authSlice";
import { getUserProfileDisplayName } from "@web-src/features/users/utils";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { singletonHook } from "react-singleton-hook";
import { useUpdateChatMessages } from "@web-src/modules/chats/hooks/useUpdateChatMessages";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useLazyChatHistoryQuery } from "../chatsApi";
import { PhoenixSocketContext } from "../providers/PheonixSocket";
import { ChatItem, ChatMessage, ChatType } from "../types";
import {
  getMessageChatId,
  messageToConversationLastMessage,
  tokenizeMessagePayload,
} from "../utils";
import useLiveConversationReceipts from "./useLiveConversationReceipts";

interface UseConversationsType {
  conversations: PaginationItem<ChatItem>[];
  isConversationsLoading: boolean;
  isConversationsError: boolean;
  addNewMessage?: (params: {
    id: string;
    message: ChatMessage;
    outgoing?: boolean;
  }) => void;
  addConversation?: (params: {
    params: Pick<
      ChatItem,
      | "id"
      | "img"
      | "title"
      | "type"
      | "lastMessage"
      | "firstUnreadMessage"
      | "unreadCount"
    >;
  }) => void;
  deleteGroupConversation: (workspaceId: string) => Promise<void>;
  updateGroupConversation: (data: {
    id: string;
    title: string;
    img?: File;
  }) => Promise<void>;
  updateItem: (
    item: PaginationItem<Partial<ChatItem>>,
    merge?: boolean
  ) => void;
}

const useConversations = (): UseConversationsType => {
  const { entity } = useEntity();
  const entityId = entity?.id;
  const { addOrUpdateMessage, updateMessage } = useUpdateChatMessages();
  const meId = useSelector(selectUserId);
  const { workspacesApi, usersApi } = useRestApiProvider();
  const [getWorkspace] = workspacesApi.useLazyGetWorkspaceQuery();
  const [deleteWorkspace] = workspacesApi.useDeleteWorkspaceMutation();
  const [updateWorkspace] = workspacesApi.useUpdateWorkspaceMutation();
  const { incomingMessages$, indicatorMessages$ } =
    useContext(PhoenixSocketContext);

  const { liveReceiptsState } = useLiveConversationReceipts();

  const {
    items: conversations,
    addItems,
    lastPageState,
    setIsLoadingState,
    updateItem,
    isLoading: paginationIsLoading,
    isInitialized,
    bumpItem,
  } = usePagination<ChatItem, { lastTime?: string; isLast?: boolean }>(
    `conversations:${entityId}`
  );
  const [getProfile] = usersApi.useLazyGetUserProfileQuery();
  const [loadingNewConversation, setLoadingNewConversation] = useState<{
    [key: string]: boolean;
  }>();
  const newConversationsMessages = useRef<{ [key: string]: ChatMessage[] }>({});

  const [
    loadChatHistory,
    { isLoading: isConversationsLoading, isError: isConversationsError },
  ] = useLazyChatHistoryQuery();

  const handleLoadConversations = useCallback(async () => {
    if (!entityId || (lastPageState && lastPageState.isLast) || !meId) {
      return;
    }
    setIsLoadingState(true);
    const response = await loadChatHistory({
      params: {
        entity_id: entityId,
        time:
          lastPageState?.lastTime ||
          `${new Date().getFullYear() + 1}-01-01 00:00:00`,
        limit: 50,
      },
    });
    const selfResponse = await loadChatHistory({
      params: {
        entity_id: entityId,
        time:
          lastPageState?.lastTime ||
          `${new Date().getFullYear() + 1}-01-01 00:00:00`,
        self_chat: "true",
        limit: 1,
      },
    });
    // TODO: optimize
    const selfChat: ChatItem = {
      ...((selfResponse.data
        ? selfResponse.data?.find((item) => item.id === meId)
        : undefined) || {
        id: meId,
        type: ChatType.chat,
      }),
    };
    selfChat.isSelf = true;
    const restChats = response.data
      ? response.data?.filter((item) => item.id !== meId)
      : [];
    addItems(
      [selfChat, ...restChats].map((item) => ({
        id: item?.id || meId,
        data: item,
      }))
    );
    setIsLoadingState(false);
  }, [
    addItems,
    entityId,
    lastPageState,
    loadChatHistory,
    meId,
    setIsLoadingState,
  ]);

  const addConversation = useCallback(
    ({
      params,
    }: {
      params: Pick<
        ChatItem,
        | "id"
        | "img"
        | "title"
        | "type"
        | "lastMessage"
        | "firstUnreadMessage"
        | "unreadCount"
        | "userCount"
        | "users"
      >;
    }) => {
      if (!params.id) {
        return;
      }
      addItems([{ id: params.id, data: params }], "start");
    },
    [addItems]
  );

  const deleteGroupConversation = useCallback(
    async (workspaceId: string) => {
      if (!entityId) {
        return;
      }
      const resp = await deleteWorkspace({ id: workspaceId, entityId });
      if ("error" in resp && resp.error) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw resp.error;
      }
      const conversation = conversations.find(
        (item) => workspaceId === item.id
      );
      if (!conversation) {
        return;
      }
      updateItem(
        {
          id: workspaceId,
          data: { deleted: true },
        },
        true
      );
    },
    [conversations, deleteWorkspace, entityId, updateItem]
  );

  const updateGroupConversation: UseConversationsType["updateGroupConversation"] =
    useCallback(
      async ({ id, title, img }) => {
        if (!entityId) {
          return;
        }
        const conversation = conversations.find((item) => id === item.id);
        if (!conversation) {
          return;
        }
        const resp = await updateWorkspace({
          entityId,
          id: conversation.id,
          data: { opts: { title }, display_pic_file: img },
        });
        if ("error" in resp && resp.error) {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw resp.error;
        }
        if ("data" in resp && resp.data) {
          updateItem(
            {
              id,
              data: {
                title,
                img: resp.data.display_picture,
              },
            },
            true
          );
        }
      },
      [conversations, entityId, updateItem, updateWorkspace]
    );

  const addNewMessage = useCallback(
    ({
      id,
      message,
      outgoing,
    }: {
      id: string;
      message: ChatMessage;
      outgoing?: boolean;
    }) => {
      const conversation = conversations.find((item) => id === item.id);
      if (!conversation) {
        return;
      }
      updateItem(
        {
          ...conversation,
          data: {
            lastMessage: messageToConversationLastMessage(message),
            firstUnreadMessage:
              outgoing || conversation?.data?.firstUnreadMessage
                ? conversation?.data?.firstUnreadMessage
                : {
                    msgId: message.msg_id,
                    createdAt: message.timestamp,
                  },
          },
        },
        true
      );
    },
    [conversations, updateItem]
  );

  useEffect(() => {
    const subscription = indicatorMessages$?.subscribe(({ message, merge }) => {
      if (message.type === "call") {
        return;
      }
      const chatId = getMessageChatId(message, meId);
      updateMessage({
        chatId,
        item: { id: message.msg_id, data: message },
        merge,
      });
      const conversation = conversations?.find((item) => chatId === item.id);
      if (!conversation) {
        return;
      }
      if (
        message.edited &&
        conversation.data.lastMessage?.msgId === message.msg_id
      ) {
        updateItem(
          {
            ...conversation,
            data: {
              lastMessage: {
                ...conversation.data.lastMessage,
                tokenizedMessage: tokenizeMessagePayload(message),
              },
            },
          },
          true
        );
      }
      if (
        message.deleted &&
        conversation.data.lastMessage?.msgId === message.msg_id
      ) {
        updateItem(
          {
            ...conversation,
            data: {
              lastMessage: {
                ...conversation.data.lastMessage,
                deleted: true,
              },
            },
          },
          true
        );
      }
    });
    return () => subscription?.unsubscribe();
  }, [indicatorMessages$, meId, updateMessage, conversations, updateItem]);

  useEffect(() => {
    const subscription = incomingMessages$?.subscribe((newMessage) => {
      const chatId = getMessageChatId(newMessage, meId);
      if (newMessage.type === "call") {
        return;
      }
      const conversation = conversations?.find((item) => item.id === chatId);
      if (!conversation) {
        newConversationsMessages.current = {
          ...newConversationsMessages.current,
          [chatId]: [
            ...(newConversationsMessages.current[chatId] || []),
            newMessage,
          ],
        };
      }
      if (!conversation && loadingNewConversation?.[chatId]) {
        return;
      }
      if (!conversation) {
        setLoadingNewConversation((prev) => ({ ...prev, [chatId]: true }));
        const messages = newConversationsMessages.current[chatId];
        const firstMessage = messages?.find((item) => item.from !== meId);
        const lastMessage = messages?.[messages.length - 1];
        // TODO: error handling
        if (newMessage.type === "muc") {
          if (!entityId || !chatId) {
            return;
          }
          getWorkspace({ workspaceId: chatId, entityId }).then(
            ({ data: workspace }) => {
              if (!workspace) {
                return;
              }
              const newConversation = {
                id: workspace.id,
                title: workspace.title,
                type: ChatType.muc,
                img: workspace.display_picture,
                unreadCount: 0,
                lastMessage: lastMessage
                  ? messageToConversationLastMessage(lastMessage)
                  : undefined,
                firstUnreadMessage: firstMessage
                  ? {
                      msgId: firstMessage.msg_id,
                      createdAt: firstMessage.timestamp,
                    }
                  : undefined,
                users: workspace.participants || [],
                userCount: workspace.participants?.length || 0,
              };

              addConversation({ params: newConversation });
            }
          );
        } else if (newMessage.type === "chat") {
          getProfile({ params: { user_id: chatId } }).then(
            ({ data: profile }) => {
              if (!profile) {
                return;
              }

              const newConversation = {
                id: chatId,
                title: getUserProfileDisplayName(profile),
                type: ChatType.chat,
                img: profile.general?.img,
                unreadCount: 0,
                lastMessage: lastMessage
                  ? messageToConversationLastMessage(lastMessage)
                  : undefined,
                firstUnreadMessage: firstMessage
                  ? {
                      msgId: firstMessage.msg_id,
                      createdAt: firstMessage.timestamp,
                    }
                  : undefined,
              };

              addConversation({ params: newConversation });
            }
          );
        }
        newConversationsMessages.current = {
          ...newConversationsMessages.current,
          [chatId]: [],
        };
        setLoadingNewConversation((prev) => ({ ...prev, [chatId]: false }));
        return;
      }

      addNewMessage({ id: chatId, message: newMessage });
      addOrUpdateMessage({
        chatId,
        item: {
          id: newMessage.msg_id,
          data: newMessage,
        },
      });
      bumpItem(chatId);
    });
    return () => subscription?.unsubscribe();
  }, [
    addConversation,
    addNewMessage,
    addOrUpdateMessage,
    bumpItem,
    conversations,
    entityId,
    getProfile,
    getWorkspace,
    incomingMessages$,
    loadingNewConversation,
    meId,
  ]);

  useEffect(() => {
    if (
      isConversationsLoading ||
      conversations?.length ||
      lastPageState ||
      isInitialized ||
      paginationIsLoading
    ) {
      return;
    }
    handleLoadConversations();
  }, [
    conversations?.length,
    handleLoadConversations,
    isConversationsLoading,
    isInitialized,
    lastPageState,
    paginationIsLoading,
  ]);

  const sortedConversations: PaginationItem<ChatItem>[] = useMemo(
    () =>
      [...(conversations || [])]
        ?.sort((a, b) => {
          if (a.data.isSelf && !b.data.isSelf) {
            return -1;
          }
          if (!a.data.isSelf && b.data.isSelf) {
            return 1;
          }
          return 0;
        })
        .map((item) => {
          const liveReceiptState = liveReceiptsState[item.id];
          if (!liveReceiptState) {
            return item;
          }
          return {
            ...item,
            data: {
              ...item.data,
              unreadCount:
                (item.data.unreadCount || 0) +
                (liveReceiptState.unreadCounterDelta || 0),
              lastReadMessage:
                liveReceiptState.lastReadMessage?.msg_id &&
                liveReceiptState.lastReadMessage?.timestamp
                  ? {
                      msgId: liveReceiptState.lastReadMessage.msg_id,
                      createdAt: liveReceiptState.lastReadMessage.timestamp,
                    }
                  : undefined,
            },
          };
        }),
    [conversations, liveReceiptsState]
  );

  const $favicon = useMemo(() => document.getElementById("favicon"), []);
  useEffect(() => {
    const hasUnread = Object.values(sortedConversations).find(
      (item) => !!item.data.unreadCount
    );
    $favicon?.setAttribute(
      "href",
      `/org/${hasUnread ? "favicon-notification" : "favicon"}.ico`
    );
  }, [$favicon, sortedConversations]);

  return {
    conversations: sortedConversations,
    isConversationsLoading,
    isConversationsError,
    addNewMessage,
    addConversation,
    deleteGroupConversation,
    updateGroupConversation,
    updateItem,
  };
};

export default singletonHook(
  {
    conversations: [],
    isConversationsLoading: true,
    isConversationsError: false,
    deleteGroupConversation: async () => undefined,
    updateGroupConversation: async () => undefined,
    updateItem: () => undefined,
  },
  useConversations
) || {};
