import {
  TaskRecurrence,
  TaskRecurrenceEndAt,
  TaskRecurrenceInterval,
  TaskRecurrenceIntervalEvery,
} from "@jugl-web/rest-api/tasks";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { useCallback, useMemo } from "react";
import { RecurrenceListOption } from "../types";
import { getDayOfWeek, getWeekdayOrdinalInGivenMonth } from "../utils";

export interface RecurrenceListOptionDetails {
  label: string;
  interval?: TaskRecurrenceInterval;
  endAt?: TaskRecurrenceEndAt;
}

export const useTaskRecurrence = () => {
  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const ordinals = useMemo(
    () => [
      t({
        id: "common.ordinal-first",
        defaultMessage: "first",
      }),
      t({
        id: "common.ordinal-second",
        defaultMessage: "second",
      }),
      t({
        id: "common.ordinal-third",
        defaultMessage: "third",
      }),
      t({
        id: "common.ordinal-fourth",
        defaultMessage: "fourth",
      }),
      t({
        id: "common.ordinal-fifth",
        defaultMessage: "fifth",
      }),
    ],
    [t]
  );

  const getButtonLabel = useCallback(
    (state: TaskRecurrence | null) => {
      if (!state) {
        return t({
          id: "tasks-page.recurring-task",
          defaultMessage: "Recurring task",
        });
      }

      const frequency = state.interval.freq;

      switch (state.interval.every) {
        case "day":
          return t(
            {
              id: "tasks-page.recurring-task-repeat-every-day",
              defaultMessage:
                "Repeat: Every {frequency, plural, one {day} other {# days}}",
            },
            { frequency }
          );

        case "week":
          return t(
            {
              id: "tasks-page.recurring-task-repeat-every-week",
              defaultMessage:
                "Repeat: Every {frequency, plural, one {week} other {# weeks}}",
            },
            { frequency }
          );

        case "month":
          return t(
            {
              id: "tasks-page.recurring-task-repeat-every-month",
              defaultMessage:
                "Repeat: Every {frequency, plural, one {month} other {# months}}",
            },
            { frequency }
          );

        case "year":
          return t(
            {
              id: "tasks-page.recurring-task-repeat-every-year",
              defaultMessage:
                "Repeat: Every {frequency, plural, one {year} other {# years}}",
            },
            { frequency }
          );
        default:
          return t({
            id: "tasks-page.recurring-task",
            defaultMessage: "Recurring Task",
          });
      }
    },
    [t]
  );

  const getRecurrenceListOptionIdToDetailsMap = useCallback(
    (
      date: Date
    ): Record<RecurrenceListOption, RecurrenceListOptionDetails> => ({
      [RecurrenceListOption.none]: {
        label: t({
          id: "tasks-page.recurrence-list-option-none",
          defaultMessage: "Does not repeat",
        }),
      },
      // Example: Daily
      [RecurrenceListOption.daily]: {
        label: t({
          id: "tasks-page.recurrence-list-option-daily",
          defaultMessage: "Daily",
        }),
        interval: {
          every: TaskRecurrenceIntervalEvery.day,
          freq: 1,
        },
        endAt: null,
      },
      // Example: Weekly on Monday
      [RecurrenceListOption.weeklyOnDay]: {
        label: t(
          {
            id: "tasks-page.recurrence-list-option-weekly-on-day",
            defaultMessage: "Weekly on {day}",
          },
          { day: localeAwareFormat(date, "EEEE") }
        ),
        interval: {
          every: TaskRecurrenceIntervalEvery.week,
          freq: 1,
          on: [getDayOfWeek(date)],
        },
        endAt: null,
      },
      // Example: Monthly on day 15
      [RecurrenceListOption.monthlyOnDay]: {
        label: t({
          id: "tasks-page.recurrence-list-option-monthly-on-day",
          defaultMessage: "Monthly on day",
        }),
        interval: {
          every: TaskRecurrenceIntervalEvery.month,
          freq: 1,
          on: {
            date: date.getDate(),
          },
        },
        endAt: null,
      },
      // Example: Monthly on the third Monday
      [RecurrenceListOption.monthlyOnWeekday]: {
        label: t(
          {
            id: "tasks-page.recurrence-list-option-monthly-on-weekday",
            defaultMessage: "Monthly on the {ordinal} {weekday}",
          },
          {
            ordinal: ordinals[getWeekdayOrdinalInGivenMonth(date) - 1],
            weekday: localeAwareFormat(date, "EEEE"),
          }
        ),
        interval: {
          every: TaskRecurrenceIntervalEvery.month,
          freq: 1,
          on: {
            week: getWeekdayOrdinalInGivenMonth(date),
            day: getDayOfWeek(date),
          },
        },
        endAt: null,
      },
      // // Example: Yearly on July 15
      [RecurrenceListOption.yearlyOnDate]: {
        label: t(
          {
            id: "tasks-page.recurrence-list-option-yearly-on-date",
            defaultMessage: "Yearly on {date}",
          },
          {
            date: localeAwareFormat(date, "MMMM d"),
          }
        ),
        interval: {
          every: TaskRecurrenceIntervalEvery.year,
          freq: 1,
          on: {
            month: date.getMonth() + 1,
            date: date.getDate(),
          },
        },
        endAt: null,
      },
      [RecurrenceListOption.customSettings]: {
        label: t({
          id: "tasks-page.recurrence-list-option-custom-settings",
          defaultMessage: "Custom settings...",
        }),
      },
    }),
    [t, localeAwareFormat, ordinals]
  );

  return {
    getButtonLabel,
    getRecurrenceListOptionIdToDetailsMap,
  };
};
