import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { TaskLabel } from "@jugl-web/rest-api/tasks";
import { DotIcon, ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const LabelCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { labels, noneLabel, getLabelById } = useTaskFields({ entityId });

  const labelsAsListItems = useMemo<ListBoxItem<TaskLabel>[]>(
    () => [
      { id: noneLabel.id, value: noneLabel },
      ...labels.map((l) => ({ id: l.id, value: l })),
    ],
    [labels, noneLabel]
  );

  const label = task.label_id ? getLabelById(task.label_id) : undefined;
  const hasLabel = !!label;
  const isEditable = permissions.canEditLabel && !isFutureTask;

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          className={cx(hasLabel && "px-px py-px")}
          {...cellProps}
        >
          {hasLabel ? (
            <TaskTableLayout.ColoredCellContent
              text={label.text}
              textColor={label.color}
            />
          ) : (
            <TaskTableLayout.AppearingAddValueCellText
              isStatic={isOpen}
              isHidden={!isEditable}
            />
          )}
        </Trigger>
      )}
      items={labelsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      hasSearch
      defaultSelectedIds={hasLabel ? [label.id] : [noneLabel.id]}
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.text}
      renderStartIcon={(item) => <DotIcon color={item.value.color} />}
      onSelect={({ item, onClose }) => {
        onUpdate({ label_id: item.id === noneLabel.id ? null : item.id });
        onClose();
      }}
    />
  );
};
