import { apiUTCToLocalDateTime } from "@jugl-web/utils";
import differenceInDays from "date-fns/differenceInDays";
import { EntityInternalSubscriptionInfo } from "../common-types/EntityInternalSubscriptionInfo";
import { EntityDto } from "./EntityDto";
import { EntityModel } from "./EntityModel";

export const transformEntityDtoToModel = (dto: EntityDto): EntityModel => {
  let subscriptionInfo: EntityInternalSubscriptionInfo = {
    type: "free",
  };
  if (dto.subscription && dto.subscription.plan !== "free") {
    if (dto.subscription.is_in_plan) {
      subscriptionInfo = {
        type:
          dto.subscription.plan === "subscription" ? "subscription" : "trial",
        validUntil: apiUTCToLocalDateTime(dto.subscription.plan_till),
        daysLeft:
          differenceInDays(
            apiUTCToLocalDateTime(dto.subscription.plan_till),
            new Date()
          ) || 1,
      };
    } else if (dto.subscription.is_in_grace) {
      subscriptionInfo = {
        type:
          dto.subscription.plan === "subscription"
            ? "subscriptionGrace"
            : "trialGrace",
        validUntil: apiUTCToLocalDateTime(dto.subscription.grace_till),
        daysLeft:
          differenceInDays(
            apiUTCToLocalDateTime(dto.subscription.grace_till),
            new Date()
          ) || 1,
      };
    } else {
      subscriptionInfo = {
        type: "expired",
      };
    }
  }
  return {
    id: dto.id,
    entityRelId: dto.entity_rel_id,
    name: dto.name,
    bannerImg: dto.banner_img,
    logoImg: dto.display_pic,
    unread: dto.unread,
    type: dto.type,
    info: dto.info,
    role: dto.role,
    country: dto.country,
    desc: dto.desc,
    subscription: dto.subscription,
    subscriptionInfo,
    billingEmail: dto.billing_email,
    isEmailVerified: dto.is_email_verified,
    userCount: dto.user_count,
    uniqueId: dto.unique_id,
    __dto: dto,
  };
};
