import { cx } from "@jugl-web/utils";
import { forwardRef, HTMLProps } from "react";

export const TaskHoverableButton = forwardRef<
  HTMLButtonElement,
  Omit<HTMLProps<HTMLButtonElement>, "type">
>(({ className, ...props }, ref) => (
  <button
    ref={ref}
    type="button"
    className={cx(
      "jugl__focusable-outline bg-dark-100 text-dark-700 flex h-8 cursor-pointer items-center justify-center gap-1.5 rounded-lg border-none text-xs transition-shadow duration-300 hover:shadow-[0_4px_4px_0_rgba(0,0,0,0.16)]",
      className
    )}
    {...props}
  />
));
