import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { IAgoraRTCRemoteUser, useRemoteVideoTracks } from "agora-rtc-react";
import { useLocation } from "react-router-dom";
import { PlainButton, Text } from "@jugl-web/ui-components/cross-platform";
import { PhoenixSocketContext } from "@web-src/features/chats/providers/PheonixSocket";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useSelector } from "react-redux";
import { selectAuthState } from "@web-src/features/auth/authSlice";
import useDebounce from "@web-src/hooks/useDebounce";
import { useToast, cx, useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { ReactComponent as GetFullScreenIcon } from "./assets/fullscreen-on.svg";
import { ReactComponent as ArrowLeft } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { CallsContext } from "../../../../providers";
import ControlPanel from "../ControlPanel";
import RemoteUserCallWindow from "../RemoteUserCallWindow/RemoteUserCallWindow";
import LocalUserCallWindow from "../LocalUserCallWindow";
import MinimizedCall from "../MinimizedCall";
import { Header } from "./components/Header/Header";
import { MainContainer } from "./components/MainContainer/MainContainer";
import { UsersGrid } from "./components/UsersGrid/UsersGrid";
import { EnterScreen } from "./components/EnterScreen/EnterScreen";
import { Info } from "./components/Info/Info";
import { CallAvatar } from "../CallAvatar";
import { parseUserId } from "../../../../utils";
import { ReactComponent as ScreenShareIcon } from "./assets/screen-share.svg";

export const Conference: React.FC = () => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { isAuthenticated } = useSelector(selectAuthState);
  const { channel } = useContext(PhoenixSocketContext);
  const { me } = useMe();
  const { activeCall, leaveCall, activeCallProps } = useContext(CallsContext);
  const isScreenShareEnabled = useRef<boolean | undefined>();
  const joinedChannel = useRef(false);
  isScreenShareEnabled.current = activeCall?.screenSharingEnabled;
  const [minimizedScreen, setMinimizedScreen] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
  const [duration, setDuration] = useState(0);
  const [currentSpeakerId, setCurrentSpeakerId] = useState<string>();
  const [mouseMoved, setMouseMoved] = useState<boolean>(false);
  const debouncedCurrentSpeakerId = useDebounce(currentSpeakerId, 500);
  const [isScreenSharingFullScreen, setScreenSharingFullScreen] =
    useState<boolean>(false);
  const location = useLocation();
  const { navigateToPage } = useNavigation();
  const { entity } = useEntitySelectedProvider();

  const { usersApi } = useRestApiProvider();
  const [getUserGeneralProfile] = usersApi.useLazyGetUserGeneralProfileQuery();
  const remoteVideoTracks = useRemoteVideoTracks(activeCall?.remoteUsers);
  const [isSharingMessageSent, setSharingMessageSent] =
    useState<boolean>(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    let mouseTimeout: NodeJS.Timeout;
    const handleMouseMove = () => {
      setMouseMoved(true);
      clearTimeout(mouseTimeout);
      mouseTimeout = setTimeout(() => {
        setMouseMoved(false);
      }, 3000);
    };
    const handleToggleFullscreen = () => {
      setScreenSharingFullScreen(Boolean(document.fullscreenElement));
    };
    document.addEventListener("fullscreenchange", handleToggleFullscreen);
    window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("fullscreenchange", handleToggleFullscreen);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  const mySharingUINTIDRef = useRef<number | undefined>();
  const mySharingUINTID = useMemo(() => {
    if (!isScreenShareEnabled.current) return undefined;
    const mySharingId = `screenSharing_${me?.id}`;
    const id = activeCall?.remoteUsers?.find(
      (user) => user.uid === mySharingId
    )?._uintid;
    mySharingUINTIDRef.current = id;
    return id;
  }, [activeCall?.remoteUsers, me?.id]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const speaker: IAgoraRTCRemoteUser[] | undefined =
        activeCall?.remoteUsers?.filter((user) => {
          const newVolumeLevel = user.audioTrack?.getVolumeLevel() || 0;
          const isTalking = newVolumeLevel > 0.25;
          if (isTalking) {
            return user;
          }
          return null;
        });
      return setCurrentSpeakerId(speaker?.[0]?.uid as string);
    }, 500);

    return () => clearInterval(intervalId);
  }, [activeCall?.remoteUsers]);

  useEffect(() => {
    const handleTrackEnded = () => {
      channel?.push("conference:update", {
        id: activeCallProps?.channel,
        screen_share: false,
        uid: mySharingUINTID,
      });
      activeCall?.handleStopScreenShare();
    };

    activeCall?.localScreenShareTrack?.on("track-ended", handleTrackEnded);

    return () => {
      activeCall?.localScreenShareTrack?.off("track-ended", handleTrackEnded);
    };
  }, [activeCall, activeCallProps?.channel, channel, mySharingUINTID]);

  const formattedDuration =
    duration > 0
      ? `${Math.floor(duration / 60)
          .toString()
          .padStart(2, "0")}:${(duration % 60).toString().padStart(2, "0")}`
      : "00:00";

  useEffect(() => {
    let durationInterval: NodeJS.Timeout;
    if (activeCall?.stage === "participation") {
      durationInterval = setInterval(() => {
        setDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    }
    return () => {
      clearInterval(durationInterval);
    };
  }, [activeCall?.stage]);

  const [latestChannelStatus, setLatestChannelStatus] = useState<{
    action: "update" | "joined" | "left";
    action_by: string;
    conference: { id: string; screen_share: boolean; uid: number };
    status: string;
  }>();

  const screenSharingUIDRef = useRef<number | undefined>();

  const screenSharingUser = useMemo(() => {
    if (latestChannelStatus?.conference.screen_share) {
      const foundUser = activeCall?.remoteUsers?.find(
        (user) => user._uintid === latestChannelStatus.conference.uid
      );
      if (foundUser) {
        screenSharingUIDRef.current = foundUser._uintid;
      }
      return foundUser;
    }
    screenSharingUIDRef.current = undefined;
    return undefined;
  }, [activeCall?.remoteUsers, latestChannelStatus]);

  useEffect(() => {
    if (
      !activeCallProps?.channel ||
      !channel ||
      joinedChannel.current ||
      activeCall?.stage === "staging"
    ) {
      return undefined;
    }
    channel.push("conference:join", {
      id: activeCallProps?.channel,
    });
    joinedChannel.current = true;
    const conferenceRespRefId = channel.on(
      "conference_resp",
      async (message: {
        response: {
          action: "update" | "joined" | "left";
          action_by: string;
          conference: { id: string; screen_share: boolean; uid: number };
          status: string;
        };
      }) => {
        const { action_by: actionBy } = message.response;
        const profile = await getUserGeneralProfile(
          { entityId: entity.id, params: { user_id: actionBy } },
          true
        );
        const profileName =
          profile.data?.firstName ||
          t({
            id: "common.unknown",
            defaultMessage: "Unknown",
          });
        if (
          (message.response?.action === "joined" &&
            message.response.action_by === me?.id) ||
          message.response?.action === "update"
        ) {
          setLatestChannelStatus(message.response);
        }
        if (
          activeCall &&
          message.response.action === "left" &&
          actionBy !== me?.id
        ) {
          toast(
            t(
              {
                id: "call-component.left-call",
                defaultMessage: "{user} left call",
              },
              {
                user: profileName,
              }
            ),
            {
              variant: "default",
              preventDuplicate: true,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              style: {
                backgroundColor: "#585858",
                marginBottom: "150px",
              },
            }
          );
        }
        if (
          activeCall &&
          message.response.action === "joined" &&
          message.response.action_by !== me?.id
        ) {
          const findUser = activeCall.remoteUsers?.find(
            (user) => user.uid === message.response.action_by
          );
          if (!findUser) {
            return;
          }
          toast(
            t(
              {
                id: "call-component.joined-call",
                defaultMessage: "{user} joined call",
              },
              {
                user: profileName,
              }
            ),
            {
              variant: "default",
              preventDuplicate: true,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              style: {
                backgroundColor: "#585858",
                marginBottom: "150px",
              },
            }
          );
        }
        if (
          isScreenShareEnabled.current &&
          message.response?.action === "update" &&
          message.response?.conference?.screen_share &&
          message.response?.action_by === me?.id
        ) {
          if (
            mySharingUINTIDRef.current &&
            mySharingUINTIDRef.current !== message.response?.conference?.uid
          ) {
            activeCall?.handleStopScreenShare();
          }
        }
        if (
          isScreenShareEnabled.current &&
          message.response?.action === "update" &&
          message.response?.conference?.screen_share &&
          message.response?.action_by !== me?.id
        ) {
          activeCall?.handleStopScreenShare();
        }
        if (
          message.response.action === "update" &&
          message.response.conference.screen_share
        ) {
          toast(
            t(
              {
                id: "call-conference.user-is-presenting-screen",
                defaultMessage: "{user} is presenting screen",
              },
              {
                user: profileName,
              }
            ),
            {
              variant: "default",
              preventDuplicate: true,
              anchorOrigin: { vertical: "bottom", horizontal: "right" },
              style: {
                backgroundColor: "#585858",
                marginBottom: "150px",
              },
            }
          );
        }
      }
    );
    return () => {
      if (activeCallProps?.channel) {
        return;
      }
      channel.off("conference_resp", conferenceRespRefId);
    };
  }, [
    activeCall,
    activeCallProps?.channel,
    activeCall?.remoteUsers,
    channel,
    joinedChannel,
    me?.id,
    mySharingUINTID,
    screenSharingUser,
    t,
    toast,
    getUserGeneralProfile,
    entity.id,
  ]);

  useEffect(() => {
    if (activeCall?.localVideoTrack && activeCall.stage === "participation") {
      activeCall?.localVideoTrack.play("localplayer", { fit: "contain" });
    }
    return () => activeCall?.localVideoTrack?.stop();
  }, [
    minimizedScreen,
    activeCall?.localVideoTrack,
    activeCall?.videoEnabled,
    activeCall?.stage,
  ]);
  const usersCount = useMemo(() => {
    const count = activeCall?.remoteUsers?.length || 0;
    return count + 1;
  }, [activeCall?.remoteUsers?.length]);

  useEffect(() => {
    const mySharingId = `screenSharing_${me?.id}`;
    if (
      isScreenShareEnabled.current &&
      !isSharingMessageSent &&
      mySharingUINTID
    ) {
      remoteVideoTracks.videoTracks.forEach((track) => {
        const isPresent = track.getUserId() === mySharingId;
        if (isPresent) {
          channel?.push("conference:update", {
            id: activeCallProps?.channel,
            screen_share: true,
            uid: mySharingUINTID,
          });
          setSharingMessageSent(true);
        }
      });
    }
  }, [
    activeCall?.screenSharingEnabled,
    activeCallProps?.channel,
    channel,
    isSharingMessageSent,
    me?.id,
    mySharingUINTID,
    remoteVideoTracks.videoTracks,
  ]);

  const pageSize = useMemo(() => {
    if (screenSharingUser) {
      return 3;
    }
    switch (true) {
      case windowSize < 767:
        return 6;
      case windowSize >= 768 && windowSize < 1536:
        return 8;
      case windowSize >= 1536:
        return 12;
      default:
        return 8;
    }
  }, [windowSize, screenSharingUser]);

  const gridAvatarSize = useMemo(() => {
    if (screenSharingUser) {
      return 80;
    }
    if (pageSize === 6) {
      return 40;
    }
    if (pageSize === 8) {
      return usersCount > 6 ? 65 : 130;
    }
    if (pageSize === 12) {
      return usersCount > 8 ? 80 : 130;
    }
    return 80;
  }, [pageSize, screenSharingUser, usersCount]);

  useEffect(() => {
    setPage(1);
  }, [screenSharingUser?.uid]);

  const totalPages = useMemo(() => {
    const isAdditionalUserOn =
      activeCall?.remoteUsers?.filter((user) => {
        const id = user.uid as string;
        return id.includes("screenSharing");
      }) || [];
    const updatedUsersCount =
      isAdditionalUserOn?.length > 0 ? usersCount - 1 : usersCount;
    return Math.ceil(updatedUsersCount / pageSize);
  }, [activeCall?.remoteUsers, pageSize, usersCount]);
  const currentPageUsers = useCallback(
    (i: number) => {
      const moreThan = page * pageSize - pageSize;
      const lessThan = page * pageSize;
      return i >= moreThan && i < lessThan;
    },
    [page, pageSize]
  );
  if (!activeCall || !isAuthenticated) {
    if (activeCall) {
      leaveCall?.();
      setMinimizedScreen(false);
      joinedChannel.current = false;
    }
    return null;
  }
  const handleSettingFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen?.();
    } else {
      document.exitFullscreen?.();
    }
  };

  const handleEndCall = async () => {
    window.close();
    if (location.pathname.includes("conference")) {
      navigateToPage("chatsChats");
    }
    await channel?.push("conference:update", {
      id: activeCallProps?.channel,
      screen_share: false,
      uid: mySharingUINTID,
    });
    await leaveCall?.();
    await channel?.push("conference:leave", {
      id: activeCallProps?.channel,
    });
    setMinimizedScreen(false);
    joinedChannel.current = false;
    setPage(1);
    setDuration(0);
  };
  const handleSetMinimizedScreen = () => {
    if (!minimizedScreen) {
      if (location.pathname.includes("conference")) {
        navigateToPage("chatsChats");
      }
    }
    setMinimizedScreen(!minimizedScreen);
  };

  const handleToggleScreenShare = async () => {
    if (isScreenShareEnabled.current) {
      if (
        latestChannelStatus?.action_by === me?.id &&
        mySharingUINTID &&
        latestChannelStatus?.conference.uid
      ) {
        channel?.push("conference:update", {
          id: activeCallProps?.channel,
          screen_share: false,
          uid: mySharingUINTID,
        });
      }
      await activeCall.handleStopScreenShare();
    }
    if (!isScreenShareEnabled.current) {
      activeCall.toggleScreenShare();
      setSharingMessageSent(false);
    }
  };
  if (minimizedScreen) {
    return (
      <MinimizedCall
        toggleMinimized={handleSetMinimizedScreen}
        containerClassName="w-[467px] h-[281px] bg-[#39393A]"
        handleEndCall={handleEndCall}
      >
        <div className="absolute top-10 left-10 right-0 flex items-center gap-6 rounded-t-xl bg-[#39393A]">
          <CallAvatar
            size={80}
            userId={
              (debouncedCurrentSpeakerId &&
                parseUserId(debouncedCurrentSpeakerId)) ||
              me?.id ||
              ""
            }
            isSpeaking={debouncedCurrentSpeakerId}
          />
          <Info
            isPresentingScreen={isScreenShareEnabled.current}
            formattedDuration={formattedDuration}
            conferenceName={activeCallProps?.conferenceMessage?.name || ""}
          />
        </div>
        {activeCall.remoteUsers?.map((user) => (
          <RemoteUserCallWindow
            user={user}
            key={user.uid}
            hide
            showAvatarOnly
          />
        ))}
      </MinimizedCall>
    );
  }
  if (activeCall?.stage === "staging") {
    return <EnterScreen />;
  }
  return (
    <div className="fixed inset-0 z-20  box-border grid grid-flow-row grid-rows-[80px,calc(100vh-193px),113px] overflow-hidden bg-[#39393A]">
      {!isScreenSharingFullScreen && (
        <Header
          formattedDuration={formattedDuration}
          participants={[]}
          handleMinimize={handleSetMinimizedScreen}
          conferenceName={activeCallProps?.conferenceMessage?.name || ""}
        />
      )}
      {isScreenSharingFullScreen && mouseMoved && (
        <Header
          formattedDuration={formattedDuration}
          participants={[]}
          handleMinimize={handleSetMinimizedScreen}
          handleToggleFullscreen={handleSettingFullScreen}
          conferenceName={activeCallProps?.conferenceMessage?.name || ""}
          variant="fullscreenScreenShare"
          screenSharingUserId={parseUserId(
            (screenSharingUser?.uid as string) || ""
          )}
        />
      )}
      <MainContainer
        isScreenSharing={Boolean(screenSharingUser)}
        isScreenSharingFullScreen={isScreenSharingFullScreen}
        usersCount={usersCount}
      >
        <PlainButton
          className={cx(
            "bg-dark/50 fixed left-4 top-1/2  h-[40px] w-[40px] -translate-y-[42px] cursor-pointer  rounded-2xl",
            {
              hidden: page === 1 || screenSharingUser,
            }
          )}
          onClick={() => setPage(page - 1)}
          disabled={screenSharingUser && page === 1}
        >
          <ArrowLeft />
        </PlainButton>
        <PlainButton
          className={cx(
            "bg-dark/50 fixed right-4 top-1/2 h-[40px] w-[40px] -translate-y-[40px] cursor-pointer rounded-2xl",
            {
              hidden: page === totalPages || screenSharingUser,
            }
          )}
          onClick={() => setPage(page + 1)}
          disabled={screenSharingUser && page === totalPages}
        >
          <ArrowRight />
        </PlainButton>
        {/* Screen Sharing Container */}
        {screenSharingUser && (
          <div
            className={cx("relative w-full", {
              "max-h-[calc(100vh-220px)] overflow-hidden":
                isScreenSharingFullScreen && mouseMoved,
              "max-h-[100vh]": isScreenSharingFullScreen && !mouseMoved,
              "max-h-[calc(100vh-220px)]": !isScreenSharingFullScreen,
            })}
            key={screenSharingUser.uid}
          >
            <div
              className={cx(
                "bg-user-call-1 absolute inset-0 z-30 flex items-center justify-center rounded-xl",
                {
                  hidden: activeCall && !activeCall.screenSharingEnabled,
                }
              )}
            >
              <div className="flex flex-col items-center gap-3">
                <ScreenShareIcon />
                <Text variant="body3" className="font-bold text-white">
                  {t({
                    id: "call-conference.you-sharing-your-screen",
                    defaultMessage: "You sharing your screen",
                  })}
                </Text>
                <PlainButton
                  className="bg-grey-200/20 mt-3 rounded-lg px-9 py-2 hover:brightness-90"
                  onClick={handleToggleScreenShare}
                >
                  <Text
                    variant="body3"
                    className="font-semibold uppercase text-white"
                  >
                    {t({
                      id: "call-conference.stop-sharing",
                      defaultMessage: "Stop sharing",
                    })}
                  </Text>
                </PlainButton>
              </div>
            </div>
            <span
              className={cx(
                "bg-dark/50 absolute bottom-6 right-6 z-30 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full",
                {
                  hidden:
                    isScreenSharingFullScreen ||
                    activeCall.screenSharingEnabled,
                }
              )}
              onClick={handleSettingFullScreen}
            >
              <GetFullScreenIcon />
            </span>
            <RemoteUserCallWindow
              user={screenSharingUser}
              key={screenSharingUser.uid}
              screenSharingId={screenSharingUser?.uid as string}
              isFullscreen={isScreenSharingFullScreen}
            />
          </div>
        )}
        <UsersGrid
          isScreenSharing={Boolean(screenSharingUser)}
          isScreenSharingFullScreen={isScreenSharingFullScreen}
          usersCount={usersCount}
        >
          <PlainButton
            className={cx(
              "bg-dark/50 z-30 h-[40px] w-[40px] cursor-pointer rounded-2xl",
              {
                "disabled:bg-dark/30 absolute -bottom-[50px] right-1/2 -translate-x-[10px]":
                  screenSharingUser,
                hidden: page === 1 || !screenSharingUser,
              }
            )}
            onClick={() => setPage(page - 1)}
            disabled={screenSharingUser && page === 1}
          >
            <ArrowLeft />
          </PlainButton>
          <PlainButton
            className={cx(
              "bg-dark/50 z-30 h-[40px] w-[40px] cursor-pointer rounded-2xl",
              {
                "disabled:bg-dark/30 absolute -bottom-[50px] right-1/2 translate-x-[46px]":
                  screenSharingUser,
                hidden: page === totalPages || !screenSharingUser,
              }
            )}
            onClick={() => setPage(page + 1)}
            disabled={screenSharingUser && page === totalPages}
          >
            <ArrowRight />
          </PlainButton>
          <div
            className={cx(
              "h-full w-full",
              !screenSharingUser && {
                "col-span-2": usersCount >= 4,
              },
              {
                "col-span-2": screenSharingUser,
                hidden: page !== 1,
              }
            )}
          >
            <LocalUserCallWindow
              screenSharingId={screenSharingUser?.uid as string}
              gridAvatarSize={gridAvatarSize}
            />
          </div>
          {activeCall?.remoteUsers?.map((user, i) => {
            if (user.uid === screenSharingUser?.uid) return null;
            return (
              <div
                className={cx(
                  "h-full w-full",
                  !screenSharingUser && {
                    "col-span-2": usersCount >= 4,
                    "col-start-3": usersCount === 4 && i === 2,
                    "col-start-2": usersCount === 5 && i === 2,
                  },
                  {
                    hidden: !currentPageUsers(i + 1),
                    "col-span-2": screenSharingUser,
                  }
                )}
                key={user.uid}
              >
                <RemoteUserCallWindow
                  user={user}
                  key={user.uid}
                  screenSharingId={screenSharingUser?.uid as string}
                  isFullscreen={isScreenSharingFullScreen}
                  gridAvatarSize={gridAvatarSize}
                />
              </div>
            );
          })}
        </UsersGrid>
      </MainContainer>
      <div
        className={cx(
          "hxs:scale-[0.8] hsm:scale-[0.9] z-20 mb-10 flex h-[73px] flex-row items-center justify-center gap-8",
          {
            hidden: isScreenSharingFullScreen && !mouseMoved,
          }
        )}
      >
        <ControlPanel
          toggleMinimize={handleSetMinimizedScreen}
          isVideoOn={activeCall.videoEnabled}
          isMicOn={activeCall.audioEnabled}
          isScreenShared={activeCall.screenSharingEnabled}
          toggleVideo={activeCall?.toggleVideo}
          toggleMic={activeCall.toggleAudio}
          toggleScreenShare={handleToggleScreenShare}
          endCall={handleEndCall}
          isMinimized={minimizedScreen}
          optionsToHide={["toggleMinimize"]}
          screenSharingId={parseUserId(screenSharingUser?.uid as string)}
        />
      </div>
    </div>
  );
};
