import { ScreenTransitionWrapper } from "@jugl-web/ui-components/cross-platform/ScreenTransitionWrapper";
import { useTranslations, useUniqueId } from "@jugl-web/utils";
import {
  Screen,
  ScreenTransitionManagerProvider,
  useScreenTransitionManager,
} from "@jugl-web/utils/utils/ScreenTransitionManager";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  TaskReminderOptionId,
  useTaskReminders,
} from "../../hooks/useTaskReminders";
import { Calendar } from "./components/Calendar";
import { EntryScreen } from "./components/EntryScreen";
import { ReminderList } from "./components/ReminderList";
import { TimezoneList } from "./components/TimezoneList";
import {
  TaskDueDateFormContext,
  TaskDueDateFormContextValue,
} from "./contexts/TaskDueDateFormContext";
import {
  TaskDueDateFormProps,
  TaskDueDateFormScreenToParametersMap,
} from "./types";

const INITIAL_SCREEN: Screen<TaskDueDateFormScreenToParametersMap> = {
  name: "entry",
};

const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const InnerTaskDueDateForm: FC<TaskDueDateFormProps> = ({
  initialState,
  defaultTimezone = systemTimezone,
  withCalendar = true,
  withAdvancedOptions = false,
  renderStartSlot,
  onSubmit,
  onClose,
}) => {
  /**
   * The initial timezone will be considered in the following order:
   * 1. Initial state timezone (the target timezone for a given task, if provided)
   * 2. Default timezone (the workspace timezone, if provided)
   * 3. System timezone (fallback)
   */
  const getInitialTimezone = () => initialState?.timezone || defaultTimezone;

  useEffect(() => {
    // TODO: Remove this after testing
    // eslint-disable-next-line no-console
    console.log({
      initialStateTimezone: initialState?.timezone,
      defaultTimezone,
      systemTimezone,
    });
  }, [defaultTimezone, initialState?.timezone]);

  const [selectedTimezone, setSelectedTimezone] =
    useState<string>(getInitialTimezone);

  const getInitialDate = () => {
    const initialDateInTargetTimezone = initialState?.date;

    if (!initialDateInTargetTimezone) {
      return null;
    }

    return utcToZonedTime(initialDateInTargetTimezone, selectedTimezone);
  };

  const [selectedDate, setSelectedDate] = useState<Date | null>(getInitialDate);

  const selectedDateInTargetTimezone = useMemo(
    () =>
      selectedDate ? zonedTimeToUtc(selectedDate, selectedTimezone) : null,
    [selectedDate, selectedTimezone]
  );

  const { findReminderOptionByNextExecutionTime } = useTaskReminders();

  const [selectedReminderId, setSelectedReminderId] =
    useState<TaskReminderOptionId>(() => {
      if (initialState?.reminderExecutionTime) {
        return (
          findReminderOptionByNextExecutionTime(
            initialState.reminderExecutionTime,
            initialState.date
          )?.id || "none"
        );
      }

      return "none";
    });

  const { screen, transitionTo, renderContent } =
    useScreenTransitionManager<TaskDueDateFormScreenToParametersMap>();

  const formId = useUniqueId();
  const { t } = useTranslations();

  const moveBackToEntryScreen = useCallback(() => {
    transitionTo({ name: "entry" });
  }, [transitionTo]);

  const content = useMemo(
    () =>
      renderContent({
        entry: <EntryScreen onSubmit={onSubmit} onClose={onClose} />,
        calendar: <Calendar onAfterSelect={moveBackToEntryScreen} />,
        timezoneList: <TimezoneList onAfterSelect={moveBackToEntryScreen} />,
        reminderList: <ReminderList onAfterSelect={moveBackToEntryScreen} />,
      }),
    [moveBackToEntryScreen, onClose, onSubmit, renderContent]
  );

  const title = useMemo(
    () =>
      renderContent({
        entry: t({
          id: "tasks-page.task-due-date",
          defaultMessage: "Task due date",
        }),
        calendar: t({
          id: "tasks-page.select-date",
          defaultMessage: "Select date",
        }),
        timezoneList: t({
          id: "tasks-page.select-timezone",
          defaultMessage: "Select timezone",
        }),
        reminderList: t({
          id: "tasks-page.select-reminder",
          defaultMessage: "Select reminder",
        }),
      }),
    [renderContent, t]
  );

  const contextValue = useMemo<TaskDueDateFormContextValue>(
    () => ({
      formConfig: {
        id: formId,
        defaultTimezone,
        withCalendar,
        withAdvancedOptions,
      },
      selectedDate,
      selectedDateInTargetTimezone,
      selectedTimezone,
      selectedReminderId,
      setSelectedDate,
      setSelectedTimezone,
      setSelectedReminderId,
    }),
    [
      formId,
      defaultTimezone,
      selectedDate,
      selectedDateInTargetTimezone,
      selectedReminderId,
      selectedTimezone,
      withAdvancedOptions,
      withCalendar,
    ]
  );

  return (
    <TaskDueDateFormContext.Provider value={contextValue}>
      <ScreenTransitionWrapper
        screenName={screen.name}
        transitionType="fade"
        transitionTime="fast"
      >
        {renderStartSlot &&
          renderStartSlot({
            currentScreenTitle: title,
            canMoveBack: screen.name !== "entry",
            onMoveBack: moveBackToEntryScreen,
          })}
        {content}
      </ScreenTransitionWrapper>
    </TaskDueDateFormContext.Provider>
  );
};

export const TaskDueDateForm: FC<TaskDueDateFormProps> = (props) => (
  <ScreenTransitionManagerProvider initialScreen={INITIAL_SCREEN}>
    <InnerTaskDueDateForm {...props} />
  </ScreenTransitionManagerProvider>
);
