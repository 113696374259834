import {
  Popover,
  TaskPropertyButton,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { FC, useState } from "react";
import { ReactComponent as RepeatIcon } from "../../assets/repeat.svg";
import { FieldComponentProps } from "../../types";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { RecurrencePausedWarningDialog } from "./components/RecurrencePausedWarningDialog";
import { RecurrencePopoverContent } from "./components/RecurrencePopoverContent";
import { RecurrenceRemovalWarningDialog } from "./components/RecurrenceRemovalWarningDialog";
import { useTaskRecurrence } from "./hooks/useTaskRecurrence";

export const RecurrenceField: FC<FieldComponentProps<"recurrence">> = ({
  state,
  defaultTimezone,
  shouldWarnBeforeRemoving = false,
  isReadonly,
  onChange,
}) => {
  const [
    isRecurrencePausedWarningDialogOpen,
    setIsRecurrencePausedWarningDialogOpen,
  ] = useState(false);

  const [isRemovalWarningDialogOpen, setIsRemovalWarningDialogOpen] =
    useState(false);

  const { getButtonLabel } = useTaskRecurrence();

  const { t } = useTranslations();
  const { toast } = useToast();

  const hasRecurrence = !!state;
  const isRecurrencePaused = state?.enabled === false;
  const isFieldVisible = hasRecurrence || !isReadonly;

  const handleRemoveRecurrence = () => {
    if (shouldWarnBeforeRemoving) {
      setIsRemovalWarningDialogOpen(true);
    } else {
      onChange?.(null);
    }
  };

  if (!isFieldVisible) {
    return null;
  }

  return (
    <>
      <Popover
        placement="bottom-start"
        className="min-w-[300px] px-3 py-2"
        isDisabled={isRecurrencePaused}
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            as={TaskPropertyButton}
            ref={triggerRef}
            isDisabled={isReadonly}
            className={cx(
              hasRecurrence &&
                (isRecurrencePaused
                  ? "bg-tertiary-50 text-tertiary-900"
                  : "bg-primary-50 text-primary-900")
            )}
            startIcon={
              <RepeatIcon
                className={cx(
                  hasRecurrence &&
                    (isRecurrencePaused
                      ? "text-gradients-danger"
                      : "text-primary")
                )}
              />
            }
            endIcon={isRecurrencePaused && <WarningIcon />}
            onClick={(event) => {
              if (isRecurrencePaused) {
                event.preventDefault();
                setIsRecurrencePausedWarningDialogOpen(true);
              }
            }}
          >
            {getButtonLabel(state)}
          </Trigger>
        )}
      >
        {({ onClose }) => (
          <RecurrencePopoverContent
            state={state}
            defaultTimezone={defaultTimezone}
            onRemove={handleRemoveRecurrence}
            onChange={(s) => onChange?.(s)}
            onClose={onClose}
          />
        )}
      </Popover>
      <RecurrencePausedWarningDialog
        isOpen={isRecurrencePausedWarningDialogOpen}
        recurrence={state}
        onRecurrenceChange={(s) => onChange?.(s)}
        onClose={() => setIsRecurrencePausedWarningDialogOpen(false)}
      />
      <RecurrenceRemovalWarningDialog
        isOpen={isRemovalWarningDialogOpen}
        onConfirm={() => {
          onChange?.(null);
          toast(
            t({
              id: "feedback.recurring-settings-removed",
              defaultMessage: "Recurring settings have been removed",
            })
          );
        }}
        onClose={() => setIsRemovalWarningDialogOpen(false)}
      />
    </>
  );
};
