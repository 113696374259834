import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { GetUpdatedFiltersFn } from "./types";

export const getActiveFiltersCount = (filters: InternalTaskFilters) => {
  let count = 0;

  count += filters.boards?.length || 0;
  count += filters.assignees?.length || 0;
  count += filters.customers?.length || 0;
  count += filters.labels?.length || 0;
  count += filters.priorities?.length || 0;
  count += filters.statuses?.length || 0;

  count += filters.customFields
    ? Object.values(filters.customFields).reduce(
        (acc, value) => acc + value.length,
        0
      )
    : 0;

  if (filters.isOverdue) count += 1;
  if (filters.isRecurring) count += 1;

  return count;
};

export const getStateTransformer =
  (state: InternalTaskFilters): GetUpdatedFiltersFn =>
  (field, value) => ({
    ...state,
    [field]:
      typeof value === "function"
        ? value(state[field] as Parameters<typeof value>[0])
        : value,
  });
