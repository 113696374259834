import { useRestApiProvider } from "@jugl-web/rest-api";
import { previewTasksAdapter, TasksSource } from "@jugl-web/rest-api/tasks";
import { useCallback, useMemo } from "react";

interface UseSelectTaskByIdOptions {
  entityId: string;
  source: TasksSource;
}

export const useSelectTaskById = ({
  entityId,
  source,
}: UseSelectTaskByIdOptions) => {
  const { tasksApi } = useRestApiProvider();

  const { data: taskEntityState } = tasksApi.endpoints.getTasks.useQueryState({
    entityId,
    source,
  });

  const adapterSelectors = useMemo(
    () => previewTasksAdapter.getSelectors(),
    []
  );

  const selectTaskById = useCallback(
    (taskId: string) => {
      const matchingTask = adapterSelectors.selectById(
        taskEntityState ?? previewTasksAdapter.getInitialState(),
        taskId
      );

      return matchingTask;
    },
    [adapterSelectors, taskEntityState]
  );

  return selectTaskById;
};
