import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import Lottie from "react-lottie";
import somethingWentWrongAnimation from "./assets/something-went-wrong-animation.json";

type TaskListErrorType = "forbidden" | "bad-request" | "unknown";

interface TaskListErrorScreenProps {
  errorType: TaskListErrorType;
  onNavigateToMyTasks: () => void;
  onRefetch: () => void;
}

export const TaskListErrorScreen: FC<TaskListErrorScreenProps> = ({
  errorType,
  onNavigateToMyTasks,
  onRefetch,
}) => {
  const { t } = useTranslations();

  const handleClick = () => {
    if (errorType === "unknown") {
      onRefetch();
    } else {
      onNavigateToMyTasks();
    }
  };

  const messageByErrorType: Record<TaskListErrorType, string> = {
    forbidden: t({
      id: "tasks-page.list-forbidden-error-screen-description",
      defaultMessage:
        "The page you are trying to reach has restricted access or no longer exists",
    }),
    "bad-request": t({
      id: "tasks-page.list-forbidden-error-screen-description",
      defaultMessage:
        "The page you are trying to reach has restricted access or no longer exists",
    }),
    unknown: t({
      id: "tasks-page.list-unknown-error-screen-description",
      defaultMessage:
        "Failed to load your tasks. Please try again in a moment.",
    }),
  };

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Lottie
        options={{ animationData: somethingWentWrongAnimation }}
        height={180}
        width={300}
      />
      <h2 className="font-secondary text-dark mt-10 mb-[18px] text-[32px] font-medium leading-8 -tracking-[0.32px]">
        {t({
          id: "tasks-page.list-error-screen-title",
          defaultMessage: "Something went wrong",
        })}
      </h2>
      <p className="text-dark-800 mt-0 mb-12 leading-4 -tracking-[0.16px]">
        {messageByErrorType[errorType]}
      </p>
      <Button
        variant="contained"
        color="primary"
        className="h-10 w-[300px]"
        onClick={handleClick}
      >
        {errorType === "unknown"
          ? t({ id: "common.retry", defaultMessage: "Retry" })
          : t({ id: "common.okay", defaultMessage: "Okay" })}
      </Button>
    </div>
  );
};
