import React, { useState } from "react";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { Switch } from "@headlessui/react";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { useToast, useTranslations } from "@jugl-web/utils";
import { UserListItem } from "@jugl-web/domain-resources/users/components/UserListItem";
import { HeadlessUsersList } from "@jugl-web/domain-resources/users/components/HeadlessUsersList";
import { useDebounce } from "react-use";
import { ReactComponent as FolderIcon } from "./assets/folder.svg";
import { ReactComponent as ChevronLeftIcon } from "./assets/chevron-left.svg";
import ModalInput from "../ModalInput";

const CreateFolderModal: React.FC<{
  visited: { id: string; name: string }[];
  onRequestClose: () => void;
}> = ({ visited, onRequestClose }) => {
  const { t } = useTranslations();
  const { driveApi } = useRestApiProvider();
  const [inputValue, setInputValue] = useState("");
  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState<string>();
  useDebounce(() => setSearchQuery(search), 300, [search]);

  const [isPrivate, setIsPrivate] = useState(false);
  const [createDirectory] = driveApi.useCreateDirectoryMutation();
  const params = useParams();
  const { toast } = useToast({ variant: "web" });
  const { entity } = useEntity();
  const { me, id: myId } = useMe();

  const entityId = entity?.id;

  const [checkboxes, setCheckboxes] = useState<{ [k: string]: boolean }>({});

  const handleSubmit = async () => {
    const res = await createDirectory({
      entity_id: params.entityId || "",
      data: {
        name: inputValue || "",
        mode: isPrivate ? "private" : "public",
        users:
          (isPrivate &&
            Object.keys(checkboxes)
              .filter((element) => checkboxes[element] === true)
              .concat(me?.id || "")
              .map((element) => element)) ||
          undefined,
        ...(params.activeChatId && { workspace_id: params.activeChatId }),
        parent_dir_id: visited[visited.length - 1].id || "",
      },
    });
    if ("data" in res && res.data) {
      onRequestClose();
      toast(
        t({
          id: "drive-page.folder-successfully-created",
          defaultMessage: "New Folder successfully created",
        }),
        { autoHideDuration: 3000 }
      );
    }
  };

  return (
    <div className="mx-auto flex h-full w-full flex-col pt-5">
      <div className="px-4">
        <div className="flex items-center">
          <FolderIcon />
          <Text variant="body1" className="ml-3">
            {t({
              id: "drive-page.drive",
              defaultMessage: "Drive",
            })}
          </Text>
          {visited.map((element, index) => {
            if (index === 0) return null;
            return (
              <div key={element.id} className="flex items-center">
                <ChevronLeftIcon />
                <Text variant="body1">{element.name}</Text>
              </div>
            );
          })}
        </div>
        <div className="mt-6 mb-px">
          <span className="text-xs">
            {t({
              id: "drive-page.folder-name",
              defaultMessage: "Folder Name",
            })}
          </span>
          <span className="text-[red]">*</span>
        </div>
        <ModalInput
          onChange={setInputValue}
          placeholder="Enter"
          hasIcon={false}
        />
        <div className="bg-grey-200 mt-8 flex h-[68px] w-full items-center justify-between px-4">
          <div className="flex flex-col">
            <span className="text-sm font-bold">
              {t({
                id: "drive-page.make-it-private",
                defaultMessage: "Do you want to make it Private?",
              })}
            </span>
            <span className="text-xs">
              {t({
                id: "drive-page.select-users-to-grant-access",
                defaultMessage: "Select the users you want to grant access",
              })}
            </span>
          </div>
          <Switch
            checked={isPrivate}
            onChange={setIsPrivate}
            className={`${isPrivate ? "bg-secondary-500" : "bg-grey-500"}
            relative inline-flex h-[20px] w-[40px] shrink-0 cursor-pointer rounded-full border-none transition-colors duration-200 ease-in-out`}
          >
            <span
              className={`${
                isPrivate
                  ? "translate-x-5 bg-white"
                  : "bg-grey-800 -translate-x-4"
              }
               absolute top-1/2 h-[25px] w-[25px] -translate-y-1/2 transform rounded-full transition duration-200 ease-in-out`}
            />
          </Switch>
        </div>
      </div>
      {isPrivate ? (
        <div className="p-4">
          <ModalInput
            onChange={setSearch}
            placeholder={t({
              id: "common.search-with-ellipsis",
              defaultMessage: "Search...",
            })}
            hasIcon
          />
        </div>
      ) : null}
      <div className="flex-1">
        {entityId && !!isPrivate && (
          <HeadlessUsersList
            virtualized
            fetchParams={{
              entityId,
              searchQuery,
              excludeUsers: myId,
              workspaceId: params?.activeChatId
                ? params.activeChatId
                : undefined,
            }}
            userRenderer={(user) => (
              <UserListItem
                entityId={entityId}
                userId={user.id}
                variant="web"
                mode="check"
                isSelected={checkboxes[user.id]}
                onSelect={() => {
                  setCheckboxes({
                    ...checkboxes,
                    [user.id]: !checkboxes[user.id],
                  });
                }}
                defaultSubTitle="department"
                highlightText={search}
              />
            )}
          />
        )}
      </div>
      <div className="px-4 py-8">
        <Button
          uppercase
          className="w-full"
          onClick={handleSubmit}
          isDisabled={inputValue.trimStart() === ""}
        >
          {t({
            id: "drive-page.create-folder",
            defaultMessage: "Create folder",
          })}
        </Button>
      </div>
    </div>
  );
};

export default CreateFolderModal;
