import { ListBox, ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import {
  TaskReminderOptionId,
  TaskReminderOptionWithId,
  useTaskReminders,
} from "../../../../hooks/useTaskReminders";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";

interface ReminderListProps {
  onAfterSelect: () => void;
}

export const ReminderList: FC<ReminderListProps> = ({ onAfterSelect }) => {
  const {
    selectedDateInTargetTimezone,
    selectedReminderId,
    setSelectedReminderId,
  } = useTaskDueDateFormContext();

  const { getDueDateMatchingReminderOptions } = useTaskReminders();

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const reminderOptionsAsListItems = useMemo<
    ListBoxItem<TaskReminderOptionWithId>[]
  >(() => {
    if (!selectedDateInTargetTimezone) {
      return [];
    }

    const dueDateMatchingReminderOptions = getDueDateMatchingReminderOptions(
      selectedDateInTargetTimezone
    );

    if (
      dueDateMatchingReminderOptions.length === 1 &&
      dueDateMatchingReminderOptions[0].id === "none"
    ) {
      return [];
    }

    return dueDateMatchingReminderOptions.map((option) => ({
      id: option.id,
      value: option,
    }));
  }, [getDueDateMatchingReminderOptions, selectedDateInTargetTimezone]);

  return (
    <>
      {isMobile && (
        <p className="text-dark-800 mt-0 mb-6 text-sm leading-[21px]">
          {t({
            id: "tasks-page.task-reminder-info",
            defaultMessage:
              "Reminder will be sent to all assignees at the selected time before the due date 📩",
          })}
        </p>
      )}
      <ListBox
        items={reminderOptionsAsListItems}
        selectionBehavior={{ mode: "single", canToggle: false }}
        onSelect={(item) => {
          setSelectedReminderId(item.id as TaskReminderOptionId);
          onAfterSelect();
        }}
        defaultSelectedIds={[selectedReminderId]}
        renderLabel={(item) => item.value.label}
        maxVisibleItems={6}
        itemSize={isMobile ? "xl" : "sm"}
        spaceBetweenItems="compact"
      />
    </>
  );
};
