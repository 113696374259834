import { Customer } from "@jugl-web/rest-api";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import { Avatar } from "@jugl-web/ui-components/cross-platform/Avatar";
import { ListBoxProps } from "@jugl-web/ui-components/cross-platform/ListBox";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { ResourcePickerDrawer } from "@jugl-web/ui-components/mobile/ResourcePickerDrawer";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { FC, useMemo, useState } from "react";
import { useCustomers } from "../../../../../customers/hooks/useCustomers";
import { useSubscriptionInfo } from "../../../../../entities/hooks/useSubscriptionInfo";
import { useCustomerListBox } from "../../../../../users/hooks/useCustomerListBox";
import { TaskCustomerUpdateConfirmationDialog } from "../../../TaskCustomerUpdateConfirmationDialog";
import { ReactComponent as CustomerIcon } from "../../assets/customer.svg";
import { FieldComponentProps } from "../../types";

interface CustomerFieldProps extends FieldComponentProps<"customer"> {
  entityId: string;
}

export const CUSTOMER_FIELD_BUTTON_ID = "customer-field";

export const CustomerField: FC<CustomerFieldProps> = ({
  entityId,
  customerId,
  isReadonly,
  addButton,
  isHidden,
  shouldWarnBeforeUpdate = false,
  onChange,
  onClose: onCloseProp,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const updateConfirmationDialogState = useConfirmationDialogState();

  const { isMobile } = useAppVariant();
  const { t } = useTranslations();
  const { isModuleAvailable } = useSubscriptionInfo({ entityId });

  const isFieldVisible =
    isModuleAvailable(SubscriptionPlanModuleId.clients) &&
    (!!customerId || !isReadonly) &&
    !isHidden;

  const { getCustomerDetailsById } = useCustomers({
    entityId,
    skipLoading: !isFieldVisible,
  });

  const { getListBoxProps, clearSearchQuery } = useCustomerListBox({
    entityId,
    skipLoading: !isFieldVisible,
  });

  const selectedCustomerDetails = useMemo(
    () => (customerId ? getCustomerDetailsById(customerId) : undefined),
    [customerId, getCustomerDetailsById]
  );

  const hasSelectedCustomer = !!selectedCustomerDetails;

  const commonButtonProps: TaskPropertyButtonProps & { "data-id": string } = {
    isDisabled: isReadonly,
    startIcon: hasSelectedCustomer ? (
      <Avatar
        size="xs"
        imageUrl={selectedCustomerDetails.avatarUrl}
        username={selectedCustomerDetails.fullName}
      />
    ) : (
      <CustomerIcon />
    ),
    className: cx(
      "max-w-[250px]",
      hasSelectedCustomer && "bg-primary-50 text-primary-900"
    ),
    children: hasSelectedCustomer
      ? t(
          {
            id: "tasks-page.customer-with-name",
            defaultMessage: "Customer: {name}",
          },
          {
            name: selectedCustomerDetails.fullName,
          }
        )
      : t({
          id: "tasks-page.add-customer",
          defaultMessage: "Add customer",
        }),
    "data-id": CUSTOMER_FIELD_BUTTON_ID,
  };

  const commonResourcePickerProps = {
    title: t({
      id: "tasks-page.select-customer",
      defaultMessage: "Select customer",
    }),
    ...getListBoxProps({
      defaultSelectedIds: customerId ? [customerId] : undefined,
    }),
  } satisfies ListBoxProps<Customer | null> & { title: string };

  if (!isFieldVisible) {
    return null;
  }

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <ResourcePickerDrawer
          isOpen={isDialogOpen}
          onSubmit={(_, [item]) => {
            if (customerId && shouldWarnBeforeUpdate) {
              updateConfirmationDialogState.open({
                onConfirm: () => onChange?.(item?.value || null),
              });
            } else {
              onChange?.(item?.value || null);
            }
          }}
          onClose={() => {
            setIsDialogOpen(false);
            clearSearchQuery();
            onCloseProp?.();
          }}
          {...commonResourcePickerProps}
        />
        <TaskCustomerUpdateConfirmationDialog
          isOpen={updateConfirmationDialogState.isOpen}
          onRequestClose={updateConfirmationDialogState.close}
          onUpdate={updateConfirmationDialogState.confirm}
        />
      </>
    );
  }

  return (
    <>
      <ResourcePickerPopover
        placement="bottom-start"
        className="w-[375px]"
        addButton={addButton}
        shouldCloseOnAddButtonClick
        renderTrigger={({ Trigger, triggerRef }) => (
          <Trigger
            ref={triggerRef}
            as={TaskPropertyButton}
            {...commonButtonProps}
            data-id="customer-field"
          />
        )}
        onSelect={({ item, isSelected, onClose }) => {
          if (customerId && shouldWarnBeforeUpdate) {
            updateConfirmationDialogState.open({
              onConfirm: () => onChange?.(isSelected ? item.value : null),
            });
          } else {
            onChange?.(isSelected ? item.value : null);
          }
          onClose();
        }}
        onUnmount={() => {
          clearSearchQuery();
          onCloseProp?.();
        }}
        {...commonResourcePickerProps}
      />
      <TaskCustomerUpdateConfirmationDialog
        isOpen={updateConfirmationDialogState.isOpen}
        onRequestClose={updateConfirmationDialogState.close}
        onUpdate={updateConfirmationDialogState.confirm}
      />
    </>
  );
};
