import {
  downloadBlobAsFile,
  useDownloadFile,
} from "@jugl-web/domain-resources/drive";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Buffer } from "buffer";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { assert } from "@jugl-web/utils";
import { useFilePreview } from "@jugl-web/domain-resources/files/providers/FilePreviewProvider";
import { DataLoadingWrapper } from "@jugl-web/ui-components";
import { useAppDispatch } from "@web-src/store";
import { useEffectOnce } from "react-use";
import { hideSideBar } from "@web-src/features/app/appSlice";
import { PreviewAttachmentDetails } from "@jugl-web/domain-resources/files/types";

export const DownloadAttachmentPage = () => {
  const { encodedAttachmentDetails } = useParams<{
    encodedAttachmentDetails: string;
  }>();
  const { downloadFile } = useDownloadFile();
  const { isOpen, previewFile } = useFilePreview();
  const [isError, setIsError] = useState(false);
  const { entity } = useEntity();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(hideSideBar());
  }, [dispatch]);

  const assertPreviewAttachmentDetails: (
    obj: unknown
  ) => asserts obj is PreviewAttachmentDetails = useCallback(
    (obj: unknown): asserts obj is PreviewAttachmentDetails => {
      if (
        typeof obj !== "object" ||
        obj === null ||
        !("id" in obj) ||
        !("contentType" in obj) ||
        !("streamUrl" in obj) ||
        !("size" in obj) ||
        !("name" in obj) ||
        typeof (obj as PreviewAttachmentDetails).id !== "string" ||
        typeof (obj as PreviewAttachmentDetails).contentType !== "string" ||
        typeof (obj as PreviewAttachmentDetails).size !== "number" ||
        typeof (obj as PreviewAttachmentDetails).name !== "string" ||
        typeof (obj as PreviewAttachmentDetails).streamUrl !== "string"
      ) {
        setIsError(true);
      }
    },
    [] // Add dependencies here if needed
  );

  const handleDownloadAttachment = useCallback(
    async (attachmentDetails: PreviewAttachmentDetails) => {
      if (!entity) {
        return;
      }
      const file = await downloadFile({
        entityId: entity?.id,
        fileName: attachmentDetails.name,
        mimeType: attachmentDetails.contentType,
        fileSize: attachmentDetails.size,
        id: attachmentDetails.id,
      });

      if ("data" in file) {
        downloadBlobAsFile(file.data, attachmentDetails.name);
      } else {
        setIsError(true);
      }
    },
    [downloadFile, entity]
  );

  const processAttachment = useCallback(
    async (attachmentDetails: PreviewAttachmentDetails) => {
      if (!entity) {
        return;
      }
      previewFile({
        name: `${attachmentDetails.name}`,
        url: attachmentDetails.streamUrl,
        mimeType: attachmentDetails.contentType,
        isCloseDisabled: true,
        onDownload: () => handleDownloadAttachment(attachmentDetails),
      });
    },
    [entity, handleDownloadAttachment, previewFile]
  );

  useEffectOnce(() => {
    try {
      assert(!!encodedAttachmentDetails);
      const parsedAttachmentDetails = JSON.parse(
        Buffer.from(encodedAttachmentDetails, "base64").toString("utf-8")
      );
      assertPreviewAttachmentDetails(parsedAttachmentDetails);
      processAttachment(parsedAttachmentDetails);
    } catch (error) {
      setIsError(true);
    }
  });

  return (
    <div className="fixed inset-0 bg-white">
      <DataLoadingWrapper
        className="h-screen w-screen"
        isLoading={!isOpen && !isError}
        isError={isError}
      />
    </div>
  );
};
