import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { TasksSource } from "@jugl-web/rest-api/tasks";
import { BoardAvatar } from "@jugl-web/ui-components/cross-platform/BoardAvatar";
import { HeaderBreadcrumbsItem } from "@jugl-web/ui-components/web/HeaderBreadcrumbs";
import { useTranslations } from "@jugl-web/utils";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useCallback } from "react";

interface UseTaskBreadcrumbsOptions {
  entityId: string;
}

export const useTaskBreadcrumbs = ({ entityId }: UseTaskBreadcrumbsOptions) => {
  const { getCustomerDetailsById } = useCustomers({ entityId });
  const { getBoardById } = useTaskBoards({ entityId });

  const { generateUrl } = useNavigation();
  const { t } = useTranslations();

  const getMyTasksBreadcrumb = useCallback(
    () => ({
      icon: <BoardAvatar size="lg" name="M" color="#AB59E4" />,
      title: t({
        id: "tasks-page.my-tasks",
        defaultMessage: "My Tasks",
      }),
      href: generateUrl("tasksTasks", { boardId: "my" }),
    }),
    [generateUrl, t]
  );

  const getTaskListBreadcrumbs = useCallback(
    (source: TasksSource): HeaderBreadcrumbsItem[] => {
      if (source.type === "customerTasks") {
        const customerDetails = getCustomerDetailsById(source.customerId);

        return [
          {
            title: t({
              id: "tasks-page.customers",
              defaultMessage: "Customers",
            }),
            href: generateUrl("customersCustomers"),
          },
          {
            title:
              customerDetails?.fullName ||
              t({ id: "tasks-page.customer", defaultMessage: "Customer" }),
            href: generateUrl("customersTasks", {
              customerId: source.customerId,
            }),
          },
        ];
      }

      if (source.boardId === "my") {
        return [getMyTasksBreadcrumb()];
      }

      if (source.boardId === "team") {
        return [
          {
            icon: <BoardAvatar size="lg" name="T" color="#F36021" />,
            title: t({
              id: "tasks-page.team-tasks",
              defaultMessage: "Team Tasks",
            }),
            href: generateUrl("tasksTasks", { boardId: "team" }),
          },
        ];
      }

      const matchingBoard = getBoardById(source.boardId);

      return [
        {
          icon: matchingBoard ? (
            <BoardAvatar
              size="lg"
              name={matchingBoard.name}
              color={matchingBoard.color}
            />
          ) : undefined,
          title: matchingBoard
            ? matchingBoard.name
            : t({
                id: "tasks-page.board-tasks",
                defaultMessage: "Board tasks",
              }),
          href: generateUrl("tasksTasks", { boardId: source.boardId }),
        },
      ];
    },
    [generateUrl, getBoardById, getCustomerDetailsById, getMyTasksBreadcrumb, t]
  );

  return { getTaskListBreadcrumbs, getMyTasksBreadcrumb };
};
