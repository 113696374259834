import { cx } from "@jugl-web/utils";
import { getFirstSymbol } from "@jugl-web/utils/utils/getFirstSymbol";
import { colord } from "colord";
import { FC, useMemo } from "react";

type BoardAvatarSize = "xs" | "sm" | "md" | "lg" | "xl";

export interface BoardAvatarProps {
  name: string;
  color: string;
  size: BoardAvatarSize;
  className?: string;
}

const sizeToClasses: Record<BoardAvatarSize, string> = {
  xs: "h-[14px] w-[14px] text-[9px]",
  sm: "h-4 w-4 text-[10px]",
  md: "h-[18px] w-[18px] text-[11px]",
  lg: "h-6 w-6 text-[13px]",
  xl: "h-8 w-8 text-[18px]",
};

export const BoardAvatar: FC<BoardAvatarProps> = ({
  name,
  color: backgroundColor,
  size,
  className,
}) => {
  const textColor = useMemo(
    () =>
      colord(backgroundColor).brightness() < 0.6
        ? "#ffffff"
        : colord(backgroundColor).darken(0.7).toHex(),
    [backgroundColor]
  );

  return (
    <div
      style={{ backgroundColor, color: textColor }}
      className={cx(
        "font-secondary flex select-none items-center justify-center whitespace-nowrap rounded border border-solid border-white text-[10px] font-semibold uppercase",
        sizeToClasses[size],
        className
      )}
    >
      {getFirstSymbol(name)}
    </div>
  );
};
