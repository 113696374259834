import { SubscriptionInfoModel } from "@jugl-web/rest-api";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { FC, ReactNode } from "react";
import { apiUTCToLocalDateTime, priceDisplay } from "@jugl-web/utils";
import { getSubscriptionInfoPlanColorInfo } from "@web-src/modules/workspace/utils/getSubscriptionInfoPlanColorInfo";
import { SeatCountSelect } from "@web-src/modules/workspace/components/SeatCountSelect";
import format from "date-fns/format";
import { InfoItem } from "../InfoItem";
import { useWorkspaceSubscriptionPageProvider } from "../../WorkspaceSubscriptionPageProvider";

type SubscriptionInfoItemProps = {
  subscriptionInfo: SubscriptionInfoModel;
};

export const SubscriptionInfoItem: FC<SubscriptionInfoItemProps> = ({
  subscriptionInfo,
}) => {
  const { seatsUpdateState, setSeatsUpdateState } =
    useWorkspaceSubscriptionPageProvider();
  const { navigateToManageSubscription } = useEntitySubscriptionProvider();
  const purchasedSeats = subscriptionInfo.additionalSeats?.purchased || 0;

  if (subscriptionInfo.status === "trialing") {
    const humanizedValidFromTime = format(
      apiUTCToLocalDateTime(subscriptionInfo.validFrom),
      "dd MMMM, yyyy"
    );
    const humanizedValidTillTime = format(
      apiUTCToLocalDateTime(subscriptionInfo.planTill),
      "dd MMMM, yyyy"
    );
    return (
      <InfoItem
        title="Free Trial Period"
        description={`Trial started on ${humanizedValidFromTime} - Ends on ${humanizedValidTillTime}`}
        icon={
          <div className="font-secondary flex h-[30px] items-center rounded bg-[#F2994A] px-[10px] text-xs font-semibold text-white">
            Trial
          </div>
        }
        action={{
          onClick: navigateToManageSubscription,
          text: "Select Plan",
        }}
      />
    );
  }
  const colorInfo = getSubscriptionInfoPlanColorInfo(subscriptionInfo);

  let description:
    | string
    | ReactNode = `Plan for a Team with up to ${subscriptionInfo.seatsLicensed} members`;
  if (subscriptionInfo.planInfo?.type === "basic") {
    description = `Free plan for a Team with up to ${subscriptionInfo.seatsLicensed} members`;
  }
  if (subscriptionInfo.additionalSeats) {
    description = (
      <div className="flex flex-col gap-1.5 text-sm">
        <div className="text-[#828282]">
          {subscriptionInfo.seatsPlanDefault} Default +{" "}
          {subscriptionInfo.additionalSeats.purchased} Additional seats
        </div>
        <div className="text-[#A4A5AA]">
          Each additional user -{" "}
          {priceDisplay(
            subscriptionInfo.additionalSeats.price,
            subscriptionInfo.additionalSeats.currency
          )}
        </div>
      </div>
    );
  }

  if (subscriptionInfo.status !== "active") {
    description = "";
  }

  const canAddSeats =
    subscriptionInfo.additionalSeats && subscriptionInfo.status === "active";

  return (
    <InfoItem
      title={`Available ${
        (subscriptionInfo.seatsLicensed || 0) - subscriptionInfo.seatsUtilized
      } out of ${subscriptionInfo.seatsLicensed} seats`}
      description={description}
      icon={
        <div
          className="flex h-[30px] items-center rounded px-[10px] text-xs font-semibold text-white"
          style={{ backgroundColor: colorInfo.main }}
        >
          {subscriptionInfo.planInfo?.name}
        </div>
      }
      action={
        !canAddSeats
          ? {
              onClick: navigateToManageSubscription,
              text: "Change Plan",
              color: "gray",
            }
          : undefined
      }
      rightContent={
        canAddSeats ? (
          <SeatCountSelect
            isDisabled={!subscriptionInfo.planIsActive}
            value={purchasedSeats + seatsUpdateState}
            onChange={(value) => setSeatsUpdateState(value - purchasedSeats)}
            minDisabledTooltip={
              subscriptionInfo.seatsUtilized -
                subscriptionInfo.seatsPlanDefault >
              0
                ? "seatsTaken"
                : undefined
            }
            minValue={
              subscriptionInfo.seatsUtilized -
                subscriptionInfo.seatsPlanDefault >
              0
                ? subscriptionInfo.seatsUtilized -
                  subscriptionInfo.seatsPlanDefault
                : 0
            }
          />
        ) : undefined
      }
    />
  );
};
