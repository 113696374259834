import { useRestApiProvider } from "@jugl-web/rest-api";
import { useCallback } from "react";

export const useDuplicateTask = ({ entityId }: { entityId: string }) => {
  const { tasksApi } = useRestApiProvider();
  const [getTask] = tasksApi.useLazyGetTaskQuery();
  const [createTask] = tasksApi.useCreateTaskMutation();

  const duplicateTask = useCallback(
    async (taskId: string) => {
      const taskResponse = await getTask({ entityId, taskId });

      if (taskResponse.data) {
        const createResponse = await createTask({
          entityId,
          task: {
            due_at: taskResponse.data.due_at,
            priority: taskResponse.data.priority,
            checklist: taskResponse.data.checklist,
            reminder: taskResponse.data.reminder,
            desc: taskResponse.data.desc,
            assignees: taskResponse.data.assignees,
            board_id: taskResponse.data.board_id,
            label_id: taskResponse.data.label_id,
            status: taskResponse.data.status,
            cust_id: taskResponse.data.cust_id,
            custom_fields: taskResponse.data.custom_fields,
            has_chklist_chk: taskResponse.data.has_chklist_chk,
            is_self: taskResponse.data.is_self,
            recurrence: taskResponse.data.recurrence,
            name: `${taskResponse.data.name} (copy)`,
            tz: taskResponse.data.tz,
            attach_dir_id: taskId,
          },
        });

        if (createResponse && "data" in createResponse) {
          return createResponse.data.id;
        }
      }

      throw new Error("Failed to duplicate the task");
    },
    [entityId, createTask, getTask]
  );

  return { duplicateTask };
};
