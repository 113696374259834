import {
  ListBoxItem,
  ListBoxProps,
} from "@jugl-web/ui-components/cross-platform/ListBox";
import { assert, useTranslations } from "@jugl-web/utils";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { useEffectOnce } from "react-use";
import { UserListBoxItem } from "../../components/UserListBoxItem";
import { WithoutUserListBoxItem } from "../../components/WithoutUserListBoxItem";
import {
  HeadlessUserItem,
  useHeadlessUsersList,
} from "../useHeadlessUsersList";
import { WITHOUT_USER_ITEM_ID } from "./consts";

type SupportedOverrides = Partial<
  Omit<ListBoxProps<HeadlessUserItem | null>, "onSelect">
>;

interface UseUserListBoxOptions {
  entityId: string;
  withoutUserItem?: { label: string };
  onlyReportees?: boolean;
  skipLoading?: boolean;
  excludeUsers?: string;
}

export const useUserListBox = ({
  entityId,
  withoutUserItem,
  onlyReportees = false,
  skipLoading = false,
  excludeUsers,
}: UseUserListBoxOptions) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { users, listState, isLoading, loadMore, reachedEnd } =
    useHeadlessUsersList({
      entityId,
      searchQuery,
      sortBy: "name",
      onlyReportees,
      excludeUsers,
    });

  const { t } = useTranslations();

  const isUsersRequestInitialized = !!listState;
  const wasSearchingInLastResponse = !!listState?.isSearch;

  const usersAsListItems = useMemo(() => {
    const initialItems: ListBoxItem<HeadlessUserItem | null>[] =
      withoutUserItem?.label && !wasSearchingInLastResponse
        ? [{ id: WITHOUT_USER_ITEM_ID, value: null }]
        : [];

    const userItems = users.map((user) => ({ id: user.id, value: user }));

    return [...initialItems, ...userItems];
  }, [withoutUserItem?.label, wasSearchingInLastResponse, users]);

  const attemptToLoadMoreUsers = useCallback(() => {
    if (isLoading || reachedEnd) {
      return;
    }
    loadMore();
  }, [isLoading, loadMore, reachedEnd]);

  useEffectOnce(() => {
    if (!skipLoading) {
      attemptToLoadMoreUsers();
    }
  });

  const getTitleProps = useCallback(
    (): { title: ReactNode; subtitle: ReactNode } => ({
      title: onlyReportees
        ? t({
            id: "tasks-page.assignee",
            defaultMessage: "Assignee",
          })
        : t({
            id: "tasks-page.select-assignee",
            defaultMessage: "Select assignee",
          }),
      subtitle: onlyReportees
        ? t(
            {
              id: "tasks-page.select-assignees-from-reportees",
              defaultMessage: "Select assignees from your <b>Reportees</b>",
            },
            {
              b: (chunks: (string | JSX.Element)[]) => (
                <span className="text-primary-700">{chunks}</span>
              ),
            }
          )
        : undefined,
    }),
    [onlyReportees, t]
  );

  const getListBoxProps = useCallback(
    (overrides?: SupportedOverrides) =>
      ({
        items: usersAsListItems,
        selectionBehavior: { mode: "multiple" },
        hasSearch: true,
        shouldFilterOnSearch: false,
        loading: isLoading
          ? isUsersRequestInitialized
            ? "bottom-spinner"
            : "skeleton"
          : undefined,
        defaultSelectedIds: [],
        maxVisibleItems: 6,
        itemSize: "lg",
        spaceBetweenItems: "normal",
        renderCustomItem: (item, _, props) => {
          if (item.id === WITHOUT_USER_ITEM_ID) {
            assert(!!withoutUserItem?.label, "withoutUserItem is not provided");

            return (
              <WithoutUserListBoxItem
                {...props}
                label={withoutUserItem.label}
              />
            );
          }

          return (
            <UserListBoxItem entityId={entityId} userId={item.id} {...props} />
          );
        },
        onReachEnd: attemptToLoadMoreUsers,
        onSearch: setSearchQuery,
        ...overrides,
      } satisfies ListBoxProps<HeadlessUserItem | null>),
    [
      usersAsListItems,
      isLoading,
      isUsersRequestInitialized,
      attemptToLoadMoreUsers,
      entityId,
      withoutUserItem?.label,
    ]
  );

  return {
    getListBoxProps,
    getTitleProps,
    clearSearchQuery: () => setSearchQuery(""),
    users,
  };
};
