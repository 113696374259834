import {
  Environment,
  devAgoraAppId,
  devSupersetDomain,
  mapTilerKey,
  devSupersetDashboardIds,
  stagingApiUrl,
  stagingWsDomain,
  prodFirebaseConfig,
  prodFirebaseVapidKey,
  prodFirebaseTokenId,
  prodFacebookConfigId,
} from ".";

export const environment: Environment = {
  production: false,
  firebaseConfig: prodFirebaseConfig,
  firebaseVapidKey: prodFirebaseVapidKey,
  sentryDsn:
    "https://129221559daee187358a59380af2b2a1@o1211156.ingest.us.sentry.io/4506969796378624",
  sentryWhitelistHosts: [
    "staging.jugl.com",
    /jugl-staging-org-web-rc-\d+-\d+-\d+.netlify.app/g,
  ],
  apiUrl: stagingApiUrl,
  wsDomain: stagingWsDomain,
  firebaseTokenId: prodFirebaseTokenId,
  mapTilerKey,
  geocodingKey: "AIzaSyBRxeHe9et0iQSg8hjqABHLneRB16KpKpw",
  agoraAppId: devAgoraAppId,
  intercomAppId: "d8ychfjy",
  supersetDomain: devSupersetDomain,
  supersetDashboardIds: devSupersetDashboardIds,
  supersetChartIdToType: { 114: "tasks", 117: "people", 122: "travelLog" },
  isReCaptchaEnabled: true,
  reCaptchaKey: "6LdMnvopAAAAAID5SLUbjhOfVLc2BDzMlvJPr3nn",
  guestUrl: "https://guest-staging.jugl.com",
  facebookConfigId: prodFacebookConfigId,
};
