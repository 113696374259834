import { useDroppable } from "@dnd-kit/core";
import { TaskColumnDroppableData } from "@jugl-web/domain-resources/tasks";
import {
  TaskCalendarCard,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { assignRefs, useTranslations } from "@jugl-web/utils";
import { useIsScrollable } from "@jugl-web/utils/hooks/useIsScrollable";
import { NewTaskPopoverProps } from "@web-src/features/tasks/TasksControlBar/components/NewTaskPopover";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC } from "react";
import { CalendarViewLayout } from "../CalendarViewLayout";
import { NewTaskColumnButton } from "../NewTaskColumnButton";

interface CalendarWeekColumnListProps {
  tasks: PreviewTask[];
  droppableConfig: {
    id: string;
    data: TaskColumnDroppableData;
  };
  newTaskConfig: Pick<
    NewTaskPopoverProps,
    "initialState" | "taskPropertiesPanelConfigOverrides"
  >;
}

export const CalendarWeekColumnList: FC<CalendarWeekColumnListProps> = ({
  tasks,
  droppableConfig,
  newTaskConfig,
}) => {
  const { tasksSource, openFutureTaskDialog, navigateToTaskDetailsPage } =
    useTasksPageContext();

  const { entityId } = useEntitySelectedProvider();
  const { searchQuery, hasActiveFilter } = useTaskFiltering();

  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: tasksSource,
  });

  const { ref: listRef, isScrollable: isColumnScrollable } = useIsScrollable();

  const { isOver, setNodeRef, active } = useDroppable(droppableConfig);

  const { t } = useTranslations();

  const handleTaskClick = (task: PreviewTask) => {
    if (task._future_task) {
      openFutureTaskDialog(task);
      return;
    }

    navigateToTaskDetailsPage(task.id);
  };

  const shouldShowNoResultsMessage =
    tasks.length === 0 && (hasActiveFilter || searchQuery.length > 0);

  return (
    <CalendarViewLayout.ColumnList
      ref={(el) => {
        setNodeRef(el);
        assignRefs(listRef)(el);
      }}
      canDrop
      isDragging={!!active}
      isOver={isOver}
    >
      {shouldShowNoResultsMessage ? (
        <CalendarViewLayout.EmptyStateMessage>
          {t({
            id: "common.no-results",
            defaultMessage: "No results",
          })}
        </CalendarViewLayout.EmptyStateMessage>
      ) : (
        <>
          {tasks.map((task) => (
            <TaskCalendarCard
              key={task.id}
              className="shrink-0"
              highlightedText={searchQuery}
              onClick={() => handleTaskClick(task)}
              columnId={droppableConfig.id}
              draggable
              {...getTaskCardProps(task)}
            />
          ))}
        </>
      )}
      <NewTaskColumnButton
        entityId={entityId}
        isElevated={isColumnScrollable}
        className="-mx-3 px-3"
        {...newTaskConfig}
      />
    </CalendarViewLayout.ColumnList>
  );
};
