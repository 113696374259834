import { FormGroup, InteractiveContainer } from "@jugl-web/ui-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  cx,
  reorder,
  useAutoResizedTextarea,
  useToast,
  useTranslations,
} from "@jugl-web/utils";
import { useDndMonitor, useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Controller, useForm } from "react-hook-form";
import { Subject } from "rxjs";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import {
  OrderFormComponent,
  OrderFormCustomField,
  OrderFormValues,
} from "@jugl-web/domain-resources/orders";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { CropImageModal } from "@jugl-web/ui-components/web/AvatarSelect/components/CropImageModal/CropImageModal";
import JuglLogoIcon from "./assets/jugl-logo.svg";
import { ReactComponent as PlayIcon } from "./assets/play.svg";
import { OrderFormPreviewDialog } from "./components/OrderFormPreviewDialog";

const ORDER_FORM_TITLE_MAX_LENGTH = 128;
const ORDER_FORM_DESCRIPTION_MAX_LENGTH = 256;
const ORDER_FORM_MESSAGE_MAX_LENGTH = 256;

export const OrderForm: React.FC<{
  entityId: string;
  formValues: OrderFormValues;
  onSubmit: (values: OrderFormValues) => void;
  customFields: OrderFormCustomField[];
  completeStep$: Subject<void>;
  onCustomFieldsChange: React.Dispatch<
    React.SetStateAction<OrderFormCustomField[]>
  >;
}> = ({
  formValues,
  completeStep$,
  onSubmit,
  onCustomFieldsChange: onSelectedCustomFieldsChange,
  customFields: selectedCustomFields,
  entityId,
}) => {
  const { t } = useTranslations();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const titleTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const descriptionTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const messageTextareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { textareaProps: titleTextareaProps } =
    useAutoResizedTextarea(titleTextareaRef);
  const { textareaProps: descriptionTextareaProps } = useAutoResizedTextarea(
    descriptionTextareaRef
  );
  const { textareaProps: messageTextareaProps } =
    useAutoResizedTextarea(messageTextareaRef);
  const { toast } = useToast({ variant: "web" });

  const { setNodeRef, active } = useDroppable({
    id: "order-form",
  });
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [imageToCrop, setImageToCrop] = useState<File>();

  const {
    setValue,
    watch,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<OrderFormValues>({
    defaultValues: formValues,
    mode: "onChange",
  });

  const image = watch("image");
  const isMinTwoCustomFieldsAdded = selectedCustomFields.length > 1;

  const { customFields } = useTaskFields({ entityId });

  const handleCustomFieldsError = useCallback(
    () =>
      toast(
        t({
          id: "order-form-create-page.to-proceed-add-at-least-two-custom-fields",
          defaultMessage: "To proceed, add at least 2 Custom Fields",
        }),
        {
          variant: "error",
        }
      ),
    [toast, t]
  );

  useEffect(() => {
    const subscription = completeStep$.subscribe(() =>
      handleSubmit((values) => {
        if (!isMinTwoCustomFieldsAdded) {
          handleCustomFieldsError();
          return;
        }
        onSubmit(values);
      })()
    );
    return () => subscription.unsubscribe();
  }, [
    completeStep$,
    handleSubmit,
    onSubmit,
    isMinTwoCustomFieldsAdded,
    handleCustomFieldsError,
  ]);

  useDndMonitor({
    onDragEnd: (event) => {
      const { active: draggable, over: droppable } = event;
      if (!draggable.data.current || !droppable || droppable.disabled) {
        return;
      }

      if (droppable.id === "order-form") {
        const customField = customFields.find(
          (field) => field.id === draggable.id
        );
        if (customField) {
          onSelectedCustomFieldsChange((prev) => [
            ...prev,
            {
              ...customField,
              isRequired: false,
            },
          ]);
        }
        return;
      }

      const activeIndex = draggable.data.current?.index;
      const overIndex = droppable?.data.current?.index;
      if (
        typeof activeIndex !== "number" ||
        typeof overIndex !== "number" ||
        activeIndex === overIndex
      ) {
        return;
      }

      onSelectedCustomFieldsChange(
        reorder(selectedCustomFields, activeIndex, overIndex)
      );
    },
  });

  return (
    <>
      <div className="flex h-full w-[720px] shrink-0 flex-col overflow-hidden rounded-t-2xl bg-white p-6">
        <div className="jugl__custom-scrollbar flex h-full flex-col justify-between overflow-y-auto">
          <div>
            <InteractiveContainer
              className={cx(
                "relative h-[120px] w-full shrink-0 overflow-hidden rounded-lg bg-[#ECEAFF] transition-colors",
                !active && "hover:bg-[#DEDCF2]"
              )}
              style={{
                backgroundImage: `url(${JuglLogoIcon}), url(${JuglLogoIcon})`,
                backgroundPosition: "10px 10px, 96px 35px",
              }}
              onClick={() => inputRef.current?.click()}
            >
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                hidden
                onChange={(e) => {
                  const file = e.target?.files?.[0];
                  if (file) {
                    setImageToCrop(file);
                    setIsCropModalOpen(true);
                  }
                  e.target.value = "";
                }}
              />
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  className="h-full w-full object-cover object-center"
                  alt=""
                />
              ) : (
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-2xl bg-white/40 py-2 px-3 backdrop-blur-[1px]">
                  <span className="font-secondary leading-2 text-dark-700 text-xs">
                    {t({
                      id: "order-form-create-page.tap-to-add-cover",
                      defaultMessage: "Tap to add Cover",
                    })}
                  </span>
                </div>
              )}
            </InteractiveContainer>
            <div className="w-full p-8">
              <FormGroup
                htmlFor="title"
                label={t({
                  id: "order-form-create-page.form-title",
                  defaultMessage: "Form Title",
                })}
                className="text-dark"
                isRequired
              >
                <Controller
                  name="title"
                  rules={{ required: true }}
                  control={control}
                  render={({
                    field: { ref, onChange, value, ...controllerProps },
                  }) => {
                    const shouldShowValueLengthWarning =
                      value.length > ORDER_FORM_TITLE_MAX_LENGTH - 30;

                    return (
                      <>
                        <textarea
                          ref={(e) => {
                            ref(e);
                            titleTextareaRef.current = e;
                          }}
                          value={value}
                          onChange={(e) => onChange(e.target.value.trimStart())}
                          {...controllerProps}
                          {...titleTextareaProps}
                          rows={1}
                          maxLength={ORDER_FORM_TITLE_MAX_LENGTH}
                          id="title"
                          className={cx(
                            "focus:border-primary-300 text-dark font-secondary mt-1 w-full resize-none border-0 border-b border-solid border-black/20 pb-2 text-2xl font-medium leading-7 outline-none transition-colors",
                            (errors.title || shouldShowValueLengthWarning) &&
                              "border-gradients-danger focus:border-gradients-danger"
                          )}
                        />
                        {shouldShowValueLengthWarning && (
                          <span className="text-gradients-danger text-xs">
                            {value.length}/{ORDER_FORM_TITLE_MAX_LENGTH}
                          </span>
                        )}
                        {errors.title && (
                          <span className="text-gradients-danger text-xs">
                            {t({
                              id: "order-form-create-page.is-required-field",
                              defaultMessage: "This is a required field",
                            })}
                          </span>
                        )}
                      </>
                    );
                  }}
                />
              </FormGroup>
              <div className="mt-8">
                <FormGroup
                  htmlFor="description"
                  label={t({
                    id: "order-form-create-page.form-description",
                    defaultMessage: "Form Description",
                  })}
                >
                  <Controller
                    name="description"
                    control={control}
                    render={({
                      field: { ref, onChange, value, ...controllerProps },
                    }) => {
                      const shouldShowValueLengthWarning =
                        value.length > ORDER_FORM_DESCRIPTION_MAX_LENGTH - 30;

                      return (
                        <>
                          <textarea
                            ref={(e) => {
                              ref(e);
                              descriptionTextareaRef.current = e;
                            }}
                            placeholder={t({
                              id: "common.enter",
                              defaultMessage: "Enter",
                            })}
                            value={value}
                            onChange={(e) =>
                              onChange(e.target.value.trimStart())
                            }
                            {...controllerProps}
                            {...descriptionTextareaProps}
                            rows={1}
                            maxLength={ORDER_FORM_DESCRIPTION_MAX_LENGTH}
                            id="description"
                            className={cx(
                              "focus:border-primary-300 text-dark mt-1 w-full resize-none border-0 border-b border-solid border-black/20 pb-2 font-[Roboto] text-base leading-4 outline-none transition-colors placeholder:text-base placeholder:text-[#828282]",
                              shouldShowValueLengthWarning &&
                                "border-gradients-danger focus:border-gradients-danger"
                            )}
                          />
                          {shouldShowValueLengthWarning && (
                            <span className="text-gradients-danger text-xs">
                              {value.length}/{ORDER_FORM_DESCRIPTION_MAX_LENGTH}
                            </span>
                          )}
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </div>
              <div className="mt-8 flex flex-col gap-8">
                <SortableContext
                  items={selectedCustomFields}
                  strategy={verticalListSortingStrategy}
                >
                  {selectedCustomFields.map((field, index) => (
                    <OrderFormComponent
                      key={field.id}
                      id={field.id}
                      index={index}
                      title={field.name}
                      type={field.type}
                      isRequired={field.isRequired}
                      isDisabled={field.id === TASK_ORDER_EMAIL_ID}
                      onChange={() =>
                        onSelectedCustomFieldsChange((prev) =>
                          prev.map((el) =>
                            el.id === field.id
                              ? { ...el, isRequired: !el.isRequired }
                              : el
                          )
                        )
                      }
                      onDelete={() =>
                        onSelectedCustomFieldsChange((prev) =>
                          prev.filter((el) => el.id !== field.id)
                        )
                      }
                    />
                  ))}
                </SortableContext>
              </div>
              {active && typeof active.data.current?.index !== "number" && (
                <div
                  className="bg-primary-50 border-primary-800 mt-8 flex h-20 items-center justify-center rounded-lg border border-dashed"
                  ref={setNodeRef}
                >
                  <span className="text-dark font-secondary text-xs">
                    {t({
                      id: "order-form-create-page.drag-and-drop-item-here",
                      defaultMessage: "Drag and drop Item here",
                    })}
                  </span>
                </div>
              )}
              <div className="mt-8">
                <FormGroup
                  htmlFor="message"
                  isRequired
                  label={t({
                    id: "order-form-create-page.confirmation-message",
                    defaultMessage: "Confirmation message",
                  })}
                >
                  <Controller
                    name="message"
                    rules={{ required: true }}
                    control={control}
                    render={({
                      field: { ref, onChange, value, ...controllerProps },
                    }) => {
                      const shouldShowValueLengthWarning =
                        value.length > ORDER_FORM_MESSAGE_MAX_LENGTH - 30;

                      return (
                        <>
                          <textarea
                            ref={(e) => {
                              ref(e);
                              messageTextareaRef.current = e;
                            }}
                            {...controllerProps}
                            {...messageTextareaProps}
                            onChange={(e) =>
                              onChange(e.target.value.trimStart())
                            }
                            value={value}
                            rows={1}
                            id="message"
                            className={cx(
                              "focus:border-primary-300 text-dark mt-1 w-full resize-none border-0 border-b border-solid border-black/20 pb-2 font-[Roboto] text-base leading-4 outline-none transition-colors",
                              (errors.message ||
                                shouldShowValueLengthWarning) &&
                                "border-gradients-danger focus:border-gradients-danger"
                            )}
                            maxLength={ORDER_FORM_MESSAGE_MAX_LENGTH}
                          />
                          {shouldShowValueLengthWarning && (
                            <span className="text-gradients-danger text-xs">
                              {value.length}/{ORDER_FORM_MESSAGE_MAX_LENGTH}
                            </span>
                          )}
                          {errors.message && (
                            <span className="text-gradients-danger text-xs">
                              {t({
                                id: "order-form-create-page.is-required-field",
                                defaultMessage: "This is a required field",
                              })}
                            </span>
                          )}
                        </>
                      );
                    }}
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <InteractiveContainer
            className="bg-primary-50 mx-auto mt-4 flex w-max items-center gap-2 rounded-xl py-2 px-2.5"
            onClick={() => {
              if (!isMinTwoCustomFieldsAdded) {
                handleCustomFieldsError();
                return;
              }
              setIsPreviewOpen(true);
            }}
            isDisabled={!isValid}
          >
            <PlayIcon />
            <span className="font-secondary text-primary-800 text-xs font-semibold">
              {t({
                id: "order-form-create-page.start-preview",
                defaultMessage: "Start Preview 👀",
              })}
            </span>
          </InteractiveContainer>
        </div>
      </div>
      <CropImageModal
        isOpen={isCropModalOpen}
        onChangeImage={() => inputRef.current?.click()}
        onClose={() => setIsCropModalOpen(false)}
        onSave={(file) => {
          setValue("image", file);
          setIsCropModalOpen(false);
        }}
        image={imageToCrop}
        horizontal
      />
      <OrderFormPreviewDialog
        isOpen={isPreviewOpen}
        onClose={() => setIsPreviewOpen(false)}
        values={{
          ...getValues(),
          image: image ? URL.createObjectURL(image) : undefined,
          fields: selectedCustomFields,
        }}
      />
    </>
  );
};
