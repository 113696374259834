import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { assert, useTranslations } from "@jugl-web/utils";
import { isEqual } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  MentionTextarea,
  MentionTextareaHandle,
} from "../../../common/components/RichTextarea";
import { taskChecklistNameValidator } from "../../validators";
import { AssigneePickerButton } from "./AssigneePickerButton";
import { DueDatePickerButton } from "./DueDatePickerButton";
import { TaskChecklistItemButton } from "./TaskChecklistItemButton";
import { TaskChecklistItemContainer } from "./TaskChecklistItemContainer";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { ReactComponent as CancelIcon } from "./icons/cancel.svg";
import { ReactComponent as MenuIcon } from "./icons/menu.svg";
import { TaskChecklistItem } from "./types";
import { getContextMenuSections, saveTaskChecklist$ } from "./utils";

export interface EditingTaskChecklistItemProps {
  item: TaskChecklistItem;
  isNew?: boolean;
  onSaveChanges: (updatedItem: TaskChecklistItem) => void | Promise<void>;
  onCancel: () => void;
  onDelete: (itemId: string) => void;
}

export const EditingTaskChecklistItem: FC<EditingTaskChecklistItemProps> = ({
  item,
  isNew = false,
  onSaveChanges,
  onCancel,
  onDelete,
}) => {
  const {
    isAssignable,
    onlyReportees,
    entityId,
    mentions,
    displayDueDateAs,
    onTriggerSaveBeforeLeavingDialog,
    onTriggerDeleteConfirmationDialog,
  } = useTaskChecklistContext();

  const [internalAssigneeId, setInternalAssigneeId] = useState<string | null>(
    null
  );
  const [internalDueDate, setInternalDueDate] = useState<Date | null>(null);
  const [isValid, setIsValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const $mentionTextareaRef = useRef<MentionTextareaHandle | null>(null);
  const { t } = useTranslations();

  const handleValidateText = (rawText: string) => {
    setIsValid(taskChecklistNameValidator(rawText));
  };

  const hasItemChanged = () => {
    assert(!!$mentionTextareaRef.current, "MentionTextarea ref is not set");

    const updatedItem: TaskChecklistItem = {
      ...item,
      text: $mentionTextareaRef.current.getRichText(),
      assigneeId: internalAssigneeId,
      dueDate: internalDueDate,
    };

    return !isEqual(item, updatedItem);
  };

  const handleSubmit = useCallback(async () => {
    assert(!!$mentionTextareaRef.current, "MentionTextarea ref is not set");

    setIsSubmitting(true);

    await onSaveChanges({
      ...item,
      text: $mentionTextareaRef.current.getRichText(),
      assigneeId: internalAssigneeId,
      dueDate: internalDueDate,
    });

    setIsSubmitting(false);
  }, [internalAssigneeId, internalDueDate, item, onSaveChanges]);

  const handleCancel = () => {
    if (hasItemChanged() && isValid) {
      onTriggerSaveBeforeLeavingDialog({
        onConfirm: handleSubmit,
        onCancel,
      });
    } else {
      onCancel();
    }
  };

  const handleDeleteWithConfirmation = (itemId: string) => {
    if (isNew && !hasItemChanged()) {
      onDelete(itemId);
      return;
    }

    onTriggerDeleteConfirmationDialog({
      onConfirm: () => onDelete(itemId),
      metadata: { item },
    });
  };

  useEffectOnce(() => {
    if (!$mentionTextareaRef.current) {
      return;
    }

    $mentionTextareaRef.current.setRichText(item.text);
    $mentionTextareaRef.current.focus();
    handleValidateText(item.text);

    setInternalAssigneeId(item.assigneeId);
    setInternalDueDate(item.dueDate);
  });

  const itemId = useMemo(() => item.id, [item.id]);

  useEffect(() => {
    const subscription = saveTaskChecklist$.subscribe(({ subtaskId }) => {
      if (subtaskId !== itemId) {
        return;
      }
      if (!$mentionTextareaRef.current?.getRichText().length) {
        onDelete(itemId);
        return;
      }
      handleSubmit();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, itemId, onDelete]);

  return (
    <TaskChecklistItemContainer className="shadow-task-checklist-item bg-[#F2F2F3]">
      <MentionTextarea
        ref={$mentionTextareaRef}
        mentions={{ type: "manual", mentions }}
        placeholder={t({
          id: "form-controls.task-checklist.placeholder",
          defaultMessage: "Enter checklist title",
        })}
        onRawTextChange={handleValidateText}
        onEscape={handleCancel}
        classes={{
          container:
            "w-full text-sm rounded bg-white font-[Roboto] text-sm font-normal leading-[21px]",
          placeholder: "left-4 top-4 text-grey-background",
          contentEditable: "p-4 min-h-[77px] text-black outline-none",
        }}
      />
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-3">
          {isAssignable && (
            <AssigneePickerButton
              itemMode="editing"
              entityId={entityId}
              assigneeId={internalAssigneeId}
              onlyReportees={onlyReportees}
              onAssigneeIdChange={setInternalAssigneeId}
            />
          )}
          <DueDatePickerButton
            itemMode="editing"
            displayAs={displayDueDateAs}
            dueDate={internalDueDate}
            onDueDateChange={setInternalDueDate}
          />
          <Menu
            placement="bottom-start"
            className="min-w-[155px]"
            sections={getContextMenuSections({
              itemId: item.id,
              onDelete: handleDeleteWithConfirmation,
              t,
            })}
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger
                as={TaskChecklistItemButton}
                ref={triggerRef}
                itemMode="editing"
              >
                <MenuIcon />
              </Trigger>
            )}
          />
        </div>
        <div className="flex items-center gap-3">
          {isValid && (
            <Button
              variant="contained"
              color="primary"
              isDisabled={isSubmitting}
              className="jugl__focusable-outline h-[32px] px-6 font-medium"
              onClick={handleSubmit}
            >
              {t({ id: "common.save", defaultMessage: "Save" })}
            </Button>
          )}
          <TaskChecklistItemButton
            itemMode="editing"
            className="w-8 rounded-lg p-0"
            onClick={handleCancel}
          >
            <CancelIcon />
          </TaskChecklistItemButton>
        </div>
      </div>
    </TaskChecklistItemContainer>
  );
};
