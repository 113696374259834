import { Alert, Button } from "@jugl-web/ui-components/cross-platform";
import { FC } from "react";
import Lottie from "react-lottie";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import errorAnimation from "./assets/error-animation.json";

export interface TaskCustomerUpdateConfirmationDialogProps {
  isOpen: boolean;
  onUpdate: () => void;
  onRequestClose: () => void;
}

export const TaskCustomerUpdateConfirmationDialog: FC<
  TaskCustomerUpdateConfirmationDialogProps
> = ({ isOpen, onUpdate, onRequestClose }) => {
  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        onClose={onRequestClose}
        className="py-8 px-4"
      >
        <div className="flex flex-col items-center gap-4 text-center">
          <Lottie options={{ animationData: errorAnimation }} width={70} />
          <div className="text-dark font-secondary text-xl font-medium">
            {t({
              id: "tasks-page.task-customer-updates",
              defaultMessage: "Task Customer Updates",
            })}
          </div>
          <span className="text-dark text-base leading-4">
            {t({
              id: "tasks-page.task-customer-updated-confirmation-message",
              defaultMessage:
                "Please note that all activities and comments made by previous Customer will be removed, since new Customer will be assigned to this Task",
            })}
          </span>
          <div className="my-8 flex w-full items-center gap-4">
            <Button onClick={onRequestClose} fullWidth color="grey">
              {t({ id: "common.cancel", defaultMessage: "Cancel" })}
            </Button>
            <Button
              onClick={() => {
                onUpdate();
                onRequestClose();
              }}
              fullWidth
            >
              {t({ id: "common.continue", defaultMessage: "Continue" })}
            </Button>
          </div>
        </div>
      </MobileDrawer>
    );
  }

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      img={<Lottie options={{ animationData: errorAnimation }} width={70} />}
      title={t({
        id: "tasks-page.task-customer-updates",
        defaultMessage: "Task Customer Updates",
      })}
      content={t({
        id: "tasks-page.task-customer-updated-confirmation-message",
        defaultMessage:
          "Please note that all activities and comments made by previous Customer will be removed, since new Customer will be assigned to this Task",
      })}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.continue", defaultMessage: "Continue" }),
          onClick: () => {
            onUpdate();
            onRequestClose();
          },
        },
      ]}
    />
  );
};
