import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform";
import React from "react";
import {
  HeaderBreadcrumbs,
  HeaderBreadcrumbsProps,
} from "@jugl-web/ui-components/web";
import { Tab } from "./components/Tab";

export type TabItem = {
  id: string;
  title: string;
  hidden?: boolean;
  href?: string;
  onClick?: () => void;
};

export type TabsLayoutProps = {
  tabs: TabItem[];
  selectedTab: string;
  breadcrumbProps?: HeaderBreadcrumbsProps;
  header?: React.ReactNode;
  rightContent?: React.ReactNode;
  children: React.ReactNode;
};

export const TabsLayout: React.FC<TabsLayoutProps> = ({
  children,
  tabs,
  breadcrumbProps,
  header,
  selectedTab,
  rightContent,
}) => (
  <MultiSectionLayout
    header={
      <>
        {header}
        {breadcrumbProps && (
          <HeaderBreadcrumbs disableShadow {...breadcrumbProps} />
        )}
        <div className="flex h-[72px] items-center justify-between bg-[#EEF2F5] px-8">
          <div className="flex h-full items-end gap-1">
            {tabs.map((option) => (
              <Tab
                key={option.id}
                title={option.title}
                isSelected={option.id === selectedTab}
                onClick={option.onClick}
              />
            ))}
          </div>
          {rightContent && <div>{rightContent}</div>}
        </div>
      </>
    }
  >
    {children}
  </MultiSectionLayout>
);
