import {
  InternalTaskFilterSet,
  InternalTaskFilters,
  TasksSource,
} from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError, assert } from "@jugl-web/utils";
import { isEqual } from "lodash";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useTaskFields } from "../../hooks/useTaskFields";
import {
  INITIAL_FILTERS_STATE,
  UpdateFiltersFn,
  getActiveFiltersCount,
  getStateTransformer,
} from "../../hooks/useTaskFiltersState";
import { useTaskListPreferences } from "../../hooks/useTaskListPreferences";

type TaskFiltering =
  | { type: "standard"; filters: InternalTaskFilters }
  | { type: "filter-set"; filterSetId: string };

interface TaskFilteringContextValue {
  filtering: TaskFiltering;
  filters: InternalTaskFilters;
  hasActiveFilter: boolean;
  activeFilterSet: InternalTaskFilterSet | undefined;
  activeFiltersCount: number;
  searchQuery: string;
  changeFiltering: (filtering: TaskFiltering) => void;
  updateStandardFilters: UpdateFiltersFn;
  resetFiltering: () => void;
  changeSearchQuery: (query: string) => void;
}

const DEFAULT_TASK_FILTERING: TaskFiltering = {
  type: "standard",
  filters: INITIAL_FILTERS_STATE,
};

const TaskFilteringContext = createContext<TaskFilteringContextValue | null>(
  null
);

interface TaskFilteringProviderProps {
  children: ReactNode;
  entityId: string;
  source: TasksSource;
}

export const TaskFilteringProvider: FC<TaskFilteringProviderProps> = ({
  children,
  entityId,
  source,
}) => {
  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({ entityId, source });

  // #region Search query
  const { searchQuery } = taskListPreferences;

  const changeSearchQuery = useCallback(
    (query: string) => updateTaskListPreference("searchQuery", query),
    [updateTaskListPreference]
  );
  // #endregion

  // #region Filtering
  const { filtering } = taskListPreferences;

  const { isLoading, getFilterSetById } = useTaskFields({ entityId });

  const activeFilterSet = useMemo<InternalTaskFilterSet | undefined>(() => {
    if (filtering.type === "filter-set") {
      return getFilterSetById(filtering.filterSetId);
    }

    return undefined;
  }, [filtering, getFilterSetById]);

  const filters = useMemo(() => {
    if (filtering.type === "standard") {
      return filtering.filters;
    }

    return activeFilterSet?.filters || INITIAL_FILTERS_STATE;
  }, [activeFilterSet, filtering]);

  const activeFiltersCount = useMemo(
    () => getActiveFiltersCount(filters),
    [filters]
  );

  const changeFiltering = useCallback(
    (
      updatedFiltering:
        | TaskFiltering
        | ((previousFiltering: TaskFiltering) => TaskFiltering)
    ) => {
      updateTaskListPreference("filtering", (previousFiltering) =>
        typeof updatedFiltering === "function"
          ? updatedFiltering(previousFiltering)
          : updatedFiltering
      );
    },
    [updateTaskListPreference]
  );

  const updateStandardFilters = useCallback<UpdateFiltersFn>(
    (field, value) => {
      changeFiltering((previousFiltering) => {
        assert(
          previousFiltering.type === "standard",
          "updateStandardFilters should be used only with standard filtering"
        );

        const transformState = getStateTransformer(previousFiltering.filters);

        return {
          type: "standard",
          filters: transformState(field, value),
        };
      });
    },
    [changeFiltering]
  );

  const resetFiltering = useCallback(() => {
    changeFiltering(DEFAULT_TASK_FILTERING);
  }, [changeFiltering]);

  const hasActiveFilter = useMemo(
    () =>
      filtering.type === "filter-set" ||
      !isEqual(filters, INITIAL_FILTERS_STATE),
    [filtering, filters]
  );

  // Reset filtering if active filter set is not found (most likely because it was deleted)
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (filtering.type === "filter-set" && !activeFilterSet) {
      resetFiltering();
    }
  }, [isLoading, activeFilterSet, filtering.type, resetFiltering]);
  // #endregion

  const contextValue = useMemo<TaskFilteringContextValue>(
    () => ({
      searchQuery,
      filtering,
      filters,
      hasActiveFilter,
      activeFilterSet,
      activeFiltersCount,
      changeSearchQuery,
      changeFiltering,
      updateStandardFilters,
      resetFiltering,
    }),
    [
      activeFiltersCount,
      activeFilterSet,
      changeFiltering,
      updateStandardFilters,
      changeSearchQuery,
      filtering,
      filters,
      hasActiveFilter,
      resetFiltering,
      searchQuery,
    ]
  );

  return (
    <TaskFilteringContext.Provider value={contextValue}>
      {children}
    </TaskFilteringContext.Provider>
  );
};

export const useTaskFiltering = () => {
  const context = useContext(TaskFilteringContext);

  if (!context) {
    throw new HookOutOfContextError("useTaskFiltering", "TaskFilteringContext");
  }

  return context;
};
