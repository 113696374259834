import { ArchivedTask } from "@jugl-web/rest-api/tasks";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";

interface ConfirmRemoveTaskDialogProps {
  isOpen: boolean;
  task?: ArchivedTask;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmRemoveTaskDialog: FC<ConfirmRemoveTaskDialogProps> = ({
  isOpen,
  task,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslations();

  if (!task) return null;

  return (
    <Alert
      isOpen={isOpen}
      title={t({
        id: "common.confirm-action",
        defaultMessage: "Confirm action",
      })}
      content={t(
        {
          id: "tasks-page.do-you-really-want-to-delete-archived-task",
          defaultMessage:
            "Do you really want to delete task <highlightedText>{taskName}</highlightedText>? You will no longer be able to restore it",
        },
        {
          highlightedText: (text: (string | JSX.Element)[]) => (
            <span className="text-primary-800">{text}</span>
          ),
          taskName: task.name,
        }
      )}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.delete", defaultMessage: "Delete" }),
          color: "tertiary",
          onClick: (_, actions) => {
            onConfirm();
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
