import { FC, useMemo, useState, useEffect } from "react";
import { Text, Button } from "@jugl-web/ui-components/cross-platform";
import { useTranslations, useToast, useAppVariant, cx } from "@jugl-web/utils";
import { useEffectOnce } from "react-use";
import Lottie from "react-lottie";
import { useRestApiProvider } from "@jugl-web/rest-api";
import savingAnimation from "./assets/saving-attachments.json";
import { FilesModule } from "../../../files/types";
import { useTusUpload } from "../../../files/hooks/useTusUpload";
import { ReactComponent as RetryIcon } from "./assets/retry-attachments.svg";

export const TaskAttachmentsUploadScreen: FC<{
  taskId: string;
  entityId: string;
  files: { id: string; file: File }[];
  onClose: () => void;
}> = ({ taskId, entityId, files, onClose }) => {
  const { t } = useTranslations();
  const { tasksApi } = useRestApiProvider();
  const [invalidateTask] = tasksApi.useInvalidateTaskMutation();
  const { variant } = useAppVariant();
  const [shouldRetry, setShouldRetry] = useState(false);
  const [isUploadFinished, setIsUploadFinished] = useState(false);
  const [filesToReupload, setFilesToReupload] = useState<
    { id: string; file: File }[]
  >([]);
  const [progressByAttachmentId, setProgressByAttachmentId] = useState<
    Record<string, number>
  >({});
  const { uploadFile } = useTusUpload({
    module: FilesModule.drive,
    entityId,
  });
  const { toast } = useToast({ variant });

  const progress = useMemo(() => {
    const totalBytes = files.reduce((acc, file) => acc + file.file.size, 0);
    const totalBytesSend = Object.values(progressByAttachmentId || {}).reduce(
      (acc, bytesSend) => acc + bytesSend,
      0
    );
    const result = (totalBytesSend / totalBytes) * 100;
    return Math.floor(result);
  }, [files, progressByAttachmentId]);
  const handleUploadingFiles = async (isRetry?: boolean) => {
    const filesToUpload = isRetry ? filesToReupload : files;
    setShouldRetry(false);
    await Promise.allSettled(
      filesToUpload.map((formAttachment) =>
        uploadFile({
          file: formAttachment.file,
          extraMetaData: {
            type: "file",
            sub_module: "task",
            task_id: taskId,
          },
          onProgress: (bytesSend) => {
            setProgressByAttachmentId((prev) => ({
              ...prev,
              [formAttachment.id]: bytesSend,
            }));
          },
        }).catch(() => {
          setFilesToReupload((prev) => [
            ...(prev.filter((file) => file.id !== formAttachment.id) || []),
            formAttachment,
          ]);
          setShouldRetry(true);
        })
      )
    );
  };

  useEffectOnce(() => {
    handleUploadingFiles();
  });

  useEffect(() => {
    if (progress === 100 && !isUploadFinished) {
      setIsUploadFinished(true);
      // Invalidate the tag after uploading all attachments
      // to ensure the latest data is fetched upon entering the task
      invalidateTask({ entityId, taskId });
      onClose();
      toast(
        t({
          id: "feedback.task-created",
          defaultMessage: "Task has been created",
        })
      );
    }
  }, [
    progress,
    onClose,
    toast,
    isUploadFinished,
    t,
    entityId,
    invalidateTask,
    taskId,
  ]);
  if (shouldRetry) {
    return (
      <div
        className={cx("flex flex-col items-center justify-center", {
          "px-8 pt-8 pb-12": variant === "mobile",
          "max-h-[378px] p-10": variant === "web",
        })}
      >
        <RetryIcon />
        <Text
          variant="h4"
          className={cx("text-dark font-medium", {
            "mt-4 mb-0": variant === "web",
          })}
        >
          {t({
            id: "tasks-page.something-went-wrong",
            defaultMessage: "Something went wrong",
          })}
        </Text>
        <Text
          variant="body2"
          className={cx("text-dark-800 text-center", {
            "mt-4": variant === "web",
          })}
        >
          {t({
            id: "tasks-page.failed-to-save-attachments",
            defaultMessage: "Failed to save Task Attachments, please try again",
          })}
        </Text>
        <div
          className={cx("flex w-full gap-4", {
            "mt-12": variant === "mobile",
            "mt-8": variant === "web",
          })}
        >
          <Button variant="contained" color="grey" fullWidth onClick={onClose}>
            {t({ id: "common.close", defaultMessage: "Close" })}
          </Button>
          <Button
            variant="contained"
            color="tertiary"
            fullWidth
            onClick={() => handleUploadingFiles(true)}
          >
            {t({ id: "common.retry", defaultMessage: "Retry" })}
          </Button>
        </div>
      </div>
    );
  }
  return (
    <div
      className={cx("flex flex-col items-center justify-center", {
        "px-8 pt-8 pb-12": variant === "mobile",
        "p-10": variant === "web",
      })}
    >
      <Lottie
        options={{
          animationData: savingAnimation,
        }}
        width={150}
        height={150}
      />
      <Text variant="h4" className="text-dark font-medium">
        {t({
          id: "tasks-page.saving-attachments",
          defaultMessage: "Saving Attachments...",
        })}
      </Text>
      {progress !== undefined && (
        <div
          className={cx("h-[4px] w-full bg-[#DADADA]", {
            "max-w-[300px]": variant === "web",
          })}
        >
          <div
            className="h-full max-w-[300px] bg-[#4E93CA] transition-all"
            style={{ width: `${progress}%` }}
          />
        </div>
      )}
      <Text variant="body2" className="text-dark-800 mt-6 text-center">
        {t({
          id: "tasks-page.please-wait-while-uploading",
          defaultMessage:
            "Please stay here for a while, we are saving Attachments to Your Task",
        })}
      </Text>
    </div>
  );
};
