import { SubscriptionInfoModel } from "@jugl-web/rest-api";
import { Button } from "@jugl-web/ui-components";
import { apiUTCToLocalDateTime, useTranslations } from "@jugl-web/utils";
import format from "date-fns/format";
import { FC } from "react";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";

export const SubscriptionIncompleteItem: FC<{
  subscriptionInfo: SubscriptionInfoModel;
}> = ({ subscriptionInfo }) => {
  const { t } = useTranslations();
  const humanizedValidFrom = format(
    apiUTCToLocalDateTime(subscriptionInfo.validFrom),
    "dd MMMM, yyyy"
  );
  const humanizedValidTillTime = format(
    apiUTCToLocalDateTime(subscriptionInfo.planTill),
    "dd MMMM, yyyy"
  );
  const { isRetryInvoiceLoading, retryLastInvoicePayment } =
    useEntitySubscriptionProvider();

  const { navigateToPage } = useNavigation();

  return (
    <div className="flex w-full min-w-[600px] max-w-[800px] items-center justify-between gap-4 rounded-lg bg-[#FAECEE] p-6">
      <div className="text-sm text-[#9F2E29]">
        {t(
          {
            id: "subscription-incomplete-item.payment-failed-message",
            defaultMessage:
              "Payment from {validFrom} was failed, please try again. On {validTill}, you will lose access to the Workspace",
          },
          {
            validFrom: humanizedValidFrom,
            validTill: humanizedValidTillTime,
          }
        )}
      </div>
      <div className="flex gap-4">
        <Button
          color="white"
          className="whitespace-nowrap"
          onClick={() => navigateToPage("workspaceSelectSubscription")}
        >
          {t({
            id: "common.manage-subscription-plan",
            defaultMessage: "Manage plan",
          })}
        </Button>
        <Button
          color="tertiary"
          // TODO: better error handling
          onClick={() => retryLastInvoicePayment().catch(() => {})}
          isDisabled={isRetryInvoiceLoading}
        >
          {t({
            id: "common.retry",
            defaultMessage: "Retry",
          })}
        </Button>
      </div>
    </div>
  );
};
