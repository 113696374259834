import React, { ReactNode, useMemo, useState } from "react";
import useActiveChat from "@web-src/features/chats/hooks/useActiveChat";
import { cx, useTranslations } from "@jugl-web/utils";
import {
  ChatMessagePayloadAttachmentType,
  ChatType,
} from "@web-src/features/chats/types";
import {
  InteractiveContainer,
  MultiSectionLayout,
} from "@jugl-web/ui-components/cross-platform";
import { DrawerHeader } from "@jugl-web/ui-components/web/DrawerHeader";
import { HorizontalScrollableContainer } from "@jugl-web/ui-components/web";
import { ReactComponent as GroupIcon } from "./assets/group.svg";
import { ReactComponent as ProfileIcon } from "./assets/profile.svg";
import { ChatInfoMembers } from "./components/ChatInfoMembers";
import { AttachmentsSection } from "./components/AttachmentsSection";
import { InfoTabId } from "./types";

type InfoTab = {
  id: InfoTabId;
  label: string;
  component: ReactNode;
};

export const ChatInfoSideBar: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { chat } = useActiveChat();
  if (!chat) {
    throw new Error("Active chat is required");
  }

  // TODO: remove once chat will have required id
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const chatId = chat.id!;

  const isGroupChat = chat?.type === ChatType.muc;
  const { t } = useTranslations();

  const [selectedTabId, setSelectedTabId] = useState<InfoTabId>(
    isGroupChat ? InfoTabId.workspaceMembers : InfoTabId.photoAttachments
  );

  const visibleTabs: InfoTab[] = useMemo(
    () => [
      ...(isGroupChat
        ? [
            {
              id: InfoTabId.workspaceMembers,
              label: t(
                {
                  id: "chats-page.members-brackets-count",
                  defaultMessage: "Members ({membersCount})",
                },
                {
                  membersCount: chat.userCount || 0,
                }
              ),
              component: <ChatInfoMembers chatId={chatId} isActive />,
            } as InfoTab,
          ]
        : []),
      {
        id: InfoTabId.photoAttachments,
        label: t({
          id: "chats-page.photo",
          defaultMessage: "Photo",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.image}
            tabId={InfoTabId.photoAttachments}
          />
        ),
      },
      {
        id: InfoTabId.videoAttachments,
        label: t({
          id: "chats-page.video",
          defaultMessage: "Video",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.video}
            tabId={InfoTabId.videoAttachments}
          />
        ),
      },
      {
        id: InfoTabId.docAttachments,
        label: t({
          id: "chats-page.documents",
          defaultMessage: "Documents",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.doc}
            tabId={InfoTabId.docAttachments}
          />
        ),
      },
      {
        id: InfoTabId.filesAttachments,
        label: t({
          id: "chats-page.files",
          defaultMessage: "Files",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.otherFile}
            tabId={InfoTabId.filesAttachments}
          />
        ),
      },
      {
        id: InfoTabId.audioAttachments,
        label: t({
          id: "chats-page.audio",
          defaultMessage: "Audio",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.audio}
            tabId={InfoTabId.audioAttachments}
          />
        ),
      },
      {
        id: InfoTabId.voiceAttachments,
        label: t({
          id: "chats-page.voice",
          defaultMessage: "Voice",
        }),
        component: (
          <AttachmentsSection
            chatId={chatId}
            attachmentType={ChatMessagePayloadAttachmentType.voice}
            tabId={InfoTabId.voiceAttachments}
          />
        ),
      },
    ],
    [isGroupChat, t, chat.userCount, chatId]
  );

  const selectedTab = useMemo(
    () =>
      visibleTabs.find(({ id }) => id === selectedTabId) || visibleTabs?.[0],
    [visibleTabs, selectedTabId]
  );

  return (
    <MultiSectionLayout
      className="max-w-[400px]"
      header={
        <DrawerHeader
          type="title"
          onClose={onClose}
          title={
            isGroupChat
              ? t({
                  id: "chats-page.group-info",
                  defaultMessage: "Group info",
                })
              : t({
                  id: "chats-page.chat-info",
                  defaultMessage: "Chat info",
                })
          }
        />
      }
    >
      <MultiSectionLayout
        header={
          <>
            <div className="flex flex-col items-center justify-center gap-5 bg-gradient-to-b from-[#CECED3] to-[#ECEFF0] py-11">
              {chat?.img ? (
                <img
                  src={chat.img}
                  className="h-[150px] w-[150px] rounded-full border border-solid border-white object-cover"
                  alt=""
                />
              ) : (
                <div className="bg-grey-100 flex h-[150px] w-[150px] items-center justify-center rounded-full">
                  {isGroupChat ? <GroupIcon /> : <ProfileIcon />}
                </div>
              )}
              <span className="w-full px-10 text-center text-xl font-medium text-[#333333]">
                {chat.title}
              </span>
            </div>
            <HorizontalScrollableContainer className="flex">
              {visibleTabs.map((sectionTab) => (
                <InteractiveContainer
                  key={sectionTab.id}
                  className={cx(
                    "flex-grow basis-0 whitespace-nowrap border-0 border-b-4 border-solid border-white px-6 py-3 pt-4 text-center text-[#69757C]",
                    selectedTab.id === sectionTab.id &&
                      "border-primary text-primary"
                  )}
                  onClick={() => setSelectedTabId(sectionTab.id)}
                >
                  {sectionTab.label}
                </InteractiveContainer>
              ))}
            </HorizontalScrollableContainer>
          </>
        }
      >
        {selectedTab?.component}
      </MultiSectionLayout>
    </MultiSectionLayout>
  );
};
