import { cx } from "@jugl-web/utils";
import { FC, InputHTMLAttributes } from "react";

export const RecurrenceNumberInput: FC<
  Omit<InputHTMLAttributes<HTMLInputElement>, "type">
> = ({ className, ...props }) => (
  <input
    type="number"
    className={cx(
      "text-dark bg-grey-100 h-10 w-[92px] shrink-0 rounded-lg border-none p-4 text-center text-sm outline-none transition-colors [appearance:textfield] focus:bg-[#F5F6F8] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
      className
    )}
    {...props}
  />
);
