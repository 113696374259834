import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { Avatar, PlainButton } from "@jugl-web/ui-components/cross-platform";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { CallsContext } from "@web-src/modules/conference/pages/ConferencePage/providers";
import useSendMessage from "@web-src/features/chats/hooks/useSendMessage";
import {
  ChatCallType,
  ChatMessagePayloadCallAction,
  ChatMessagePayloadPushType,
  ChatMessagePayloadType,
  ChatMessageType,
  ScenarioCallType,
  PheonixPushAction,
  ChatMessage,
} from "@web-src/features/chats/types";
import { useTranslations, cx, usePrevious } from "@jugl-web/utils";
import { getEntityUserDisplayName } from "@web-src/features/entities/utils";
import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  PhoenixSocketContext,
  PhxResponse,
} from "@web-src/features/chats/providers/PheonixSocket";
import { useLocation } from "react-router-dom";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { getUserProfileDisplayName } from "@web-src/features/users/utils";
import MessageButton from "./MessageButton";
import { ReactComponent as CallCutIcon } from "./icons/call-cut.svg";
import { ReactComponent as PhoneIcon } from "./icons/phone.svg";
import { MessageSidebar } from "./MessageSidebar";

const Component: React.FC = () => {
  const me = useMe();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sendMessage = useSendMessage();
  const { t } = useTranslations();
  const { incomingMessages$, channel } = useContext(PhoenixSocketContext);
  const location = useLocation();
  const { navigateToPage } = useNavigation();
  const {
    joinCall,
    incomingCall,
    activeCall,
    leaveCall,
    setIncomingCall,
    callStage,
    setCallStage,
    activeCallProps,
  } = useContext(CallsContext);
  const { usersApi } = useRestApiProvider();
  const { data: callingUser } = usersApi.useGetUserProfileQuery(
    incomingCall?.from
      ? {
          params: {
            user_id: incomingCall.from,
          },
        }
      : skipToken
  );
  const previousIncomingCall = usePrevious(incomingCall);
  const callStageRef = useRef(callStage);
  const incomingCallRef = useRef(incomingCall);
  useEffect(() => {
    incomingCallRef.current = incomingCall;
  }, [incomingCall]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;
    const audio = new Audio("/call.mp3");
    if (incomingCall) {
      intervalId = setInterval(() => {
        audio.play();
      }, 2500);
    }
    if (callStage !== "ringing" && intervalId) {
      clearInterval(intervalId);
      audio.pause();
      audio.currentTime = 0;
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [incomingCall, callStage]);
  const isVideoCall = useMemo(
    () => incomingCall?.payload?.call_type?.toLowerCase().includes("video"),
    [incomingCall]
  );
  const callTypeString = useMemo(
    () => `Incoming ${isVideoCall ? "Video" : "Voice"} Call..`,
    [isVideoCall]
  );
  const joinedCallsFromThisBanner = useRef<{ [key: string]: boolean }>({});
  const handleJoinCall = async () => {
    if (joinCall && incomingCall?.payload.call_channel) {
      joinedCallsFromThisBanner.current[incomingCall?.payload.call_channel] =
        true;
      if (activeCall && leaveCall) {
        if (location.pathname.includes("conference")) {
          navigateToPage("chatsChats");
        }
        await leaveCall();
      }
      setCallStage?.("call");
      callStageRef.current = "call";
      joinCall?.({
        channel: incomingCall?.payload.call_channel,
        callType: isVideoCall ? ChatCallType.video : ChatCallType.audio,
        type: ScenarioCallType.call,
        to: incomingCall.from,
        isIncomingCall: true,
      });
    }
  };
  const sendCallDeclinedMessage = useCallback(async () => {
    if (!incomingCall) return;
    await channel?.push(PheonixPushAction.update_call, {
      to: incomingCall.from,
      msg_id: incomingCall.msg_id,
      payload: {
        body: "declined",
        call_type: callTypeString,
        call_action: ChatMessagePayloadCallAction.call_declined,
        call_channel: incomingCall.payload.call_channel,
        push_type: ChatMessagePayloadPushType.silent,
        sender_name: (me.me && getEntityUserDisplayName(me.me)) || undefined,
        title: t({
          id: "common.new-message",
          defaultMessage: "New Message",
        }),
        type: ChatMessagePayloadType.call,
      },
      type: ChatMessageType.call,
      entity_id: incomingCall.entity_id,
    });
  }, [callTypeString, channel, incomingCall, me.me, t]);

  const handleDeclineCall = async () => {
    if (incomingCall) {
      await sendCallDeclinedMessage();
      setIncomingCall?.(undefined);
      incomingCallRef.current = undefined;
      callStageRef.current = "off";
      setCallStage?.("off");
    }
  };
  const handleMessageToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const callerUsername = useMemo(() => {
    if (!callingUser)
      return t({
        id: "common.unknown",
        defaultMessage: "Unknown",
      });
    const username = `${callingUser?.general?.first_name} ${callingUser?.general?.last_name}`;
    return username;
  }, [callingUser, t]);
  useEffect(() => {
    const handleEvents = (message: ChatMessage) => {
      const isMessageFromMe = message.from === me.me?.id;

      const isIncomingCallAnsweredByMeInDifferentApp =
        message?.payload.call_action ===
          ChatMessagePayloadCallAction.call_answered &&
        message.payload?.call_channel &&
        activeCallProps?.channel !== message.payload?.call_channel &&
        !joinedCallsFromThisBanner.current[message.payload.call_channel] &&
        isMessageFromMe;

      const isIncomingCallDeclined =
        message?.payload.call_action ===
          ChatMessagePayloadCallAction.call_declined &&
        (message.from === incomingCall?.from || isMessageFromMe);

      if (isIncomingCallDeclined || isIncomingCallAnsweredByMeInDifferentApp) {
        setIncomingCall?.(undefined);
        setCallStage?.("off");
      }
    };
    const phxCallResponse = channel?.on(
      "phx_call",
      (message: PhxResponse<ChatMessage>) => handleEvents(message.response)
    );
    const phxCallSentResponse = channel?.on(
      "phx_call_sent",
      (message: PhxResponse<ChatMessage>) => handleEvents(message.response)
    );
    const incomingMessagesSubscription = incomingMessages$?.subscribe(
      (message) => handleEvents(message)
    );
    return () => {
      incomingMessagesSubscription?.unsubscribe();
      channel?.off("phx_call", phxCallResponse);
      channel?.off("phx_call_sent", phxCallSentResponse);
    };
  });
  const sendCallDeliveredMessage = useCallback(async () => {
    if (!incomingCall) return;
    channel?.push(PheonixPushAction.update_call, {
      to: incomingCall.from,
      msg_id: incomingCall.msg_id,
      payload: {
        body: "delivered",
        call_type: callTypeString,
        call_action: ChatMessagePayloadCallAction.call_delivered,
        call_channel: incomingCall.payload.call_channel,
        push_type: ChatMessagePayloadPushType.silent,
        sender_name: (me.me && getEntityUserDisplayName(me.me)) || undefined,
        title: t({
          id: "common.new-message",
          defaultMessage: "New Message",
        }),
        type: ChatMessagePayloadType.call,
      },
      type: ChatMessageType.call,
      entity_id: incomingCall.entity_id,
    });
  }, [callTypeString, channel, incomingCall, me.me, t]);
  const handleTimeoutCall = useCallback(async () => {
    const delay = (ms: number) =>
      new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    await delay(40000);

    if (incomingCallRef.current && callStageRef.current === "off") {
      sendCallDeclinedMessage();
      setIncomingCall?.(undefined);
    }
  }, [sendCallDeclinedMessage, setIncomingCall]);
  useEffect(() => {
    if (
      incomingCall &&
      previousIncomingCall?.payload.call_channel !==
        incomingCall?.payload.call_channel
    ) {
      sendCallDeliveredMessage();
      handleTimeoutCall();
    }
  }, [
    incomingCall,
    callStage,
    incomingCall?.payload.call_channel,
    previousIncomingCall?.payload.call_channel,
    sendCallDeclinedMessage,
    sendCallDeliveredMessage,
    setIncomingCall,
    handleTimeoutCall,
  ]);
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (incomingCall) {
      intervalId = setInterval(() => {
        new Audio("/call.mp3").play();
      }, 2500);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [incomingCall, setIncomingCall, callStage, sendCallDeclinedMessage]);

  if (!incomingCall) return null;
  const handleMessageSidebarClose = () => {
    setIsSidebarOpen(false);
  };
  const handleSendImBusyMessage = async (message: string) => {
    if (!incomingCall) return;
    await sendMessage({
      to: incomingCall.from || "",
      body: message,
      type: ChatMessageType.chat,
    });
    setIsSidebarOpen(false);
    handleDeclineCall();
  };
  return (
    <>
      <div
        className={cx(
          "fixed top-[40px] right-[40px] z-50 h-[260px] w-[467px] rounded-2xl bg-[#000000]/90"
        )}
      >
        <div className="flex items-center gap-3 pt-10 pl-10">
          <Avatar
            imageUrl={callingUser?.general?.img}
            username={callingUser ? getUserProfileDisplayName(callingUser) : ""}
            size="4xl"
            className="border-2 border-solid border-white"
          />
          <div className="flex flex-col">
            <Text
              variant="h4"
              className="m-0 mb-1 max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-[700] text-white"
              title={callerUsername}
            >
              {callerUsername}
            </Text>
            <Text
              variant="timestamp"
              className="text-primary-200 leading-0 whitespace-nowrap"
            >
              {isVideoCall
                ? t({
                    id: "incoming-call-banner-component.incoming-video-call",
                    defaultMessage: "Incoming Video Call...",
                  })
                : t({
                    id: "incoming-call-banner-component.incoming-audio-call",
                    defaultMessage: "Incoming Audio Call...",
                  })}
            </Text>
          </div>
        </div>
        <div className="align-center mt-8 flex items-center justify-center gap-8">
          <PlainButton
            className="bg-gradients-danger h-[70px] w-[70px] rounded-full"
            onClick={handleDeclineCall}
          >
            <CallCutIcon />
          </PlainButton>
          <MessageButton onClick={handleMessageToggle} />
          <PlainButton
            onClick={() => handleJoinCall()}
            className="bg-secondary relative h-[70px] w-[70px] rounded-full"
          >
            <div className="border-secondary/60 bg-secondary-600/40 absolute top-0 h-[70px] w-[70px] animate-ping rounded-full border-2 border-solid" />
            <PhoneIcon />
          </PlainButton>
        </div>
      </div>
      <MessageSidebar
        isOpen={isSidebarOpen}
        onClose={handleMessageSidebarClose}
        onSubmit={handleSendImBusyMessage}
      />
    </>
  );
};

const IncomingCallBanner = React.memo(Component);
export default IncomingCallBanner;
