import { NoParameters } from "@jugl-web/utils/utils/ScreenTransitionManager";

export type TasksImportScreenToParametersMap = {
  fileUpload: NoParameters;
  tasksName: NoParameters;
  alignColumn: NoParameters;
  importSettings: NoParameters;
  importLoading: NoParameters;
  importError: NoParameters;
  importSuccess: {
    duplicates: number;
    tasksCreated: number;
  };
};

export enum DuplicateTaskAction {
  newTask = "newTask",
  skip = "skip",
}
