export const sortCollectionAlphabetically = <TItem>(
  collection: TItem[],
  keyGetter?: (item: TItem) => string
) =>
  [...collection].sort((itemA, itemB) => {
    if (!keyGetter && typeof itemA !== "string") {
      throw new Error(
        "keyGetter is required when collection items are not strings"
      );
    }

    const keyA = (keyGetter?.(itemA) || (itemA as string)).toUpperCase();
    const keyB = (keyGetter?.(itemB) || (itemB as string)).toUpperCase();

    return keyA.localeCompare(keyB);
  });
