import { useEntityProvider } from "@web-src/modules/entities/providers/EntityProvider";

const useEntity = () => {
  const {
    entity: selectedEntity,
    isInitialized,
    entitiesList,
    isEntitiesFetching,
  } = useEntityProvider();
  return {
    initialized: isInitialized,
    list: entitiesList,
    entity: selectedEntity,
    isEntitiesFetching,
  };
};

export default useEntity;
