import React, { useState } from "react";
import { useTranslations } from "@jugl-web/utils";
import { Menu, Pill } from "@jugl-web/ui-components";
import { Control, Controller } from "react-hook-form";
import { Section } from "../Section/Section";
import { ReactComponent as BlueCheckIcon } from "./assets/blue-check.svg";
import { InternalExportFormParams } from "../../types";

export const FilterByField: React.FC<{
  control: Control<InternalExportFormParams>;
  formParams: InternalExportFormParams;
}> = ({ control, formParams }) => {
  const { t } = useTranslations();
  const [isHovering, setIsHovering] = useState(false);

  const labels = {
    completedOn: t({
      id: "tasks-export-dialog.dateOfCompletion",
      defaultMessage: "Date of Completion",
    }),
    dueAt: t({
      id: "tasks-export-dialog.due-date",
      defaultMessage: "Due Date",
    }),
  };

  return (
    <Section
      isActive={isHovering}
      label={t({
        id: "tasks-export-dialog.filter-tasks-by",
        defaultMessage: "Filter Tasks by",
      })}
      button={
        <Controller
          name="date_type"
          control={control}
          render={({ field }) => (
            <Menu
              placement="bottom-end"
              className="z-[100]"
              renderTrigger={({ Trigger, triggerRef, isOpen }) => {
                if (isOpen) {
                  setIsHovering(true);
                } else {
                  setIsHovering(false);
                }
                return (
                  <Trigger
                    as={Pill}
                    ref={triggerRef}
                    label={
                      formParams.date_type === "completed_on"
                        ? labels.completedOn
                        : labels.dueAt
                    }
                    size="md"
                    className="cursor-pointer"
                  />
                );
              }}
              onSelect={(_item, _event, close) => {
                setIsHovering(false);
                close();
              }}
              sections={[
                [
                  {
                    id: "completedOn",
                    label: labels.completedOn,
                    onSelect: () => field.onChange("completed_on"),
                    isSelected: field.value === "completed_on",
                    endSlot: field.value === "completed_on" && (
                      <BlueCheckIcon />
                    ),
                  },
                  {
                    id: "dueDate",
                    label: labels.dueAt,
                    onSelect: () => field.onChange("due_at"),
                    isSelected: field.value === "due_at",
                    endSlot: field.value === "due_at" && <BlueCheckIcon />,
                  },
                ],
              ]}
            />
          )}
        />
      }
    />
  );
};
