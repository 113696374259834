import { cx } from "@jugl-web/utils";

interface UnreadCountBoxProps {
  count: number;
  isItemActive: boolean;
  className?: string;
}

const MAX_COUNT = 99;

export const UnreadCountBox = ({
  count,
  isItemActive,
  className,
}: UnreadCountBoxProps) => (
  <div
    className={cx(
      "flex h-[24px] w-[26px] shrink-0 items-center justify-center rounded-lg border-[2px] border-solid bg-white px-1 transition-colors duration-300",
      isItemActive
        ? "border-[#1A75D2]"
        : "border-primary-600 group-hover:border-[#107CDE]",
      className
    )}
  >
    <span className="text-primary text-xs font-black">
      {count > MAX_COUNT ? `${MAX_COUNT}+` : count}
    </span>
  </div>
);
