import { useDndContext } from "@dnd-kit/core";
import { PopoverPanel } from "@headlessui/react";
import { TASK_COLUMNS_IDS } from "@jugl-web/domain-resources/tasks";
import {
  TaskCalendarPill,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { PreviewTask } from "@jugl-web/rest-api/tasks";
import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { ComponentLifecycleListener } from "@jugl-web/utils/utils/ComponentLifecycleListener";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { format } from "date-fns";
import { FC } from "react";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { MONTH_DAY_CELL_PILLS_GAP } from "../../consts";

interface CalendarMonthDayExpandableCellPanelProps {
  cellDate: Date;
  cellIndex: number;
  tasks: PreviewTask[];
  onInternalOpenStateChange: (isOpen: boolean) => void;
  onTaskClick: (task: PreviewTask) => void;
}

export const CalendarMonthDayExpandableCellPanel: FC<
  CalendarMonthDayExpandableCellPanelProps
> = ({
  cellDate,
  cellIndex,
  tasks,
  onInternalOpenStateChange,
  onTaskClick,
}) => {
  const { entityId } = useEntitySelectedProvider();
  const { searchQuery } = useTaskFiltering();
  const { tasksSource } = useTasksPageContext();

  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: tasksSource,
  });

  const { active } = useDndContext();
  const isDragging = !!active;

  const { dateLocale } = useLanguage();

  const columnId = TASK_COLUMNS_IDS.DATE_VIEW(cellDate);
  const isCellInFirstRow = cellIndex < 7;

  return (
    <PopoverPanel
      unmount
      transition
      className={cx(
        "absolute inset-x-0 z-10 flex h-[272px] min-h-[140%] flex-col gap-3 overflow-hidden bg-white px-3 pt-3 pb-8 transition duration-200",
        "data-[closed]:scale-y-0 data-[closed]:opacity-0",
        isDragging ? "scale-y-0 opacity-0" : "scale-y-100 opacity-100",
        isCellInFirstRow
          ? "top-0 origin-top rounded-b-xl shadow-[0px_6px_15px_1px_rgba(0,0,0,0.12)]"
          : "bottom-0 origin-bottom rounded-t-xl shadow-[0px_-6px_15px_1px_rgba(0,0,0,0.12)]"
      )}
    >
      {({ close: closePopover }) => (
        <>
          <div className="flex items-center justify-between">
            <span className="text-dark-800 text-xs leading-4">
              {format(cellDate, "d MMM", { locale: dateLocale })}
            </span>
            <PlainButton
              className="hover:bg-dark-100 rounded-lg transition-colors"
              onClick={() => closePopover()}
            >
              <CloseIcon />
            </PlainButton>
          </div>
          <div
            className="jugl__custom-scrollbar -mr-2 flex grow flex-col overflow-y-auto pr-2"
            style={{ gap: MONTH_DAY_CELL_PILLS_GAP }}
          >
            {tasks.map((task) => (
              <TaskCalendarPill
                key={task.id}
                highlightedText={searchQuery}
                onClick={(event) => {
                  event.stopPropagation();
                  onTaskClick(task);
                }}
                columnId={columnId}
                draggable
                {...getTaskCardProps(task)}
              >
                {task.name}
              </TaskCalendarPill>
            ))}
          </div>
          <ComponentLifecycleListener
            onMount={() => onInternalOpenStateChange(true)}
            onUnmount={() => onInternalOpenStateChange(false)}
          />
        </>
      )}
    </PopoverPanel>
  );
};
