import {
  InteractiveContainer,
  InteractiveContainerProps,
} from "@jugl-web/ui-components/cross-platform";
import { cx } from "@jugl-web/utils";
import { FC, forwardRef, ReactNode, SVGProps } from "react";
import { ReactComponent as ChevronRightIcon } from "../../assets/chevron-right.svg";

interface TriggerButtonBaseProps
  extends Omit<InteractiveContainerProps, "children"> {
  label: string;
  value: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  isFocused?: boolean;
  customEndSlot?: ReactNode;
}
export const TriggerButtonBase = forwardRef<
  HTMLDivElement,
  TriggerButtonBaseProps
>(
  (
    {
      label,
      value,
      Icon,
      isFocused,
      isDisabled,
      customEndSlot,
      className,
      ...props
    },
    ref
  ) => (
    <InteractiveContainer
      ref={ref}
      className={cx(
        "bg-grey-100 focus-visible:border-primary flex h-10 items-center justify-between rounded-lg border border-solid border-transparent px-2 outline-none transition-colors",
        isFocused && "border-primary",
        !isDisabled && "hover:bg-grey-200",
        className
      )}
      isDisabled={isDisabled}
      {...props}
    >
      <div
        className={cx(
          "flex items-center overflow-hidden",
          isDisabled ? "text-dark-300" : "text-dark-800"
        )}
      >
        <Icon
          className={cx(
            "shrink-0",
            isDisabled
              ? "text-grey-background"
              : "text-messages-chatBubbleSender"
          )}
        />
        <span className="ml-2.5 mr-2 text-sm font-medium">{label}:</span>
        <span className="truncate text-base font-normal">{value}</span>
      </div>
      {customEndSlot || (
        <ChevronRightIcon
          className={cx(
            "shrink-0",
            isDisabled ? "text-[#E0E0E0]" : "text-grey-700"
          )}
        />
      )}
    </InteractiveContainer>
  )
);
