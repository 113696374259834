import cx from "classnames";
import { ReactNode, useMemo } from "react";

export interface AvatarMember {
  firstName: string;
  lastName: string;
  imageUrl: string | null;
  isFormerMember?: boolean;
}

export interface CollectiveAvatarProps {
  members: AvatarMember[];
}

const AvatarContainer = ({ children }: { children: ReactNode }) => (
  <div className="relative inline-flex h-[62px] w-[62px] overflow-hidden rounded-full">
    {children}
  </div>
);

const AvatarImage = ({
  member,
  className,
}: {
  member: AvatarMember;
  className: string;
}) => {
  const hasImage = !!member.imageUrl;
  const initials = member.firstName.charAt(0).concat(member.lastName.charAt(0));

  if (!hasImage) {
    return (
      <span
        className={cx(
          className,
          "flex items-center justify-center text-xs font-semibold uppercase leading-[14px] text-[#333333]"
        )}
        style={{
          background: "linear-gradient(180deg, #CECED3 0%, #ECEFF0 69.27%)",
        }}
      >
        {initials}
      </span>
    );
  }

  return (
    <img
      src={member.imageUrl as string}
      alt={`${member.firstName} ${member.lastName}`}
      className={cx(className, "object-cover")}
    />
  );
};

export const CollectiveAvatar = ({ members }: CollectiveAvatarProps) => {
  const imagesJSX = useMemo(() => {
    switch (members.length) {
      case 0:
        return null;
      case 1:
        return <AvatarImage member={members[0]} className="h-full w-full" />;
      case 2:
        return (
          <>
            <AvatarImage member={members[0]} className="h-full w-1/2" />
            <AvatarImage member={members[1]} className="h-full w-1/2" />
          </>
        );
      case 3:
        // TODO: Implement proper design
        return (
          <>
            <AvatarImage
              member={members[0]}
              className="absolute h-full w-full"
            />
            <AvatarImage
              member={members[1]}
              className="absolute top-0 right-0 h-full w-1/2"
            />
            <AvatarImage
              member={members[2]}
              className="absolute bottom-0 left-0 h-1/2 w-1/2"
            />
          </>
        );
      default: {
        const remainingMembers = members.length - 4;
        const shouldApplyOverlay = remainingMembers > 0;

        return (
          <>
            {shouldApplyOverlay && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black/50">
                <span className="text-base font-medium leading-[23px] -tracking-[0.01em] text-white">
                  +{remainingMembers}
                </span>
              </div>
            )}
            <AvatarImage
              member={members[members.length - 4]}
              className="absolute top-0 left-0 h-1/2 w-1/2"
            />
            <AvatarImage
              member={members[members.length - 3]}
              className="absolute top-0 right-0 h-1/2 w-1/2"
            />
            <AvatarImage
              member={members[members.length - 2]}
              className="absolute bottom-0 left-0 h-1/2 w-1/2"
            />
            <AvatarImage
              member={members[members.length - 1]}
              className="absolute bottom-0 right-0 h-1/2 w-1/2"
            />
          </>
        );
      }
    }
  }, [members]);

  if (members.length === 0) {
    return null;
  }

  return <AvatarContainer>{imagesJSX}</AvatarContainer>;
};
