import { FeedbackBox } from "@jugl-web/ui-components/cross-platform";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { SidebarDrawer } from "@jugl-web/ui-components/web/SidebarDrawer";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { ErrorBoundary } from "@jugl-web/utils/utils/ErrorBoundary";
import {
  ModuleNotificationsList,
  ModuleNotificationsListProps,
} from "./components/ModuleNotificationsList";
import { ModuleToLogMap, OnNotificationClickFn } from "./types";

interface ModuleNotificationsDialogProps<
  TModule extends keyof ModuleToLogMap,
  TLog extends ModuleToLogMap[TModule]
> {
  isOpen: boolean;
  entityId: string;
  module: TModule;
  boardId?: string;
  onNotificationClick: OnNotificationClickFn<TLog>;
  onClose: () => void;
}

const ErrorBoundaryFallback = () => (
  <FeedbackBox
    className="mt-[200px]"
    iconType="unknown-error"
    title="Something went wrong"
    subtitle={
      <>
        We&apos;re sorry for the inconvenience.
        <br />
        Please try again later
      </>
    }
  />
);

export const ModuleNotificationsDialog = <
  TModule extends keyof ModuleToLogMap,
  TLog extends ModuleToLogMap[TModule]
>({
  isOpen,
  entityId,
  module,
  boardId,
  onNotificationClick,
  onClose,
}: ModuleNotificationsDialogProps<TModule, TLog>) => {
  const { isMobile } = useAppVariant();
  const { t } = useTranslations();

  const listProps: ModuleNotificationsListProps<TModule, TLog> = {
    entityId,
    module,
    boardId,
    onNotificationClick,
  };

  const title = boardId
    ? t({
        id: "tasks-page.board-notifications",
        defaultMessage: "Board Notifications",
      })
    : t({
        id: "tasks-page.notifications",
        defaultMessage: "Notifications",
      });

  if (isMobile) {
    return (
      <MobileDrawer
        isOpen={isOpen}
        header={{ title }}
        placement="right"
        size="full-screen"
        onClose={onClose}
      >
        <MobileDrawer.Content className="bg-dark-100 pt-6">
          <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
            <ModuleNotificationsList {...listProps} />
          </ErrorBoundary>
        </MobileDrawer.Content>
      </MobileDrawer>
    );
  }

  return (
    <SidebarDrawer isOpen={isOpen} title={title} onClose={onClose}>
      <SidebarDrawer.Content className="bg-dark-100 p-8">
        <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
          <ModuleNotificationsList {...listProps} />
        </ErrorBoundary>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};
