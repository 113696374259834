import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import {
  WITHOUT_USER_ITEM_ID,
  useUserListBox,
} from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FilterSectionProps } from "../../types";
import { AssigneeFilterPill } from "../AssigneeFilterPill";
import { FilterSectionLayout } from "../FilterSectionLayout";

export const AssigneeFilterSection: FC<FilterSectionProps> = ({
  entityId,
  source,
}) => {
  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const { getListBoxProps, getTitleProps, clearSearchQuery } = useUserListBox({
    entityId,
    withoutUserItem: {
      label: t({
        id: "tasks-page.without-assignee",
        defaultMessage: "Without assignee",
      }),
    },
    onlyReportees: source.type === "boardTasks" && source.boardId === "team",
  });

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-assignee-filter",
          defaultMessage: "By Assignee",
        })}
        endSlot={
          <ResourcePickerPopover
            placement="bottom-end"
            className="w-[315px]"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
            )}
            onSubmit={(ids) =>
              updateFiltersState(
                "assignees",
                ids.map((id) => (id === WITHOUT_USER_ITEM_ID ? null : id))
              )
            }
            onUnmount={clearSearchQuery}
            {...getListBoxProps({
              selectionBehavior: { mode: "multiple" },
              defaultSelectedIds: filtersState.assignees.map(
                (assigneeId) => assigneeId || WITHOUT_USER_ITEM_ID
              ),
              maxVisibleItems: 5,
            })}
            {...getTitleProps()}
          />
        }
      />
      {filtersState.assignees.length > 0 && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.assignees.map((assigneeId) => (
            <AssigneeFilterPill
              key={assigneeId}
              entityId={entityId}
              assigneeId={assigneeId}
              onRemove={() =>
                updateFiltersState("assignees", (previousAssignees) =>
                  previousAssignees.filter((a) => a !== assigneeId)
                )
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
