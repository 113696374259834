import React, { useMemo, memo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ChatMessage } from "@web-src/features/chats/types";
import UserProfileName from "@web-src/features/users/components/UserProfileName";
import { useTranslations } from "@jugl-web/utils";

interface Info {
  color: string;
  tokens: { type: string; value?: string; suffix?: string }[];
}

const GroupInfoBubble: React.FC<{
  message: ChatMessage;
}> = ({ message }) => {
  const { t } = useTranslations();
  const info: Info | null = useMemo(() => {
    if (!message) {
      return null;
    }
    const forUsername = message.payload?.action_for;
    const fromUsername = message.payload?.action_from;
    if (message.payload?.action === "removed") {
      return {
        tokens: [
          { type: "user", value: forUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.removed-from-group",
              defaultMessage: "has been removed from the group",
            }),
          },
        ],
        color: "tertiary.main",
      };
    }
    // TODO: re-check with backend/design
    if (message.payload?.action === "deleted") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.deleted-group",
              defaultMessage: "deleted the group",
            }),
          },
        ],
        color: "tertiary.main",
      };
    }
    if (message.payload?.action === "added") {
      if (message.payload?.action_for === message.payload?.action_from) {
        return {
          tokens: [
            { type: "user", value: forUsername },
            {
              type: "string",
              value: t({
                id: "chats-page.created-group",
                defaultMessage: "created the group",
              }),
            },
          ],
          color: "primary.main",
        };
      }
      let participantsToDisplay = message.payload.participants || [];
      let participantsMore = 0;
      if (participantsToDisplay.length > 4) {
        participantsMore = participantsToDisplay.length - 4;
        participantsToDisplay = participantsToDisplay.slice(0, 4);
      }
      return {
        tokens: [
          { type: "user", value: fromUsername },
          { type: "string", value: "added" },
          ...(participantsToDisplay.map((item, idx) => ({
            type: "user",
            value: item,
            suffix:
              message.payload.participants &&
              idx < message.payload.participants.length - 1
                ? ", "
                : "",
          })) || []),
          ...(participantsMore > 0
            ? [{ type: "string", value: `and ${participantsMore} more users` }]
            : []),
          { type: "user", value: forUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.to-group",
              defaultMessage: "to the group",
            }),
          },
        ],
        color: "primary.main",
      };
    }
    if (message.payload?.action === "updated") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.updated-group",
              defaultMessage: "updated the group",
            }),
          },
        ],
        color: "primary.main",
      };
    }
    if (message.payload?.action === "left") {
      return {
        tokens: [
          { type: "user", value: fromUsername },
          {
            type: "string",
            value: t({
              id: "chats-page.left-group",
              defaultMessage: "left the group",
            }),
          },
        ],
        color: "primary.main",
      };
    }
    return null;
  }, [message, t]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        py: "5px",
      }}
    >
      <Typography
        fontSize="14px"
        color={info?.color}
        fontWeight="700"
        textTransform="uppercase"
        className="text-center"
      >
        {info?.tokens.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`${item.value}-${idx}`}>
            {item.type === "user"
              ? !!item.value && (
                  <UserProfileName userId={item.value} suffix={item.suffix} />
                )
              : item.value}{" "}
          </span>
        ))}
      </Typography>
    </Box>
  );
};

export default memo(GroupInfoBubble);
