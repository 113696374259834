import { InteractiveContainer, PlainButton } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as CancelIcon } from "./assets/cancel.svg";

export const PickerButton = forwardRef<
  HTMLElement,
  PropsWithChildren<
    {
      isOpen: boolean;
      className?: string;
      hasDeleteIcon?: boolean;
      onDelete?: () => void;
    } & Omit<HTMLAttributes<HTMLElement>, "as" | "onClick">
  >
>(({ isOpen, className, children, hasDeleteIcon, onDelete, ...props }, ref) => {
  const { t } = useTranslations();

  return (
    <InteractiveContainer
      className={cx(
        "bg-grey-100 hover:bg-grey-200 flex h-12 w-full items-center justify-between gap-1 truncate rounded-lg px-6 transition-colors",
        isOpen && "bg-grey-200",
        className
      )}
      ref={ref}
      {...props}
    >
      {children || (
        <span className="text-sm text-[#828282]">
          {t({
            id: "common.select",
            defaultMessage: "Select",
          })}
        </span>
      )}
      {hasDeleteIcon ? (
        <PlainButton
          className="shrink-0"
          onClick={(e) => {
            e.stopPropagation();
            onDelete?.();
          }}
        >
          <CancelIcon />
        </PlainButton>
      ) : (
        <ArrowIcon
          className={cx(
            "shrink-0 transition-transform",
            isOpen && "rotate-180"
          )}
        />
      )}
    </InteractiveContainer>
  );
});
