import { BaseTask, TaskActivity } from "@jugl-web/rest-api/tasks";
import { TaskCommentData } from "@jugl-web/rest-api/tasks-comments";
import { HookOutOfContextError } from "@jugl-web/utils/errors/HookOutOfContext";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { Subject } from "rxjs";

export type ManageCommentsSubject = Subject<{
  action: "create" | "delete" | "update";
  taskId: string;
  comment?: TaskActivity;
  parentCommentId?: string;
  data?: TaskCommentData;
}>;

const manageComments$: ManageCommentsSubject = new Subject();

type TaskCommentActionType = "edit" | "reply" | "delete";

type TaskCommentAction = {
  type: TaskCommentActionType;
  comment: TaskActivity;
  parentCommentId?: string;
};

interface TasksCommentsContextProps {
  manageComments$: ManageCommentsSubject;
  commentAction?: TaskCommentAction;
  userId: string;
  entityId: string;
  taskId: string;
  task?: BaseTask;
  setCommentAction: (arg: TasksCommentsContextProps["commentAction"]) => void;
  cancelCommentAction: () => void;
}

const TaskCommentsContext = createContext<TasksCommentsContextProps>(
  null as unknown as TasksCommentsContextProps
);

interface TaskCommentsProviderProps {
  children: ReactNode;
  userId: string;
  entityId: string;
  taskId: string;
  task?: BaseTask;
}

const TaskCommentsProvider: FC<TaskCommentsProviderProps> = ({
  children,
  userId,
  entityId,
  taskId,
  task,
}) => {
  const [commentAction, setCommentAction] =
    useState<TasksCommentsContextProps["commentAction"]>();

  const value: TasksCommentsContextProps = useMemo(
    () => ({
      manageComments$,
      userId,
      entityId,
      taskId,
      commentAction,
      setCommentAction,
      cancelCommentAction: () => setCommentAction(undefined),
      task,
    }),
    [userId, entityId, taskId, commentAction, setCommentAction, task]
  );

  return (
    <TaskCommentsContext.Provider value={value}>
      {children}
    </TaskCommentsContext.Provider>
  );
};

export const useTaskCommentsProvider = () => {
  const context = useContext(TaskCommentsContext);
  if (!context) {
    throw new HookOutOfContextError(
      "useTaskCommentsProvider",
      "TaskCommentsProvider"
    );
  }

  return context;
};

export { TaskCommentsContext, TaskCommentsProvider };
