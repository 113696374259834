import {
  ListBoxItem,
  PlainButton,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { addMinutes, endOfDay, format, startOfDay } from "date-fns";
import { useMemo } from "react";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { ReactComponent as WatchIcon } from "../../assets/watch.svg";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";
import { TriggerButtonBase } from "../TriggerButtonBase";

const FORMAT_STRING = "hh:mm a";

export const TimeInput = () => {
  const { selectedDate, setSelectedDate } = useTaskDueDateFormContext();

  const { t } = useTranslations();
  const { localeAwareFormat } = useFormattedDate();

  const isEOD = selectedDate
    ? format(selectedDate, "HH:mm") === "23:59"
    : false;

  const timeIntervalsAsListItems = useMemo<
    ListBoxItem<{ date: Date; label: string }>[]
  >(() => {
    if (!selectedDate) {
      return [];
    }

    const startOfSelectedDate = startOfDay(selectedDate);

    const quarters = Array.from({ length: 24 * (60 / 15) }, (_, index) => {
      const date = addMinutes(startOfSelectedDate, index * 15);
      const label = localeAwareFormat(date, FORMAT_STRING);

      return {
        id: label,
        value: { date, label },
      };
    });

    const eodDate = endOfDay(selectedDate);
    const eodId = localeAwareFormat(eodDate, FORMAT_STRING);
    const eodLabel = t({
      id: "tasks-page.end-of-day",
      defaultMessage: "End of the day",
    });

    return [
      ...quarters,
      { id: eodId, value: { date: eodDate, label: eodLabel } },
    ];
  }, [localeAwareFormat, selectedDate, t]);

  return (
    <ResourcePickerPopover
      items={timeIntervalsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      placement="bottom-end"
      defaultSelectedIds={
        selectedDate ? [localeAwareFormat(selectedDate, FORMAT_STRING)] : []
      }
      shouldScrollToFirstSelectedItem
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as={TriggerButtonBase}
          ref={triggerRef}
          isDisabled={!selectedDate}
          isFocused={isOpen}
          label={t({
            id: "tasks-page.complete-before",
            defaultMessage: "Complete before",
          })}
          value={
            selectedDate
              ? isEOD
                ? t({
                    id: "tasks-page.end-of-day",
                    defaultMessage: "End of the day",
                  })
                : localeAwareFormat(selectedDate, "hh:mm a")
              : t({
                  id: "tasks-page.select-date-first",
                  defaultMessage: "Select date first",
                })
          }
          Icon={WatchIcon}
          customEndSlot={
            selectedDate && !isEOD ? (
              <PlainButton
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedDate(endOfDay(selectedDate));
                }}
              >
                <RemoveIcon />
              </PlainButton>
            ) : undefined
          }
        />
      )}
      onSelect={({ item, onClose }) => {
        setSelectedDate(item.value.date);
        onClose();
      }}
      renderLabel={(item) => item.value.label}
      maxVisibleItems={6}
      itemSize="sm"
      spaceBetweenItems="compact"
      className="w-[190px]"
    />
  );
};
