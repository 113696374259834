import { FC, ReactNode, useMemo } from "react";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useTranslations } from "@jugl-web/utils";
import {
  DEFAULT_DEAL_MIRROR_PLAN_NAME,
  SubscriptionInfoModel,
} from "@jugl-web/rest-api";
import Lottie from "react-lottie";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import basicPlanActivatedAnimation from "./assets/basic-plan-activated-animation.json";
import planActivatedAnimation from "./assets/plan-activated-animation.json";
import paymentFailedAnimation from "./assets/payment-failed-animation.json";

export type SubscriptionUpdatedAlertProps = {
  isOpen: boolean;
  subscriptionInfo: SubscriptionInfoModel;
  onRequestClose: () => void;
  onRequestRetry?: () => void;
  isRetryLoading?: boolean;
};

export const SubscriptionUpdatedAlert: FC<SubscriptionUpdatedAlertProps> = ({
  isOpen,
  subscriptionInfo,
  onRequestClose,
  onRequestRetry,
  isRetryLoading,
}) => {
  const { t } = useTranslations();
  const { navigateToPage } = useNavigation();
  const isPaymentFailed =
    subscriptionInfo.status === "incomplete" ||
    subscriptionInfo.status === "past_due";
  const { icon, title, content } = useMemo<{
    title: string;
    icon: unknown;
    content?: string | ReactNode;
  }>(() => {
    if (isPaymentFailed) {
      return {
        title: t({
          id: "subscription-updated-alert-component.payment-failed",
          defaultMessage: "Payment Failed",
        }),
        content: subscriptionInfo.card
          ? t(
              {
                id: "subscription-updated-alert-component.payment-failed-details",
                defaultMessage:
                  "Unfortunately, we couldn't make payment from the card {card}. Please check the details and try again",
              },
              {
                card: (
                  <b className="text-primary-800">
                    {subscriptionInfo.card.card.brand} ****{" "}
                    {subscriptionInfo.card.card.last4}
                  </b>
                ),
              }
            )
          : t({
              id: "subscription-updated-alert-component.payment-failed-generic",
              defaultMessage:
                "Unfortunately, we couldn't make payment. Please check the details and try again",
            }),
        icon: paymentFailedAnimation,
      };
    }
    if (subscriptionInfo.isDealMirror) {
      return {
        title: DEFAULT_DEAL_MIRROR_PLAN_NAME,
        content: t(
          {
            id: "subscription-updated-alert-component.deal-mirror-plan-content",
            defaultMessage:
              "Lifetime plan for {seatsLicensed} members with all Pro features 🤩",
          },
          { seatsLicensed: subscriptionInfo.seatsLicensed }
        ),
        icon: basicPlanActivatedAnimation,
      };
    }
    if (subscriptionInfo.planInfo?.type === "basic") {
      return {
        title: t(
          {
            id: "subscription-updated-alert-component.workspace-plan-activated",
            defaultMessage: "{planName} Activated",
          },
          {
            planName: subscriptionInfo.planInfo.name,
          }
        ),
        content: t(
          {
            id: "subscription-updated-alert-component.workspace-plan-activated-content",
            defaultMessage:
              "Workspace can handle small Team of {seatsLicensed} People 😍",
          },
          { seatsLicensed: subscriptionInfo.seatsLicensed }
        ),
        icon: basicPlanActivatedAnimation,
      };
    }
    return {
      title: t(
        {
          id: "subscription-updated-alert-component.plan-activated",
          defaultMessage: "{planName} Activated",
        },
        {
          planName: subscriptionInfo.planInfo?.name || "Jugl Plan",
        }
      ),
      content: t({
        id: "subscription-updated-alert-component.plan-activated-content",
        defaultMessage: "Smart Team Management starts here 🚀",
      }),
      icon: planActivatedAnimation,
    };
  }, [isPaymentFailed, subscriptionInfo, t]);

  return (
    <Alert
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={title}
      content={content}
      img={
        <Lottie options={{ animationData: icon }} height={240} width={240} />
      }
      buttons={
        isPaymentFailed
          ? [
              {
                text: t({ id: "common.retry", defaultMessage: "Retry" }),
                onClick: onRequestRetry,
                isDisabled: isRetryLoading,
              },
              {
                text: t({
                  id: "common.manage-subscription-plan",
                  defaultMessage: "Manage plan",
                }),
                onClick: () => {
                  onRequestClose();
                  navigateToPage("workspaceSelectSubscription");
                },
                variant: "text",
                isDisabled: isRetryLoading,
              },
            ]
          : [
              {
                text: t({ id: "common.okay", defaultMessage: "Okay" }),
                role: "close",
                color: "primary",
              },
            ]
      }
    />
  );
};
