import { PageNavParams } from "@web-src/modules/navigation/types";
import { ModulePagesConfig } from "@web-src/modules/navigation/types/ModulePagesConfigs";
import { OrderFormsPage } from "./OrderFormsPage";
import { OrdersServicesPage } from "./OrderServicesPage";
import { OrderFormCreatePage } from "./OrderFormCreatePage";

export type OrdersPagesNavigationParams = {
  orderForms: PageNavParams;
  orderFormCreate: PageNavParams;
  orderServices: PageNavParams;
  orderFormEdit: PageNavParams<{
    formId: string;
  }>;
};

export const ordersPagesNavigationConfig: ModulePagesConfig<OrdersPagesNavigationParams> =
  {
    orderForms: {
      element: <OrderFormsPage />,
      path: "orders/forms",
      isPrivate: true,
      isAdminRequired: true,
    },
    orderFormCreate: {
      element: <OrderFormCreatePage />,
      path: "orders/forms/create",
      isPrivate: true,
      isAdminRequired: true,
    },
    orderFormEdit: {
      element: <OrderFormCreatePage />,
      path: "orders/forms/edit/:formId",
      isPrivate: true,
      isAdminRequired: true,
    },
    orderServices: {
      element: <OrdersServicesPage />,
      path: "orders/services",
      isPrivate: true,
      isAdminRequired: true,
    },
  };
