import { Dialog, InteractiveContainer } from "@jugl-web/ui-components";
import React, { useState } from "react";
import {
  OrderFormSubmission,
  OrderFormSubmissionValues,
  OrderFormValues,
} from "@jugl-web/domain-resources/orders";
import { useSpotlight } from "@jugl-web/utils/hooks/useSpotlight";
import { SUBMITTED_ORDER_FORM_SPOTLIGHT_KEY } from "@jugl-web/utils/storage";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { useTranslations } from "@jugl-web/utils";
import { ReactComponent as CancelIcon } from "./assets/cancel.svg";

export const OrderFormPreviewDialog: React.FC<{
  isOpen: boolean;
  values: OrderFormSubmissionValues & Pick<OrderFormValues, "message">;
  onClose: () => void;
}> = ({ isOpen, values, onClose }) => {
  const { isActive: isSpotlightActive, markAsSeen: markSpotlightAsSeen } =
    useSpotlight({
      id: SUBMITTED_ORDER_FORM_SPOTLIGHT_KEY,
      delay: 200,
    });
  const { t } = useTranslations();
  const { message } = values;
  const [fieldsValues, setFieldsValues] = useState<{
    [key: string]: string | Date;
  }>();

  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="flex w-[720px] flex-col overflow-y-auto p-6 pb-12"
      onTransitionEnd={() => {
        if (!isOpen) {
          setIsSubmitted(false);
        }
      }}
    >
      <InteractiveContainer
        onClick={onClose}
        className="absolute top-4 right-4 z-[10] flex h-8 w-8 items-center justify-center rounded-full bg-white"
      >
        <CancelIcon />
      </InteractiveContainer>
      <div className="text-primary-800 bg-primary-50 font-secondary absolute top-4 left-4 w-max rounded-xl border-2 border-solid border-white py-2 px-2.5 text-xs font-semibold leading-[140%]">
        {t({
          id: "order-form-create-page.form-preview",
          defaultMessage: "Form Preview 👀",
        })}
      </div>
      <SpotlightTooltip
        isOpen={isSpotlightActive && isSubmitted}
        placement="left"
        className="!z-[2000]"
        onDismiss={markSpotlightAsSeen}
        tip={t(
          {
            id: "order-form-create-page.submitted-order-form-message",
            defaultMessage:
              "After Client Submitted the Form - New task will be created inside of this Workspace ✅{br}{br}All selected assignees of the Task will receive a notification about new Order✨",
          },
          {
            br: <br />,
          }
        )}
        showButton
        renderTrigger={({ ref, props }) => (
          <div
            ref={ref}
            {...props}
            className="jugl__custom-scrollbar w-full overflow-auto"
          >
            <OrderFormSubmission
              values={values}
              onSubmit={(newFieldsValues) => {
                setFieldsValues(newFieldsValues);
                setIsSubmitted(true);
              }}
              submittedMessage={message}
              isSubmitted={isSubmitted}
              initialFieldsValues={fieldsValues}
              onReset={() => {
                setIsSubmitted(false);
              }}
              resetButtonText={t({
                id: "order-form-create-page.restart-preview",
                defaultMessage: "Restart Preview",
              })}
            />
          </div>
        )}
      />
    </Dialog>
  );
};
