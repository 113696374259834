import { MY_TASKS_SOURCE } from "@jugl-web/domain-resources/tasks";
import {
  TaskFiltersStateProvider,
  useTaskFiltersState,
} from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { InternalTaskFilters } from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useTranslations } from "@jugl-web/utils";
import { TaskFilterSections } from "@web-src/features/tasks/TaskFilterSections";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useEffect } from "react";

interface TaskArchiveFiltersDialogProps {
  isOpen: boolean;
  filters: InternalTaskFilters;
  onChangeFilters: (state: InternalTaskFilters) => void;
  onClose: () => void;
}

export const TaskArchiveFiltersDialog: FC<TaskArchiveFiltersDialogProps> = ({
  isOpen,
  filters,
  onChangeFilters,
  onClose,
}) => {
  const { t } = useTranslations();
  const { entity } = useEntitySelectedProvider();

  const {
    filtersState,
    activeFiltersCount,
    filtersStateContext,
    resetFiltersState,
  } = useTaskFiltersState();

  const handleSubmit = () => {
    onChangeFilters(filtersState);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      resetFiltersState(filters);
    }
  }, [filters, isOpen, resetFiltersState]);

  return (
    <TaskFiltersStateProvider context={filtersStateContext}>
      <BottomCenteredDrawer
        isOpen={isOpen}
        header={{
          type: "title",
          title: t({ id: "common.filters", defaultMessage: "Filters" }),
        }}
        onClose={onClose}
      >
        <BottomCenteredDrawer.Content className="py-4">
          <TaskFilterSections
            entityId={entity.id}
            source={MY_TASKS_SOURCE}
            customVisibility={{
              byLabel: true,
              byAssignee: true,
            }}
          />
        </BottomCenteredDrawer.Content>
        <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
          <Button
            variant="contained"
            color="grey"
            className="h-10 w-[200px]"
            onClick={() =>
              activeFiltersCount ? resetFiltersState() : onClose()
            }
          >
            {activeFiltersCount > 0
              ? t({ id: "common.clear-all", defaultMessage: "Clear all" })
              : t({ id: "common.cancel", defaultMessage: "Cancel" })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="h-10 w-[200px]"
            isDisabled={activeFiltersCount === 0}
            onClick={handleSubmit}
          >
            {t({ id: "common.apply", defaultMessage: "Apply" })}
          </Button>
        </BottomCenteredDrawer.Actions>
      </BottomCenteredDrawer>
    </TaskFiltersStateProvider>
  );
};
