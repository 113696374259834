import { Button } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import React from "react";
import { ReactComponent as InfoIcon } from "./assets/info.svg";

export const ImportSuccessScreen: React.FC<{
  tasksCreated: number;
  duplicates: number;
  isDuplicateTaskActionSkip: boolean;
  boardName: string;
  onClose: () => void;
}> = ({
  isDuplicateTaskActionSkip,
  duplicates,
  boardName,
  tasksCreated,
  onClose,
}) => {
  const { t } = useTranslations();

  return (
    <div className="flex h-full flex-col items-center text-center">
      <span className="text-dark font-secondary mt-6 text-xl font-medium leading-5">
        {t({
          id: "tasks-page.importing-tasks-done",
          defaultMessage: "Done! ✅",
        })}
      </span>
      <span className="text-dark-700 mt-3 leading-[140%] tracking-[0.16px]">
        {t(
          {
            id: "tasks-page.tasks-was-successfully-imported-to-board",
            defaultMessage:
              "{tasksCreated} {tasksCreated, plural, one {Task} other {Tasks}} was successfully imported into the board {boardName}",
          },
          {
            tasksCreated,
            boardName: (
              <span className="text-primary-800 font-semibold">
                {boardName}
              </span>
            ),
          }
        )}
      </span>
      {isDuplicateTaskActionSkip && duplicates > 0 && (
        <div className="bg-primary-50 mt-5 flex items-center gap-1 rounded-lg py-2 px-6">
          <InfoIcon />
          <span className="text-dark-800 font-secondary leading-2 text-xs tracking-[0.12px]">
            {t(
              {
                id: "tasks-page.duplicates-was-skipped",
                defaultMessage:
                  "{duplicates} {duplicates, plural, one {duplicate} other {duplicates}} was skipped",
              },
              { duplicates }
            )}
          </span>
        </div>
      )}
      <Button className="mt-14 h-10 w-[300px]" onClick={onClose}>
        {t({
          id: "common.okay",
          defaultMessage: "Okay",
        })}
      </Button>
    </div>
  );
};
