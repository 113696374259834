import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Checkbox,
  EmptyListContent,
  ListLoading,
  LoadingAnimation,
  TableGrid,
} from "@jugl-web/ui-components/cross-platform";
import { cx, useTranslations } from "@jugl-web/utils";
import { UserCPanelModel, useRestApiProvider } from "@jugl-web/rest-api";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { UserEntityStatusBadge } from "@web-src/modules/people/components/UserEntityStatusBadge";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useEffectOnce, usePrevious } from "react-use";
import { ReactComponent as GroupIcon } from "./icons/peoples.svg";
import { useCPanelPageContext } from "../../CPanelPageProvider";
import { ReactComponent as DocumentIcon } from "./icons/document.svg";
import { ReactComponent as DocumentActiveIcon } from "./icons/document-active.svg";
import { CpanelUserInfo } from "./components/CpanelUserInfo";
import { UserLastActivityInfo } from "./components/UserLastActivityInfo";
import { UserContextMenu } from "./components/UserContextMenu";
import { DeleteUsersConfirmation } from "../DeleteUsersConfirmation";
import { UserTimeZoneInfo } from "./components/UserTimeZoneInfo";
import { NoInfoCell } from "./components/NoInfoCell";
import { UserManagers } from "./components/UserManagers";
import { UserRolePicker } from "./components/UserRolePicker";

type PageState = {
  pageNumber: number;
  requestId: number;
  isError?: boolean;
  hasMore?: boolean;
};

export const CPanelTable = () => {
  const { me } = useMe();
  const { t } = useTranslations();
  const {
    selectedUsers,
    toggleUserSelectState,
    setOpenedSideBar,
    searchQuery,
    userIdToDocCountDelta,
    resetUserDocCountDelta,
    refetchUsers$,
    updateUser$,
  } = useCPanelPageContext();
  const [
    isOpenDeleteUserConfirmationPopup,
    setIsOpenDeleteUserConfirmationPopup,
  ] = useState(false);
  const { entityId } = useEntitySelectedProvider();

  const [userInfo, setUserInfo] = useState<UserCPanelModel>();

  const { usersApi } = useRestApiProvider();
  const currentPageState = useRef<PageState | null>(null);

  const [loadUsers, { isLoading, isFetching, isError }] =
    usersApi.useLazyGetCPanelUsersQuery();

  const [{ total, users }, setUsersState] = useState<{
    total: number;
    users: UserCPanelModel[];
  }>({ total: 0, users: [] });

  const loadUsersNextPage = useCallback(async () => {
    let nextPageNumber = 1;
    if (currentPageState.current) {
      if (currentPageState.current.isError) {
        nextPageNumber = currentPageState.current.pageNumber;
      } else {
        nextPageNumber = currentPageState.current.pageNumber + 1;
      }
    } else {
      resetUserDocCountDelta();
    }
    const nextPageState: PageState = {
      requestId: Math.random(),
      pageNumber: nextPageNumber,
    };
    currentPageState.current = nextPageState;
    const result = await loadUsers({
      entityId,
      params: {
        page: nextPageNumber,
        page_size: 10,
        status: "all",
        search: searchQuery ? `%${searchQuery}%` : undefined,
      },
    });
    if (currentPageState.current?.requestId !== nextPageState.requestId) {
      return;
    }
    if ("error" in result && result.error) {
      nextPageState.isError = true;
      currentPageState.current = nextPageState;
      return;
    }
    if ("data" in result && result.data && result.data.data) {
      const responseData = result.data;
      setUsersState((prev) =>
        nextPageNumber === 1
          ? { total: responseData.total_entries, users: responseData.data }
          : {
              total: responseData.total_entries,
              users: [...prev.users, ...responseData.data],
            }
      );
      nextPageState.hasMore = result.data.total_pages > nextPageNumber;
      currentPageState.current = nextPageState;
    }
  }, [entityId, loadUsers, searchQuery, resetUserDocCountDelta]);

  useEffect(() => {
    const subscription = refetchUsers$.subscribe(() => {
      setUsersState({ total: 0, users: [] });
      currentPageState.current = null;
      loadUsersNextPage();
    });

    return () => subscription.unsubscribe();
  }, [loadUsersNextPage, refetchUsers$]);

  useEffect(() => {
    const subscription = updateUser$.subscribe(({ id, data }) => {
      setUsersState((prev) => ({
        total: prev.total,
        users: prev.users.map((user) =>
          user.entity_rel_id === id ? { ...user, ...data } : user
        ),
      }));
    });
    return () => subscription.unsubscribe();
  }, [updateUser$]);

  useEffectOnce(() => {
    loadUsersNextPage();
  });

  const previousSearchQuery = usePrevious(searchQuery);
  useEffect(() => {
    if (
      (!previousSearchQuery && !searchQuery) ||
      searchQuery === previousSearchQuery
    ) {
      return;
    }
    currentPageState.current = null;
    loadUsersNextPage();
  }, [loadUsersNextPage, searchQuery, previousSearchQuery]);
  return (
    <>
      <TableGrid
        className="overflow-auto whitespace-nowrap"
        cellClassName="flex items-center"
        headerCellClassName="flex items-center border-t-0"
        data={users || []}
        emptyContent={(() => {
          if (isLoading || isFetching) {
            return <ListLoading />;
          }
          if (isError) {
            return (
              <EmptyListContent type="error" onRefetch={loadUsersNextPage} />
            );
          }
          return <EmptyListContent type="noResults" />;
        })()}
        bottomContent={(() => {
          if (
            !currentPageState.current?.pageNumber ||
            currentPageState.current.pageNumber === 1
          ) {
            return undefined;
          }
          if (isFetching) {
            return (
              <div className="flex justify-center p-8">
                <LoadingAnimation />
              </div>
            );
          }
          if (isError) {
            return (
              <EmptyListContent
                type="error"
                customImg={<></>}
                onRefetch={loadUsersNextPage}
              />
            );
          }
          return undefined;
        })()}
        onReachEnd={() =>
          currentPageState.current?.hasMore && !isLoading && !isFetching
            ? loadUsersNextPage()
            : undefined
        }
        columns={[
          {
            title: (
              <div className="ml-4 flex gap-2">
                <GroupIcon /> {total}
              </div>
            ),
            content: (user) => (
              <div className="mr-4 flex items-center gap-4">
                <UserContextMenu
                  user={user}
                  setUserInfo={setUserInfo}
                  setIsOpenDeleteUserConfirmationPopup={
                    setIsOpenDeleteUserConfirmationPopup
                  }
                />
                <Checkbox
                  isDisabled={user.id === me?.id}
                  className="disabled:cursor-default"
                  isChecked={selectedUsers.some(
                    (selectedUser) =>
                      selectedUser.entity_rel_id === user.entity_rel_id
                  )}
                  onChange={() => toggleUserSelectState(user)}
                />
              </div>
            ),
          },
          {
            title: t({
              id: "cpanel-page.username",
              defaultMessage: "User name",
            }),
            className: "min-w-[270px]",
            content: (user) => (
              <CpanelUserInfo
                key={user.entity_rel_id}
                userId={user.id}
                cPanelUser={user}
                searchQuery={searchQuery}
              />
            ),
          },
          {
            title: t({
              id: "cpanel-page.status",
              defaultMessage: "Status",
            }),
            className: "min-w-[270px]",
            content: (user) => <UserEntityStatusBadge status={user.status} />,
          },
          {
            title: t({
              id: "cpanel-page.account-login-methods",
              defaultMessage: "Account login methods",
            }),
            className: "min-w-[250px]",
            content: (user) => (
              <div className="flex flex-col gap-2">
                {[...user.emails, ...user.mobiles].map((item, idx) => (
                  <div className="flex items-center gap-2" key={+idx}>
                    <div className="bg-grey-600 flex h-4 w-4 items-center justify-center rounded text-xs font-bold text-white">
                      {idx + 1}
                    </div>
                    <span className="text-sm text-[#4F4F4F]">{item}</span>
                  </div>
                ))}
              </div>
            ),
          },
          {
            title: t({
              id: "cpanel-page.Role",
              defaultMessage: "Role",
            }),
            content: (user) => <UserRolePicker cPanelUser={user} />,
          },
          {
            title: t({
              id: "cpanel-page.direct-managers",
              defaultMessage: "Direct managers",
            }),
            className: "min-w-[120px]",
            content: (user) => (
              <UserManagers
                managers={user.managers}
                userId={user.id}
                userEntityRelId={user.entity_rel_id}
                status={user.status}
              />
            ),
          },
          {
            title: t({
              id: "cpanel-page.last-activity",
              defaultMessage: "Last activity",
            }),
            className: "min-w-[250px]",
            content: (user) =>
              user.last_active_on ? (
                <UserLastActivityInfo
                  lastActivity={user.last_active_on}
                  onClick={() => {
                    setOpenedSideBar({ user, sideBar: "loginActivity" });
                  }}
                />
              ) : (
                <NoInfoCell />
              ),
          },
          {
            title: t({
              id: "cpanel-page.time-zone",
              defaultMessage: "Time Zone",
            }),
            className: "min-w-[260px]",
            content: (user) => <UserTimeZoneInfo timeZone={user.timezone} />,
          },
          {
            title: t({
              id: "cpanel-page.documents",
              defaultMessage: "Documents",
            }),
            className: "min-w-[92px]",
            content: (user) => {
              if (!user.id) {
                return <NoInfoCell />;
              }
              const docCount =
                user.doc_count + (userIdToDocCountDelta[user.id] || 0);
              return (
                <button
                  className="hover:bg-grey-100 flex w-full cursor-pointer items-center gap-2 border-none bg-transparent py-4 outline-none"
                  onClick={() => {
                    setUserInfo(user);
                    setOpenedSideBar({ user, sideBar: "documents" });
                  }}
                  type="button"
                >
                  {docCount > 0 ? <DocumentActiveIcon /> : <DocumentIcon />}
                  <span
                    className={cx(
                      "text-sm",
                      docCount > 0 ? "text-[#1A75D2]" : "text-[#4F4F4F]"
                    )}
                  >
                    {docCount}
                  </span>
                </button>
              );
            },
          },
        ]}
      />
      <DeleteUsersConfirmation
        isOpen={isOpenDeleteUserConfirmationPopup && !!userInfo}
        onRequestClose={() => setIsOpenDeleteUserConfirmationPopup(false)}
        users={userInfo ? [userInfo] : []}
        onSuccess={() => {
          if (
            userInfo &&
            selectedUsers.some(
              (selectedUser) =>
                selectedUser.entity_rel_id === userInfo.entity_rel_id
            )
          ) {
            toggleUserSelectState(userInfo);
          }
        }}
      />
    </>
  );
};
