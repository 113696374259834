import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  EmptyListContent,
  InteractiveContainer,
  ListLoading,
  TableGrid,
} from "@jugl-web/ui-components/cross-platform";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { FC, useMemo } from "react";
import { format } from "date-fns";
import { cx, priceDisplay, useTranslations } from "@jugl-web/utils";
import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";

export const InvoicesTable: FC = () => {
  const { entitiesApi } = useRestApiProvider();
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const {
    data: invoicesList,
    isLoading: invoicesListIsLoading,
    isError: invoicesListIsError,
    refetch,
  } = entitiesApi.useGetInvoicesListQuery({
    entityId,
  });
  const invoiceList = useMemo(
    () =>
      invoicesList?.invoice_list.filter((item) => item.status !== "draft") ||
      [],
    [invoicesList]
  );

  const getInvoiceStatusText = (status: string) => {
    switch (status) {
      case "paid":
        return t({
          id: "invoices-table-component.status-paid",
          defaultMessage: "Paid",
        });
      case "open":
        return t({
          id: "invoices-table-component.status-open",
          defaultMessage: "Open",
        });
      case "draft":
        return t({
          id: "invoices-table-component.status-draft",
          defaultMessage: "Draft",
        });
      case "uncollectible":
        return t({
          id: "invoices-table-component.status-uncollectible",
          defaultMessage: "Uncollectible",
        });
      case "void":
        return t({
          id: "invoices-table-component.status-void",
          defaultMessage: "Void",
        });
      default:
        return status.charAt(0).toUpperCase() + status.slice(1);
    }
  };

  return (
    <TableGrid
      data={invoiceList}
      className="min-h-[200px]"
      inline
      columns={[
        {
          title: t({
            id: "invoices-table-component.payment-date",
            defaultMessage: "Payment date",
          }),
          content: (invoice) => (
            <div className="whitespace-nowrap">
              {format(new Date(invoice.period_start * 1000), "MMM dd, yyyy")}
            </div>
          ),
        },
        {
          title: t({
            id: "invoices-table-component.number",
            defaultMessage: "Number",
          }),
          content: (invoice) => (
            <div className="whitespace-nowrap">{invoice.number}</div>
          ),
        },
        {
          title: t({
            id: "invoices-table-component.amount-paid",
            defaultMessage: "Amount paid",
          }),
          content: (invoice) => (
            <div
              className={cx(
                "whitespace-nowrap",
                invoice.status !== "paid" && "text-gray-400"
              )}
            >
              {priceDisplay(invoice.amount_paid, invoice.currency)}
            </div>
          ),
        },
        {
          title: t({
            id: "invoices-table-component.status",
            defaultMessage: "Status",
          }),
          content: (invoice) => (
            <div
              className={cx("whitespace-nowrap", {
                "text-green-500": invoice.status === "paid",
                "text-red-500": invoice.status === "open",
              })}
            >
              {getInvoiceStatusText(invoice.status)}
            </div>
          ),
        },
        {
          title: "",
          grow: true,
          content: (invoice) => (
            <div className="flex flex-1 justify-end">
              <InteractiveContainer
                onClick={() => window.open(invoice.invoice_pdf, "_blank")}
              >
                <DownloadIcon />
              </InteractiveContainer>
            </div>
          ),
        },
      ]}
      emptyContent={(() => {
        if (invoicesListIsLoading) {
          return <ListLoading />;
        }
        if (invoicesListIsError) {
          return <EmptyListContent type="error" onRefetch={refetch} />;
        }
        return (
          <div className="px-8 py-6 text-[#5A6367]">
            {t({
              id: "invoices-table-component.empty-message",
              defaultMessage:
                "💸 After making payment, all invoices will be listed here",
            })}
          </div>
        );
      })()}
    />
  );
};
