import React from "react";
import Lottie from "react-lottie";
import { useTranslations } from "@jugl-web/utils";
import { Button } from "@jugl-web/ui-components";
import errorAnimation from "./assets/error-animation.json";

export const ImportErrorScreen: React.FC<{
  onRetry: () => void;
}> = ({ onRetry }) => {
  const { t } = useTranslations();

  return (
    <div className="flex h-full flex-col items-center text-center">
      <Lottie
        options={{ animationData: errorAnimation }}
        height={180}
        width={300}
      />
      <span className="text-dark font-secondary mt-6 text-xl font-medium leading-5">
        {t({
          id: "tasks-page.something-went-wrong",
          defaultMessage: "Something went wrong",
        })}
      </span>
      <span className="text-dark-700 mt-3 leading-[140%] tracking-[0.16px]">
        {t({
          id: "tasks-page.failed-to-import-tasks-try-again",
          defaultMessage: "Failed to import Tasks, please try again",
        })}
      </span>
      <Button className="mt-12 h-10 w-[300px]" onClick={onRetry}>
        {t({
          id: "common.retry",
          defaultMessage: "Retry",
        })}
      </Button>
    </div>
  );
};
