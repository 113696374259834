import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import { FC, ReactNode } from "react";

export interface TopBarButtonProps {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
}

export const TopBarButton: FC<TopBarButtonProps> = ({
  label,
  icon,
  onClick,
}) => (
  <PlainButton
    onClick={onClick}
    className="jugl__focusable-outline flex h-[40px] items-center gap-2 rounded-lg p-2.5 transition-colors hover:bg-[#EEF2F5]"
  >
    {icon}
    <span className="text-dark-800 font-secondary font-normal leading-[21px]">
      {label}
    </span>
  </PlainButton>
);
