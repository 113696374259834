import { useEffect } from "react";
import {
  ConditionalPageArgs,
  NavigateFnConfig,
  NavigationPage,
  useNavigation,
} from "../hooks/useNavigation";
import { RootPagesNavigationParams } from "../root-config";

type NavigateToPageProps<TPage extends NavigationPage> = {
  page: TPage;
  config?: NavigateFnConfig<TPage>;
} & (RootPagesNavigationParams[TPage]["pathParams"] extends never
  ? { pathParams?: never }
  : { pathParams: RootPagesNavigationParams[TPage]["pathParams"] });

export const NavigateToPage = <TPage extends NavigationPage>({
  page,
  pathParams,
  config,
}: NavigateToPageProps<TPage>) => {
  const { navigateToPage } = useNavigation();

  useEffect(() => {
    navigateToPage(
      page,
      ...([pathParams, config] as ConditionalPageArgs<TPage>)
    );
  }, [config, navigateToPage, page, pathParams]);

  return null;
};
