import { useTaskFiltering } from "@jugl-web/domain-resources/tasks/components/TaskFilteringProvider";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { BottomCenteredDrawer } from "@jugl-web/ui-components/web/BottomCenteredDrawer";
import { useTranslations } from "@jugl-web/utils";
import {
  DEFAULT_FILTER_SECTIONS_VISIBILITY,
  TaskFilterSections,
} from "@web-src/features/tasks/TaskFilterSections";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { FC } from "react";

interface FiltersScreenProps {
  entityId: string;
  onDialogClose: () => void;
  onDialogSafeClose: () => void;
}

export const FiltersScreen: FC<FiltersScreenProps> = ({
  entityId,
  onDialogClose,
  onDialogSafeClose,
}) => {
  const { tasksSource, tasksSourceInfo } = useTasksPageContext();
  const { changeFiltering } = useTaskFiltering();

  const { filtersState, activeFiltersCount, clearFiltersState } =
    useTaskFiltersStateContext();

  const { t } = useTranslations();

  const handleApplyFilters = () => {
    changeFiltering({ type: "standard", filters: filtersState });
    onDialogClose();
  };

  return (
    <>
      <BottomCenteredDrawer.Content className="py-4">
        <TaskFilterSections
          entityId={entityId}
          source={tasksSource}
          customVisibility={{
            ...DEFAULT_FILTER_SECTIONS_VISIBILITY,
            byBoard: !tasksSourceInfo.isUserBoardTasks,
            byCustomer: !tasksSourceInfo.isCustomerTasks,
          }}
        />
      </BottomCenteredDrawer.Content>
      <BottomCenteredDrawer.Actions className="flex items-center justify-center gap-3.5">
        <Button
          variant="contained"
          color="grey"
          className="h-10 w-[200px]"
          onClick={() =>
            activeFiltersCount > 0 ? clearFiltersState() : onDialogSafeClose()
          }
        >
          {activeFiltersCount > 0
            ? t({ id: "common.clear-all", defaultMessage: "Clear all" })
            : t({ id: "common.cancel", defaultMessage: "Cancel" })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="h-10 w-[200px]"
          onClick={handleApplyFilters}
        >
          {activeFiltersCount > 0
            ? t(
                {
                  id: "common.apply-with-count",
                  defaultMessage: "Apply {count}",
                },
                { count: activeFiltersCount }
              )
            : t({ id: "common.apply", defaultMessage: "Apply" })}
        </Button>
      </BottomCenteredDrawer.Actions>
    </>
  );
};
