import {
  DEFAULT_DEAL_MIRROR_PLAN_NAME,
  getDealMirrorPromoCodePrice,
  useRestApiProvider,
} from "@jugl-web/rest-api";
import {
  DealMirrorPromoCodeInfo,
  PromoCodeInfo,
} from "@jugl-web/rest-api/entities/models/common-types/PromoCodeInfo";
import {
  Button,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { priceDisplay } from "@jugl-web/utils";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useEntitySubscriptionProvider } from "@web-src/modules/entities/providers/EntitySubscriptionProvider";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as CardIcon } from "./assets/card-icon.svg";
import { SummaryItem, SummaryItemProps } from "./components/SummaryItem";
import { ReactComponent as CrossIcon } from "./assets/cross-icon.svg";
import { ReactComponent as DiscountIcon } from "./assets/discount.svg";

export type PaymentSummarySideBarItem = SummaryItemProps["data"];

export type PaymentSummarySideBarProps = {
  items: PaymentSummarySideBarItem[];
  currency: string;
  allowPromoCode?: boolean;
  emptyMessage?: string;
  onConfirm: () => void;
  isSubmitting?: boolean;
  totalLabel?: string;
  summaryItem?: PaymentSummarySideBarItem;

  appliedPromoCodes?: PromoCodeInfo[];
  onApplyPromoCode?: (promo: PromoCodeInfo) => void;
  onRemovePromoCode?: (idx: number) => void;
};

export const PaymentSummarySideBar: FC<PaymentSummarySideBarProps> = ({
  items,
  currency,
  emptyMessage,
  onConfirm,
  allowPromoCode,
  isSubmitting,
  totalLabel,
  summaryItem,
  appliedPromoCodes,
  onApplyPromoCode,
  onRemovePromoCode,
}) => {
  const { entityId, subscriptionInfo } = useEntitySelectedProvider();
  const { entitiesApi } = useRestApiProvider();
  const { updateCardDetails, isCardPaymentNotSupported } =
    useEntitySubscriptionProvider();

  const [enteredPromoCode, setEnteredPromoCode] = useState<string>("");

  useEffect(() => {
    if (!allowPromoCode) {
      setEnteredPromoCode("");
    }
  }, [allowPromoCode]);

  const [validatePromoCode, { isLoading }] =
    entitiesApi.useValidatePromoCodeMutation();
  const handleValidatePromocode = useCallback(async () => {
    if (!enteredPromoCode) {
      return;
    }
    if (appliedPromoCodes?.find((item) => item.code === enteredPromoCode)) {
      // eslint-disable-next-line no-alert
      alert("Promo code already applied");
      return;
    }
    const response = await validatePromoCode({
      entityId,
      data: { code: enteredPromoCode },
    });
    if (!("data" in response) || !response.data) {
      return;
    }
    if (!response.data.active) {
      // eslint-disable-next-line no-alert
      alert("Promo code is not active");
      return;
    }
    setEnteredPromoCode("");
    onApplyPromoCode?.(response.data);
  }, [
    enteredPromoCode,
    entityId,
    validatePromoCode,
    appliedPromoCodes,
    onApplyPromoCode,
  ]);

  const totalCostInfo: {
    totalToPay: number;
    discount: number;
    discountPercent: number;
    appliedBalance: number;
    remainingBalance: number;
  } = useMemo(() => {
    const appliedPromoCode = appliedPromoCodes?.[0];
    if (appliedPromoCode?.type === "deal_mirror") {
      return {
        totalToPay: 0,
        discount: appliedPromoCode.yearly_saving,
        discountPercent: 100,
        appliedBalance: 0,
        remainingBalance: 0,
      };
    }
    const total = items.reduce((acc, item) => acc + item.amount, 0);
    const discount = appliedPromoCode
      ? total * (appliedPromoCode.percent_off / 100)
      : 0;
    let totalToPay = total - discount;
    let appliedBalance = 0;
    let remainingBalance = -subscriptionInfo.balance;
    if (totalToPay > 0) {
      const balance = -subscriptionInfo.balance;
      if (balance > 0) {
        const totalToPayWithBalance =
          totalToPay - Math.min(balance, totalToPay);
        appliedBalance = totalToPay - totalToPayWithBalance;
        totalToPay = totalToPayWithBalance;
        if (appliedBalance < balance) {
          remainingBalance = balance - appliedBalance;
        } else {
          remainingBalance = 0;
        }
      }
    }
    return {
      totalToPay,
      discount,
      discountPercent: appliedPromoCode?.percent_off || 0,
      appliedBalance,
      remainingBalance,
    };
  }, [appliedPromoCodes, items, subscriptionInfo.balance]);

  const shouldShowPromoCodeInput = useMemo(
    () =>
      !subscriptionInfo.balance && allowPromoCode && !appliedPromoCodes?.length,
    [allowPromoCode, appliedPromoCodes, subscriptionInfo]
  );
  const isDealMirrorMode = appliedPromoCodes?.[0]?.type === "deal_mirror";

  return (
    <div
      style={{ boxShadow: "0px 2px 40px 0px rgba(0, 0, 0, 0.08)" }}
      className="h-full w-[460px] bg-white px-10 py-12"
    >
      <div className="font-secondary text-[20px] font-medium leading-[150%]">
        Summary
      </div>
      {!isCardPaymentNotSupported && (
        <InteractiveContainer
          onClick={updateCardDetails}
          className="mt-2 flex items-center gap-2"
        >
          <CardIcon />
          {subscriptionInfo.card ? (
            <span className="text-dark-700 text-sm">
              {subscriptionInfo.card.card.brand} ****{" "}
              {subscriptionInfo.card.card.last4}
            </span>
          ) : (
            <span>No card</span>
          )}
        </InteractiveContainer>
      )}
      <div className="pt-6 pb-12">
        <div className="h-[1px] bg-[#ECEFF0]" />
      </div>
      {!items.length && !shouldShowPromoCodeInput && !isDealMirrorMode ? (
        <div className="text-[#828282]">{emptyMessage || "No items"}</div>
      ) : (
        <>
          {appliedPromoCodes?.[0]?.type === "deal_mirror" ? (
            <SummaryItem
              currency={currency}
              hideAmount
              promoText="Lifetime plan 😍"
              data={{
                title: DEFAULT_DEAL_MIRROR_PLAN_NAME,
                amount: 0,
                summary: [
                  `${appliedPromoCodes
                    .map((item) => (item as DealMirrorPromoCodeInfo).seats)
                    .reduce(
                      (total, seats) => total + seats,
                      0
                    )} x seats x lifetime`,
                ],
              }}
            />
          ) : (
            <div className="flex flex-col gap-4">
              {items.map((item, idx) => (
                <SummaryItem key={+idx} currency={currency} data={item} />
              ))}
            </div>
          )}

          {shouldShowPromoCodeInput && (
            <div className="mt-4 grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <input
                  placeholder="Apply promocode"
                  className="bg-grey-100 placeholder:text-grey-700 text-dark h-12 w-full rounded-lg border-none py-2 px-4 font-[Roboto] outline-none"
                  value={enteredPromoCode}
                  onChange={(e) => setEnteredPromoCode(e.target.value)}
                />
              </div>
              <div>
                <Button
                  onClick={handleValidatePromocode}
                  isDisabled={!enteredPromoCode || isLoading}
                  color="grey"
                  fullWidth
                  className="flex items-center gap-2 py-3"
                >
                  <span>Apply</span>
                  <DiscountIcon />
                </Button>
              </div>
            </div>
          )}

          <div className="pt-12 pb-6">
            <div className="h-[1px] bg-[#ECEFF0]" />
          </div>

          {appliedPromoCodes?.map((promoCode, idx) => (
            <div className="group flex items-center justify-between rounded-lg px-4 py-2 leading-[150%] hover:bg-gray-100">
              <div className="font-secondary font-medium">
                {promoCode?.type === "deal_mirror"
                  ? `DealMirror Coupon ${idx + 1} ${currency}`
                  : `Discount ${totalCostInfo.discountPercent}%`}
              </div>
              <div className="flex items-center gap-2">
                <div>
                  -
                  {promoCode?.type === "deal_mirror"
                    ? priceDisplay(
                        getDealMirrorPromoCodePrice(promoCode, currency) ||
                          totalCostInfo.discount,
                        currency
                      )
                    : priceDisplay(totalCostInfo.discount, currency)}
                </div>
                <div
                  className="hidden h-5 w-5 cursor-pointer rounded-full bg-gray-200 group-hover:block"
                  onClick={() => onRemovePromoCode?.(idx)}
                >
                  <CrossIcon />
                </div>
              </div>
            </div>
          ))}

          {!!totalCostInfo.appliedBalance && (
            <div className="flex items-center justify-between px-4 py-2 leading-[150%]">
              <div className="font-secondary font-medium">Applied balance</div>
              <div>-{priceDisplay(totalCostInfo.appliedBalance, currency)}</div>
            </div>
          )}

          <div className="flex items-center justify-between px-4 py-2  leading-[150%]">
            <div className="font-secondary font-medium">
              {totalLabel || "Total"}
            </div>
            <div>
              {priceDisplay(
                totalCostInfo.totalToPay > 0 ? totalCostInfo.totalToPay : 0,
                currency
              )}
            </div>
          </div>

          {!!totalCostInfo.remainingBalance && (
            <>
              <div className="pt-6 pb-6">
                <div className="h-[1px] bg-[#ECEFF0]" />
              </div>
              <div className="flex items-center justify-between px-4 py-2 leading-[150%]">
                <div className="font-secondary font-medium">
                  Remaining balance
                </div>
                <div>
                  {priceDisplay(totalCostInfo.remainingBalance, currency)}
                </div>
              </div>
            </>
          )}

          <div className="mt-12">
            <Button onClick={onConfirm} fullWidth isDisabled={isSubmitting}>
              Confirm
            </Button>
          </div>
          {summaryItem && (
            <div className="mt-12">
              <SummaryItem data={summaryItem} currency={currency} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
