import { Dialog } from "@jugl-web/ui-components";
import React from "react";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useToast, useTranslations } from "@jugl-web/utils";
import { OrderSubmitForm } from "@jugl-web/domain-resources/orders/components/OrderSubmitForm";

export const OrderFormSubmissionDialog: React.FC<{
  isOpen: boolean;
  formId?: string;
  onClose: () => void;
}> = ({ isOpen, formId, onClose }) => {
  const { entityId } = useEntitySelectedProvider();
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });

  if (!formId) return null;
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      className="flex w-[720px] flex-col overflow-y-auto p-6 pb-12"
    >
      <OrderSubmitForm
        entityId={entityId}
        formId={formId}
        onSubmit={() => {
          onClose();
          toast(
            t({
              id: "order-forms-page.form-successfully-submitted",
              defaultMessage: "Form was successfully submitted",
            })
          );
        }}
      />
    </Dialog>
  );
};
