import { useTaskViewsContext } from "@jugl-web/domain-resources/tasks";
import { TaskView } from "@jugl-web/domain-resources/tasks/hooks/useTasksViews";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { PopoverProps } from "@jugl-web/ui-components/cross-platform/Popover";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useFCM } from "@web-src/modules/notifications/providers/FCMProvider";
import { FC, useMemo } from "react";

export const TaskKanbanViewsPopover: FC<
  Pick<PopoverProps, "renderTrigger">
> = ({ renderTrigger }) => {
  const { accessibleViews, selectedView, selectView } = useTaskViewsContext();

  const { logEvent } = useFCM();

  const viewsAsListItems = useMemo<ListBoxItem<TaskView>[]>(
    () => accessibleViews.map((view) => ({ id: view.id, value: view })),
    [accessibleViews]
  );

  return (
    <ResourcePickerPopover
      placement="bottom-start"
      items={viewsAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      defaultSelectedIds={[selectedView.id]}
      maxVisibleItems={6}
      itemSize="lg"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.title}
      className="w-[300px]"
      renderTrigger={renderTrigger}
      onSelect={({ item, onClose }) => {
        selectView(item.id);
        logEvent("action_task_views");
        onClose();
      }}
    />
  );
};
