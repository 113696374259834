import { selectUserId } from "@web-src/features/auth/authSlice";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { selectActiveChatId, setActiveChatId } from "../chatsSlice";
import useConversations from "./useConversations";

const useActiveChat = () => {
  const dispatch = useDispatch();
  const match = useMatch(`/:entityId/chats/:chatId/*`);
  const { navigateToPage } = useNavigation();
  const activeChatId = useSelector(selectActiveChatId);
  const { conversations } = useConversations();
  const chatId = activeChatId || match?.params.chatId;
  const chat = useMemo(
    () =>
      chatId ? conversations?.find((item) => item.id === chatId) : undefined,
    [chatId, conversations]
  );
  const meId = useSelector(selectUserId);
  const isParticipant = useMemo(() => {
    if (
      chat?.data?.type === "chat" ||
      chat?.data.type === "entity_conversation"
    ) {
      return undefined;
    }
    return !!chat?.data?.users?.find((userId) => userId === meId);
  }, [meId, chat]);

  return {
    chat: chat?.data,
    isParticipant,
    setActiveChat: (id: string, navigate = true) => {
      if (navigate) {
        navigateToPage(
          "chatsActiveChat",
          { activeChatId: id },
          { replacePathOnly: true }
        );
      }
      dispatch(setActiveChatId(id));
    },
  };
};

export default useActiveChat;
