import { PlainButton, Popover } from "@jugl-web/ui-components/cross-platform";
import { useAppVariant, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { getTimezone } from "countries-and-timezones";
import { ReactComponent as RemoveIcon } from "../../assets/remove.svg";
import { ReactComponent as TimezoneIcon } from "../../assets/timezone.svg";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";
import { TaskDueDateFormScreenToParametersMap } from "../../types";
import { TimezoneList } from "../TimezoneList/TimezoneList";
import { TriggerButtonBase } from "../TriggerButtonBase";

export const TimezoneListTrigger = () => {
  const { formConfig, selectedDate, selectedTimezone, setSelectedTimezone } =
    useTaskDueDateFormContext();

  const { transitionTo } =
    useScreenTransitionManager<TaskDueDateFormScreenToParametersMap>();

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const selectedTimezoneLabel = (() => {
    const matchingTimezone = getTimezone(selectedTimezone);

    if (matchingTimezone) {
      return matchingTimezone.name.replaceAll("_", " ");
    }

    return t({ id: "common.unknown", defaultMessage: "Unknown" });
  })();

  return (
    <Popover
      placement="bottom-end"
      isDisabled={isMobile}
      className="w-[315px] p-3"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          as={TriggerButtonBase}
          ref={triggerRef}
          isDisabled={!selectedDate}
          isFocused={isOpen}
          label={t({ id: "tasks-page.timezone", defaultMessage: "Timezone" })}
          value={selectedTimezoneLabel}
          Icon={TimezoneIcon}
          customEndSlot={
            selectedTimezone !== formConfig.defaultTimezone ? (
              <PlainButton
                onClick={(event) => {
                  event.stopPropagation();
                  setSelectedTimezone(formConfig.defaultTimezone);
                }}
              >
                <RemoveIcon />
              </PlainButton>
            ) : undefined
          }
          onClick={() => {
            if (isMobile) {
              transitionTo({ name: "timezoneList" });
            }
          }}
        />
      )}
    >
      {({ onClose }) => <TimezoneList onAfterSelect={onClose} />}
    </Popover>
  );
};
