import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { useToast, useTranslations } from "@jugl-web/utils";
import { getFileSizeLabel } from "@jugl-web/utils/utils/files";
import useEntity from "@web-src/features/app/hooks/useEntity";
import { RootState } from "@web-src/store";
import format from "date-fns-tz/format";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  DirectoryListItem,
  PermissionsMode,
} from "@jugl-web/rest-api/drive/types";
import InfiniteScroll from "@web-src/components/InfiniteScroll";
import { displayIcon, FilePermissionType } from "@web-src/utils/displayIcon";
import { isImageType, isoToLocalDate } from "@web-src/utils/helper";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { useParams } from "react-router-dom";
import { useMe } from "@web-src/features/app/hooks/useMe";
import { LoadingSpinner } from "@jugl-web/ui-components/cross-platform";
import ModalInput from "../ModalInput";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as ChevronLeftIcon } from "./assets/chevron-left.svg";
import { ReactComponent as FolderIcon } from "./assets/folder.svg";
import { useDrive } from "../../../../../../hooks/useDrive";
import { calculatedSize, matchesInsensitive } from "../../../../utils";
import { ReactComponent as NormalFolder } from "./assets/normalFolder.svg";

const MoveFileModal: React.FC<{
  onCreateFolder: () => void;
  onClose: () => void;
  folders: DirectoryListItem[];
  visited: { id: string; name: string }[];
  setVisited: Dispatch<SetStateAction<{ id: string; name: string }[]>>;
  isLoading: boolean;
  fileElement: { id: string; name: string };
}> = ({
  onCreateFolder,
  onClose,
  folders,
  visited,
  setVisited,
  isLoading,
  fileElement,
}) => {
  const { t } = useTranslations();
  const { driveApi } = useRestApiProvider();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState<DirectoryListItem[]>([]);
  const [moveFile] = driveApi.useMoveFileMutation();
  const { toast } = useToast({ variant: "web" });
  const { me } = useMe();
  const params = useParams();
  const { entity } = useEntity();
  const visitedGeneral = useSelector((state: RootState) => state.drive.visited);
  const { rootDirectory } = useDrive();
  const handleMove = async () => {
    const res = await moveFile({
      entityId: entity?.id || "",
      id: fileElement.id,
      name: fileElement.name,
      ...(params.activeChatId && { workspace_id: params.activeChatId }),
      parent_id: visited[visited.length - 1].id,
    });
    if ("data" in res && res.data) {
      toast(
        t(
          {
            id: "drive-page.file-successfully-moved",
            defaultMessage: "File {name} successfully moved to {location}",
          },
          {
            name: fileElement.name,
            location: visited.at(-1)?.name,
          }
        )
      );
      onClose();
    }
  };
  useEffect(() => {
    setFilteredData(
      folders.filter((element) => matchesInsensitive(element.name, search))
    );
  }, [search, folders]);

  const fileType = useCallback(
    (
      accessType: PermissionsMode,
      restricted: { id: string }[]
    ): FilePermissionType => {
      if (accessType === PermissionsMode.public) return "public";
      if (restricted.filter((el) => el.id !== me?.id).length === 0)
        return "private";
      return "shared";
    },
    [me?.id]
  );
  return (
    <div className="relative mx-auto flex h-full w-[90%] flex-col pt-5">
      <ModalInput
        onChange={setSearch}
        placeholder={t({
          id: "common.search-with-ellipsis",
          defaultMessage: "Search...",
        })}
        hasIcon
      />
      <div className="my-10 flex items-center">
        <FolderIcon />
        <button
          type="button"
          className="ml-3 cursor-pointer border-none bg-transparent"
          onClick={() =>
            setVisited([
              { id: rootDirectory?.current_dir_id || "", name: "root" },
            ])
          }
        >
          <Text variant="body1">
            {t({
              id: "drive-page.drive",
              defaultMessage: "Drive",
            })}
          </Text>
        </button>
        {visited.map((element, index) => {
          if (index === 0) return null;
          return (
            <div key={element.id} className="flex items-center">
              <ChevronLeftIcon />
              <button
                className="border-none bg-transparent"
                type="button"
                onClick={() => {
                  const array = [...visited];
                  array.splice(index + 1);
                  setVisited(array);
                }}
              >
                <Text variant="body1" className="cursor-pointer">
                  {element.name}
                </Text>
              </button>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        className="bg-grey-200 text-grey-900 flex min-h-[56px] w-full cursor-pointer items-center justify-center rounded-lg border-none uppercase"
        onClick={onCreateFolder}
      >
        {t({
          id: "drive-page.create-new-folder",
          defaultMessage: "Create new folder",
        })}
        <AddIcon />
      </button>
      <div className="bg-grey-200 my-5 h-px w-full" />
      {isLoading && filteredData.length === 0 ? (
        <div className="w-full text-center">
          <LoadingSpinner />
        </div>
      ) : folders.length === 0 ? (
        <div className="flex w-full flex-col items-center pt-5">
          <NormalFolder />
          <Text variant="h4">
            {t({
              id: "drive-page.folder-is-empty",
              defaultMessage: "Folder is empty",
            })}
          </Text>
        </div>
      ) : folders ? (
        <InfiniteScroll className="flex flex-wrap gap-2" disabled={isLoading}>
          {filteredData.map((el) => (
            <div
              key={el.id}
              className={`bg-grey-200 flex h-[72px] w-full ${
                el.type === "dir" ? "cursor-pointer" : ""
              } items-center rounded-xl`}
              onClick={
                el.type === "dir"
                  ? () =>
                      setVisited((prev) => [
                        ...prev,
                        { id: el.id, name: el.name },
                      ])
                  : undefined
              }
            >
              <div className="ml-5">
                {el.type === "dir" ? (
                  <img
                    src={displayIcon(
                      "folder",
                      fileType(el.permission.mode, el.permission.users)
                    )}
                    className="h-[40px] w-[40px] rounded-lg object-cover"
                    alt=""
                  />
                ) : el.preview_available || isImageType(el?.mime_type) ? (
                  <img
                    src={el.preview_url}
                    className="h-[40px] w-[40px] rounded-lg object-cover"
                    alt=""
                  />
                ) : (
                  <img
                    src={displayIcon(el.preview_url || "", false)}
                    className="h-[40px] w-[40px] rounded-lg object-cover"
                    alt=""
                  />
                )}
              </div>
              <div className="ml-5 flex h-12 w-[235px] flex-col justify-center">
                <Text variant="body2" className="truncate">
                  {el.type === "dir" ? el.name : `${el.name}`}
                </Text>
                <Text variant="body2" className="text-grey-900 mt-2 truncate">
                  {el.type === "dir"
                    ? `${t(
                        {
                          id: "drive-page.documents-count",
                          defaultMessage:
                            "{count} {count, plural, one {document} other {documents}}",
                        },
                        {
                          count: el.doc_count || 0,
                        }
                      )} • ${calculatedSize(el.size || 0)}`
                    : `${format(
                        isoToLocalDate(el.updated_at),
                        "MMM dd, yyyy"
                      )} • ${getFileSizeLabel(el.size)}`}
                </Text>
              </div>
              {el.type === "dir" && <ArrowIcon />}
            </div>
          ))}
        </InfiniteScroll>
      ) : null}
      <div className="sticky bottom-0 mt-auto flex min-h-[80px] w-full items-center justify-items-center gap-5 bg-white">
        <button
          type="button"
          className="bg-grey-200 text-grey-900 h-[50px] grow cursor-pointer rounded-lg border-none uppercase"
          onClick={onClose}
        >
          {t({
            id: "common.cancel",
            defaultMessage: "Cancel",
          })}
        </button>
        {visited.length > 1 &&
          visited[visited.length - 1].id !==
            visitedGeneral[visitedGeneral.length - 1].id && (
            <Button className="grow uppercase" onClick={handleMove}>
              {t({
                id: "common.move",
                defaultMessage: "Move",
              })}
            </Button>
          )}
      </div>
    </div>
  );
};

export default MoveFileModal;
