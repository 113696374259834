import { InternalExportFormParams } from "./types";

type DialogVariant = NonNullable<InternalExportFormParams["view"]>;
type DialogSection =
  | "dataRange"
  | "filterBy"
  | "additional"
  | "teamMembers"
  | "boards"
  | "status"
  | "label";

export const TASKS_EXPORT_DIALOG_SECTIONS: Record<
  DialogVariant,
  DialogSection[]
> = {
  board: ["dataRange", "filterBy", "additional", "status", "label"],
  my: ["dataRange", "filterBy", "additional", "boards", "status", "label"],
  team: [
    "dataRange",
    "filterBy",
    "additional",
    "teamMembers",
    "status",
    "label",
  ],
};
