import { useRestApiProvider } from "@jugl-web/rest-api";
import { BaseTask } from "@jugl-web/rest-api/tasks";
import { Alert } from "@jugl-web/ui-components/cross-platform";
import { useToast, useTranslations } from "@jugl-web/utils";
import { FC } from "react";

interface TaskArchiveConfirmationDialogProps {
  isOpen: boolean;
  entityId: string;
  task: BaseTask;
  onClose: () => void;
  onSuccess?: () => void;
}

export const TaskArchiveConfirmationDialog: FC<
  TaskArchiveConfirmationDialogProps
> = ({ isOpen, entityId, task, onClose, onSuccess }) => {
  const { tasksApi } = useRestApiProvider();
  const [archiveTask] = tasksApi.useArchiveTaskMutation();

  const { toast } = useToast();
  const { t } = useTranslations();

  const onConfirm = async () => {
    const response = await archiveTask({ entityId, taskId: task.id });

    if (!("error" in response)) {
      toast(
        t({
          id: "feedback.task-successfully-archived",
          defaultMessage: "Task has been successfully archived",
        })
      );

      onSuccess?.();
    }
  };

  return (
    <Alert
      isOpen={isOpen}
      title={t({
        id: "tasks-page.archive-task",
        defaultMessage: "Archive task",
      })}
      content={t(
        {
          id: "tasks-page.do-you-really-want-to-archive-task",
          defaultMessage:
            "Do you really want to archive task <b>{taskName}</b>? Task will be stored in Archive for 90 days",
        },
        {
          b: (chunks: (string | JSX.Element)[]) => (
            <span className="text-primary-800 font-medium">{chunks}</span>
          ),
          taskName: task.name,
        }
      )}
      buttons={[
        {
          text: t({ id: "common.cancel", defaultMessage: "Cancel" }),
          role: "close",
        },
        {
          text: t({ id: "common.archive", defaultMessage: "Archive" }),
          color: "tertiary",
          onClick: (_, actions) => {
            onConfirm();
            actions.closeAlert();
          },
        },
      ]}
      onRequestClose={onClose}
    />
  );
};
