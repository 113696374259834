import { autoUpdate, flip, shift, useFloating } from "@floating-ui/react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  ActionType,
  DirectoryListItem,
  DriveItemDisplayType,
  FileType,
} from "@jugl-web/rest-api/drive/types";
import { MultiSectionLayout } from "@jugl-web/ui-components/cross-platform";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { useMarkModuleAsRead } from "@web-src/hooks/useMarkModuleAsRead";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { OnboardingProgressBar } from "@web-src/modules/preferences/components/OnboardingProgressBar";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { RootState } from "@web-src/store";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import DeleteIcon from "./assets/bin.svg";
import CopyIcon from "./assets/copy.svg";
import EditIcon from "./assets/edit.svg";
import { ReactComponent as ListIcon } from "./assets/list.svg";
import { ReactComponent as TilesIcon } from "./assets/tiles.svg";
import { ReactComponent as TrailingIcon } from "./assets/trailing.svg";
import { DisplayFolders } from "./components/DisplayFolders";
import DriveButton from "./components/DriveButton";
import DriveDeleteModal from "./components/DriveDeleteModal";
import DriveForm from "./components/DriveForm";
import DriveHeader from "./components/DriveHeader";
import DriveInput from "./components/DriveInput";
import DriveMenu from "./components/DriveMenu";
import { actionSidebar, setVisited } from "./driveSlice";
import { useDeleteObject } from "./hooks/useDeleteObject";
import { DriveProvider, useDriveProvider } from "./providers";

const DrivePageContent: React.FC = () => {
  const dispatch = useDispatch();

  const { driveHook } = useDriveProvider();
  const { rootDirectory, data, isFetching } = driveHook;

  const { t } = useTranslations();
  const fileAction = useSelector(
    (state: RootState) => state.drive.objectAction
  );
  const displayObject = useSelector(
    (state: RootState) => state.drive.displayType
  );
  const visited = useSelector((state: RootState) => state.drive.visited);
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  const { entity } = useEntitySelectedProvider();
  const { deleteObject } = useDeleteObject();
  const [deleteDirectoryModalOpen, setDeleteDirectoryModalOpen] =
    useState(false);
  const { isOnboardingActive } = useOnboarding();

  const [display, setDisplay] = useState<DriveItemDisplayType>(
    DriveItemDisplayType.list
  );

  const [openedFile, setOpenedFile] = useState<DirectoryListItem | null>(null);

  useEffect(() => {
    if (visited.length > 0) {
      setDisplay(
        displayObject[visited[visited.length - 1].id] ||
          DriveItemDisplayType.list
      );
    }
    // eslint-disable-next-line
  }, [visited]);

  useEffect(() => {
    if (!data || visited.length !== 0) return;
    dispatch(
      setVisited([
        {
          id: searchParams.get("directoryId") || "",
          name: searchParams.get("directoryName") || "",
        },
      ])
    );
  }, [data, visited, dispatch, searchParams]);

  // useEffect(() => {
  //   if (visited.length > 0)
  //     dispatch(change([visited[visited.length - 1].id, display]));
  //   // eslint-disable-next-line
  // }, [visited, display]);

  const initialRender = useRef(true);
  useEffect(() => {
    if (!initialRender.current) {
      return;
    }
    initialRender.current = false;
    if (visited.length) return;
    const path = searchParams.get("path");
    const fullPath = searchParams.get("full_path");
    if (path && fullPath) {
      const basedPathVisited = fullPath
        .split("/")
        .slice(2)
        .reduce((acc: { name: string; id: string }[], el, idx) => {
          if (el.includes(".")) {
            return acc;
          }
          acc.push({
            id: path
              .split(".")
              .slice(0, idx + 3)
              .join("."),
            name: el,
          });
          return acc;
        }, []);

      dispatch(
        setVisited([
          {
            id: rootDirectory?.current_dir_id || "",
            name: "root",
          },
          ...basedPathVisited,
        ])
      );
    } else if (
      searchParams.get("directoryId") &&
      searchParams.get("directoryName")
    ) {
      dispatch(
        setVisited([
          {
            id: rootDirectory?.current_dir_id || "",
            name: "root",
          },
          {
            id: searchParams.get("directoryId") || "",
            name: searchParams.get("directoryName") || "",
          },
        ])
      );
    } else {
      dispatch(
        setVisited([
          {
            id: rootDirectory?.current_dir_id || "",
            name: "root",
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [dispatch, searchParams]);

  useMarkModuleAsRead({ entityId: entity?.id, module: "drive" });

  const { refs, floatingStyles } = useFloating({
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });

  const { toast } = useToast({ variant: "web" });

  const handleCopyLink = useCallback(() => {
    if (!data?.short_url) return;
    navigator.clipboard.writeText(data.short_url);
    toast(
      t(
        {
          id: "drive-page.link-was-copied-to-clipboard",
          defaultMessage: "{type} link was copied to clipboard",
        },
        {
          type: "Folder",
        }
      )
    );
  }, [data, toast, t]);

  const displayFileMenuElements = [
    {
      id: "edit",
      icon: EditIcon,
      text: t({
        id: "library-page.edit-folder",
        defaultMessage: "Edit folder",
      }),
      onClick: () =>
        dispatch(
          actionSidebar({
            id: data?.current_dir_id,
            type: FileType.dir,
            name: data?.current_dir_name,
            allowed_users: data?.current_dir_permission.users,
            action: "edit",
          })
        ),
    },
    {
      id: "copy-link",
      icon: CopyIcon,
      text: t({
        id: "common.copy-link",
        defaultMessage: "Copy link",
      }),
      onClick: handleCopyLink,
    },
    {
      id: "delete",
      icon: DeleteIcon,
      text: t({
        id: "common.delete",
        defaultMessage: "Delete",
      }),
      onClick: () => setDeleteDirectoryModalOpen(true),
    },
  ];

  const options = (
    <Menu>
      <div className="relative" ref={refs.setReference}>
        <MenuButton as={DriveButton} Icon={TrailingIcon} />
        <MenuItems
          className="absolute z-10 mt-2 flex flex-col rounded-lg bg-white shadow-2xl"
          ref={refs.setFloating}
          style={{ ...floatingStyles }}
        >
          {displayFileMenuElements.map((el) => (
            <MenuItem key={el.id}>
              <div
                role="button"
                tabIndex={0}
                className="flex h-[40px] w-[132px] cursor-pointer items-center gap-3"
                onClick={() => {
                  el.onClick();
                }}
                onKeyDown={() => {
                  el.onClick();
                }}
              >
                <img src={el.icon} className="ml-4" alt="" />
                {el.text}
              </div>
            </MenuItem>
          ))}
        </MenuItems>
      </div>
    </Menu>
  );

  const directoryId = data?.current_dir_id;

  useEffect(() => setSearch(""), [directoryId]);

  useEffect(() => {
    if (!data) return;
    const fileId = searchParams.get("openedFile");
    const fileToOpen = data.data.find((file) => file.id === fileId);
    if (fileToOpen) {
      setOpenedFile(fileToOpen);
    }
  }, [data, searchParams]);

  return (
    <>
      <MultiSectionLayout
        header={
          <DriveHeader
            entityId={entity?.id}
            openedFile={openedFile}
            onFolderClick={() => {
              setOpenedFile(null);
            }}
          />
        }
      >
        <div className="flex h-full flex-col">
          <div
            className={cx(
              "bg-grey-100 flex h-full flex-col overflow-y-auto",
              !openedFile && "p-8"
            )}
          >
            {!openedFile && (
              <div className="mb-10 flex items-center justify-between">
                <div className="flex gap-3">
                  {!true && (
                    <DriveButton
                      Icon={
                        display === DriveItemDisplayType.list
                          ? ListIcon
                          : TilesIcon
                      }
                      onClick={() =>
                        setDisplay(
                          display === DriveItemDisplayType.tiles
                            ? DriveItemDisplayType.list
                            : DriveItemDisplayType.tiles
                        )
                      }
                    />
                  )}
                  <DriveInput value={search} onChange={setSearch} />
                  {visited.length > 1 &&
                    visited[visited.length - 1].id === directoryId &&
                    !data?.current_dir_meta?.sys_dir &&
                    options}
                </div>
                {!isFetching && !data?.current_dir_meta?.sys_dir ? (
                  <DriveMenu />
                ) : null}
              </div>
            )}
            <div className={openedFile ? "h-full w-full" : "flex-1"}>
              <DisplayFolders
                search={search}
                display={display}
                openedFile={openedFile}
                onOpenFile={setOpenedFile}
                readOnly={data?.current_dir_meta?.sys_dir}
              />
            </div>
          </div>
          {isOnboardingActive && <OnboardingProgressBar />}
        </div>
      </MultiSectionLayout>
      {fileAction.action === "edit" && (
        <DriveForm
          isOpen={fileAction.action === "edit"}
          onRequestClose={() =>
            dispatch(
              actionSidebar({
                id: undefined,
                type: undefined,
                name: undefined,
                allowed_users: undefined,
                action: undefined,
              })
            )
          }
          parentDirectory={directoryId || ""}
          type={ActionType.edit}
        />
      )}
      {fileAction.action === "rename" && (
        <DriveForm
          isOpen={fileAction.action === "rename"}
          onRequestClose={() =>
            dispatch(
              actionSidebar({
                id: undefined,
                type: undefined,
                name: undefined,
                allowed_users: undefined,
                action: undefined,
              })
            )
          }
          parentDirectory={directoryId || ""}
          type={ActionType.rename}
        />
      )}
      {deleteDirectoryModalOpen && (
        <DriveDeleteModal
          name={visited[visited.length - 1].name}
          type="directory"
          onClick={() => {
            dispatch(
              setVisited([
                {
                  id: rootDirectory?.current_dir_id || "",
                  name: "root",
                },
              ])
            );
            deleteObject("directory", directoryId || "");
          }}
          onClose={() => setDeleteDirectoryModalOpen(false)}
          isOpen={deleteDirectoryModalOpen}
        />
      )}
    </>
  );
};

const DrivePage: React.FC = () => (
  <DriveProvider>
    <DrivePageContent />
  </DriveProvider>
);

export default DrivePage;
