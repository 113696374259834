export const downloadFile = async (source: string, name: string) => {
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = source;
  downloadLink.style.display = "none";
  downloadLink.target = "_self";
  downloadLink.download = name;
  downloadLink.click();
  downloadLink.remove();
};
