import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  PageLoaderFull,
  InteractiveContainer,
} from "@jugl-web/ui-components/cross-platform";
import { Country } from "@jugl-web/ui-components/cross-platform/PhoneInput/types";
import { AvatarSelectSize, CountryPicker } from "@jugl-web/ui-components/web";
import { cx, useToast, useTranslations } from "@jugl-web/utils";
import { ResourceMainSettingsItem } from "@web-src/modules/common/components";
import {
  LeaveEntityConfirmationDialogsFlow,
  LeaveEntityConfirmationDialogsFlowHandle,
} from "@web-src/modules/entities/components/LeaveEntityConfirmationDialogsFlow";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import countries from "countries-phone-masks";
import format from "date-fns/format";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { WorkspaceEditableName } from "@web-src/modules/workspace/pages/WorkspaceSettingsPage/components/WorkspaceEditableName";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import { ReactComponent as EmailIcon } from "./assets/email.svg";
import { ReactComponent as LeaveIcon } from "./assets/leave.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { WorkspaceEmailVerificationDialog } from "./components/WorkspaceEmailVerificationDialog";
import { SettingsTabsLayout } from "../../components/SettingsTabsLayout/SettingsTabsLayout";

const WorkspaceSettingsPage: React.FC = () => {
  const { t } = useTranslations();
  const { toast } = useToast({ variant: "web" });
  const { entitiesApi } = useRestApiProvider();
  const { entity } = useEntitySelectedProvider();
  const [avatar, setAvatar] = useState<File>();
  const [emailVerificationDialogIsOpen, setEmailVerificationDialogIsOpen] =
    useState(false);

  const leaveEntityConfirmationDialogsFlowRef =
    useRef<LeaveEntityConfirmationDialogsFlowHandle | null>(null);

  const [updateEntity, { isLoading }] = entitiesApi.useEditEntityMutation();

  const { selectFile } = useFileSelect(avatarFileSelectConfig);

  const handleAvatarChange = async (file?: File) => {
    const response = await updateEntity({
      data: {},
      entityId: entity.id,
      displayImg: file,
    });
    if (response && "data" in response) {
      setAvatar(file);
      toast(
        file
          ? t({
              id: "workspace-page.workspace-image-was-updated",
              defaultMessage: "Workspace image was updated",
            })
          : t({
              id: "workspace-page.workspace-image-was-removed",
              defaultMessage: "Workspace image was removed",
            })
      );
    }
  };

  const handleCopyWorkspaceIdToClipboard = useCallback(() => {
    navigator.clipboard.writeText(entity.uniqueId).then(() => {
      toast(
        t({
          id: "workspace-page.workspace-id-copied-to-clipboard",
          defaultMessage: "Workspace ID was copied to clipboard",
        })
      );
    });
  }, [toast, t, entity.uniqueId]);

  const handleCountryChange = async (country: Country) => {
    const response = await updateEntity({
      data: { country: country.iso },
      entityId: entity.id,
    });
    if (response && "data" in response) {
      toast(
        t({
          id: "workspace-page.workspace-country-was-updated",
          defaultMessage: "Workspace country was updated",
        })
      );
    }
  };

  const country = useMemo(
    () => countries.find((countryObj) => countryObj.iso === entity.country),
    [entity.country]
  );

  return (
    <SettingsTabsLayout selectedTab="settings">
      <PageLoaderFull isActive={isLoading} isTransparent />
      <div className="flex flex-col gap-10 p-12">
        <ResourceMainSettingsItem
          avatarSelectProps={{
            onSave: handleAvatarChange,
            size: AvatarSelectSize.xxl,
            value: avatar,
            defaultSrc: entity.logoImg || undefined,
            externalSelectFile: selectFile,
          }}
          title={<WorkspaceEditableName />}
          details={[
            <InteractiveContainer
              className="hover:bg-dark-100 group flex items-center gap-2 rounded-lg px-1 py-1.5 transition-colors"
              onClick={handleCopyWorkspaceIdToClipboard}
            >
              <span>
                {t(
                  {
                    id: "workspace-page.workspace-id",
                    defaultMessage: "Workspace ID: {workspaceId}",
                  },
                  { workspaceId: entity.uniqueId }
                )}
              </span>
              <CopyIcon className="opacity-0 transition-opacity group-hover:opacity-100" />
            </InteractiveContainer>,
            <span className="ml-1">
              {t(
                {
                  id: "workspace-page.created-on-date",
                  defaultMessage: "Created on: {date}",
                },
                {
                  date: format(
                    new Date(entity.__dto.inserted_at),
                    "MMM d, uuuu"
                  ),
                }
              )}
            </span>,
          ]}
          menuItems={[
            {
              id: "leave",
              label: t({
                id: "workspace-page.leave-workspace",
                defaultMessage: "Leave Workspace",
              }),
              icon: <LeaveIcon />,
              onSelect: (_, close) => {
                leaveEntityConfirmationDialogsFlowRef.current?.triggerLeaveEntityFlow(
                  entity
                );
                close();
              },
            },
            {
              id: "delete",
              label: t({
                id: "workspace-page.delete-workspace",
                defaultMessage: "Delete workspace",
              }),
              icon: <DeleteIcon />,
              onSelect: (_, close) => {
                leaveEntityConfirmationDialogsFlowRef.current?.triggerDeleteEntityFlow(
                  entity
                );
                close();
              },
            },
          ]}
        />
        <div className="flex items-center gap-10">
          <CountryPicker
            countryIso={country?.iso}
            onSelect={handleCountryChange}
          >
            <div className="group relative flex h-[260px] w-[288px] items-center justify-center rounded-xl bg-[#F7F8F9] p-6 outline-none duration-500 hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.12)]">
              <div className="flex flex-col items-center gap-3 duration-500 group-hover:mt-[-90px]">
                <div className="h-10 w-10">
                  <img
                    src={country?.flag}
                    className="h-full w-full"
                    alt={country?.name}
                  />
                </div>
                <div className="flex flex-col gap-[6px] text-center">
                  <span className="text-dark-800 font-medium">
                    {country?.name}
                  </span>
                  <span className="text-sm text-[#828282]">
                    {t({
                      id: "workspace-page.workspace-country",
                      defaultMessage: "Workspace country",
                    })}
                  </span>
                </div>
              </div>
              <div className="bg-primary absolute left-1/2 bottom-[50px] w-max -translate-x-1/2 rounded-lg py-3 px-6 text-sm font-semibold text-white opacity-0 duration-500 group-hover:opacity-100">
                {t({
                  id: "common.change",
                  defaultMessage: "Change",
                })}
              </div>
            </div>
          </CountryPicker>
          <InteractiveContainer
            onClick={() => setEmailVerificationDialogIsOpen(true)}
            className="group relative flex h-[260px] w-[288px] items-center justify-center rounded-xl bg-[#F7F8F9] p-6 outline-none duration-500 hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.12)]"
          >
            <div className="flex flex-col items-center gap-3 duration-500 group-hover:mt-[-90px]">
              <EmailIcon />
              <div className="flex flex-col gap-[6px] text-center">
                <span className="text-dark-800 font-medium">
                  {entity.billingEmail}
                </span>
                <span
                  className={cx(
                    "text-sm",
                    entity.isEmailVerified
                      ? "text-[#828282]"
                      : "text-gradients-danger"
                  )}
                >
                  {entity.isEmailVerified
                    ? t({
                        id: "workspace-page.workspace-email",
                        defaultMessage: "Workspace email",
                      })
                    : t({
                        id: "workspace-page.workspace-email-verification-needed",
                        defaultMessage: "Email verification needed",
                      })}
                </span>
              </div>
            </div>
            <div className="bg-primary absolute left-1/2 bottom-[50px] w-max -translate-x-1/2 rounded-lg py-3 px-6 text-sm font-semibold text-white opacity-0 duration-500 group-hover:opacity-100">
              {entity.isEmailVerified
                ? t({
                    id: "common.edit",
                    defaultMessage: "Edit",
                  })
                : t({
                    id: "common.verify",
                    defaultMessage: "Verify",
                  })}
            </div>
          </InteractiveContainer>
        </div>
      </div>
      <WorkspaceEmailVerificationDialog
        isOpen={emailVerificationDialogIsOpen}
        onRequestClose={() => setEmailVerificationDialogIsOpen(false)}
      />
      <LeaveEntityConfirmationDialogsFlow
        ref={leaveEntityConfirmationDialogsFlowRef}
      />
    </SettingsTabsLayout>
  );
};

export default WorkspaceSettingsPage;
