import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { MY_TASKS_SOURCE } from "@jugl-web/domain-resources/tasks";
import {
  TaskCard,
  useTaskCardAdapter,
} from "@jugl-web/domain-resources/tasks/components/TaskCard";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  DetailedTask,
  TaskChecklistSummaryItem,
  adaptDetailedTaskToPreviewTask,
} from "@jugl-web/rest-api/tasks";
import { Text } from "@jugl-web/ui-components/cross-platform";
import { SidebarDrawer } from "@jugl-web/ui-components/web";
import { cx, useTranslations } from "@jugl-web/utils";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { useNavigation } from "@web-src/modules/navigation/hooks/useNavigation";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { CheckListCard } from "../CheckListCard/CheckListCard";
import noCompletedItemsImage from "./assets/no-completed-items.png";

const TaskListSidebar: React.FC<{
  isOpen: boolean;
  date: string | undefined;
  onClose: () => void;
  tasksList?: {
    task_id: string;
    completed_on: string;
  }[];
  checkLists?: TaskChecklistSummaryItem[];
}> = ({ isOpen, date, onClose, tasksList, checkLists }) => {
  const [tasks, setTasks] = useState<DetailedTask[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { dateLocale } = useLanguage();
  const { t } = useTranslations();
  const { entityId } = useEntitySelectedProvider();
  const { tasksApi } = useRestApiProvider();
  const [getTask] = tasksApi.useLazyGetTaskQuery();
  const { navigateToPage } = useNavigation();
  const { getTaskCardProps } = useTaskCardAdapter({
    entityId,
    source: MY_TASKS_SOURCE,
  });

  const formattedDate =
    (date && format(new Date(date), "MMMM d, yyyy", { locale: dateLocale })) ||
    "";
  useEffect(() => {
    if (!tasksList || tasksList.length === 0) {
      return;
    }
    const getAllTasks = async () => {
      const taskser = await Promise.all(
        tasksList.map((element) =>
          getTask({
            taskId: element.task_id,
            entityId,
          }).then((res) => res.data)
        )
      );
      const filteredTasks = taskser.filter(
        (task) => task !== undefined
      ) as DetailedTask[];

      setTasks(filteredTasks);
    };
    getAllTasks();
  }, [tasksList, date, isOpen, getTask, entityId]);
  const groupedChecklists = useMemo(
    () =>
      checkLists?.reduce(
        (acc: { [key: string]: TaskChecklistSummaryItem[] }, curr) => {
          const key = curr?.task_id;
          if (!acc[key]) {
            acc[key] = [] as TaskChecklistSummaryItem[];
          }
          acc[key].push(curr);
          return acc;
        },
        {}
      ),
    [checkLists]
  );

  return (
    <SidebarDrawer
      isOpen={isOpen}
      title={`${t({
        id: "location-page.task-done",
        defaultMessage: "Task done",
      })}: ${formattedDate}`}
      onClose={onClose}
    >
      <SidebarDrawer.Content className="bg-dark-100 flex-col px-8 pt-8">
        <TabGroup selectedIndex={selectedTab} onChange={setSelectedTab}>
          <TabList className="mb-6 flex flex-row rounded-lg bg-white">
            <Tab className="flex w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
              <Text
                variant="body1"
                className="ui-selected:text-dark text-dark-500 ui-selected:border-primary border-r-0 border-t-0 border-l-0 border-b-[2px] border-solid border-transparent text-center "
              >
                {t({
                  id: "day-summary-cell-sidebar.checklists",
                  defaultMessage: "Checklists",
                })}
              </Text>
            </Tab>

            <Tab className="flex w-full cursor-pointer flex-row items-center justify-center gap-2 rounded-lg border-transparent bg-transparent px-2 py-1">
              <Text
                variant="body1"
                className="ui-selected:text-dark text-dark-500 ui-selected:border-primary border-r-0 border-t-0 border-l-0 border-b-[2px] border-solid border-transparent text-center "
              >
                {t({
                  id: "day-summary-cell-sidebar.tasks",
                  defaultMessage: "Tasks",
                })}
              </Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div
                className={cx(
                  "mt-14 flex flex-col items-center justify-center gap-4",
                  {
                    hidden: checkLists?.length !== 0,
                  }
                )}
              >
                <img src={noCompletedItemsImage} alt="no-items-indicator" />
                <Text
                  variant="body1"
                  className="text-dark text-center font-medium"
                >
                  {t({
                    id: "day-summary-cell-sidebar.no-checklists",
                    defaultMessage: "No Completed Checklists",
                  })}
                </Text>
              </div>
              {groupedChecklists &&
                Object.keys(groupedChecklists || {}).map((key) => (
                  <CheckListCard
                    key={key}
                    list={groupedChecklists[key] || []}
                  />
                ))}
            </TabPanel>
            <TabPanel>
              <div
                className={cx(
                  "mt-14 flex flex-col items-center justify-center gap-4",
                  {
                    hidden: tasks?.length !== 0,
                  }
                )}
              >
                <img src={noCompletedItemsImage} alt="no-items-indicator" />
                <Text
                  variant="body1"
                  className="text-dark text-center font-medium"
                >
                  {t({
                    id: "day-summary-cell-sidebar.no-tasks",
                    defaultMessage: "No Completed Tasks",
                  })}
                </Text>
              </div>
              <div className="flex flex-col gap-8">
                {tasks.map((task) => (
                  <TaskCard
                    key={task.id}
                    className="rounded-xl"
                    onClick={() => {
                      navigateToPage("tasksDetails", { taskId: task.id });
                    }}
                    {...getTaskCardProps(adaptDetailedTaskToPreviewTask(task), {
                      isUnread: false,
                    })}
                  />
                ))}
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </SidebarDrawer.Content>
    </SidebarDrawer>
  );
};

export default TaskListSidebar;
