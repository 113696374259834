import PrivateRoute from "@web-src/features/app/components/PrivateRoute";
import PublicRoute from "@web-src/features/app/components/PublicRoute";
import { PageRoute } from "@web-src/features/app/types";
import { useClaimOauthTokenMutation } from "@web-src/features/auth/authApi";
import { proceedLogin } from "@web-src/features/auth/authSlice";
import { isAppInDevMode } from "@web-src/modules/common/utils/isAppInDevMode";
import { rootNavigationPageConfig } from "@web-src/modules/navigation/root-config";
import { getEntityPagePath } from "@web-src/modules/navigation/utils/getEntityPagePath";
import { useAppDispatch } from "@web-src/store";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

const DebugSubscriptionPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugSubscriptionPage")
);
const DebugUserSettingsPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugUserSettingsPage")
);
const DebugMobileAppPage = lazy(
  () => import("@web-src/modules/debug/pages/DebugMobileAppPage")
);

// TODO: to remove
const TokenLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>("Logging in...");
  const [claimOAuthToken] = useClaimOauthTokenMutation();
  const login = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tkn = urlParams.get("tkn");
    if (!tkn) {
      setMessage("No token in get parameters");
      return;
    }
    try {
      const response = await claimOAuthToken({ tkn });
      if (!("data" in response) || !response.data) {
        setMessage("Error");
        return;
      }
      const { token, refreshToken } = response.data;
      if (!token) {
        setMessage("No x-auth-joiint return");
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dispatch(proceedLogin({ token, refreshToken: refreshToken! }));
    } catch (error) {
      try {
        setMessage(String(error));
      } catch {
        setMessage("Unknown error");
      }
    }
  }, [claimOAuthToken, dispatch]);

  useEffect(() => {
    login();
  }, [login]);

  return <div>{message}</div>;
};
// END TODO: to remove

const rootNavigationBasedRoutes = Object.values(rootNavigationPageConfig);

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Suspense>
      <Routes location={location} key={location.pathname}>
        {rootNavigationBasedRoutes.map((item, index) => (
          <Route
            key={+index}
            path={
              item.isPrivate && !item.isEntityOptional
                ? getEntityPagePath(item.path)
                : item.path
            }
            element={
              item.isPrivate ? (
                <PrivateRoute
                  component={item.element}
                  entityRequired={!item.isEntityOptional}
                  profileRequired={!item.isProfileOptional}
                  activeSubscriptionRequired={
                    !item.isActiveSubscriptionOptional
                  }
                  adminRequired={item.isAdminRequired}
                  subscriptionModuleRequired={item.subscriptionModuleRequired}
                />
              ) : (
                <PublicRoute component={item.element} />
              )
            }
          />
        ))}
        <Route
          path="/jugl-callback"
          element={<PublicRoute component={<TokenLogin />} />}
        />
        {isAppInDevMode() && (
          <>
            <Route
              path="/:entityId/debug/subscription"
              element={<PrivateRoute component={<DebugSubscriptionPage />} />}
            />
            <Route
              path="/:entityId/debug/user-settings"
              element={<PrivateRoute component={<DebugUserSettingsPage />} />}
            />
            <Route
              path="/:entityId/debug/mobile-app"
              element={<PrivateRoute component={<DebugMobileAppPage />} />}
            />
          </>
        )}
        <Route path="*" element={<Navigate to={`/${PageRoute.login}`} />} />
      </Routes>
    </Suspense>
  );
};
