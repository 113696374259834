import { ReactNode } from "react";
import { cx } from "@jugl-web/utils";
import { InteractiveContainer } from "@jugl-web/ui-components";

export const Tab = ({
  title,
  isSelected,
  className,
  onClick,
}: {
  title: ReactNode;
  isSelected: boolean;
  className?: string;
  onClick?: () => void;
}) => (
  <InteractiveContainer
    className={cx(
      "group flex w-full flex-col justify-between gap-3",
      isSelected ? "text-primary-800 font-medium" : " text-dark-700",
      className
    )}
    onClick={onClick}
  >
    <span className="font-secondary whitespace-nowrap px-4 pt-2 text-base">
      {title}
    </span>
    <div
      className={cx(
        "h-1 w-full rounded-lg transition-colors",
        isSelected ? "bg-primary" : "group-hover:bg-grey-300 bg-transparent"
      )}
    />
  </InteractiveContainer>
);
