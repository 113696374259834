import { useTranslations, ClickAwayListener } from "@jugl-web/utils";
import { InteractiveContainer, Menu, Pill } from "@jugl-web/ui-components";
import { useState, useMemo } from "react";
import { DatePicker } from "@jugl-web/ui-components/cross-platform/DatePicker";
import { Controller, Control } from "react-hook-form";
import {
  format,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  isSameDay,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useFloating, autoUpdate } from "@floating-ui/react-dom";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { Section } from "../Section/Section";
import { ReactComponent as BlueCheckIcon } from "./assets/blue-check.svg";
import { InternalExportFormParams } from "../../types";

export const DataRangeField: React.FC<{
  control: Control<InternalExportFormParams>;
  formParams: InternalExportFormParams;
}> = ({ control, formParams }) => {
  const { t } = useTranslations();

  const labels = {
    today: t({
      id: "tasks-export-dialog.today",
      defaultMessage: "Today",
    }),
    thisWeek: t({
      id: "tasks-export-dialog.this-week",
      defaultMessage: "This Week",
    }),
    thisMonth: t({
      id: "tasks-export-dialog.this-month",
      defaultMessage: "This Month",
    }),
    previousMonth: t({
      id: "tasks-export-dialog.previous-month",
      defaultMessage: "Previous Month",
    }),
    last3Months: t({
      id: "tasks-export-dialog.last-3-months",
      defaultMessage: "Last 3 Months",
    }),
    select: t({
      id: "tasks-export-dialog.select",
      defaultMessage: "Select..",
    }),
  };

  const [isHovering, setIsHovering] = useState(false);
  const [isDataPickerOpen, setIsDataPickerOpen] = useState(false);

  const [internalSelectedRange, setInternalSelectedRange] =
    useState<keyof typeof labels>("thisMonth");

  const [
    internalSelectedDatePickerRangeValue,
    setInternalDatePickerSelectedRangeValue,
  ] = useState<Date[]>();
  const { refs, floatingStyles } = useFloating({
    placement: "bottom-end",
    transform: false,
    whileElementsMounted: autoUpdate,
  });

  const dateString = useMemo(() => {
    if (!formParams.fromTo) return "";
    const [from, to] = formParams.fromTo.split(",");
    if (isSameDay(new Date(from), new Date(to))) {
      return format(new Date(from), "MMM dd, yyyy");
    }
    return `${format(new Date(from), "MMM dd")} - ${format(
      new Date(to),
      "MMM dd, yyyy"
    )}`;
  }, [formParams.fromTo]);

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Section
        isActive={isHovering}
        label={t({
          id: "tasks-export-dialog.data-range",
          defaultMessage: "Data Range",
        })}
        button={
          <Controller
            control={control}
            name="fromTo"
            render={({ field }) => (
              <div ref={refs.setReference}>
                <Menu
                  placement="bottom-end"
                  className="z-[100]"
                  renderTrigger={({ Trigger, triggerRef, isOpen }) => {
                    if (isOpen) {
                      setIsHovering(true);
                    } else {
                      setIsHovering(false);
                    }
                    return (
                      <Trigger
                        as={Pill}
                        ref={triggerRef}
                        label={
                          internalSelectedRange === "select" ? (
                            <>{dateString}</>
                          ) : (
                            labels[internalSelectedRange]
                          )
                        }
                        size="md"
                        startIcon={<CalendarIcon />}
                        className="cursor-pointer select-none"
                      />
                    );
                  }}
                  onSelect={(_item, _event, close) => {
                    if (_item.id !== "select") {
                      setInternalSelectedRange(_item.id as keyof typeof labels);
                      setInternalDatePickerSelectedRangeValue(undefined);
                    }
                    setIsHovering(false);
                    close();
                  }}
                  sections={[
                    [
                      {
                        id: "today",
                        label: labels.today,
                        onSelect: () => {
                          const from = zonedTimeToUtc(
                            startOfDay(new Date()),
                            "UTC"
                          ).toISOString();
                          const to = zonedTimeToUtc(
                            endOfDay(new Date()),
                            "UTC"
                          ).toISOString();

                          field.onChange(`${from},${to}`);
                        },
                        isSelected: internalSelectedRange === "today",
                        endSlot: internalSelectedRange === "today" && (
                          <BlueCheckIcon />
                        ),
                      },
                      {
                        id: "thisWeek",
                        label: labels.thisWeek,
                        onSelect: () => {
                          const from = zonedTimeToUtc(
                            startOfWeek(new Date()),
                            "UTC"
                          ).toISOString();
                          const to = zonedTimeToUtc(
                            endOfWeek(new Date()),
                            "UTC"
                          ).toISOString();

                          field.onChange(`${from},${to}`);
                        },
                        isSelected: internalSelectedRange === "thisWeek",
                        endSlot: internalSelectedRange === "thisWeek" && (
                          <BlueCheckIcon />
                        ),
                      },
                      {
                        id: "thisMonth",
                        label: labels.thisMonth,
                        onSelect: () => {
                          const from = zonedTimeToUtc(
                            startOfMonth(new Date()),
                            "UTC"
                          ).toISOString();
                          const to = zonedTimeToUtc(
                            endOfMonth(new Date()),
                            "UTC"
                          ).toISOString();

                          field.onChange(`${from},${to}`);
                        },
                        isSelected: internalSelectedRange === "thisMonth",
                        endSlot: internalSelectedRange === "thisMonth" && (
                          <BlueCheckIcon />
                        ),
                      },
                      {
                        id: "previousMonth",
                        label: labels.previousMonth,
                        onSelect: () => {
                          const from = zonedTimeToUtc(
                            startOfMonth(subMonths(new Date(), 1)),
                            "UTC"
                          ).toISOString();
                          const to = zonedTimeToUtc(
                            endOfMonth(subMonths(new Date(), 1)),
                            "UTC"
                          ).toISOString();

                          field.onChange(`${from},${to}`);
                        },
                        isSelected: internalSelectedRange === "previousMonth",
                        endSlot: internalSelectedRange === "previousMonth" && (
                          <BlueCheckIcon />
                        ),
                      },
                      {
                        id: "last3Months",
                        label: labels.last3Months,
                        onSelect: () => {
                          const from = zonedTimeToUtc(
                            startOfMonth(subMonths(new Date(), 2)),
                            "UTC"
                          ).toISOString();
                          const to = zonedTimeToUtc(
                            endOfMonth(new Date()),
                            "UTC"
                          ).toISOString();

                          field.onChange(`${from},${to}`);
                        },
                        isSelected: internalSelectedRange === "last3Months",
                        endSlot: internalSelectedRange === "last3Months" && (
                          <BlueCheckIcon />
                        ),
                      },
                      {
                        id: "select",
                        label: labels.select,
                        onSelect: () => setIsDataPickerOpen(true),
                        isSelected: internalSelectedRange === "select",
                        endSlot: <CalendarIcon />,
                      },
                    ],
                  ]}
                />
              </div>
            )}
          />
        }
      >
        {isDataPickerOpen && (
          <>
            <InteractiveContainer
              ref={refs.setFloating}
              style={floatingStyles}
              className="rounded-xl shadow-lg"
            >
              <ClickAwayListener onClickAway={() => setIsDataPickerOpen(false)}>
                <Controller
                  name="fromTo"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      onClose={() => {
                        setIsDataPickerOpen(false);
                      }}
                      className="z-50 w-[343px] rounded-xl py-6 px-4"
                      mode="range"
                      onSubmit={(date, date2) => {
                        if (!date) {
                          return;
                        }
                        setInternalDatePickerSelectedRangeValue(
                          date2 ? [date, date2] : [date]
                        );
                        setInternalSelectedRange("select");
                        const from = startOfDay(date).toISOString();
                        const to = date2
                          ? endOfDay(date2).toISOString()
                          : endOfDay(date).toISOString();
                        onChange(`${from},${to}`);
                      }}
                      showCancelButton
                      maxRangeInMonths={6}
                      initialDate={internalSelectedDatePickerRangeValue}
                    />
                  )}
                />
              </ClickAwayListener>
            </InteractiveContainer>
          </>
        )}
      </Section>
    </div>
  );
};
