import React from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import { Avatar, Pill } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useUserListBox } from "@jugl-web/domain-resources/users/hooks/useUserListBox";
import { ListBoxProps } from "@jugl-web/ui-components/cross-platform/ListBox";
import { HeadlessUserItem } from "@jugl-web/domain-resources/users/hooks/useHeadlessUsersList";
import { UserGeneralProfile } from "@jugl-web/domain-resources/users/components/UserGeneralProfile";
import { Controller, Control } from "react-hook-form";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";
import { Section } from "../Section/Section";
import { InternalExportFormParams } from "../../types";

export const TeamMembers: React.FC<{
  entityId: string;
  control: Control<InternalExportFormParams>;
  formParams: InternalExportFormParams;
}> = ({ entityId, control, formParams }) => {
  const { t } = useTranslations();

  const { getListBoxProps } = useUserListBox({
    entityId,
    onlyReportees: true,
  });

  const selectedItems =
    (formParams.user_ids && formParams.user_ids.split(",")) || [];

  const commonResourcePickerProps = {
    ...getListBoxProps({
      selectionBehavior: { mode: "multiple" },
      defaultSelectedIds: selectedItems,
    }),
  } satisfies ListBoxProps<HeadlessUserItem | null>;

  return (
    <Section
      label={t({
        id: "tasks-export-dialog.team-members   ",
        defaultMessage: "Team Members",
      })}
      button={
        <Controller
          control={control}
          name="user_ids"
          render={({ field }) => (
            <ResourcePickerPopover
              placement="bottom-start"
              className="w-[375px]"
              shouldCloseOnAddButtonClick
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  size="md"
                  className="cursor-pointer select-none"
                  ref={triggerRef}
                  as={Pill}
                  endIcon={<AddIcon />}
                  label={
                    selectedItems.length
                      ? t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })
                      : t({
                          id: "common.all",
                          defaultMessage: "All",
                        })
                  }
                />
              )}
              onSubmit={(items) => {
                field.onChange(items.join(","));
              }}
              {...commonResourcePickerProps}
            />
          )}
        />
      }
    >
      <div
        className={cx(
          "animate-fade-in flex flex-wrap items-center gap-2.5 pb-5",
          {
            "pb-0": !selectedItems.length,
          }
        )}
      >
        {selectedItems.map((userId) => {
          if (!userId) return null;
          return (
            <Controller
              key={userId}
              control={control}
              name="user_ids"
              render={({ field }) => (
                <UserGeneralProfile userId={userId} entityId={entityId}>
                  {({ safeProfile }) => (
                    <Pill
                      label={safeProfile.displayName}
                      size="md"
                      startIcon={
                        <Avatar
                          size="xs"
                          imageUrl={safeProfile.avatar}
                          username={safeProfile.displayName}
                        />
                      }
                      endIcon={
                        <RemoveIcon
                          onClick={() =>
                            field.onChange(
                              selectedItems
                                .filter((id) => id !== userId)
                                .join(",")
                            )
                          }
                          className="cursor-pointer"
                        />
                      }
                    />
                  )}
                </UserGeneralProfile>
              )}
            />
          );
        })}
      </div>
    </Section>
  );
};
