import { sortCollectionAlphabetically } from "@jugl-web/utils/sorting/sortCollectionAlphabetically";
import { useMemo } from "react";
import { Mention } from "../../common/components/RichTextarea";
import { useMultipleUserProfiles } from "../../users/hooks/useMultipleUserProfiles";
import { useUserGeneralProfile } from "../../users/hooks/useUserGeneralProfile";

export interface UseTaskMentionsOptions {
  entityId: string;
  meId: string;
  taskAssigneeIds: string[];
  taskCreatorId?: string;
}

export const useTaskMentions = ({
  entityId,
  meId,
  taskAssigneeIds,
  taskCreatorId,
}: UseTaskMentionsOptions) => {
  const { profile: creatorProfile } = useUserGeneralProfile({
    entityId,
    userId: taskCreatorId,
    skip: !taskCreatorId || meId === taskCreatorId,
  });

  const { profiles: assigneeProfiles } = useMultipleUserProfiles({
    entityId,
    userIds: taskAssigneeIds,
  });

  const mentions = useMemo<Mention[]>(() => {
    const assigneeMentions = assigneeProfiles.reduce<Mention[]>(
      (acc, profile) => {
        if (profile.id === meId) {
          return acc;
        }

        return [
          ...acc,
          {
            userId: profile.id,
            name: profile.displayName,
            imageUrl: profile.avatar || null,
          },
        ];
      },
      []
    );

    if (creatorProfile && meId !== taskCreatorId) {
      const isCreatorAnAssignee = taskAssigneeIds.some(
        (assigneeId) => assigneeId === taskCreatorId
      );

      if (!isCreatorAnAssignee) {
        assigneeMentions.push({
          userId: creatorProfile.id,
          name: creatorProfile.displayName,
          imageUrl: creatorProfile.avatar || null,
        });
      }
    }

    return sortCollectionAlphabetically(
      assigneeMentions,
      (mention) => mention.name
    );
  }, [assigneeProfiles, creatorProfile, meId, taskAssigneeIds, taskCreatorId]);

  return { mentions };
};
