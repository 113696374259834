import { DetailedTask } from "@jugl-web/rest-api/tasks";
import { Button } from "@jugl-web/ui-components/cross-platform/Button";
import { Tooltip } from "@jugl-web/ui-components/cross-platform/Tooltip";
import { TaskHoverableButton } from "@jugl-web/ui-components/cross-platform/tasks/TaskHoverableButton";
import { MobileDrawer } from "@jugl-web/ui-components/mobile/MobileDrawer";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { FC, useMemo, useState } from "react";
import Lottie from "react-lottie";
import { useTaskRoles } from "../../hooks/useTaskRoles";
import dangerAnimation from "./assets/danger-animation.json";
import { ReactComponent as LockIcon } from "./assets/lock.svg";
import { ReactComponent as WarnIcon } from "./assets/warn.svg";

interface TaskInfoAccessButtonProps {
  task: DetailedTask;
  entityId: string;
  meId: string;
  className?: string;
}

type TaskInfoAccessLevel = "view-only" | "partial" | "full";

export const TaskInfoAccessButton: FC<TaskInfoAccessButtonProps> = ({
  task,
  entityId,
  meId,
  className,
}) => {
  const [isMobileDialogOpen, setIsMobileDialogOpen] = useState(false);

  const { isTaskCreator, isTaskAssignee, isBoardMember } = useTaskRoles({
    task,
    entityId,
    meId,
  });

  const { t } = useTranslations();
  const { isMobile } = useAppVariant();

  const accessLevel = useMemo<TaskInfoAccessLevel>(() => {
    if (!isTaskCreator && !isTaskAssignee && !isBoardMember) {
      return "view-only";
    }

    if (task.board_id && !isBoardMember) {
      return "partial";
    }

    return "full";
  }, [isBoardMember, isTaskAssignee, isTaskCreator, task.board_id]);

  const title =
    accessLevel === "view-only"
      ? t({
          id: "tasks-page.view-only-access",
          defaultMessage: "View Only",
        })
      : t({
          id: "tasks-page.partial-access",
          defaultMessage: "Partial Access",
        });

  const message =
    accessLevel === "view-only"
      ? t({
          id: "tasks-page.view-only-access-description",
          defaultMessage:
            "Only task assignees and board members can edit task info 🙌",
        })
      : t({
          id: "tasks-page.partial-access-description",
          defaultMessage:
            "This task belongs to a board you are not a member of. Some task info cannot be edited by you 🔒",
        });

  if (accessLevel === "full") {
    return null;
  }

  return (
    <>
      <Tooltip
        isDisabled={isMobile}
        delay="none"
        className="bg-dark w-[252px] text-center"
        renderTrigger={({ ref, props }) => (
          <TaskHoverableButton
            ref={ref}
            className={cx("cursor-help px-2", className)}
            onClick={() => {
              if (isMobile) {
                setIsMobileDialogOpen(true);
              }
            }}
            {...props}
          >
            <span>{title}</span>
            {accessLevel === "view-only" ? <WarnIcon /> : <LockIcon />}
          </TaskHoverableButton>
        )}
      >
        {message}
      </Tooltip>
      <MobileDrawer
        header={{ title: "", hasCloseButton: false }}
        isOpen={isMobileDialogOpen}
        onClose={() => setIsMobileDialogOpen(false)}
      >
        <MobileDrawer.Content className="flex flex-col items-center px-9 pb-6 text-center">
          <Lottie
            options={{ animationData: dangerAnimation }}
            height={70}
            width={70}
          />
          <h2 className="font-secondary text-dark mt-2 mb-3 text-base font-medium leading-4">
            {title}
          </h2>
          <p className="text-dark-700 mt-0 mb-8 text-sm leading-[21px]">
            {message}
          </p>
          <Button
            variant="contained"
            color="primary"
            className="mb-8 h-10 max-w-[300px]"
            fullWidth
            onClick={() => setIsMobileDialogOpen(false)}
          >
            {t({
              id: "common.okay",
              defaultMessage: "Okay",
            })}
          </Button>
        </MobileDrawer.Content>
      </MobileDrawer>
    </>
  );
};
