import { TaskPropertyButton } from "@jugl-web/ui-components";
import { useTranslations } from "@jugl-web/utils";
import { FC } from "react";
import { FieldComponentProps } from "../../types";

type UserEmailFieldProps = FieldComponentProps<"userEmail">;

export const UserEmailField: FC<UserEmailFieldProps> = ({ email }) => {
  const { t } = useTranslations();

  const isFieldVisible = !!email;

  if (!isFieldVisible) {
    return null;
  }

  return (
    <TaskPropertyButton>
      {t(
        {
          id: "tasks-page.email-with-value",
          defaultMessage: "Email: {email}",
        },
        { email }
      )}
    </TaskPropertyButton>
  );
};
