import { TasksSource } from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError } from "@jugl-web/utils";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useTaskListPreferences } from "../hooks/useTaskListPreferences";
import { TaskView, useTasksViews } from "../hooks/useTasksViews";

interface TaskViewsContextValue {
  accessibleViews: TaskView[];
  selectedView: TaskView;
  selectView: (viewId: string) => void;
}

const TaskViewsContext = createContext<TaskViewsContextValue | null>(null);

interface TaskViewsProviderProps {
  children: ReactNode;
  entityId: string;
  source: TasksSource;
}

export const TaskViewsProvider: FC<TaskViewsProviderProps> = ({
  children,
  entityId,
  source,
}) => {
  const { accessibleViews, getViewById } = useTasksViews({ entityId, source });

  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({
      entityId,
      source,
    });

  const selectedView = useMemo(
    () => getViewById(taskListPreferences.kanbanViewId),
    [getViewById, taskListPreferences.kanbanViewId]
  );

  const selectView = useCallback(
    (viewId: string) => {
      updateTaskListPreference("kanbanViewId", viewId);
    },
    [updateTaskListPreference]
  );

  const contextValue: TaskViewsContextValue = useMemo(
    () => ({ accessibleViews, selectedView, selectView }),
    [accessibleViews, selectedView, selectView]
  );

  return (
    <TaskViewsContext.Provider value={contextValue}>
      {children}
    </TaskViewsContext.Provider>
  );
};

export const useTaskViewsContext = () => {
  const context = useContext(TaskViewsContext);

  if (!context) {
    throw new HookOutOfContextError("useTaskViewsContext", "TaskViewsContext");
  }

  return context;
};
