import { ExportedTaskModel } from "@jugl-web/rest-api/tasks/models/ExportedTask";
import { useCallback } from "react";
import { Cell } from "write-excel-file";
import {
  InternalTaskCustomField,
  TaskDefaultStatus,
  TaskPriority,
} from "@jugl-web/rest-api/tasks";
import { TASK_ORDER_EMAIL_ID } from "@jugl-web/utils/consts";
import { ExportedTaskChecklist } from "@jugl-web/rest-api/tasks/models/common-types/ExportedTaskChecklist";
import { useTranslations } from "@jugl-web/utils";
import { PreviewAttachmentDetails } from "../../../../files/types";
import {
  formatDate,
  formatUserName,
  formatUsersList,
  parseMention,
} from "./utils";
import { useTaskPriorities } from "../../useTaskPriorities";
import { useTaskStatuses } from "../../useTaskStatuses";

const nameCellStyle: Cell = {
  backgroundColor: "#1A2023",
  fontWeight: "bold",
  color: "#ffffff",
  fontSize: 20,
  alignVertical: "center",
};

const headerCellStyle: Cell = {
  height: 20,
  alignVertical: "center",
  wrap: true,
  backgroundColor: "#ECEFF0",
  borderStyle: "medium",
  borderColor: "#D0D0D0",
  color: "#1A2023",
  fontWeight: "bold",
};

const cellStyle: Cell = {
  alignVertical: "center",
  color: "#1A2023",
  wrap: true,
};

const highlightedCellStyle: Cell = {
  ...cellStyle,
  color: "#ffffff",
  fontWeight: "bold",
};

const taskPriorityColorMap = {
  [TaskPriority.low]: "#22C59E",
  [TaskPriority.medium]: "#F2994A",
  [TaskPriority.high]: "#EA5353",
};

export const useExportedTableCellGenerator = ({
  entityId,
}: {
  entityId: string;
}) => {
  const { getPriorityDetailsById } = useTaskPriorities();
  const { getStatusDetailsById } = useTaskStatuses({ entityId });
  const { t } = useTranslations();

  const generateNameCell = useCallback(
    (name: string): Cell => ({
      value: name,
      ...nameCellStyle,
    }),
    []
  );

  const generateOrderIDCell = useCallback(
    ({ order_id: orderId }: ExportedTaskModel): Cell => ({
      value: orderId || "",
      ...cellStyle,
    }),
    []
  );

  const generateHeaderCell = useCallback(
    (name: string): Cell => ({
      value: name,
      ...headerCellStyle,
    }),
    []
  );

  const generateTitleCell = useCallback(
    ({ name }: ExportedTaskModel): Cell => ({
      value: name,
      ...cellStyle,
      fontWeight: "bold",
    }),
    []
  );

  const generateDueDateCell = useCallback(
    ({ due_at: dueAt }: ExportedTaskModel): Cell => ({
      value: dueAt ? formatDate(dueAt) : "",
      ...cellStyle,
    }),
    []
  );

  const generateAssigneesCell = useCallback(
    ({ assignees }: ExportedTaskModel): Cell => ({
      value: assignees.length ? formatUsersList(assignees) : "",
      ...cellStyle,
    }),
    []
  );

  const generateLabelCell = useCallback(
    ({ label }: ExportedTaskModel): Cell => ({
      value: label?.text || "",
      backgroundColor: label?.color,
      ...highlightedCellStyle,
    }),
    []
  );

  const generateStatusCell = useCallback(
    ({ status }: ExportedTaskModel): Cell => {
      const colors: {
        [key: string]: string;
      } = {
        [TaskDefaultStatus.notStarted]: "#2196F3",
        [TaskDefaultStatus.inProgress]: "#F2994A",
        [TaskDefaultStatus.completed]: "#B50870",
        custom: "#F33A9E",
      };

      const label =
        status === "not-started"
          ? t({ id: "common.new", defaultMessage: "New" })
          : getStatusDetailsById(status || "").label;
      return {
        value: `\n${status && colors[status] ? label : status || ""}\n`,
        backgroundColor: status ? colors[status] || colors.custom : undefined,
        ...highlightedCellStyle,
      };
    },
    [getStatusDetailsById, t]
  );

  const generatePriorityCell = useCallback(
    ({ priority }: ExportedTaskModel): Cell => {
      const hasPriority = priority !== TaskPriority.none;
      return {
        value: hasPriority ? getPriorityDetailsById(priority).shortLabel : "",
        backgroundColor: hasPriority
          ? taskPriorityColorMap[priority]
          : undefined,
        ...highlightedCellStyle,
      };
    },
    [getPriorityDetailsById]
  );

  const generateCustomerCell = useCallback(
    ({ customer }: ExportedTaskModel): Cell => ({
      value: customer ? formatUserName(customer) : "",
      ...cellStyle,
    }),
    []
  );

  const generateBoardCell = useCallback(
    ({ board }: ExportedTaskModel): Cell => ({
      value: board?.name || "",
      backgroundColor: board?.color,
      ...highlightedCellStyle,
    }),
    []
  );

  const generateAttachmentsCell = useCallback(
    ({ attachments }: ExportedTaskModel): Cell => ({
      value: attachments.length
        ? attachments
            .map((attachment) => {
              const attachmentDetails: PreviewAttachmentDetails = {
                id: attachment.id,
                contentType: attachment.content_type,
                size: attachment.size,
                name: attachment.name,
                streamUrl: attachment.stream_url,
              };
              const encodedAttachmentObject = btoa(
                JSON.stringify(attachmentDetails)
              );
              return `${window.location.protocol}//${window.location.host}/${entityId}/attachment/${encodedAttachmentObject}`;
            })
            .join("\n\n")
        : "",
      ...cellStyle,
      color: "#2196F3",
    }),
    [entityId]
  );

  const generateCustomFieldCell = useCallback(
    (
      { custom_fields: customFields }: ExportedTaskModel,
      customField: InternalTaskCustomField
    ): Cell => {
      let value = customFields?.[customField.id] || "";

      switch (customField.type) {
        case "date":
          value = value ? formatDate(value) : value;
          break;
        case "dropdown":
          value =
            customField.values?.find((el) => el.id === value)?.value || "";
          break;
        default:
          break;
      }

      return {
        value,
        ...cellStyle,
      };
    },
    []
  );

  const generateClientEmailCell = useCallback(
    ({ custom_fields: customFields }: ExportedTaskModel): Cell => ({
      value: customFields?.[TASK_ORDER_EMAIL_ID],
      ...cellStyle,
    }),
    []
  );

  const generateChecklistNameCell = useCallback(
    ({ name, is_completed: isCompleted }: ExportedTaskChecklist): Cell => ({
      ...cellStyle,
      value: `${isCompleted ? "✓ " : ""}${parseMention(name)}`,
      color: "#5A6367",
      backgroundColor: isCompleted ? "#EBFFF5" : undefined,
    }),
    []
  );

  const generateChecklistDueDateCell = useCallback(
    ({ due_at: dueAt }: ExportedTaskChecklist): Cell => ({
      value: dueAt ? formatDate(dueAt) : "",
      ...cellStyle,
    }),
    []
  );

  const generateChecklistAssigneeCell = useCallback(
    ({ user }: ExportedTaskChecklist): Cell => ({
      value: user ? formatUserName(user) : "",
      ...cellStyle,
    }),
    []
  );

  return {
    generateAssigneesCell,
    generateAttachmentsCell,
    generateBoardCell,
    generateCustomFieldCell,
    generateCustomerCell,
    generateDueDateCell,
    generateHeaderCell,
    generateLabelCell,
    generateNameCell,
    generateOrderIDCell,
    generatePriorityCell,
    generateTitleCell,
    generateStatusCell,
    generateClientEmailCell,
    generateChecklistNameCell,
    generateChecklistDueDateCell,
    generateChecklistAssigneeCell,
  };
};
