import {
  BoardAvatar,
  Button,
  InteractiveContainer,
  ListBoxItem,
  PlainButton,
  Popover,
  Radio,
  Tooltip,
} from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useMemo } from "react";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import {
  DuplicateTaskAction,
  TasksImportScreenToParametersMap,
} from "../../types";
import { PickerButton } from "../PickerButton";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import { ReactComponent as TaskNameIcon } from "./assets/task-name.svg";
import { useAlignColumnFieldItems } from "../../hooks/useAlignColumnFieldItems";

export const ImportSettingsScreen: React.FC<{
  duplicateTaskAction: DuplicateTaskAction;
  boardId: string;
  skipValue?: string;
  onSkipValueChange: (skipValue: string) => void;
  setDuplicateTaskAction: (duplicateTaskAction: DuplicateTaskAction) => void;
  setBoardId: (boardId: string) => void;
  onImportTasks: () => void;
}> = ({
  boardId,
  setBoardId,
  duplicateTaskAction,
  setDuplicateTaskAction,
  skipValue,
  onSkipValueChange,
  onImportTasks,
}) => {
  const { t } = useTranslations();
  const { transitionTo } =
    useScreenTransitionManager<TasksImportScreenToParametersMap>();
  const { alignColumnFieldItems } = useAlignColumnFieldItems();
  const { entityId } = useEntitySelectedProvider();
  const { boards, isLoading, getBoardById } = useTaskBoards({ entityId });

  const boardAsListItems = useMemo<ListBoxItem<TaskBoardModel>[]>(
    () =>
      (boards || []).map((board) => ({
        id: board.id,
        value: board,
      })),
    [boards]
  );

  const selectedBoardDetails = useMemo(
    () => (boardId ? getBoardById(boardId) : null),
    [boardId, getBoardById]
  );

  const handleDuplicatesOptions = useMemo(
    () => [
      {
        id: DuplicateTaskAction.newTask,
        title: t({
          id: "tasks-page.create-new-task-items",
          defaultMessage: "❇️ Create new Task items",
        }),
        desc: t({
          id: "tasks-page.duplicated-tasks-as-new-items-description",
          defaultMessage:
            "All duplicated items will be added to the Board as New Items",
        }),
      },
      {
        id: DuplicateTaskAction.skip,
        title: t({
          id: "tasks-page.skip-duplicates",
          defaultMessage: "❌ Skip duplicates",
        }),
        desc: t({
          id: "tasks-page.ignore-duplicated-tasks-description",
          defaultMessage:
            "Ignore the item if the Data from Excel matches selected Column",
        }),
      },
    ],
    [t]
  );

  const pickerItemsWithTaskName = useMemo(
    () => [
      {
        id: "name",
        value: {
          label: t({
            id: "tasks-page.task-name",
            defaultMessage: "Task Name",
          }),
          icon: <TaskNameIcon className="shrink-0" />,
        },
      },
      ...alignColumnFieldItems,
    ],
    [alignColumnFieldItems, t]
  );

  const skipValueDetails = useMemo(
    () => pickerItemsWithTaskName.find((el) => el.id === skipValue),
    [pickerItemsWithTaskName, skipValue]
  );

  return (
    <div className="flex h-full flex-col items-center text-center">
      <span className="text-dark font-secondary text-[22px] font-semibold leading-[42px]">
        {t({
          id: "tasks-page.import-tasks-last-step-settings",
          defaultMessage: "Last step - Settings 🤖",
        })}
      </span>
      <span className="text-dark font-secondary mt-3 text-sm leading-[21px] tracking-[0.14px]">
        {t({
          id: "tasks-page.set-details-imported-tasks",
          defaultMessage: "Set the details for imported Tasks",
        })}
      </span>
      <div className="mt-12 flex w-[416px] flex-col items-start">
        <span className="ml-4 text-xs leading-[20px] text-[#828282]">
          {t({
            id: "tasks-page.import-tasks-to",
            defaultMessage: "Import Tasks to",
          })}
        </span>
        <ResourcePickerPopover
          items={boardAsListItems}
          selectionBehavior={{ mode: "single", canToggle: false }}
          hasSearch
          shouldFilterOnSearch
          loading={isLoading ? "skeleton" : undefined}
          defaultSelectedIds={boardId ? [boardId] : undefined}
          maxVisibleItems={6}
          itemSize="lg"
          spaceBetweenItems="normal"
          renderLabel={(item) => item.value.name}
          renderStartIcon={(item) => (
            <BoardAvatar
              size="md"
              name={item.value.name}
              color={item.value.color}
            />
          )}
          onSelect={({ item, onClose }) => {
            setBoardId(item.value.id);
            onClose();
          }}
          placement="bottom"
          className="z-[2000]"
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger ref={triggerRef} as={PickerButton} isOpen={isOpen}>
              {selectedBoardDetails && (
                <div className="flex w-full items-center gap-2">
                  <BoardAvatar
                    color={selectedBoardDetails.color}
                    name={selectedBoardDetails.name}
                    size="lg"
                    className="shrink-0"
                  />
                  <span className="text-dark truncate text-sm">
                    {selectedBoardDetails.name}
                  </span>
                </div>
              )}
            </Trigger>
          )}
        />
      </div>
      <div className="mt-12 flex w-[416px] flex-col items-start">
        <span className="ml-4 text-xs leading-[20px] text-[#828282]">
          {t({
            id: "tasks-page.how-to-handle-duplicates",
            defaultMessage: "How to handle duplicates?",
          })}
        </span>
        <Popover
          placement="bottom"
          className="z-[2000] w-[350px] p-4"
          renderTrigger={({ Trigger, triggerRef, isOpen }) => (
            <Trigger ref={triggerRef} as={PickerButton} isOpen={isOpen}>
              <span className="text-dark text-sm">
                {
                  handleDuplicatesOptions.find(
                    (el) => el.id === duplicateTaskAction
                  )?.title
                }
              </span>
            </Trigger>
          )}
        >
          {({ onClose }) => (
            <div className="flex flex-col gap-1.5">
              {handleDuplicatesOptions.map((el) => (
                <InteractiveContainer
                  className={cx(
                    "bg-grey-100 hover:bg-grey-200 flex flex-col gap-1.5 rounded-lg p-3 transition-colors",
                    el.id === duplicateTaskAction &&
                      "bg-primary-50 hover:bg-primary-50"
                  )}
                  key={el.id}
                  onClick={() => {
                    setDuplicateTaskAction(el.id);
                    onClose();
                  }}
                >
                  <div className="flex items-center justify-between">
                    <span className="text-dark font-secondary font-medium leading-4">
                      {el.title}
                    </span>
                    <Radio readOnly isChecked={el.id === duplicateTaskAction} />
                  </div>
                  <span className="text-grey-900 text-sm leading-[140%]">
                    {el.desc}
                  </span>
                </InteractiveContainer>
              ))}
            </div>
          )}
        </Popover>
      </div>
      {duplicateTaskAction === DuplicateTaskAction.skip && (
        <div className="mt-6 flex w-[416px] flex-col items-start">
          <div className="flex items-center gap-2">
            <span className="ml-4 text-xs leading-[20px] text-[#828282]">
              {t({
                id: "tasks-page.select-column",
                defaultMessage: "Select Column",
              })}
            </span>
            <Tooltip
              renderTrigger={({ props, ref }) => (
                <PlainButton
                  ref={ref}
                  {...props}
                  className="text-grey-400 hover:text-messages-chatBubbleSender transition-colors"
                >
                  <InfoIcon />
                </PlainButton>
              )}
              className="!z-[2000]"
              placement="top-start"
              delay="none"
            >
              {t({
                id: "tasks-page.ignore-duplicates-from-column-message",
                defaultMessage:
                  "Ignore the item if the Data from Excel matches selected Column",
              })}
            </Tooltip>
          </div>
          <ResourcePickerPopover
            items={pickerItemsWithTaskName}
            defaultSelectedIds={skipValue ? [skipValue] : undefined}
            onSelect={({ item, onClose }) => {
              onSkipValueChange(item.id);
              onClose();
            }}
            placement="bottom"
            className="z-[2000]"
            renderLabel={(item) => item.value.label}
            renderStartIcon={(item) => item.value.icon}
            maxVisibleItems={5}
            selectionBehavior={{ mode: "single", canToggle: false }}
            renderTrigger={({ Trigger, triggerRef, isOpen }) => (
              <Trigger ref={triggerRef} as={PickerButton} isOpen={isOpen}>
                {skipValueDetails && (
                  <div className="flex w-full items-center gap-2">
                    {skipValueDetails.value.icon}
                    <span className="text-dark truncate text-sm">
                      {skipValueDetails.value.label}
                    </span>
                  </div>
                )}
              </Trigger>
            )}
          />
        </div>
      )}
      <div className="mt-12 flex items-center justify-center gap-4">
        <Button
          className="w-[200px]"
          color="grey"
          onClick={() => transitionTo({ name: "alignColumn" })}
        >
          {t({
            id: "common.back",
            defaultMessage: "Back",
          })}
        </Button>
        <Button
          className="w-[200px]"
          onClick={onImportTasks}
          isDisabled={
            (duplicateTaskAction === DuplicateTaskAction.skip && !skipValue) ||
            !boardId
          }
        >
          {t({
            id: "common.import",
            defaultMessage: "Import",
          })}
        </Button>
      </div>
    </div>
  );
};
