import {
  ArchivedTask,
  BaseTask,
  PreviewTask,
  TaskDefaultStatus,
  TasksSource,
} from "@jugl-web/rest-api/tasks";
import { BoardAvatar } from "@jugl-web/ui-components/cross-platform/BoardAvatar";
import {
  PILL_BASE_BG_COLOR,
  PILL_BASE_TEXT_COLOR,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { convertMillisecondsToTimeComponents } from "@jugl-web/ui-components/cross-platform/tasks/TimeSpentPicker/utils";
import { useTranslations } from "@jugl-web/utils";
import { isoToLocalDate } from "@jugl-web/utils/date-time/isoToLocalDate";
import { useLanguage } from "@jugl-web/utils/i18n/EnhancedIntlProvider";
import { colord } from "colord";
import { useCallback } from "react";
import { useTaskBoards } from "../../hooks/useTaskBoards";
import { useTaskFields } from "../../hooks/useTaskFields";
import { useTaskStatuses } from "../../hooks/useTaskStatuses";
import { useTaskUnreadIndicator } from "../../hooks/useTaskUnreadIndicator";
import { isTaskOverdue } from "../../utils";
import { TaskCardField, TaskCardProps } from "./TaskCard";
import {
  adaptCustomFieldsToTaskCardFields,
  getBoardFieldColors,
  TaskCardFieldsManager,
} from "./utils";

type AdaptedTaskCardProps = Omit<
  TaskCardProps,
  "highlightedText" | "onClick" | "className"
>;

interface UseTaskCardOptions {
  entityId: string;
  source: TasksSource;
}

export const useTaskCardAdapter = ({
  entityId,
  source,
}: UseTaskCardOptions) => {
  const { getCustomFieldById, getLabelById } = useTaskFields({ entityId });
  const { getStatusDetailsById } = useTaskStatuses({ entityId });
  const { getBoardById } = useTaskBoards({ entityId });
  const { isTaskUnread } = useTaskUnreadIndicator({ entityId, source });

  const { t } = useTranslations();
  const { dateLocale } = useLanguage();

  const getBoardField = useCallback(
    (boardId: BaseTask["board_id"]): TaskCardField | null => {
      if (
        boardId &&
        // Skip the board field if the task's board is the same as the source
        (source.type !== "boardTasks" || source.boardId !== boardId)
      ) {
        const matchingBoard = getBoardById(boardId);

        if (matchingBoard) {
          const boardField: TaskCardField = {
            id: "board",
            startIcon: (
              <BoardAvatar
                name={matchingBoard.name}
                color={matchingBoard.color}
                size="xs"
              />
            ),
            text: matchingBoard.name,
            ...getBoardFieldColors(matchingBoard.color),
          };

          return boardField;
        }
      }

      return null;
    },
    [getBoardById, source]
  );

  const getStatusField = useCallback(
    (status: BaseTask["status"]): TaskCardField => {
      const statusDetails = getStatusDetailsById(status);

      const statusField: TaskCardField = {
        id: "status",
        text: t(
          {
            id: "tasks-page.status-with-label",
            defaultMessage: "Status: {label}",
          },
          { label: statusDetails.label }
        ),
        textColor: PILL_BASE_TEXT_COLOR,
        backgroundColor: PILL_BASE_BG_COLOR,
      };

      return statusField;
    },
    [getStatusDetailsById, t]
  );

  const getLabelField = useCallback(
    (labelId: BaseTask["label_id"]): TaskCardField | null => {
      if (labelId) {
        const label = getLabelById(labelId);

        if (label) {
          const labelField: TaskCardField = {
            id: "label",
            text: t(
              {
                id: "tasks-page.label-with-text",
                defaultMessage: "Label: {text}",
              },
              { text: label.text }
            ),
            textColor: label.color,
            backgroundColor: colord(label.color).alpha(0.12).toHex(),
          };

          return labelField;
        }
      }

      return null;
    },
    [getLabelById, t]
  );

  const getTaskCardProps = useCallback(
    (
      task: PreviewTask,
      overrides?: Partial<AdaptedTaskCardProps>
    ): AdaptedTaskCardProps => {
      const fieldsManager = new TaskCardFieldsManager();

      fieldsManager.appendIfRelevant(getBoardField(task.board_id));
      fieldsManager.appendIfRelevant(getStatusField(task.status));
      fieldsManager.appendIfRelevant(getLabelField(task.label_id));

      fieldsManager.appendIfRelevant(
        ...adaptCustomFieldsToTaskCardFields(
          task.custom_fields,
          getCustomFieldById,
          dateLocale
        )
      );

      const isFutureTask = !!task._future_task;

      return {
        taskId: task.id,
        orderId: task.order_id || undefined,
        isUnread: isFutureTask ? false : isTaskUnread(task.id),
        title: task.name || "",
        dueDate: task.due_at ? isoToLocalDate(task.due_at) : null,
        isOverdue: isTaskOverdue(task),
        isCompleted: task.status === TaskDefaultStatus.completed,
        checklist: {
          completedItemsCount: task.chklist_completed,
          totalItemsCount: task.chklist_total,
          isOverdue: task.chklist_overdue,
        },
        timeSpent: convertMillisecondsToTimeComponents(task.duration),
        fields: fieldsManager.toArray(),
        recurrence: task.recurrence
          ? {
              isFutureTask,
              isPaused: task.recurrence.enabled === false,
            }
          : undefined,
        ...overrides,
      };
    },
    [
      dateLocale,
      getBoardField,
      getCustomFieldById,
      getLabelField,
      getStatusField,
      isTaskUnread,
    ]
  );

  const getArchivedTaskCardProps = useCallback(
    (task: ArchivedTask): AdaptedTaskCardProps => {
      const fieldsManager = new TaskCardFieldsManager();

      fieldsManager.appendIfRelevant(getStatusField(task.status));
      fieldsManager.appendIfRelevant(getLabelField(task.label_id));

      return {
        taskId: task.id,
        title: task.name || "",
        dueDate: task.due_at ? isoToLocalDate(task.due_at) : null,
        isOverdue: isTaskOverdue(task),
        isCompleted: task.status === TaskDefaultStatus.completed,
        fields: fieldsManager.toArray(),
      };
    },
    [getLabelField, getStatusField]
  );

  return { getTaskCardProps, getArchivedTaskCardProps };
};
