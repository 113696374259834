import { ListBox, ListBoxItem } from "@jugl-web/ui-components/cross-platform";
import { useAppVariant } from "@jugl-web/utils";
import { getAllTimezones, Timezone } from "countries-and-timezones";
import { FC, useMemo } from "react";
import { useTaskDueDateFormContext } from "../../contexts/TaskDueDateFormContext";

const timezoneDict = getAllTimezones();
const timezoneCodes = Object.keys(timezoneDict);

interface TimezoneListProps {
  onAfterSelect: () => void;
}

export const TimezoneList: FC<TimezoneListProps> = ({ onAfterSelect }) => {
  const { selectedTimezone, setSelectedTimezone } = useTaskDueDateFormContext();

  const { isMobile } = useAppVariant();

  const timezonesAsListItems = useMemo<ListBoxItem<Timezone>[]>(
    () =>
      timezoneCodes.map((timezoneCode) => {
        const timezone =
          timezoneDict[timezoneCode as keyof typeof timezoneDict];

        return {
          id: timezone.name,
          value: timezone,
        };
      }),
    []
  );

  return (
    <ListBox
      items={timezonesAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      onSelect={(item) => {
        setSelectedTimezone(item.value.name);
        onAfterSelect();
      }}
      hasSearch
      defaultSelectedIds={[selectedTimezone]}
      shouldScrollToFirstSelectedItem
      renderLabel={(item) => item.value.name.replaceAll("_", " ")}
      renderSecondaryText={(item) => `(UTC ${item.value.utcOffsetStr})`}
      maxVisibleItems={isMobile ? 8 : 6}
      itemSize="xl"
      spaceBetweenItems="normal"
    />
  );
};
