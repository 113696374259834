import {
  TaskCommentInput,
  TaskCommentsContext,
  useTaskCommentsProvider,
} from "@jugl-web/domain-resources/tasks-comments";
import { useManageCommentsListener } from "@jugl-web/domain-resources/tasks-comments/hooks/useManageCommentsListener";
import { PaginatedParsedActivity } from "@jugl-web/domain-resources/tasks/hooks/useTaskActivities";
import { useTaskPermissions } from "@jugl-web/domain-resources/tasks/hooks/useTaskPermissions";
import { DetailedTask, TaskActivity } from "@jugl-web/rest-api/tasks";
import { LoadingSpinner } from "@jugl-web/ui-components";
import { Menu } from "@jugl-web/ui-components/cross-platform/Menu";
import { PlainButton } from "@jugl-web/ui-components/cross-platform/PlainButton";
import {
  TaskActivityItem,
  parseActivityType,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskActivityItem";
import { Text } from "@jugl-web/ui-components/cross-platform/Text";
import {
  PaginationComponent,
  PaginationComponentHandle,
  cx,
  isoToLocalDate,
  useTranslations,
} from "@jugl-web/utils";
import { tasksCommentsApi } from "@web-src/features/api/createApi";
import { useMe } from "@web-src/features/app/hooks/useMe";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as ActivityIcon } from "./icons/activity.svg";
import { ReactComponent as OptionsVerticalIcon } from "./icons/options-vertical.svg";
import { TaskActivityFilterMenuTrigger } from "./TaskActivityFilterMenuTrigger";

interface TaskActivitiesColumnProps {
  entityId: string;
  task: DetailedTask;
  activities: PaginatedParsedActivity[];
  areActivitiesLoading: boolean;
  activitiesFilters: string[];
  isInitialLoading: boolean;
  hasCustomerAssigned: boolean;
  setActivitiesFilters: Dispatch<SetStateAction<string[]>>;
  loadMoreActivities: () => void;
  addActivity: (activity: TaskActivity) => void;
  modifyActivity: (
    activity: TaskActivity,
    actionType: "delete" | "update"
  ) => void;
}

export const TaskActivitiesColumn: FC<TaskActivitiesColumnProps> = ({
  entityId,
  task,
  activities,
  areActivitiesLoading,
  activitiesFilters,
  isInitialLoading,
  hasCustomerAssigned,
  setActivitiesFilters,
  loadMoreActivities,
  addActivity,
  modifyActivity,
}) => {
  const $pagination = useRef<PaginationComponentHandle>(null);
  const [currentLastActivity, setCurrentLastActivity] =
    useState<PaginatedParsedActivity>();
  const { manageComments$ } = useContext(TaskCommentsContext);
  const { setCommentAction } = useTaskCommentsProvider();
  const { t } = useTranslations();
  const { me } = useMe();
  const isGuestActivities = activitiesFilters.includes("guest");

  const handleEditComment = (comment: TaskActivity) =>
    setCommentAction({ type: "edit", comment });

  const handleDeleteComment = (comment: TaskActivity) => {
    setCommentAction({ type: "delete", comment });
    manageComments$.next({
      action: "delete",
      taskId: task.id,
      comment,
    });
  };

  const permissions = useTaskPermissions({ task, entityId, meId: me?.id });

  useManageCommentsListener({
    taskId: task.id,
    entityId,
    tasksCommentsApi,
    manageComments$,
    addActivity,
    addGuestActivity: addActivity,
    modifyActivity,
    modifyGuestActivity: modifyActivity,
  });

  useEffect(() => {
    const oldLastActivity =
      currentLastActivity && JSON.stringify(currentLastActivity);
    const newLastActivity =
      activities[activities.length - 1] &&
      JSON.stringify(activities[activities.length - 1]);
    const isLastActivityComment =
      activities[activities.length - 1]?.data.raw.action_details.action ===
      "commented";
    if (oldLastActivity !== newLastActivity && isLastActivityComment) {
      setCurrentLastActivity(activities[activities.length - 1]);
    }
  }, [activities, currentLastActivity]);

  useEffect(() => {
    $pagination.current?.scrollToBottom();
  }, [currentLastActivity, activitiesFilters]);

  return (
    <div className="flex h-full flex-col">
      {!isGuestActivities && (
        <div className="border-b-grey-200 flex items-center justify-between gap-2.5 border border-t-0 border-l-0 border-r-0 border-solid px-8 py-3.5">
          {!hasCustomerAssigned && (
            <div className="flex items-center gap-2.5 font-medium text-[#69757C]">
              <ActivityIcon />
              {t({
                id: "tasks-page.activity",
                defaultMessage: "Activity",
              })}
            </div>
          )}
          <div className="ml-auto">
            <TaskActivityFilterMenuTrigger
              activityFilters={activitiesFilters}
              setActivityFilters={setActivitiesFilters}
            />
          </div>
        </div>
      )}
      <div className="grow overflow-y-auto">
        {!isInitialLoading && activities.length === 0 && (
          <div className="p-[80px] text-center">
            <Text variant="body2" className="text-[#828282]">
              {isGuestActivities
                ? t({
                    id: "tasks-page.no-feedback",
                    defaultMessage: "No feedback",
                  })
                : t(
                    {
                      id: "tasks-page.empty-activity-column",
                      defaultMessage:
                        "Post a comment to start a discussion. <mark>@Mention</mark> someone to notify them.",
                    },
                    {
                      mark: (mention: (string | JSX.Element)[]) => (
                        <span className="text-primary">{mention}</span>
                      ),
                    }
                  )}
            </Text>
          </div>
        )}
        {isInitialLoading ? (
          <LoadingSpinner className="my-7" />
        ) : (
          <PaginationComponent
            items={activities}
            isLoading={areActivitiesLoading}
            endReached={loadMoreActivities}
            reverse
            extraVirtuosoParams={{
              alignToBottom: false,
            }}
            ref={$pagination}
            renderer={(index, item) => {
              const isComment =
                item.data.raw.action_details.action === "commented" ||
                item.data.raw.action_details.action === "guest_comment_reply";

              const canManageComment =
                isComment &&
                permissions.canEditOrDeleteComment(
                  item.data.raw.action_by.user_id
                );

              const isLast = activities.length - 1 === index;

              return (
                <div
                  className={cx({
                    "border-grey-200 border-t-0 border-b-[1px] border-r-0 border-l-0 border-solid":
                      !isLast,
                  })}
                  key={item.id}
                >
                  <TaskActivityItem
                    action={item.data.raw.action_details.action}
                    type={parseActivityType(
                      item.data.raw.action_details.action
                    )}
                    variant="web"
                    isExternalClient={item.data.user.isExternalClient}
                    username={item.data.user.username}
                    userImageUrl={item.data.user.avatarUrl}
                    message={item.data.message}
                    date={isoToLocalDate(item.data.raw.inserted_at)}
                    ratingRate={item.data.rating?.rate}
                    ratingTags={item.data.rating?.tags}
                    contextMenuSlot={
                      canManageComment && (
                        <Menu
                          placement="bottom-end"
                          sections={[
                            [
                              {
                                id: "edit",
                                label: t({
                                  id: "common.edit",
                                  defaultMessage: "Edit",
                                }),
                                onSelect: (_, close) => {
                                  handleEditComment(item.data.raw);
                                  close();
                                },
                              },
                              {
                                id: "delete",
                                label: t({
                                  id: "common.delete",
                                  defaultMessage: "Delete",
                                }),
                                onSelect: () =>
                                  handleDeleteComment(item.data.raw),
                              },
                            ],
                          ]}
                          renderTrigger={({ Trigger, triggerRef }) => (
                            <Trigger as={PlainButton} ref={triggerRef}>
                              <OptionsVerticalIcon />
                            </Trigger>
                          )}
                        />
                      )
                    }
                  />
                </div>
              );
            }}
          />
        )}
      </div>
      <div
        className="flex items-center justify-center gap-2 bg-white p-4 px-4"
        style={{
          boxShadow: "1px -6px 10px rgba(18, 22, 34, 0.08)",
        }}
      >
        <TaskCommentInput
          entityId={entityId}
          meId={me?.id || ""}
          apiAction={isGuestActivities ? "guest_comment_reply" : undefined}
        />
      </div>
    </div>
  );
};
