import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUpdateEffect } from "react-use";
import { useTaskMentions } from "../../hooks/useTaskMentions";
import { TaskChecklistItemDeleteConfirmationDialog } from "../TaskChecklistItemDeleteConfirmationDialog";
import { AddNewItemButton } from "./AddNewItemButton";
import { EditingTaskChecklistItem } from "./EditingTaskChecklistItem";
import { SaveBeforeLeavingDialog } from "./SaveBeforeLeavingDialog";
import { TaskChecklistItemComponent } from "./TaskChecklistItem";
import { TaskChecklistProvider } from "./TaskChecklistProvider";
import {
  TaskChecklistHandle,
  TaskChecklistItem,
  TaskChecklistProps,
} from "./types";
import { useTaskChecklistDnd } from "./useTaskChecklistDnd";
import {
  getSpecificOrderCompletionState,
  produceNewItem,
  saveTaskChecklist$,
  sortItemsByCompleted,
} from "./utils";

const MIN_ITEMS_TO_RENDER_ADD_BUTTON = 4;

const noop = () => {};

export const TaskChecklist = forwardRef<
  TaskChecklistHandle,
  TaskChecklistProps
>(
  (
    {
      entityId,
      meId,
      items,
      taskCreatorId,
      taskAssigneeIds = [],
      isCompleteInSpecificOrder = false,
      areCompletedItemsHidden = false,
      onlyReportees = false,
      displayDueDateAs = "date",
      className,
      isManageable = true,
      isCompletable = true,
      isAssignable = true,
      canEditItem = () => false,
      onAddItem = noop,
      onUpdateItem = noop,
      onDeleteItem = noop,
      onReorderItems = noop,
    },
    ref
  ) => {
    const [editingItemId, setEditingItemId] = useState<string | null>(null);

    const [newItemState, setNewItemState] = useState<TaskChecklistItem | null>(
      null
    );

    const { mentions } = useTaskMentions({
      entityId,
      meId,
      taskAssigneeIds,
      taskCreatorId,
    });

    const { dndContextProps } = useTaskChecklistDnd({ items, onReorderItems });

    const saveBeforeLeavingDialogState = useConfirmationDialogState();
    const deleteConfirmationDialogState = useConfirmationDialogState<{
      item: TaskChecklistItem;
    }>();

    const endListAnchorRef = useRef<HTMLDivElement | null>(null);

    const indexedItems = useMemo(
      () => items.map((item, index) => ({ ...item, primaryIndex: index })),
      [items]
    );

    const visibleItems = useMemo(() => {
      if (!areCompletedItemsHidden) {
        return indexedItems;
      }

      return indexedItems.filter((item) => !item.isCompleted);
    }, [indexedItems, areCompletedItemsHidden]);

    const isEmpty = visibleItems.length === 0 && !newItemState;

    const shouldShowAddButton =
      isManageable &&
      !newItemState &&
      items.length >= MIN_ITEMS_TO_RENDER_ADD_BUTTON;

    const scrollToBottom = () => {
      endListAnchorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    };

    // #region Public API
    const addItem = () => {
      setNewItemState(produceNewItem());

      // Make sure that scroll happens after the new item is rendered
      window.setTimeout(scrollToBottom, 0);
    };

    useImperativeHandle(ref, () => ({ addItem }));
    // #endregion

    useUpdateEffect(() => {
      if (isCompleteInSpecificOrder) {
        onReorderItems(sortItemsByCompleted(items));
      }
    }, [isCompleteInSpecificOrder]);

    if (isEmpty) {
      return null;
    }

    return (
      <TaskChecklistProvider
        value={{
          entityId,
          meId,
          mentions,
          isCompleteInSpecificOrder,
          onlyReportees,
          displayDueDateAs,
          isManageable,
          isCompletable,
          isAssignable,
          canEditItem,
          onTriggerSaveBeforeLeavingDialog: saveBeforeLeavingDialogState.open,
          onTriggerDeleteConfirmationDialog: deleteConfirmationDialogState.open,
        }}
      >
        <div className={className}>
          <DndContext {...dndContextProps}>
            <SortableContext
              items={visibleItems}
              strategy={verticalListSortingStrategy}
              disabled={!isManageable}
            >
              {visibleItems.map((item, index, array) => {
                const specificOrderCompletionState =
                  getSpecificOrderCompletionState({
                    isCompleteInSpecificOrder,
                    item,
                    previousItem: array[index - 1],
                    nextItem: array[index + 1],
                  });

                return (
                  <TaskChecklistItemComponent
                    key={item.id}
                    index={item.primaryIndex}
                    item={item}
                    specificOrderCompletionState={specificOrderCompletionState}
                    onUpdate={onUpdateItem}
                    onDelete={onDeleteItem}
                    isEditing={editingItemId === item.id}
                    onEditModeChange={(isEditing) => {
                      if (isEditing && editingItemId) {
                        saveTaskChecklist$.next({
                          subtaskId: editingItemId,
                        });
                      }
                      setEditingItemId(isEditing ? item.id : null);
                    }}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
          {newItemState && (
            <EditingTaskChecklistItem
              item={newItemState}
              isNew
              onSaveChanges={async (item) => {
                await onAddItem(item, items.length);
                setNewItemState(null);
              }}
              onCancel={() => setNewItemState(null)}
              onDelete={() => setNewItemState(null)}
            />
          )}
          {shouldShowAddButton && <AddNewItemButton onClick={addItem} />}
        </div>
        <div ref={endListAnchorRef} />
        <SaveBeforeLeavingDialog
          isOpen={saveBeforeLeavingDialogState.isOpen}
          onSave={() =>
            saveBeforeLeavingDialogState.confirm({ closeOnceConfirmed: true })
          }
          onDiscard={() =>
            saveBeforeLeavingDialogState.cancel({ closeOnceCanceled: true })
          }
          onCancel={saveBeforeLeavingDialogState.close}
        />
        <TaskChecklistItemDeleteConfirmationDialog
          isOpen={deleteConfirmationDialogState.isOpen}
          itemContent={deleteConfirmationDialogState.metadata?.item.text}
          onDelete={() =>
            deleteConfirmationDialogState.confirm({ closeOnceConfirmed: true })
          }
          onClose={deleteConfirmationDialogState.close}
        />
      </TaskChecklistProvider>
    );
  }
);
