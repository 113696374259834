import { TasksSource } from "@jugl-web/rest-api/tasks";
import { HookOutOfContextError, useTranslations } from "@jugl-web/utils";
import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useTaskListPreferences } from "../../hooks/useTaskListPreferences";
import { TaskSorting } from "../../types";

export interface TaskSortingOption {
  id: string;
  label: string;
  value: TaskSorting;
}

interface TaskSortingContextValue {
  sorting: TaskSorting;
  sortingOptions: TaskSortingOption[];
  sortingButtonLabelByType: { full: string; concise: string };
  changeSorting: (sorting: TaskSorting) => void;
}

const TaskSortingContext = createContext<TaskSortingContextValue | null>(null);

interface TaskSortingProviderProps {
  entityId: string;
  source: TasksSource;
  children: ReactNode;
}

export const TaskSortingProvider: FC<TaskSortingProviderProps> = ({
  entityId,
  source,
  children,
}) => {
  const { taskListPreferences, updateTaskListPreference } =
    useTaskListPreferences({ entityId, source });

  const { t } = useTranslations();

  const { sorting } = taskListPreferences;

  const changeSorting = useCallback(
    (s: TaskSorting) => updateTaskListPreference("sorting", s),
    [updateTaskListPreference]
  );

  const sortingButtonLabelByType = useMemo<
    TaskSortingContextValue["sortingButtonLabelByType"]
  >(
    () =>
      ({
        dueDateAsc: {
          full: t({
            id: "tasks-page.sort-by-due-date-ascending",
            defaultMessage: "By due date - ascending",
          }),
          concise: t({
            id: "tasks-page.sort-by-due-date-ascending-concise",
            defaultMessage: "Due date - ascending",
          }),
        },
        dueDateDesc: {
          full: t({
            id: "tasks-page.sort-by-due-date-descending",
            defaultMessage: "By due date - descending",
          }),
          concise: t({
            id: "tasks-page.sort-by-due-date-descending-concise",
            defaultMessage: "Due date - descending",
          }),
        },
        lastUpdated: {
          full: t({
            id: "tasks-page.sort-by-last-updated",
            defaultMessage: "By last updated",
          }),
          concise: t({
            id: "tasks-page.sort-by-last-updated-concise",
            defaultMessage: "Last updated",
          }),
        },
      }[sorting]),
    [sorting, t]
  );

  const sortingOptions = useMemo(
    (): TaskSortingOption[] => [
      {
        id: "last-updated",
        label: t({
          id: "tasks-page.sort-by-last-updated",
          defaultMessage: "By last updated",
        }),
        value: "lastUpdated",
      },
      {
        id: "due-date-ascending",
        label: t({
          id: "tasks-page.sort-by-due-date-ascending",
          defaultMessage: "By due date - ascending",
        }),
        value: "dueDateAsc",
      },
      {
        id: "due-date-descending",
        label: t({
          id: "tasks-page.sort-by-due-date-descending",
          defaultMessage: "By due date - descending",
        }),
        value: "dueDateDesc",
      },
    ],
    [t]
  );

  const contextValue = useMemo<TaskSortingContextValue>(
    () => ({
      sorting,
      sortingOptions,
      sortingButtonLabelByType,
      changeSorting,
    }),
    [changeSorting, sorting, sortingButtonLabelByType, sortingOptions]
  );

  return (
    <TaskSortingContext.Provider value={contextValue}>
      {children}
    </TaskSortingContext.Provider>
  );
};

export const useTaskSorting = () => {
  const context = useContext(TaskSortingContext);

  if (!context) {
    throw new HookOutOfContextError(
      "useTaskSortingModifiers",
      "TaskSortingContext"
    );
  }

  return context;
};
