import React from "react";
import { useTranslations, cx } from "@jugl-web/utils";
import { ListBoxProps, Pill } from "@jugl-web/ui-components";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useRestApiProvider } from "@jugl-web/rest-api";
import { Control, Controller, FieldValues } from "react-hook-form";
import { ExportTasksParams } from "@jugl-web/rest-api/tasks/types";
import { ReactComponent as AddIcon } from "./assets/add.svg";
import { ReactComponent as RemoveIcon } from "./assets/remove.svg";
import { Section } from "../Section/Section";

export const BoardSign: React.FC<{ name: string; color: string }> = ({
  name,
  color,
}) => (
  <div
    style={{
      backgroundColor: color,
    }}
    className="font-secondary flex h-4 w-4 select-none items-center justify-center rounded-[4px] text-[10px] font-semibold text-white"
  >
    {name.charAt(0)}
  </div>
);

export const BoardsField: React.FC<{
  entityId: string;
  control: Control<FieldValues>;
  formParams: ExportTasksParams;
}> = ({ entityId, control, formParams }) => {
  const { t } = useTranslations();

  const { tasksApi } = useRestApiProvider();
  const { data: boards, isLoading } = tasksApi.useGetTaskBoardsQuery({
    entityId,
  });

  const selectedBoards =
    (formParams.board && formParams.board.split(",")) || [];
  const commonResourcePickerProps = {
    title: "",
    items:
      boards?.map(({ id, color, name }) => ({
        id,
        value: {
          color,
          name,
        },
      })) || [],
    selectionBehavior: { mode: "multiple" },
    hasSearch: true,
    shouldFilterOnSearch: true,
    loading: isLoading ? "skeleton" : undefined,
    defaultSelectedIds: selectedBoards,
    maxVisibleItems: 6,
    itemSize: "lg",
    spaceBetweenItems: "normal",
    renderLabel: (item) => item.value.name,
    renderStartIcon: (item) => <BoardSign {...item.value} />,
  } satisfies ListBoxProps<{
    color: string;
    name: string;
  }> & { title: string };

  return (
    <Section
      label={t({
        id: "tasks-export-dialog.boards",
        defaultMessage: "Boards",
      })}
      button={
        <Controller
          name="board"
          control={control}
          render={({ field }) => (
            <ResourcePickerPopover
              placement="bottom-start"
              className="w-[375px]"
              shouldCloseOnAddButtonClick
              renderTrigger={({ Trigger, triggerRef }) => (
                <Trigger
                  size="md"
                  className="cursor-pointer select-none"
                  ref={triggerRef}
                  as={Pill}
                  endIcon={<AddIcon />}
                  label={
                    formParams.board?.length
                      ? t({
                          id: "common.add",
                          defaultMessage: "Add",
                        })
                      : t({
                          id: "common.all",
                          defaultMessage: "All",
                        })
                  }
                />
              )}
              onSubmit={(items) => {
                field.onChange(items.join(","));
              }}
              {...commonResourcePickerProps}
            />
          )}
        />
      }
    >
      <div
        className={cx(
          "animate-fade-in flex flex-wrap items-center gap-2.5 pb-5",
          {
            "pb-0": !selectedBoards.length,
          }
        )}
      >
        {selectedBoards.map((boardId) => {
          const boardData = boards?.find((board) => board.id === boardId);
          if (!boardData) {
            return null;
          }
          return (
            <Controller
              name="board"
              control={control}
              render={({ field }) => (
                <Pill
                  label={boardData.name}
                  size="md"
                  startIcon={
                    <BoardSign name={boardData.name} color={boardData.color} />
                  }
                  endIcon={
                    <RemoveIcon
                      onClick={() =>
                        field.onChange(
                          selectedBoards
                            .filter((item) => item !== boardId)
                            .join(",")
                        )
                      }
                      className="cursor-pointer"
                    />
                  }
                />
              )}
            />
          );
        })}
      </div>
    </Section>
  );
};
