import { useCustomers } from "@jugl-web/domain-resources/customers/hooks/useCustomers";
import { useSubscriptionInfo } from "@jugl-web/domain-resources/entities/hooks/useSubscriptionInfo";
import { TaskCustomerUpdateConfirmationDialog } from "@jugl-web/domain-resources/tasks/components/TaskCustomerUpdateConfirmationDialog";
import { useCustomerListBox } from "@jugl-web/domain-resources/users/hooks/useCustomerListBox";
import { SubscriptionPlanModuleId } from "@jugl-web/rest-api/entities/models/common-types/SubscriptionPlanModuleId";
import {
  AvatarMember,
  AvatarStack,
} from "@jugl-web/ui-components/cross-platform";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useConfirmationDialogState } from "@jugl-web/utils/hooks/useConfirmationDialogState";
import { FC, useMemo } from "react";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const TASK_TABLE_ROW_CUSTOMER_CELL_ID = (taskId: string) =>
  `task-table-row-customer-cell-${taskId}`;

export const CustomerCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const updateConfirmationDialogState = useConfirmationDialogState();

  const { isModuleAvailable } = useSubscriptionInfo({ entityId });

  const isEditable = permissions.canEditCustomer && !isFutureTask;
  const isValueHidden = !isModuleAvailable(SubscriptionPlanModuleId.clients);

  const { getCustomerDetailsById } = useCustomers({
    entityId,
    skipLoading: isValueHidden,
  });

  const { getListBoxProps, clearSearchQuery } = useCustomerListBox({
    entityId,
    skipLoading: isValueHidden,
  });

  const customerAsAvatarMember = useMemo<AvatarMember | null>(() => {
    const matchingCustomer = task.cust_id
      ? getCustomerDetailsById(task.cust_id)
      : undefined;

    if (!matchingCustomer) {
      return null;
    }

    const [firstName, lastName] = matchingCustomer.fullName.split(" ");

    return {
      firstName,
      lastName,
      imageUrl: matchingCustomer.avatarUrl,
    };
  }, [getCustomerDetailsById, task.cust_id]);

  return (
    <>
      <ResourcePickerPopover
        placement="bottom"
        className="w-[375px]"
        renderTrigger={({ Trigger, triggerRef, isOpen }) => (
          <Trigger
            as={TaskTableLayout.Cell}
            ref={triggerRef}
            isFocused={isOpen}
            isHoverable
            isDisabled={!isEditable}
            data-id={TASK_TABLE_ROW_CUSTOMER_CELL_ID(task.id)}
            {...cellProps}
          >
            {!isValueHidden && (
              <>
                {customerAsAvatarMember ? (
                  <AvatarStack
                    maxCount={1}
                    size="sm"
                    members={[customerAsAvatarMember]}
                    shouldShowNameForSingleMember
                  />
                ) : (
                  <TaskTableLayout.AppearingAddValueCellText
                    isStatic={isOpen}
                    isHidden={!isEditable}
                  />
                )}
              </>
            )}
          </Trigger>
        )}
        {...getListBoxProps({
          defaultSelectedIds: task.cust_id ? [task.cust_id] : undefined,
        })}
        onSelect={({ item, isSelected, onClose }) => {
          if (task.cust_id) {
            updateConfirmationDialogState.open({
              onConfirm: () =>
                onUpdate({ cust_id: isSelected ? item.id : null }),
            });
          } else {
            onUpdate({ cust_id: isSelected ? item.id : null });
          }

          onClose();
        }}
        onUnmount={clearSearchQuery}
      />
      <TaskCustomerUpdateConfirmationDialog
        isOpen={updateConfirmationDialogState.isOpen}
        onRequestClose={updateConfirmationDialogState.close}
        onUpdate={updateConfirmationDialogState.confirm}
      />
    </>
  );
};
