import { TasksPageLayout } from "@web-src/components/TasksPageLayout";
import { TaskTableView } from "@web-src/features/tasks/TaskTableView";
import { useTasksPageContext } from "@web-src/features/tasks/TasksPageContext";
import { TaskCalendarView } from "../TaskCalendarView";
import { TaskKanbanView } from "../TaskKanbanView";

export const TasksContent = () => {
  const { taskListLayout } = useTasksPageContext();

  return (
    <TasksPageLayout.Content
      backgroundColor={taskListLayout === "kanban" ? "white" : "grey"}
    >
      {taskListLayout === "kanban" && <TaskKanbanView />}
      {taskListLayout === "table" && <TaskTableView />}
      {taskListLayout === "calendar" && <TaskCalendarView />}
    </TasksPageLayout.Content>
  );
};
