import { PlainButton } from "@jugl-web/ui-components/cross-platform";
import {
  TaskPropertyButton,
  TaskPropertyButtonProps,
} from "@jugl-web/ui-components/cross-platform/tasks/TaskPropertyButton";
import { MobileDrawer } from "@jugl-web/ui-components/mobile";
import { cx, useAppVariant, useTranslations } from "@jugl-web/utils";
import { useFormattedDate } from "@jugl-web/utils/hooks/useFormattedDate";
import { FC, useState } from "react";
import { getDueDateInDays } from "../../../../utils";
import { TaskDueDateFormPopover } from "../../../DueDatePickerPopover";
import {
  TaskDueDateForm,
  TaskDueDateFormProps,
} from "../../../TaskDueDateForm";
import { ReactComponent as ArrowLeftIcon } from "../../assets/arrow-left.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import { ReactComponent as DueDateIcon } from "../../assets/due-date.svg";
import { FieldComponentProps } from "../../types";

export const DueDateField: FC<FieldComponentProps<"dueDate">> = ({
  state,
  defaultTimezone,
  displayAs = "date",
  isReadonly,
  isLabelHidden,
  withCalendar = true,
  withAdvancedOptions = false,
  onChange,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const hasDueDate = !!state;
  const isFieldVisible = hasDueDate || !isReadonly;

  const { t } = useTranslations();
  const { formatDateTimeLabel } = useFormattedDate();

  const { isMobile } = useAppVariant();

  const label = (() => {
    if (!hasDueDate) {
      return isLabelHidden
        ? null
        : t({
            id: "task-templates-page.due-date",
            defaultMessage: "Due date",
          });
    }

    if (displayAs === "days") {
      return t(
        {
          id: "tasks-page.due-in-days",
          defaultMessage:
            "Due in: {days} {days, plural, one {day} other {days}}",
        },
        { days: getDueDateInDays(state.date) }
      );
    }

    const dueDateTimeLabel = formatDateTimeLabel(state.date);

    return isLabelHidden
      ? dueDateTimeLabel
      : t(
          { id: "tasks-page.due-to-date", defaultMessage: "Due to: {date}" },
          { date: dueDateTimeLabel }
        );
  })();

  if (!isFieldVisible) {
    return null;
  }

  const commonButtonProps: TaskPropertyButtonProps = {
    isDisabled: isReadonly,
    startIcon: <DueDateIcon className={cx(hasDueDate && "text-primary")} />,
    className: cx(hasDueDate && "bg-primary-50 text-primary-900"),
    children: label,
  };

  const commonDueDateFormProps = {
    initialState: state,
    defaultTimezone,
    withCalendar,
    withAdvancedOptions,
  } satisfies Partial<TaskDueDateFormProps>;

  if (isMobile) {
    return (
      <>
        <TaskPropertyButton
          onClick={() => setIsDialogOpen(true)}
          {...commonButtonProps}
        />
        <MobileDrawer
          isOpen={isDialogOpen}
          animateHeight
          className="overflow-y-auto"
          onClose={() => setIsDialogOpen(false)}
        >
          <MobileDrawer.Content className="pt-4">
            <TaskDueDateForm
              {...commonDueDateFormProps}
              renderStartSlot={({
                currentScreenTitle,
                canMoveBack,
                onMoveBack,
              }) => (
                <header className="border-dark-100 mb-6 flex justify-between border-b border-l-0 border-r-0 border-t-0 border-solid pb-3">
                  <div className="flex items-center gap-4">
                    {canMoveBack && (
                      <PlainButton
                        className="flex h-8 w-8 items-center justify-center"
                        onClick={onMoveBack}
                      >
                        <ArrowLeftIcon />
                      </PlainButton>
                    )}
                    <h3 className="text-normal m-0 font-semibold text-[#383838]">
                      {currentScreenTitle}
                    </h3>
                  </div>
                  <PlainButton onClick={() => setIsDialogOpen(false)}>
                    <CloseIcon />
                  </PlainButton>
                </header>
              )}
              onSubmit={(s) => onChange?.(s)}
              onClose={() => setIsDialogOpen(false)}
            />
          </MobileDrawer.Content>
        </MobileDrawer>
      </>
    );
  }

  return (
    <TaskDueDateFormPopover
      placement="bottom-start"
      renderTrigger={({ Trigger, triggerRef }) => (
        <Trigger
          ref={triggerRef}
          as={TaskPropertyButton}
          {...commonButtonProps}
        />
      )}
      {...commonDueDateFormProps}
      onSubmit={(s) => onChange?.(s)}
    />
  );
};
