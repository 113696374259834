import { Button, Tooltip } from "@jugl-web/ui-components";
import { cx, useTranslations } from "@jugl-web/utils";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import React, { useCallback } from "react";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { Row } from "read-excel-file";
import { useTaskFields } from "@jugl-web/domain-resources/tasks/hooks/useTaskFields";
import { useEntitySelectedProvider } from "@web-src/modules/entities/providers/EntityProvider";
import { TasksImportScreenToParametersMap } from "../../types";
import { ReactComponent as WarningIcon } from "./assets/warning.svg";
import { ReactComponent as ArrowFlowIcon } from "./assets/arrow-flow.svg";
import { useAlignColumnFieldItems } from "../../hooks/useAlignColumnFieldItems";
import { PickerButton } from "../PickerButton";

export const AlignColumnScreen: React.FC<{
  data: Row[];
  taskNameColumn?: number;
  mappedColumns: {
    column: number;
    value: string;
  }[];
  onMappedColumnsChange: React.Dispatch<
    React.SetStateAction<
      {
        column: number;
        value: string;
      }[]
    >
  >;
}> = ({ data, taskNameColumn, mappedColumns, onMappedColumnsChange }) => {
  const { t } = useTranslations();
  const { transitionTo } =
    useScreenTransitionManager<TasksImportScreenToParametersMap>();
  const { alignColumnFieldItems } = useAlignColumnFieldItems();
  const { entityId } = useEntitySelectedProvider();
  const { getCustomFieldById } = useTaskFields({ entityId });

  const getPickerItems = useCallback(
    (id?: string) =>
      alignColumnFieldItems.filter(
        (el) =>
          !mappedColumns.some((item) => item.value === el.id && el.id !== id)
      ),
    [alignColumnFieldItems, mappedColumns]
  );

  const checkIsValueMatch = useCallback(
    (item: { column: number; value: string }) => {
      const dataColumn = data.slice(1).map((row) => row[item.column]);

      const isColumnEmpty = dataColumn.every((el) => !el);

      if (isColumnEmpty) return true;

      return dataColumn.some((el) => {
        const value = el?.toString();
        switch (item.value) {
          case "status":
          case "label":
            return true;
          case "due_at":
            {
              const date = new Date(value);
              if (!Number.isNaN(date.getTime())) {
                return true;
              }
            }
            return false;
          default: {
            const customField = getCustomFieldById(item.value);
            if (!customField) return false;

            switch (customField.type) {
              case "date": {
                const customDate = new Date(value);
                if (!Number.isNaN(customDate.getTime())) {
                  return true;
                }
                return false;
              }
              case "number": {
                const numberValue = Number(value);
                if (!Number.isNaN(numberValue)) {
                  return true;
                }
                return false;
              }
              default:
                return true;
            }
          }
        }
      });
    },
    [data, getCustomFieldById]
  );

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="jugl__custom-scrollbar flex w-full flex-col items-center overflow-y-auto text-center">
        <span className="text-dark font-secondary text-[22px] font-semibold leading-[42px]">
          {t({
            id: "tasks-page.align-excel-columns-with-board",
            defaultMessage: "Align Excel Columns with Board ones 🖇️",
          })}
        </span>
        <span className="text-dark font-secondary mt-3 text-sm leading-[21px] tracking-[0.14px]">
          {t({
            id: "tasks-page.map-excel-column-to-board-columns",
            defaultMessage:
              "Please map Excel columns to Existing Board columns.",
          })}
        </span>
        <div className="mt-2 flex items-center gap-1">
          <WarningIcon />
          <span className="text-dark-800 font-secondary text-xs leading-[18px] tracking-[0.12px]">
            {t({
              id: "tasks-page.not-mapped-excel-columns-will-not-be-imported",
              defaultMessage: "Not mapped Excel columns will not be imported.",
            })}
          </span>
        </div>
        <div className="mt-12 flex w-full items-center justify-between">
          <div className="bg-secondary-50 text-secondary-700 font-secondary rounded-2xl py-1.5 px-2.5 text-xs font-medium leading-[18px] tracking-[0.12px]">
            {t({
              id: "tasks-page.excel-columns",
              defaultMessage: "Excel Columns",
            })}
          </div>
          <div className="bg-primary-50 text-primary-700 font-secondary rounded-2xl py-1.5 px-2.5 text-xs font-medium leading-[18px] tracking-[0.12px]">
            {t({
              id: "tasks-page.jugl-columns",
              defaultMessage: "Jugl Columns",
            })}
          </div>
        </div>
        <div className="mt-2 flex h-full w-full flex-col gap-6">
          {data[0].map((el, idx) => {
            if (idx === taskNameColumn) return null;
            const selectedItem = mappedColumns.find(
              (item) => item.column === idx
            );

            const isValueMatch = selectedItem
              ? checkIsValueMatch(selectedItem)
              : undefined;

            const shouldShowValueNotMatchWarning =
              selectedItem && !isValueMatch;

            return (
              <Tooltip
                key={+idx}
                className="!z-[2000]"
                placement="right"
                delay="none"
                isDisabled={!shouldShowValueNotMatchWarning}
                renderTrigger={({ props, ref }) => (
                  <div
                    ref={ref}
                    {...props}
                    className="flex w-full min-w-0 items-center gap-4"
                  >
                    <div
                      className={cx(
                        "bg-grey-100 text-dark font-secondary flex h-12 grow basis-0 items-center rounded-lg px-6 text-sm font-medium leading-[140%]",
                        shouldShowValueNotMatchWarning && "bg-tertiary-50"
                      )}
                    >
                      {el?.toString()}
                    </div>
                    <ArrowFlowIcon
                      className={cx(
                        "shrink-0 transition-colors",
                        selectedItem
                          ? shouldShowValueNotMatchWarning
                            ? "text-gradients-danger"
                            : "text-messages-chatBubbleSender"
                          : "text-grey-400"
                      )}
                    />
                    <ResourcePickerPopover
                      items={getPickerItems(selectedItem?.value)}
                      defaultSelectedIds={
                        selectedItem ? [selectedItem.value] : undefined
                      }
                      placement="bottom"
                      className="z-[2000]"
                      renderLabel={(item) => item.value.label}
                      renderStartIcon={(item) => item.value.icon}
                      maxVisibleItems={5}
                      selectionBehavior={{ mode: "single", canToggle: true }}
                      onSelect={({ isSelected, item, onClose }) => {
                        onMappedColumnsChange((prev) => {
                          if (selectedItem) {
                            const filteredArray = prev.filter(
                              (columnItem) => columnItem.column !== idx
                            );
                            if (isSelected) {
                              return [
                                ...filteredArray,
                                { column: idx, value: item.id },
                              ];
                            }
                            return filteredArray;
                          }
                          return [...prev, { column: idx, value: item.id }];
                        });
                        onClose();
                      }}
                      renderTrigger={({ Trigger, triggerRef, isOpen }) => {
                        const elementDetails = selectedItem
                          ? getPickerItems(selectedItem.value).find(
                              (item) => item.id === selectedItem.value
                            )
                          : undefined;

                        return (
                          <Trigger
                            ref={triggerRef}
                            as={PickerButton}
                            className={cx(
                              "grow basis-0",
                              shouldShowValueNotMatchWarning &&
                                "bg-tertiary-50 hover:bg-tertiary-50"
                            )}
                            isOpen={isOpen}
                            hasDeleteIcon={!!elementDetails}
                            onDelete={() =>
                              onMappedColumnsChange((prev) =>
                                prev.filter((item) => item.column !== idx)
                              )
                            }
                          >
                            {elementDetails && (
                              <div className="flex w-full items-center gap-2">
                                {elementDetails.value.icon}
                                <span className="text-dark truncate text-sm">
                                  {elementDetails.value.label}
                                </span>
                              </div>
                            )}
                          </Trigger>
                        );
                      }}
                    />
                  </div>
                )}
              >
                {t({
                  id: "tasks-page.excel-column-value-does-not-match",
                  defaultMessage:
                    "Excel Column value doesn't match selected Field Type ❌",
                })}
              </Tooltip>
            );
          })}
        </div>
      </div>
      <div className="mt-12 flex items-center justify-center gap-4">
        <Button
          className="w-[200px]"
          color="grey"
          onClick={() => transitionTo({ name: "tasksName" })}
        >
          {t({
            id: "common.back",
            defaultMessage: "Back",
          })}
        </Button>
        <Button
          className="w-[200px]"
          onClick={() => transitionTo({ name: "importSettings" })}
        >
          {t({
            id: "common.next",
            defaultMessage: "Next",
          })}
        </Button>
      </div>
    </div>
  );
};
