import React, { ReactNode } from "react";
import { cx } from "@jugl-web/utils";

export const Section: React.FC<{
  isActive?: boolean;
  label: ReactNode;
  button: ReactNode;
  children?: React.ReactNode;
}> = ({ isActive, label, button, children }) => (
  <div
    className={cx("px-10 hover:bg-[#FAFAFA]", {
      "bg-[#FAFAFA]": isActive,
    })}
  >
    <div className="flex h-[72px] w-full items-center justify-between">
      <div className="font-secondary text-dark">{label}</div>
      {button}
    </div>
    {children}
  </div>
);
