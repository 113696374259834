import { useTaskBoards } from "@jugl-web/domain-resources/tasks/hooks/useTaskBoards";
import { useTaskFiltersStateContext } from "@jugl-web/domain-resources/tasks/hooks/useTaskFiltersState";
import { TaskBoardModel } from "@jugl-web/rest-api/tasks/models/TaskBoard";
import { BoardAvatar } from "@jugl-web/ui-components";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { useTranslations } from "@jugl-web/utils";
import { FC, useMemo } from "react";
import { ReactComponent as BoardIcon } from "../../assets/board.svg";
import { FilterSectionProps } from "../../types";
import { BoardFilterPill } from "../BoardFilterPill";
import { FilterSectionLayout } from "../FilterSectionLayout";

const WITHOUT_BOARD_ITEM_ID = "__none__";

export const BoardFilterSection: FC<FilterSectionProps> = ({ entityId }) => {
  const { boards } = useTaskBoards({ entityId });

  const { filtersState, updateFiltersState } = useTaskFiltersStateContext();

  const { t } = useTranslations();

  const boardsAsListItems = useMemo<ListBoxItem<TaskBoardModel | null>[]>(
    () => [
      { id: WITHOUT_BOARD_ITEM_ID, value: null },
      ...boards.map((b) => ({ id: b.id, value: b })),
    ],
    [boards]
  );

  return (
    <FilterSectionLayout>
      <FilterSectionLayout.Header
        title={t({
          id: "tasks-page.by-board-filter",
          defaultMessage: "By Board",
        })}
        endSlot={
          <ResourcePickerPopover
            placement="bottom-end"
            title={t({
              id: "tasks-page.select-board",
              defaultMessage: "Select board",
            })}
            items={boardsAsListItems}
            selectionBehavior={{ mode: "multiple" }}
            hasSearch
            defaultSelectedIds={filtersState.boards.map(
              (board) => board || WITHOUT_BOARD_ITEM_ID
            )}
            maxVisibleItems={5}
            itemSize="md"
            spaceBetweenItems="compact"
            renderLabel={(item) =>
              item.value
                ? item.value.name
                : t({
                    id: "tasks-page.without-board",
                    defaultMessage: "Without board",
                  })
            }
            renderStartIcon={(item) =>
              item.value ? (
                <BoardAvatar
                  size="md"
                  name={item.value.name}
                  color={item.value.color}
                />
              ) : (
                <BoardIcon />
              )
            }
            className="w-[315px]"
            renderTrigger={({ Trigger, triggerRef }) => (
              <Trigger ref={triggerRef} as={FilterSectionLayout.AddButton} />
            )}
            onSubmit={(ids) =>
              updateFiltersState(
                "boards",
                ids.map((id) => (id === WITHOUT_BOARD_ITEM_ID ? null : id))
              )
            }
          />
        }
      />
      {filtersState.boards.length > 0 && (
        <FilterSectionLayout.PillsContainer>
          {filtersState.boards.map((boardId) => (
            <BoardFilterPill
              key={boardId}
              entityId={entityId}
              boardId={boardId}
              onRemove={() =>
                updateFiltersState("boards", (previousBoards) =>
                  previousBoards.filter((b) => b !== boardId)
                )
              }
            />
          ))}
        </FilterSectionLayout.PillsContainer>
      )}
    </FilterSectionLayout>
  );
};
