import React, { useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import useEntity from "@web-src/features/app/hooks/useEntity";
import {
  AvatarSelect,
  AvatarSelectSize,
} from "@jugl-web/ui-components/web/AvatarSelect";
import { useRestApiProvider } from "@jugl-web/rest-api";
import {
  useFileSelect,
  avatarFileSelectConfig,
} from "@jugl-web/domain-resources/files/providers/FileSelectProvider";
import { cx, useTranslations } from "@jugl-web/utils";
import { useOnboarding } from "@web-src/modules/preferences/providers";
import { SpotlightTooltip } from "@jugl-web/ui-components/cross-platform/SpotlightTooltip";
import { AVATAR_VERSION_STORAGE_KEY } from "@jugl-web/utils/storage";
import { InteractiveContainer } from "@jugl-web/ui-components/cross-platform";
import { ReactComponent as ChevronIcon } from "./assets/chevron.svg";

const colorsClasses = {
  green: {
    backgroundColor: "bg-[rgba(34,197,158,0.1)]",
  },
  pink: {
    backgroundColor: "bg-[rgba(238,90,138,0.1)]",
  },
  purple: {
    backgroundColor: "bg-[rgba(171,89,228,0.1)]",
  },
};

const SidebarTile: React.FC<{
  listElement: {
    id: string;
    title: string;
    icon: React.ReactNode;
    color: keyof typeof colorsClasses;
    link: string;
  };
}> = ({ listElement }) => {
  const { entity } = useEntity();
  const match = useMatch(`/${entity?.id}/settings/${listElement.link}`);
  return (
    <NavLink
      to={`/${entity?.id}/settings/${listElement.link}`}
      className={({ isActive }) =>
        cx(
          "flex h-[68px] items-center justify-between rounded-[10px] bg-white px-6 text-lg font-medium text-black no-underline",
          isActive && "bg-primary text-white"
        )
      }
    >
      <div className="flex items-center gap-3">
        <div
          className={cx(
            "flex h-9 w-9 items-center justify-center rounded-full",
            match
              ? "bg-white"
              : colorsClasses[listElement.color].backgroundColor
          )}
        >
          {listElement.icon}
        </div>
        {listElement.title}
      </div>
      <ChevronIcon className={match ? "text-white" : "text-grey-500"} />
    </NavLink>
  );
};

const getAvatarVersion = () =>
  localStorage.getItem(AVATAR_VERSION_STORAGE_KEY) || "1";

const Sidebar: React.FC<{
  listElements: {
    id: string;
    title: string;
    icon: React.ReactNode;
    color: keyof typeof colorsClasses;
    link: string;
  }[];
}> = ({ listElements }) => {
  const { usersApi } = useRestApiProvider();
  const { data } = usersApi.useGetUserProfileQuery({});
  const [updateProfilePhoto] = usersApi.useUpdateUserProfilePictureMutation();
  const [avatarVersion, setAvatarVersion] = useState(getAvatarVersion());
  const { selectFile } = useFileSelect(avatarFileSelectConfig);
  const { t } = useTranslations();
  const {
    completeOnboardingStep,
    openSpotlight,
    openedSpotlight,
    isOnboardingActive,
  } = useOnboarding();
  const handleImageChange = (image?: File) => {
    updateProfilePhoto(image).finally(() => {
      if (image) {
        if (isOnboardingActive) {
          completeOnboardingStep("profile");
        }
        const newAvatarVersion = Math.random().toString();
        localStorage.setItem(AVATAR_VERSION_STORAGE_KEY, newAvatarVersion);
        setAvatarVersion(newAvatarVersion);
      }
    });
  };

  return (
    <div className="bg-grey-200 border-t-0.5 sticky top-0 h-full w-[400px] shrink-0 overflow-y-auto border-x-0 border-b-0 border-solid border-white p-8">
      <div className="relative mb-10 flex justify-center">
        <SpotlightTooltip
          isOpen={openedSpotlight === "profile"}
          placement="bottom"
          tip={t({
            id: "edit-profile-page.add-profile-picture-onboarding-hint",
            defaultMessage: "Tap here to add your Profile Picture 😍",
          })}
          showButton={false}
          renderTrigger={({ ref, props }) => (
            <InteractiveContainer
              ref={ref}
              {...props}
              onClick={() => openSpotlight(undefined)}
            >
              <AvatarSelect
                defaultSrc={
                  data?.general.img
                    ? `${data.general.img}?v=${avatarVersion}`
                    : undefined
                }
                onSave={handleImageChange}
                size={AvatarSelectSize.xxl}
                showRemoveButton
                externalSelectFile={selectFile}
              />
            </InteractiveContainer>
          )}
          onDismiss={() => openSpotlight(undefined)}
        />
      </div>
      <div className="flex w-full flex-col gap-2.5">
        {listElements.map((el) => (
          <SidebarTile key={el.id} listElement={el} />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
