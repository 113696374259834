import { FC } from "react";
import { DraggableTaskChecklistContainer } from "./DraggableTaskChecklistContainer";
import { EditingTaskChecklistItem } from "./EditingTaskChecklistItem";
import { useTaskChecklistContext } from "./TaskChecklistProvider";
import { TaskChecklistHandlers, TaskChecklistItem } from "./types";
import { SpecificOrderCompletionState } from "./utils";
import { ViewTaskChecklistItem } from "./ViewTaskChecklistItem";

export interface TaskChecklistItemProps {
  item: TaskChecklistItem;
  index: number;
  specificOrderCompletionState: SpecificOrderCompletionState;
  onUpdate: TaskChecklistHandlers["onUpdateItem"];
  onEditModeChange: (isEditing: boolean) => void;
  onDelete: (itemId: string) => void;
  isEditing?: boolean;
}

export const TaskChecklistItemComponent: FC<TaskChecklistItemProps> = ({
  item,
  index,
  specificOrderCompletionState,
  onUpdate,
  onEditModeChange,
  onDelete,
  isEditing,
}) => {
  const { isManageable, isCompleteInSpecificOrder } = useTaskChecklistContext();

  return (
    <DraggableTaskChecklistContainer
      id={item.id}
      index={index}
      isDragDisabled={isEditing || !isManageable || isCompleteInSpecificOrder}
    >
      {isEditing ? (
        <EditingTaskChecklistItem
          item={item}
          onSaveChanges={(updatedItem) => {
            onUpdate(updatedItem, index);
            onEditModeChange(false);
          }}
          onCancel={() => {
            onEditModeChange(false);
          }}
          onDelete={onDelete}
        />
      ) : (
        <ViewTaskChecklistItem
          item={item}
          index={index}
          specificOrderCompletionState={specificOrderCompletionState}
          onUpdate={(updatedItem) => onUpdate(updatedItem, index)}
          onToggleComplete={() =>
            onUpdate({ ...item, isCompleted: !item.isCompleted }, index)
          }
          onEnterEditMode={() => onEditModeChange(true)}
          onDelete={onDelete}
        />
      )}
    </DraggableTaskChecklistContainer>
  );
};
