import { useTranslations } from "@jugl-web/utils";
import React, { useCallback, useState } from "react";
import {
  Button,
  DroppableFileInput,
  DroppableFileInputProps,
  InteractiveContainer,
} from "@jugl-web/ui-components";
import { useScreenTransitionManager } from "@jugl-web/utils/utils/ScreenTransitionManager";
import { Row } from "read-excel-file";
import { downloadFile } from "@jugl-web/utils/utils/downloadFile";
import { TasksImportScreenToParametersMap } from "../../types";
import { useParseFile } from "../../hooks/useParseFile";
import { ReactComponent as ExcelIcon } from "./assets/excel.svg";

export const FileUploadScreen: React.FC<{
  inputValue?: DroppableFileInputProps["value"];
  onInputValueChange: React.Dispatch<
    React.SetStateAction<DroppableFileInputProps["value"] | undefined>
  >;
  onReset: () => void;
  onReadFile: (data: Row[]) => void;
}> = ({ inputValue, onInputValueChange, onReadFile, onReset }) => {
  const { t } = useTranslations();
  const { transitionTo } =
    useScreenTransitionManager<TasksImportScreenToParametersMap>();
  const { parseFile } = useParseFile();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleReadFile = useCallback(
    async (uploadedFile?: File) => {
      const isValidFileType = (fileType: string) => {
        const allowedFileTypes = [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
        ];
        return allowedFileTypes.includes(fileType);
      };

      const invalidFileState: DroppableFileInputProps["value"]["state"] = {
        type: "error",
        title: t({
          id: "tasks-page.incorrect-file-format",
          defaultMessage: "Incorrect file format ❌",
        }),
        info: t({
          id: "tasks-page.please-upload-xls-or-csv-file-only",
          defaultMessage: "Please upload XLS, XLSX or CSV file only",
        }),
      };

      const successFileState: DroppableFileInputProps["value"]["state"] = {
        type: "success",
        title: t({
          id: "tasks-page.file-successfully-uploaded",
          defaultMessage: "File successfully uploaded ✅",
        }),
      };

      const corruptedFileState: DroppableFileInputProps["value"]["state"] = {
        type: "error",
        title: t({
          id: "tasks-page.cant-read-file",
          defaultMessage: "Can't read the file ❌",
        }),
        info: t({
          id: "tasks-page.file-is-corrupted-or-empty-message",
          defaultMessage:
            "Your file is corrupted or empty. Please upload different XLS, XLSX or CSX file",
        }),
      };

      setIsProcessing(true);
      onReset();
      onInputValueChange((prev) => ({ ...prev, file: uploadedFile }));

      if (!uploadedFile) {
        setIsProcessing(false);
        return;
      }

      if (!isValidFileType(uploadedFile.type)) {
        onInputValueChange((prev) => ({
          ...prev,
          state: invalidFileState,
        }));
        setIsProcessing(false);
        return;
      }

      try {
        const data = await parseFile(uploadedFile);
        onReadFile(data);
        onInputValueChange((prev) => ({
          ...prev,
          state: successFileState,
        }));
      } catch {
        onInputValueChange((prev) => ({
          ...prev,
          state: corruptedFileState,
        }));
      } finally {
        setIsProcessing(false);
      }
    },
    [parseFile, onReadFile, t, onReset, onInputValueChange]
  );

  return (
    <div className="flex h-full flex-col items-center text-center">
      <span className="text-dark font-secondary text-[22px] font-semibold leading-[42px]">
        {t(
          {
            id: "tasks-page.import-tasks-from-excel-to-board",
            defaultMessage: "Import Tasks from Excel file to Your Board {icon}",
          },
          {
            icon: <ExcelIcon />,
          }
        )}
      </span>
      <InteractiveContainer
        className="text-primary font-primary mt-3 text-base leading-4 underline"
        onClick={() =>
          downloadFile("/import-sample.xlsx", "Import sample.xlsx")
        }
      >
        {t({
          id: "tasks-page.download-sample-file",
          defaultMessage: "Download a Sample File",
        })}
      </InteractiveContainer>
      <div className="mt-12 mb-10 w-full">
        <DroppableFileInput
          onUpdate={handleReadFile}
          value={{ ...inputValue }}
          isProcessing={isProcessing}
        />
      </div>
      <Button
        className="mt-auto h-10 w-[300px]"
        onClick={() => transitionTo({ name: "tasksName" })}
        isDisabled={inputValue?.state?.type !== "success"}
      >
        {t({
          id: "common.next",
          defaultMessage: "Next",
        })}
      </Button>
    </div>
  );
};
