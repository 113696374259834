import { taskDefaultStatusColorById } from "@jugl-web/domain-resources/tasks";
import { TaskStatusDetails } from "@jugl-web/domain-resources/tasks/hooks/useDefaultTaskStatuses";
import { useTaskStatuses } from "@jugl-web/domain-resources/tasks/hooks/useTaskStatuses";
import { TaskDefaultStatus } from "@jugl-web/rest-api/tasks";
import { ListBoxItem } from "@jugl-web/ui-components/cross-platform/ListBox";
import { PILL_BASE_TEXT_COLOR } from "@jugl-web/ui-components/cross-platform/tasks/TaskCardPill";
import { ResourcePickerPopover } from "@jugl-web/ui-components/web/ResourcePickerPopover";
import { FC, useMemo } from "react";
import { ReactComponent as CompletedCheckIcon } from "../../assets/completed-check.svg";
import { TaskTableCellComponentProps } from "../../types";
import { TaskTableLayout } from "../TaskTableLayout";

export const StatusCell: FC<TaskTableCellComponentProps> = ({
  task,
  entityId,
  isFutureTask,
  permissions,
  cellProps,
  onUpdate,
}) => {
  const { allStatuses, getStatusDetailsById } = useTaskStatuses({ entityId });

  const statusesAsListItems = useMemo<ListBoxItem<TaskStatusDetails>[]>(
    () => allStatuses.map((status) => ({ id: status.id, value: status })),
    [allStatuses]
  );

  const status = getStatusDetailsById(task.status);

  const isEditable = permissions.canEditStatus && !isFutureTask;
  const isValueHidden = isFutureTask;

  return (
    <ResourcePickerPopover
      placement="bottom"
      className="w-[375px]"
      renderTrigger={({ Trigger, triggerRef, isOpen }) => (
        <Trigger
          ref={triggerRef}
          as={TaskTableLayout.Cell}
          isFocused={isOpen}
          isHoverable
          isDisabled={!isEditable}
          className="p-px"
          {...cellProps}
        >
          {!isValueHidden && (
            <TaskTableLayout.ColoredCellContent
              text={
                <span className="flex items-center gap-1">
                  {status.label}
                  {status.id === TaskDefaultStatus.completed && (
                    <CompletedCheckIcon />
                  )}
                </span>
              }
              textColor={
                taskDefaultStatusColorById[status.id as TaskDefaultStatus] ||
                PILL_BASE_TEXT_COLOR
              }
            />
          )}
        </Trigger>
      )}
      items={statusesAsListItems}
      selectionBehavior={{ mode: "single", canToggle: false }}
      hasSearch
      defaultSelectedIds={[task.status]}
      maxVisibleItems={5}
      itemSize="md"
      spaceBetweenItems="compact"
      renderLabel={(item) => item.value.label}
      onSelect={({ item, onClose }) => {
        onUpdate({ status: item.value.id });
        onClose();
      }}
    />
  );
};
